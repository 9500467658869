import {
  ConfigTypes as CONFIG,
  ApiTypes as API,
  HouseholdTypes,
} from '../actions';
import { ConvertObjectToArray } from '../../utils';

const initialState = {
  loadedConfigs: false,
  loadedHouseholdFields: false,
  currencies: [],
  countries: [],
  allMemberCategories: [],
  memberCategories: [],
  memberCategoriesUser: [],
  memberGenderOptions: [],
  memberGenderOptionsUser: [],
  entityCategories: [],
  entityCategoriesUser: [],
  systemProfessionalCategories: [],
  professionalCategories: [],
  instrumentDateReference: [],
  allInstrumentCategories: [],
  incomeCategories: [],
  assetCategories: [],
  assetInstrumentChoices: [],
  assetRiskPreferences: [],
  assetSubcategories: [],
  liabilityCategories: [],
  liabilitySubcategories: [],
  insuranceCategories: [],
  insuranceAmountPeriod: [],
  insuranceBenefitPeriodTypes: [],
  insurancePlannedPremiumIntervals: [],
  insuranceSubcategories: [],
  instrumentCategories: [],
  milestonesOptions: [],
  autoLogoutChoices: [],
  householdPageChoices: [],
  configError: {},
  selectedCurrencyObj: {},
  googleAnalyticsId: null,
  recurlyKey: null,
  environment: null,
  version: null,
  SSORedirectURL: null,
  discoveryDurationChoices: [],
  legalCategories: [],
};

const configs = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG.GET_CONFIGS:
      return {
        ...state,
        loadedConfigs: true,
        currencies: ConvertObjectToArray(action.payload?.currency),
        countries: ConvertObjectToArray(action.payload?.country),
        indexFields: ConvertObjectToArray(action.payload?.index?.fields, true),
        instrumentCategories: action.payload?.instrument?.category,
        milestonesOptions: action.payload?.milestone,
        autoLogoutChoices: action.payload?.auto_logout_choices,
        householdPageChoices: action.payload?.household_landing_page_choices,
        reportTypes: action.payload?.report?.available,
        pagination: action.payload?.pagination,
        intercomId: action.payload?.third_party?.intercom?.app_id || null,
        googleAnalyticsId:
          action.payload?.third_party?.google?.analytics_id || null,
        recurlyKey: action.payload?.third_party?.recurly?.key || null,
        environment: action.payload?.site?.environment || null,
        dashboardBackground:
          action.payload?.site?.backgrounds?.member_dashboard,
        addHouseholdBackground:
          action.payload?.site?.backgrounds?.add_household,
        discoveryDurationChoices: action.payload?.discovery_duration_choices,
        systemMemberCategories: action.payload?.householdmember?.category,
        systemProfessionalCategories:
          action.payload?.householdprofessional?.category,
        legalCategories: action.payload?.householdlegal?.category,
      };
    case CONFIG.GET_SYSTEM_FIELDS:
      console.log(action);
      return {
        ...state,
        allFields: action.payload,
        memberCategories: action.payload?.Member__member__category,
        memberGenderOptions: action.payload?.Member__member__gender,
        entityCategories: action.payload?.Member__entity__category,
        professionalCategories:
          action.payload?.HouseholdProfessional__professional__category,
        allMemberCategories: [
          ...action.payload?.Member__member__category,
          ...action.payload?.Member__entity__category,
        ],
        instrumentDateReference:
          action.payload?.Instrument__any__date_reference,
        allInstrumentCategories: [
          ...action.payload?.Instrument__income__category,
          ...action.payload?.Instrument__asset__category,
          ...action.payload?.Instrument__liability__category,
          ...action.payload?.Instrument__insurance__category,
        ],
        incomeCategories: action.payload?.Instrument__income__category,
        assetCategories: action.payload?.Instrument__asset__category,
        assetInstrumentChoices:
          action.payload?.Instrument__asset__instrument_choice,
        assetRiskPreferences:
          action.payload?.Instrument__asset__risk_preference,
        assetSubcategories: action.payload?.Instrument__asset__subcategory,
        liabilityCategories: action.payload?.Instrument__liability__category,
        liabilitySubcategories:
          action.payload?.Instrument__liability__subcategory,
        insuranceCategories: action.payload?.Instrument__insurance__category,
        insuranceAmountPeriod:
          action.payload?.Instrument__insurance__amount_period,
        insuranceBenefitPeriodTypes:
          action.payload?.Instrument__insurance__benefit_period_type,
        insurancePlannedPremiumIntervals:
          action.payload?.Instrument__insurance__planned_premium_interval,
        insuranceSubcategories:
          action.payload?.Instrument__insurance__subcategory,
      };
    case CONFIG.GET_USER_FIELDS:
      return {
        ...state,
        memberCategoriesUser: action.payload?.Member__member__category,
        memberGenderOptionsUser: action.payload?.Member__member__gender,
        entityCategoriesUser: action.payload?.Member__entity__category,
      };
    case CONFIG.GET_HOUSEHOLD_FIELDS:
      return {
        ...state,
        loadedHouseholdFields: true,
        allFields: action.payload,
        reports: action.payload?.reports,
        memberCategories: action.payload?.fields?.Member__member__category,
        memberGenderOptions: action.payload?.fields?.Member__member__gender,
        entityCategories: action.payload?.fields?.Member__entity__category,
        allMemberCategories: [
          ...action.payload?.fields?.Member__member__category,
          ...action.payload?.fields?.Member__entity__category,
          ...(action.payload?.fields
            ?.HouseholdProfessional__professional__category
            ? action.payload?.fields
                ?.HouseholdProfessional__professional__category
            : []),
        ],
        professionalCategories:
          action.payload?.fields?.HouseholdProfessional__professional__category,
        instrumentDateReference:
          action.payload?.fields?.Instrument__any__date_reference,
        allInstrumentCategories: [
          ...action.payload?.fields?.Instrument__income__category,
          ...action.payload?.fields?.Instrument__asset__category,
          ...action.payload?.fields?.Instrument__liability__category,
          ...action.payload?.fields?.Instrument__insurance__category,
        ],
        incomeCategories: action.payload?.fields?.Instrument__income__category,
        assetCategories: action.payload?.fields?.Instrument__asset__category,
        assetInstrumentChoices:
          action.payload?.fields?.Instrument__asset__instrument_choice,
        assetRiskPreferences:
          action.payload?.fields?.Instrument__asset__risk_preference,
        assetSubcategories:
          action.payload?.fields?.Instrument__asset__subcategory,
        liabilityCategories:
          action.payload?.fields?.Instrument__liability__category,
        liabilitySubcategories:
          action.payload?.fields?.Instrument__liability__subcategory,
        insuranceCategories:
          action.payload?.fields?.Instrument__insurance__category,
        insuranceAmountPeriod:
          action.payload?.fields?.Instrument__insurance__amount_period,
        insuranceBenefitPeriodTypes:
          action.payload?.fields?.Instrument__insurance__benefit_period_type,
        insurancePlannedPremiumIntervals:
          action.payload?.fields
            ?.Instrument__insurance__planned_premium_interval,
        insuranceSubcategories:
          action.payload?.fields?.Instrument__insurance__subcategory,
      };
    case CONFIG.GET_GLOBAL_CONFIGS:
      return {
        ...state,
        globalConfigs: action.payload,
        authBackground: action.payload?.site?.backgrounds?.login,
        SSORedirectURL: action.payload?.site?.sso_url,
      };
    case CONFIG.GET_SYSTEM_VERSION:
      return {
        ...state,
        version: action.payload?.version,
      };
    case CONFIG.SET_CURRENCY:
      return {
        ...state,
        selectedCurrencyObj: action.payload,
      };
    case CONFIG.SET_INVOCATION_ID:
      return {
        ...state,
        invocationId: action.payload,
      };
    case HouseholdTypes.REMOVE_HOUSEHOLD_INFO:
      return {
        ...state,
        loadedHouseholdFields: false,
      };
    case API.API_ERROR:
      switch (action.payload.label) {
        case CONFIG.GET_CONFIGS:
          return {
            ...state,
            configError: action.payload.error,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default configs;
