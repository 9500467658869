import { useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Button, Error, GenerateInput, InfoBox } from '..';
import { colors, fonts } from '../../styles/variables';
import { timesWhite, trash, noComments } from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  SmallerModalContainer,
  ModalContentContainer,
} from '../../styles/library/modalStyles';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';

const SignalCommentsModal = ({
  hide,
  isLoading,
  snooze = false,
  signal = {},
  inputs,
  content,
  deleteOnClick,
  errors,
}) => {
  const ref = useRef();
  let commentsPlaceholder = [];
  commentsPlaceholder = [...Array(signal?.comments)];
  commentsPlaceholder = commentsPlaceholder.map((x, i) => i);

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <SmallerModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>
              {snooze ? `Snooze ${signal.label}` : `${signal.label} Comments`}{' '}
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            {snooze ? (
              <>
                <div>
                  {/* {GenerateSignalIcon(signal.key)} */}
                  <ModalText>
                    You are about to snooze a system generated Signal.
                  </ModalText>
                  {/* <ModalSubText>
                    Please leave a comment below to document why this signal was
                    snoozed.
                  </ModalSubText> */}
                </div>
              </>
            ) : isLoading ? (
              <CommentsListContainer>
                {commentsPlaceholder.length !== 0 ? (
                  commentsPlaceholder.map((index) => {
                    return (
                      <CommentContainer key={index} last={true}>
                        <CommentDate>
                          <LoadingPlaceholder width="85px" />
                          {/* <LoadingPlaceholder width="18px" round={true} /> */}
                        </CommentDate>
                        <LoadingPlaceholder width="100%" />
                        <LoadingPlaceholder width="100%" />
                        <LoadingPlaceholder width="100%" />
                      </CommentContainer>
                    );
                  })
                ) : (
                  <NoCommentsContainer>
                    <img
                      src={noComments}
                      alt="comment"
                      data-image="no-comments"
                    />
                    <NoCommentsText>No Comments</NoCommentsText>
                  </NoCommentsContainer>
                )}
              </CommentsListContainer>
            ) : (
              <CommentsListContainer>
                {signal?.commentsList && signal?.commentsList.length !== 0 ? (
                  signal.commentsList.map((comment, index) => {
                    return (
                      <CommentContainer
                        key={index}
                        last={index === signal?.commentsList.length - 1}
                      >
                        <CommentDate>
                          <span>
                            {comment.date} {comment.snoozed && '(Snoozed)'}
                          </span>
                          <DeleteIcon
                            src={trash}
                            alt="delete"
                            data-image={`trash-${index}`}
                            onClick={() => deleteOnClick(comment)}
                          />
                        </CommentDate>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: comment.value,
                          }}
                        />
                      </CommentContainer>
                    );
                  })
                ) : (
                  <NoCommentsContainer>
                    <img
                      src={noComments}
                      alt="comment"
                      data-image="no-comments"
                    />
                    <NoCommentsText>No Comments</NoCommentsText>
                  </NoCommentsContainer>
                )}
              </CommentsListContainer>
            )}
            {inputs &&
              inputs.length !== 0 &&
              inputs.map((input, index) => {
                return GenerateInput(input, index);
              })}
            {snooze && (
              <InfoBox
                margin="10px 0 10px 0"
                fontSize="11px"
                message="This signal will stay snoozed for the selected duration but
                    you can always wake the signal at any point."
              />
            )}
            {content.buttons && content.buttons.length !== 0 && (
              <ButtonsRowContainer>
                {content.buttons.map((button, index) => {
                  return (
                    <ButtonContainer
                      key={index}
                      style={index === 1 ? { marginLeft: '10px' } : null}
                    >
                      <Button
                        text={button.text}
                        showLoading={button.showLoading}
                        loadingText={button.loadingText}
                        onClick={button.function}
                        theme={button.theme}
                      />
                    </ButtonContainer>
                  );
                })}
              </ButtonsRowContainer>
            )}
          </ModalContentContainer>
          {errors && errors !== '' && <Error errorMessage={errors} />}
        </SmallerModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const CommentsListContainer = styled.div`
  /* border: 1px solid ${colors.lightGrey}; */
  /* border-radius: 3px; */
  min-height: 150px;
  max-height: 250px;
  overflow-y: auto;
  text-align: left;
  margin-bottom: 15px;
`;

const DeleteIcon = styled.img`
  width: 11px;
  height: 11px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`;

const CommentDate = styled.p`
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  margin-bottom: 3px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  span {
    margin-right: 5px;
  }
`;

const CommentContainer = styled.div`
  border-bottom: ${(props) =>
    props.last ? '1px solid transparent' : `1px solid ${colors.lightGrey}`};
  font-size: 12px;
  padding: 12px 8px;
  &:hover {
    ${DeleteIcon} {
      opacity: 1;
    }
  }
`;

const NoCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    max-width: 180px;
    max-height: 125px;
  }
`;

const NoCommentsText = styled.p`
  margin-top: 5px;
  font-weight: ${fonts.bold};
  font-size: 16px;
  color: ${colors.paleGrey};
`;

const ModalText = styled.p`
  font-size: 13px;
  margin: 5px 0 15px 0;
  font-weight: ${fonts.semiBold};
  color: ${colors.darkGrey};
`;

// const ModalSubText = styled.p`
//   font-size: 10px;
//   margin-bottom: 15px;
//   color: ${colors.paleGrey};
// `;

const ButtonContainer = styled.div``;

const ButtonsRowContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  ${ButtonContainer} {
    flex: 1 1 50%;
    button {
      width: 100%;
    }
  }
`;

export default SignalCommentsModal;
