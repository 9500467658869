/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TargetMapPercentCircle, LoadingAnimation } from '../';
import {
  bullseye,
  bullseyeSolid,
  cancel,
  chevronRight,
  copy,
  ellipsisH,
  errorCircleRed,
  gripLines,
  members,
  pencil,
  // swap,
  // trashDark,
} from '../../assets';
import {
  maxDevice,
  colors,
  boxShadows,
  fonts,
  inputColors,
  messageColors,
} from '../../styles/variables';
import {
  HtmlToString,
  IsNegative,
  FormatCurrency,
  UseOutsideClick,
  UseDoubleClick,
  DetectEnterKeyPress,
  SendTrackingEvent,
} from '../../utils';
import {
  FlexCenterAll,
  FlexCenterEnd,
  FlexCenterStart,
} from '../../styles/library/layoutStyles';
import {
  cloneTargetMap,
  updateTargetMap,
  updateTargetMapSummaryValue,
  updateUserStoreValue,
} from '../../store/actions';
import { TooltipIconError } from '../../styles/library/inputStyles';
import { Draggable } from 'react-beautiful-dnd';

const TargetMapCompare = ({
  householdId,
  tm,
  index,
  removeTargetMap,
  showClone,
  error,
  currentHousehold,
}) => {
  const ref = useRef();
  const inputRef = useRef();
  const nameRef = useRef();
  const memberRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currency, invocationId } = useSelector((state) => ({
    currency: state.households.householdCurrency,
    invocationId: state.configs.invocationId,
  }));
  const [targetMap, setTargetMap] = useState({ ...tm });
  const [membersList, setMembersList] = useState([{ name: 'No Members' }]);
  const [editingName, setEditingName] = useState(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [showMembersMenu, setShowMembersMenu] = useState(false);
  const [updatingTargetMap, setUpdatingTargetMap] = useState(false);

  UseOutsideClick(ref, () => {
    if (showMoreMenu) {
      setShowMoreMenu(false);
    }
  });

  UseOutsideClick(memberRef, () => {
    if (showMembersMenu) {
      setShowMembersMenu(false);
    }
  });

  UseDoubleClick({
    onSingleClick: (e) => {
      // return actionOnClick({ id: 'details' });
      return setEditingName(!editingName);
    },
    onDoubleClick: (e) => {
      return setEditingName(!editingName);
    },
    ref: nameRef,
  });

  useEffect(() => {
    if (error) {
      setUpdatingTargetMap(false);
    }
  }, [tm]);

  useEffect(() => {
    if (tm) {
      if (tm?.members?.length) {
        setMembersList(tm?.members);
      }
      if (error) {
        setTargetMap({
          ...tm,
          capital_required: -Math.abs(tm.capital_required),
          contribution_needed: Math.abs(tm.contribution_needed),
          return_rate: error.return_rate
            ? targetMap.return_rate
            : tm.return_rate,
          inflation_rate: error.inflation_rate
            ? targetMap.inflation_rate
            : tm.inflation_rate,
          duration: error.duration ? targetMap.duration : tm.duration,
        });
      } else {
        setTargetMap({
          ...tm,
          capital_required: -Math.abs(tm.capital_required),
          contribution_needed: Math.abs(tm.contribution_needed),
        });
      }
      setUpdatingTargetMap(false);
    }
  }, [tm]);

  const actionOnClick = (action) => {
    if (action.id === 'details') {
      dispatch(updateUserStoreValue('inCompareMode', true));
      SendTrackingEvent(invocationId, 'action', 'targetmap_details', null, {
        targetmap: targetMap.id,
        in_compare: true,
      });
      return navigate(`/household/${householdId}/targetmap/${targetMap.id}`);
    }
    if (action.id === 'remove') {
      return removeTargetMap(targetMap.id);
    }
    if (action.id === 'clone') {
      dispatch(cloneTargetMap(householdId, targetMap.id));
    }
  };

  const actions = [
    {
      id: 'details',
      label: 'Edit',
      icon: pencil,
    },
    { id: 'clone', label: 'Clone', icon: copy, hidden: !showClone },
    // {
    //   id: 'swap',
    //   label: 'Swap',
    //   icon: swap,
    // },
    { id: 'remove', label: 'Remove', icon: cancel },
    // {
    //   id: 'delete',
    //   label: 'Delete',
    //   icon: trashDark,
    // },
  ];

  const fields = [
    {
      name: 'return_rate',
      label: 'Expected Net Return on Capital',
      type: 'input',
      suffix: '%',
      showArrows: true,
    },
    {
      name: 'capital_net',
      label: 'Present Capital Deficit',
      type: 'currency',
    },
    {
      name: 'contribution_needed',
      label: 'Additional Contributions to Fund',
      type: 'currency',
      period: '/mo',
      checkAvailable: true,
    },
    {
      name: 'inflation_rate',
      label: 'Annual Increase of Contributions',
      type: 'input',
      suffix: '%',
      showArrows: true,
    },
    {
      name: 'duration',
      label: 'Duration of Contributions',
      type: 'input',
      suffix: 'yrs',
      inputWidth: '45px',
      showArrows: false,
    },
    {
      name: 'capital_required',
      label: 'Capital Required (Present Value)',
      type: 'currency',
    },
    {
      name: 'want',
      label: '',
      type: 'want',
    },
  ];

  const transformMember = (memberId) => {
    const matchedMember = currentHousehold?.householdmembers?.find(
      (mem) => mem.id === memberId
    );
    if (matchedMember) {
      matchedMember.name = HtmlToString(matchedMember.name);
    }
    return matchedMember;
  };

  const generateDisplayLabel = (field) => {
    if (field.name === 'capital_net') {
      return IsNegative(targetMap.capital_net)
        ? 'Present Capital Deficit'
        : 'Present Capital Surplus';
    }
    if (field.name === 'contribution_needed') {
      return IsNegative(targetMap.capital_net)
        ? 'Additional Contributions to Fund'
        : 'Surplus Contributions';
    }
    if (field.name === 'want') {
      const memberObj = transformMember(tm.member_want?.member);
      return (
        tm?.member_want && (
          <SummaryDataLabel>
            {tm.member_want?.end_date && 'Starting at '}
            {memberObj && <span>{memberObj?.name}</span>} age{' '}
            {tm.member_want?.start_age}
          </SummaryDataLabel>
        )
      );
      //  : (
      //   <SummaryDataLabel>No Active Want</SummaryDataLabel>
      // );
    }
    return field.label;
  };

  const fieldOnChange = (field, value) => {
    if (!isNaN(value) && value) {
      setTargetMap({
        ...targetMap,
        [field]: value,
      });
    }
  };

  const fieldOnUpdate = (field, value, increment = false, isIncrease) => {
    if (!updatingTargetMap) {
      //Check if value is different
      const current = parseInt(tm[field]);
      const changedValue = current !== parseInt(value);
      if (value) {
        let updatedValue = value;
        if (increment) {
          let parsed = parseInt(value);
          if (isIncrease) {
            updatedValue = parsed + 1;
          } else {
            updatedValue = parsed - 1;
          }
        } else if (!changedValue) {
          return;
        }
        fieldOnChange(field, updatedValue);
        setUpdatingTargetMap(true);
        SendTrackingEvent(invocationId, 'action', 'targetmap_edit', null, {
          targetmap: targetMap.id,
          [field]: updatedValue,
          in_compare: true,
        });
        dispatch(
          updateTargetMap(
            householdId,
            targetMap.id,
            { [field]: updatedValue },
            false
          )
        );
      }
    }
  };

  const saveTargetMapName = () => {
    const newName = targetMap.name.trim();
    const changedName = tm?.name !== newName;
    if (changedName) {
      setEditingName(false);
      return fieldOnUpdate('name', newName);
    }
    setEditingName(false);
  };

  const updateTargetMapName = (value) => {
    if (value) {
      setTargetMap({
        ...targetMap,
        name: value,
      });
    }
  };

  const updateTargetMapActive = () => {
    dispatch(
      updateTargetMapSummaryValue(
        householdId,
        targetMap.id,
        {
          active: !targetMap.active,
        },
        `Target-Map ${targetMap.active ? 'Inactive' : 'Active'}`
      )
    );
    SendTrackingEvent(invocationId, 'click', 'targetmap_active', null, {
      targetmap: targetMap.id,
      active: !targetMap.active,
      in_compare: true,
    });
  };

  const generateErrorIcon = (input) => {
    return (
      <ToolTipContainer>
        <Tooltip
          title={input.errorMessage}
          position="top"
          trigger="mouseenter click"
          followCursor="true"
          delay={0}
          arrow={true}
          arrowSize={'small'}
          animation={'fade'}
          animateFill={false}
          transitionFlip={false}
          size={'small'}
        >
          <TooltipIconError
            src={errorCircleRed}
            alt="error"
            data-image={`tooltip-error-${input.name}`}
          />
        </Tooltip>
      </ToolTipContainer>
    );
  };

  const checkAvailable = (field) => {
    return (
      field.checkAvailable &&
      Math.abs(Math.round(targetMap.contribution_needed)) ===
        Math.abs(Math.round(targetMap.capital_net))
    );
  };

  const linkOnClick = (action) => {
    dispatch(updateUserStoreValue('inCompareMode', true));
    SendTrackingEvent(invocationId, 'action', 'targetmap_details', null, {
      targetmap: targetMap.id,
      in_compare: true,
    });
    return true;
  };

  return (
    <Draggable draggableId={targetMap.id} index={index}>
      {(provided) => (
        <TargetMapContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <TopContent>
            <ActionBar>
              {updatingTargetMap && (
                <AnimationContainer>
                  <LoadingAnimation dots={true} color={targetMap.color} />
                </AnimationContainer>
              )}
              <FixedIcon
                src={gripLines}
                alt="grip"
                data-image={`grip-${targetMap.id}`}
                title={'Drag to reorder'}
                {...provided.dragHandleProps}
              />
              <MenuIcon
                src={targetMap.active ? bullseyeSolid : bullseye}
                alt="members"
                $width={targetMap.active ? '17px' : '16px'}
                $margin="0 10px 0 0"
                onClick={updateTargetMapActive}
              />
              <MenuIcon
                src={members}
                alt="members"
                $width="20px"
                $margin="0 10px 0 0"
                onClick={() => setShowMembersMenu(!showMembersMenu)}
              />
              <MenuIcon
                src={ellipsisH}
                alt="more"
                onClick={() => setShowMoreMenu(!showMoreMenu)}
              />
              {showMoreMenu && (
                <DropdownContainer ref={ref}>
                  <DropdownHeading>Actions</DropdownHeading>
                  <DropDownContent>
                    {actions.map((action, index) => {
                      return (
                        !action.hidden && (
                          <DropdownItem
                            key={index}
                            onClick={() => actionOnClick(action)}
                            $clickable={true}
                          >
                            {action.icon && (
                              <DropdownItemImage
                                src={action.icon}
                                alt={action.label}
                              />
                            )}
                            <DropdownItemText>{action.label}</DropdownItemText>
                          </DropdownItem>
                        )
                      );
                    })}
                  </DropDownContent>
                </DropdownContainer>
              )}
              {showMembersMenu && (
                <DropdownContainer ref={memberRef} $right="30px" $width="110px">
                  <DropdownHeading>Members</DropdownHeading>
                  <DropDownContent>
                    {membersList.map((member, index) => {
                      return (
                        <DropdownItem key={index}>
                          <DropdownItemText>{member.name}</DropdownItemText>
                        </DropdownItem>
                      );
                    })}
                  </DropDownContent>
                </DropdownContainer>
              )}
            </ActionBar>
            <Link
              to={`/household/${householdId}/targetmap/${targetMap.id}`}
              onClick={linkOnClick}
            >
              <TargetMapPercentCircle
                targetMap={targetMap}
                emptyColor={colors.white}
              />
            </Link>
            {/* <NameContainer>{HtmlToString(targetMap.name)}</NameContainer> */}
            <NameContainer>
              <LabelInput
                ref={inputRef}
                id="test"
                type="text"
                value={targetMap.name}
                name="name"
                autoFocus={true}
                onKeyPress={(e) => DetectEnterKeyPress(e, saveTargetMapName)}
                onChange={(e) => updateTargetMapName(e.currentTarget.value)}
                onBlur={() => saveTargetMapName()}
                visible={editingName}
              />
              <LabelType
                ref={nameRef}
                visible={!editingName}
                title={HtmlToString(targetMap.name)}
              >
                {HtmlToString(targetMap.name)}
              </LabelType>
            </NameContainer>
          </TopContent>
          <BottomContent>
            {fields.map((field, index) => {
              return (
                <FieldLineItem key={index} $last={fields.length - 1 === index}>
                  <FieldLabel>{generateDisplayLabel(field)}</FieldLabel>
                  {field.type === 'input' ? (
                    <FieldValue>
                      {error[field.name] &&
                        generateErrorIcon({
                          name: field.name,
                          errorMessage: error[field.name],
                        })}
                      <FieldInput
                        $width={field.inputWidth}
                        $hasError={error[field.name]}
                        type="number"
                        value={targetMap[field.name]}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) =>
                          fieldOnChange(field.name, e.currentTarget.value)
                        }
                        onBlur={(e) =>
                          fieldOnUpdate(field.name, e.currentTarget.value)
                        }
                      />
                      <span>{field.suffix}</span>
                      {field.showArrows && (
                        <ChevronContainer>
                          <ChevronUp
                            src={chevronRight}
                            alt="up"
                            data-image={`arrow-up-return`}
                            onClick={() => {
                              fieldOnUpdate(
                                field.name,
                                targetMap[field.name],
                                true,
                                true
                              );
                            }}
                          />
                          <ChevronDown
                            src={chevronRight}
                            alt="down"
                            data-image={`arrow-down-return`}
                            onClick={() => {
                              fieldOnUpdate(
                                field.name,
                                targetMap[field.name],
                                true,
                                false
                              );
                            }}
                          />
                        </ChevronContainer>
                      )}
                    </FieldValue>
                  ) : field.type === 'currency' ? (
                    <FieldValue
                      style={
                        IsNegative(targetMap[field.name])
                          ? { color: messageColors.error }
                          : null
                      }
                    >
                      {checkAvailable(field) ? (
                        'N/A'
                      ) : (
                        <>
                          {FormatCurrency(
                            Math.round(targetMap[field.name]),
                            currency
                          )}
                          {field.period ? field.period : null}
                        </>
                      )}
                    </FieldValue>
                  ) : (
                    <FieldValue>
                      {field.type !== 'want' && targetMap[field.name]}
                    </FieldValue>
                  )}
                </FieldLineItem>
              );
            })}
          </BottomContent>
        </TargetMapContainer>
      )}
    </Draggable>
  );
};

const SummaryDataLabel = styled.div`
  flex: 1 1 auto;
`;

const LabelType = styled.span`
  cursor: pointer;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  width: ${(props) => (props.visible ? 'auto' : 0)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;

const LabelInput = styled.input`
  border: ${inputColors.border};
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
  margin: -3px 0;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  flex: ${(props) => (props.visible ? '1 1 auto' : '0 0 auto')};
  width: ${(props) => (props.visible ? 'auto' : 0)};
  padding: ${(props) => (props.visible ? '4px 8px' : '0')};
`;

const FieldLineItem = styled.div`
  ${FlexCenterStart};
  border-bottom: ${(props) =>
    props.$last ? null : `1px solid ${colors.lightGrey}`};
  padding ${(props) => (props.$last ? '10px 10px 5px 10px' : '10px')};
  min-height: 24px;
`;

const FieldLabel = styled.p`
  flex: 1 1 auto;
  font-size: 12px;
  /* font-weight: ${fonts.semiBold}; */
`;

const FieldValue = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 14px;
    justify-content: center;
  }
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  margin: 0 0 5px 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const FieldInput = styled.input`
  border: 1px solid ${inputColors.border};
  color: ${colors.darkGrey};
  width: ${(props) => (props.$width ? props.$width : '35px')};
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  text-align: center;
  border-radius: 3px;
  flex: 0 0 auto;
  margin-right: 3px;
  ${({ $hasError }) =>
    $hasError &&
    `
    border: 1px solid ${messageColors.error}
  `}
`;

const ToolTipContainer = styled.div`
  position: relative;
  ${TooltipIconError} {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 5px;
    top: -5px;
  }
`;

const ChevronContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

const ChevronUp = styled.img`
  width: 10px;
  height: 10px;
  transform: rotate(-90deg);
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const ChevronDown = styled(ChevronUp)`
  transform: rotate(90deg);
`;

const ActionBar = styled.div`
  height: 30px;
  ${FlexCenterEnd};
  padding: 0 10px;
  position: relative;
`;

const AnimationContainer = styled.div`
  flex: 1 1 auto;
  margin: 0 0 0 -10px;
`;

const NameContainer = styled.div`
  ${FlexCenterAll};
  padding: 18px;
  font-weight: ${fonts.semiBold};
  font-size: 16px;
`;

const TopContent = styled.div`
  max-height: 200px;
  background: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: center;
  justify-content: center-start;
  a {
    color: ${colors.darkGrey};
  }
`;

const BottomContent = styled.div`
  padding: 10px 15px;
  background: ${colors.white};
`;

const TargetMapContainer = styled.div`
  border: 1px solid ${colors.paleGrey};
  border-radius: 6px;
  max-width: 34%;
  min-width: 300px;
  flex: 1 1 32%;
  /* height: 450px; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  box-shadow: ${boxShadows.boxShadow};
`;

const MenuIcon = styled.img`
  width: ${(props) => (props.$width ? props.$width : '18px')};
  height: ${(props) =>
    props.$height ? props.$height : props.$width ? props.$width : '18px'};
  margin: ${(props) => (props.$margin ? props.$margin : null)};
  cursor: pointer;
`;

const FixedIcon = styled(MenuIcon)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: grab;
`;

const DropdownContainer = styled.div`
  position: absolute;
  z-index: 99;
  background: white;
  right: ${(props) => (props.$right ? props.$right : '1px')};
  top: ${(props) => (props.$top ? props.$top : '7px')};
  margin-top: 23px;
  border-radius: 0 0 5px 5px;
  font-size: 11px;
  width: ${(props) => (props.$width ? props.$width : '100px')};
  box-shadow: ${boxShadows.boxShadow};
  &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    right: 12px;
    top: -6px;
    z-index: 9999 !important;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${colors.darkGrey};
  }
`;

const DropdownHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  padding: 6px 10px;
  background: ${colors.darkGrey};
  color: ${colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropDownContent = styled.div`
  padding: 8px 10px;
`;

const DropdownItem = styled.div`
  margin: 8px 0;
  border-radius: 3px;
  display: block;
  cursor: ${(props) => (props.$clickable ? 'pointer' : null)};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    opacity: ${(props) => (props.$clickable ? 0.75 : 1)};
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const DropdownItemImage = styled.img`
  width: 15px;
  height: 15px;
  max-height: 14px;
  margin-right: 10px;
`;

const DropdownItemText = styled.p``;

TargetMapCompare.propTypes = {
  tm: PropTypes.object,
  householdId: PropTypes.string,
  index: PropTypes.number,
  removeTargetMap: PropTypes.func,
  showClone: PropTypes.bool,
  error: PropTypes.object,
  currentHousehold: PropTypes.object,
};

export default TargetMapCompare;
