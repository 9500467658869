/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import {
  Background,
  Controls,
  ReactFlow,
  addEdge,
  // useNodesState,
  // useEdgesState,
  Panel,
  NodeToolbar,
  NodeResizer,
  applyEdgeChanges,
  applyNodeChanges,
  // ControlButton,
} from 'reactflow';
import 'reactflow/dist/style.css';
import {
  CapitalizeFirstLetter,
  DetectEnterKeyPress,
  GenerateAge,
  GenerateEntityLabel,
  HtmlToString,
  UpdatePageTitle,
  UseDoubleClick,
  UseOutsideClick,
} from '../../utils';
import {
  boxShadows,
  colors,
  fonts,
  heights,
  inputColors,
} from '../../styles/variables';
import {
  ConnectorEdge,
  MemberNode,
  JoinNode,
  LoadingAnimation,
  Error,
} from '../../components';
import {
  createFamilyMap,
  deleteFamilyMap,
  getFamilyMap,
  getFamilyMaps,
  updateFamilyMap,
  updateHouseholdStoreValue,
} from '../../store/actions';
import { useDispatch } from 'react-redux';
import {
  navAssetMaps,
  plusCircleDark,
  save,
  trashDark,
  userAdd,
} from '../../assets';
import { ErrorThemes } from '../../styles/themes';

const HouseholdFamilyMap = ({ isConsumer, householdId }) => {
  UpdatePageTitle('Family Map');
  const nameRef = useRef();
  const inputRef = useRef();
  const menuRef = useRef();
  const dispatch = useDispatch();
  const {
    currentHousehold,
    familyMaps,
    familyMap,
    updatedFamilyMap,
    justDeletedFamilyMap,
    familyMapError,
    sideNavWidth,
    allMemberCategories,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    familyMaps: state.households.familyMaps,
    familyMap: state.households.familyMap,
    updatedFamilyMap: state.households.updatedFamilyMap,
    justDeletedFamilyMap: state.households.justDeletedFamilyMap,
    familyMapError: state.households.familyMapError,
    sideNavWidth: state.user.sideNavWidth,
    allMemberCategories: state.configs.allMemberCategories,
  }));
  const [loadedFamilyMap, setLoadedFamilyMap] = useState(false);
  const [currentMap, setCurrentMap] = useState({
    name: 'First Family Map',
    default: false,
    tree: {},
  });
  const [membersList, setMembersList] = useState([]);
  const [loadedMembers, setLoadedMembers] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [mapName, setMapName] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [isUpdatingMap, setIsUpdatingMap] = useState(false);
  const [showChangeMaps, setShowChangeMaps] = useState(false);
  const [showMapsList, setShowMapsList] = useState(false);
  const [mapsList, setMapsList] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unknown Error');

  //Determine which row based off category
  const top = [
    'employer',
    'accountant',
    'attorney',
    'parent',
    'grandparent',
    'busprof',
  ];
  const center = ['primary', 'spouse', 'partner', 'sibling'];
  const bottom = [
    'child',
    'grandchild',
    'family',
    'friend',
    'pet',
    'trustee',
    'other',
    'employee',
  ];

  //Need to group them by type and then place them that way
  const nodeTypes = useMemo(() => ({ member: MemberNode, join: JoinNode }), []);
  const edgeTypes = useMemo(() => ({ 'custom-edge': ConnectorEdge }), []);

  UseOutsideClick(menuRef, () => {
    if (showMapsList) {
      setShowMapsList(false);
    }
  });

  UseDoubleClick({
    onDoubleClick: (e) => {
      setIsEditingName(true);
    },
    ref: nameRef,
  });

  useEffect(() => {
    dispatch(getFamilyMaps(householdId));
    return () => {
      localStorage.setItem('membersView', 'list');
    };
  }, []);

  useEffect(() => {
    if (familyMapError) {
      setIsEditingName(false);
      setIsUpdatingMap(false);
      setLoadedFamilyMap(true);
      setShowError(true);
      const errorData = familyMapError?.data;
      let errorMessage = 'Unknown Error';
      let action = 'creating';
      if (familyMapError?.action) {
        action = familyMapError?.action;
        errorMessage = `Error ${action} Family Map.`;
      }
      if (errorData) {
        let errors = [];
        for (let [key, value] of Object.entries(errorData)) {
          errors.push({ field: key, message: value });
        }
        errorMessage = errors.map((error) => {
          if (error.field === 'non_field_errors') {
            return `${error.message}`;
          }
          let fieldName = error.field.replaceAll('_', ' ');
          fieldName = CapitalizeFirstLetter(fieldName);
          return `${fieldName}: ${error.message}`;
        });
      }
      setErrorMessage(errorMessage);
    }
  }, [familyMapError]);

  useEffect(() => {
    if (currentHousehold?.allHouseholdMembers) {
      const members = currentHousehold?.allHouseholdMembers.reduce(
        (acc, mem) => {
          if (mem.member_type === 'member') {
            mem.age = GenerateAge(mem.date_of_birth);
            mem.name = HtmlToString(mem.name);
            mem.role = GenerateEntityLabel(mem.category, allMemberCategories);
            mem.isCenter = center.includes(mem.category);
            mem.isTop = top.includes(mem.category);
            mem.isBottom = bottom.includes(mem.category);
            return [...acc, mem];
          }
          return acc;
        },
        []
      );
      setMembersList(members);
      setLoadedMembers(true);
    }
  }, [currentHousehold?.allHouseholdMembers]);

  useEffect(() => {
    if (familyMaps && loadedMembers) {
      if (Array.isArray(familyMaps) && familyMaps.length === 0) {
        setShowChangeMaps(false);
        const defaultNodes = generateDefaultNodes();
        setCurrentMap({
          name: 'First Family Map',
          default: false,
          tree: { nodes: defaultNodes, edges: [] },
        });
        setNodes(defaultNodes);
      } else {
        const filteredMaps = familyMaps.filter(
          (map) => map.id !== currentMap.id
        );
        setMapsList(filteredMaps);
        setShowChangeMaps(familyMaps.length > 1);
        const defaultMap = familyMaps.find((map) => map.default);
        if (defaultMap) {
          !hasLoaded && setCurrentMap(defaultMap);
        } else {
          const matchingMap = familyMaps[0];
          !hasLoaded && setCurrentMap(matchingMap);
        }
      }
      setHasLoaded(true);
      setLoadedFamilyMap(true);
    }
  }, [familyMaps?.length, familyMaps, loadedMembers, updateFamilyMap]);

  useEffect(() => {
    if (familyMap) {
      setCurrentMap(familyMap);
      setMapName(familyMap?.name);
      setIsUpdatingMap(false);
      resetErrorDisplay();
    }
  }, [familyMap]);

  useEffect(() => {
    if (currentMap) {
      setMapName(currentMap?.name);
      let updatedNodes = [];
      if (Array.isArray(currentMap?.tree?.nodes)) {
        const transformedNodes = transformMemberNodes(currentMap?.tree?.nodes);
        updatedNodes = transformedNodes;
      }
      setNodes(updatedNodes);
      setEdges(currentMap?.tree?.edges ? currentMap?.tree?.edges : []);
    }
  }, [currentMap]);

  useEffect(() => {
    if (updatedFamilyMap) {
      setIsEditingName(false);
      setIsUpdatingMap(false);
      dispatch(getFamilyMaps(householdId));
      resetErrorDisplay();
      dispatch(updateHouseholdStoreValue('updatedFamilyMap', false));
    }
  }, [updatedFamilyMap]);

  useEffect(() => {
    if (justDeletedFamilyMap) {
      dispatch(updateHouseholdStoreValue('justDeletedFamilyMap', false));
      setHasLoaded(false);
    }
  }, [justDeletedFamilyMap]);

  const resetErrorDisplay = () => {
    setShowError(false);
    setErrorMessage('Unknown Error');
  };

  //Remove nodes if member does not exist
  const transformMemberNodes = (nodes) => {
    return nodes.reduce((acc, node) => {
      const member = { ...node };
      if (node.type === 'member') {
        const matchingMember = membersList.find((mem) => mem.id === node.id);
        if (matchingMember) {
          member.data = matchingMember;
          return [...acc, member];
        }
      } else {
        return [...acc, node];
      }
      return acc;
    }, []);
  };

  const generateMemberNode = (member) => {
    const { yAxis, xAxis } = member;
    return {
      id: member.id,
      position: {
        x: xAxis ? xAxis : 0,
        y: yAxis ? yAxis : 0,
      },
      data: member,
      type: 'member',
    };
  };

  const getRowMembers = (members, location, distance) => {
    let transformedMembers = members.filter((mem) => mem[location]);
    return transformedMembers.map((mem, index) => {
      mem.xAxis = 200 * index;
      mem.yAxis = distance;
      return mem;
    });
  };

  const joinNode = {
    id: '1',
    position: { x: -200, y: 0 },
    data: { label: 'Join' },
    type: 'join',
    num: 1,
  };

  const generateDefaultNodes = () => {
    if (loadedMembers) {
      const topMembers = getRowMembers(membersList, 'isTop', -200);
      const centerMembers = getRowMembers(membersList, 'isCenter', 0);
      const bottomMembers = getRowMembers(membersList, 'isBottom', 200);
      const nodes = [...topMembers, ...centerMembers, ...bottomMembers].map(
        (mem) => {
          return generateMemberNode(mem);
        }
      );
      return [...nodes, joinNode];
    }
  };

  // const [nodes, setNodes, onNodesChange] = useNodesState(nodesList);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (connection) => {
      const edge = { ...connection, type: 'custom-edge' };
      setEdges((eds) => addEdge(edge, eds));
    },
    [setEdges]
  );

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const addNode = () => {
    const maxNode = nodes.reduce((prev, current) =>
      prev && prev.num > current.num ? prev : current
    );
    const newId = maxNode.num + 1;
    let newNode = {
      ...maxNode,
      num: newId,
      selected: false,
      id: newId.toString(),
      position: { x: -200, y: 0 },
    };
    if (maxNode.type !== 'join') {
      newNode = joinNode;
    }
    setNodes([...nodes, newNode]);
  };

  const updateMapName = () => {
    if (isEditingName) {
      const originalName = currentMap?.name.trim();
      const newName = mapName.trim();
      const changedName = originalName !== newName;
      if (changedName) {
        setIsUpdatingMap(true);
        setIsEditingName(false);
        const data = {
          ...currentMap,
          name: newName,
          tree: { nodes: nodes, edges: edges },
        };
        if (currentMap?.id) {
          return dispatch(updateFamilyMap(householdId, data));
        } else {
          return dispatch(createFamilyMap(householdId, data));
        }
      }
    }
  };

  const mapOnClick = (map) => {
    dispatch(getFamilyMap(householdId, map?.id));
    setShowMapsList(false);
  };

  const createNewFamilyMap = () => {
    dispatch(
      createFamilyMap(householdId, {
        name: 'New Family Map',
        default: false,
        tree: { nodes: generateDefaultNodes(), edges: [] },
      })
    );
  };

  const updateFamilyMapOnClick = () => {
    setIsUpdatingMap(true);
    if (currentMap?.id) {
      return dispatch(
        updateFamilyMap(householdId, {
          ...currentMap,
          tree: { nodes: nodes, edges: edges },
        })
      );
    } else {
      return dispatch(
        createFamilyMap(householdId, {
          ...currentMap,
          tree: { nodes: nodes, edges: edges },
        })
      );
    }
  };

  const deleteFamilyMapOnClick = () => {
    dispatch(deleteFamilyMap(householdId, currentMap?.id));
  };

  return (
    <PageContainer>
      {!loadedFamilyMap && (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      )}
      <FlowContainer>
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          edges={edges}
          edgeTypes={edgeTypes}
          connectionMode="loose"
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          snapToGrid
          snapGrid={[10, 10]}
          proOptions={{ hideAttribution: true }}
          fitView
        >
          <Background variant={'dots'} />
          <Controls>
            {/* <ControlButton onClick={onSave}>
              <img src={save} alt="save" style={{ width: '12px' }} />
            </ControlButton> */}
          </Controls>
          <NodeToolbar />
          <NodeResizer />
          {showError && (
            <Panel position="top-center">
              <Error
                theme={ErrorThemes.inverted}
                errorMessage={errorMessage}
                dismissOnClick={resetErrorDisplay}
              />
            </Panel>
          )}
          <Panel position="bottom-center">
            <ActionBar sideNavWidth={sideNavWidth}>
              <LeftContent>
                <NameContainer>
                  <NameInput
                    ref={inputRef}
                    type="text"
                    maxLength="56"
                    value={mapName}
                    name="name"
                    onChange={(e) => setMapName(e.currentTarget.value)}
                    onBlur={() => updateMapName()}
                    onKeyPress={(e) => DetectEnterKeyPress(e, updateMapName)}
                    visible={isEditingName}
                  />
                  <Name
                    ref={nameRef}
                    visible={!isEditingName && loadedFamilyMap}
                    title={`${currentMap?.name} (Double click to edit)`}
                  >
                    {currentMap?.name}
                  </Name>
                  {isUpdatingMap && (
                    <span style={{ marginLeft: '5px' }}>
                      <LoadingAnimation
                        dots={true}
                        smaller={true}
                        color={colors.green}
                      />
                    </span>
                  )}
                </NameContainer>
              </LeftContent>
              <CenterContent>
                <ActionContainer onClick={addNode}>
                  <ActionIcon
                    src={plusCircleDark}
                    alt={'connect'}
                    data-image={'connection'}
                    width={'15px'}
                  />
                  <ActionText>Connection</ActionText>
                </ActionContainer>
                <ActionContainer onClick={addNode}>
                  <ActionIcon
                    src={userAdd}
                    alt={'member'}
                    data-image={'add-member'}
                    width={'17px'}
                  />
                  <ActionText>Member</ActionText>
                </ActionContainer>
              </CenterContent>
              <RightContent>
                {showChangeMaps && (
                  <ListContainer>
                    <ActionContainer onClick={() => setShowMapsList(true)}>
                      <ActionIcon
                        src={navAssetMaps}
                        alt=" map"
                        data-image="family-map-select"
                        width="17px"
                      />
                      <ActionText>Change Family-Map</ActionText>
                    </ActionContainer>
                    {showMapsList && (
                      <MapListContainer ref={menuRef}>
                        {mapsList.map((map, index) => {
                          return (
                            <MapItem
                              onClick={() => mapOnClick(map)}
                              key={index}
                              isLast={mapsList.length === index + 1}
                            >
                              <p>{map?.name}</p>
                            </MapItem>
                          );
                        })}
                      </MapListContainer>
                    )}
                  </ListContainer>
                )}
                {currentMap?.id && familyMaps.length < 10 && (
                  <ActionContainer onClick={createNewFamilyMap}>
                    <ActionIcon
                      src={plusCircleDark}
                      alt={'new'}
                      data-image={'new'}
                      width={'14px'}
                    />
                    <ActionText>New</ActionText>
                  </ActionContainer>
                )}
                <ActionContainer onClick={updateFamilyMapOnClick}>
                  <ActionIcon
                    src={save}
                    alt={'save'}
                    data-image={'save'}
                    width={'13px'}
                  />
                  <ActionText>Save</ActionText>
                </ActionContainer>
                {currentMap?.id && (
                  <ActionContainer onClick={deleteFamilyMapOnClick}>
                    <ActionIcon
                      src={trashDark}
                      alt={'delete'}
                      data-image={'delete'}
                      width={'15px'}
                    />
                    <ActionText>Delete</ActionText>
                  </ActionContainer>
                )}
              </RightContent>
            </ActionBar>
          </Panel>
        </ReactFlow>
      </FlowContainer>
    </PageContainer>
  );
};

const LoadingContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, 0);
  z-index: 1;
`;

const PageContainer = styled.div`
  flex: 1 1 auto;
  height: calc(100vh - 85px - ${heights.navBar});
`;

const FlowContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ActionBar = styled.div`
  width: ${(props) => `calc(100vw - ${props.sideNavWidth} - 110px)`};
  min-height: 25px;
  max-height: 75px;
  background: ${colors.white};
  color: ${colors.darkGrey};
  border: 1px solid ${colors.lightGrey};
  box-shadow: ${boxShadows.boxShadowSoft};
  padding: 10px 15px;
  border-radius: 4px;
  z-index: 6;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 13px;
`;

const LeftContent = styled.div`
  @media (max-width: 1290px) {
    flex: 1 1 35%;
  }
  @media (max-width: 1080px) {
    flex: 1 1 20%;
  }
  flex: 1 1 30%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const NameContainer = styled.div`
  flex: 1 1 250px;
  color: ${colors.darkGrey};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const Name = styled.span`
  @media (max-width: 1400px) {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  @media (max-width: 1100px) {
    max-width: 250px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  @media (max-width: 1010px) {
    max-width: 200px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  @media (max-width: 800px) {
    max-width: 180px;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  font-weight: ${fonts.semiBold};
  margin: 0 10px 0 5px;
  font-size: 15px;
  flex: 0 0 auto;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: ${(props) => (props.visible ? '2px 0' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  width: ${(props) => (props.visible ? 'auto' : '0px')};
  height: ${(props) => (props.visible ? 'auto' : '0px')};
`;

const NameInput = styled.input`
  @media (max-width: 1100px) {
    font-size: 12px;
  }
  border: 1px solid ${inputColors.border};
  border-radius: 3px;
  margin: -2px 0;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  flex: ${(props) => (props.visible ? '1 1 250px' : '0 0 auto')};
  width: ${(props) => (props.visible ? 'auto' : 0)};
  padding: ${(props) => (props.visible ? '4px 8px' : '0')};
`;

const CenterContent = styled.div`
  @media (max-width: 1290px) {
    flex: 1 1 30%;
  }
  @media (max-width: 1290px) {
    flex: 1 1 35%;
  }
  flex: 1 1 40%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`;

const RightContent = styled.div`
  @media (max-width: 1290px) {
    flex: 1 1 35%;
  }
  flex: 1 1 30%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
`;

const ListContainer = styled.div`
  position: relative;
`;

const MapItem = styled.div`
  cursor: pointer;
  padding: 6px 3px;
  border-bottom: ${(props) =>
    props.isLast ? null : `1px solid ${colors.lightGrey}`};
  p {
    white-space: nowrap;
    font-size: 11px;
    font-weight: ${fonts.semiBold};
    width: 124px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const fadeInMenu = keyframes`
  from {
    bottom: 23px;
  }
  to {
    bottom: 35px;
  }
  `;

const MapListContainer = styled.div`
  @media (max-width: 890px) {
    bottom: 40px;
  }
  position: absolute;
  /* left: 0; */
  left: 50%;
  bottom: 35px;
  width: 100%;
  min-width: 130px;
  /* width: calc(100% - 16px); */
  text-align: left;
  background: white;
  color: ${colors.darkGrey};
  border: 1px solid ${colors.lightGrey};
  box-shadow: ${boxShadows.boxShadowNavbarAlt};
  padding: 3px 6px;
  border-radius: 3px;
  z-index: 6;
  /* display: flex;
  align-content: center;
  align-items: center;
  justify-content: center; */
  transform: scale(1) translateX(-50%);
  animation: ${fadeInMenu} 0.2s ease-in-out;
`;

const ActionContainer = styled.div`
  @media (max-width: 1290px) {
    margin: 0 8px;
    width: ${(props) => (props.width ? '85px' : null)};
  }
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  cursor: pointer;
  margin: 0 10px;
  width: ${(props) => props.width};
  &:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
`;

const ActionText = styled.p`
  @media (max-width: 1290px) {
    font-size: 10px;
  }
  font-size: 12px;
  font-weight: ${fonts.regular};
`;

const ActionIcon = styled.img`
  @media (max-width: 1290px) {
    width: 11px;
    height: 11px;
  }
  width: ${(props) => (props.width ? props.width : '13px')};
  height: ${(props) => (props.width ? props.width : '13px')};
  margin-right: 5px;
`;

HouseholdFamilyMap.propTypes = {
  isConsumer: PropTypes.bool,
  householdId: PropTypes.string,
};

export default HouseholdFamilyMap;
