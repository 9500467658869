/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { PageFooter, PageHeader, SmallTable } from '../../components';
import {
  toggleShowMemberModal,
  toggleShowEntityModal,
  toggleShowProfessionalModal,
  toggleShowDeleteModal,
  getMemberInfo,
} from '../../store/actions';
import { PageContent } from '../../styles/library/layoutStyles';
import {
  UpdatePageTitle,
  headerContent,
  HtmlToString,
  SendTrackingEvent,
} from '../../utils';
import { getProfessionalInfo } from '../../store/actions/members';

const HouseholdMembersList = ({ isConsumer, canEdit = true }) => {
  UpdatePageTitle('Members');
  const dispatch = useDispatch();
  const {
    members,
    professionals,
    excludedHouseholdMembers,
    currentHousehold,
    allMemberCategories,
    loadedHouseholdFields,
    hasEntityRollup,
    showProfessionals,
    showExcludedMembers,
    invocationId,
  } = useSelector((state) => ({
    members: state.households.currentHousehold.householdmembers,
    professionals: state.households.currentHousehold?.householdprofessionals,
    excludedHouseholdMembers:
      state.households.currentHousehold?.excludedHouseholdMembers,
    currentHousehold: state.households.currentHousehold,
    allMemberCategories: state.configs.allMemberCategories,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    hasEntityRollup: state.user.hasEntityRollup,
    showProfessionals: state.user.showProfessionals,
    showExcludedMembers: state.user.showExcludedMembers,
    invocationId: state.configs.invocationId,
  }));
  const [membersList, setMembersList] = useState([]);
  const [entitiesList, setEntitiesList] = useState([]);
  const [professionalsList, setProfessionalsList] = useState([]);
  const [otherEntitiesList, setOtherEntitiesList] = useState([]);
  const [personalList, setPersonalList] = useState([]);
  const [loadedMembers, setLoadedMembers] = useState(false);
  const initialEntityColumns = [
    { text: 'Category', display: true },
    { text: 'Legal Name', display: true },
    { text: 'Linked Members', display: true },
    { text: 'Actions', display: false },
  ];
  const [entityColumns, setEntityColumns] = useState(initialEntityColumns);

  useEffect(() => {
    if (loadedHouseholdFields && currentHousehold?.id) {
      const transformedMembers = transformMembers(members);
      setMembersList(
        transformedMembers.filter((mem) => mem.member_type === 'member')
      );
      const transformedEntities = transformEntities(
        transformedMembers.filter((mem) => mem.member_type === 'entity')
      );
      setEntitiesList(transformedEntities);

      const transformedExcludedMembers = transformMembers(
        excludedHouseholdMembers
      );
      setPersonalList(
        transformedExcludedMembers.filter((mem) => mem.member_type === 'member')
      );
      const transformedExcludedEntities = transformEntities(
        transformedExcludedMembers.filter((mem) => mem.member_type === 'entity')
      );
      setOtherEntitiesList(transformedExcludedEntities);
      const transformedProfessionals = transformProfessionals(professionals);
      setProfessionalsList(transformedProfessionals);
      setLoadedMembers(true);
    }
  }, [members, loadedHouseholdFields, professionals]);

  useEffect(() => {
    if (hasEntityRollup) {
      setEntityColumns([
        { text: 'Category', display: true },
        { text: 'Legal Name', display: true },
        { text: 'Linked Members', display: true },
        { text: 'Entity Ownership', display: true, align: 'center' },
        { text: 'Actions', display: false },
      ]);
    }
  }, [hasEntityRollup]);

  const transformMembers = (members) => {
    if (members) {
      const copied = [...members];
      return copied.map((mem) => {
        const member = { ...mem };
        let categoryDisplay = member.category;
        const matchedCategory = allMemberCategories.find(
          (cat) => cat.value === member.category
        );
        if (matchedCategory?.label) {
          categoryDisplay = matchedCategory?.label;
        }
        member.categoryDisplay = categoryDisplay;
        member.first_name = HtmlToString(member.first_name);
        member.last_name = HtmlToString(member.last_name);
        member.name = HtmlToString(member.name);
        return member;
      });
    } else {
      return [];
    }
  };

  const transformEntities = (entities) => {
    if (entities) {
      return entities.map((entity) => {
        if (entity.owners && entity.owners.length !== 0) {
          entity.owners.map((owner) => {
            let matched = members.find((mem) => mem.id === owner.id);
            if (matched !== undefined) {
              owner.member = matched;
            } else {
              owner.member = { hide: true };
            }
            return owner;
          });
        }
        return entity;
      });
    } else {
      return [];
    }
  };

  const transformProfessionals = (professionals) => {
    if (professionals) {
      return professionals.map((pro) => {
        let categoryDisplay = pro.category;
        const matchedCategory = allMemberCategories.find(
          (cat) => cat.value === pro.category
        );
        if (matchedCategory?.label) {
          categoryDisplay = matchedCategory?.label;
        }
        pro.categoryDisplay = categoryDisplay;
        pro.first_name = HtmlToString(pro.first_name);
        pro.last_name = HtmlToString(pro.last_name);
        pro.business_name = HtmlToString(pro.business_name);
        return pro;
      });
    } else {
      return [];
    }
  };

  const memberDeleteFunction = (member) => {
    dispatch(toggleShowDeleteModal(true, 'member', member.name, member.id));
  };

  const memberEditFunction = (member, isPersonal = false) => {
    dispatch(getMemberInfo(currentHousehold.id, member.id, isConsumer));
    dispatch(toggleShowMemberModal(true, true, null, isPersonal));
  };

  const entityDeleteFunction = (entity) => {
    dispatch(toggleShowDeleteModal(true, 'entity', entity.name, entity.id));
  };

  const entityEditFunction = (entity) => {
    dispatch(getMemberInfo(currentHousehold.id, entity.id, isConsumer));
    dispatch(toggleShowEntityModal(true, true));
  };

  const personalEditFunction = (member) => {
    dispatch(getMemberInfo(currentHousehold.id, member.id, isConsumer));
    dispatch(toggleShowMemberModal(true, true, null, true));
  };

  const personalDeleteFunction = (member) => {
    dispatch(toggleShowDeleteModal(true, 'personal', member.name, member.id));
  };

  const otherEntityEditFunction = (entity) => {
    dispatch(getMemberInfo(currentHousehold.id, entity.id, isConsumer));
    dispatch(toggleShowEntityModal(true, true, null, true));
  };

  const otherEntityDeleteFunction = (entity) => {
    dispatch(
      toggleShowDeleteModal(true, 'otherEntity', entity.name, entity.id)
    );
  };

  const professionalDeleteFunction = (professional) => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'professional',
        professional.business_name,
        professional.id
      )
    );
  };

  const professionalEditFunction = (professional) => {
    dispatch(
      getProfessionalInfo(currentHousehold.id, professional.id, isConsumer)
    );
    dispatch(toggleShowProfessionalModal(true, true));
  };

  const membersTable = {
    heading: 'Members',
    headingButton: canEdit
      ? {
          text: 'New Member',
          function: () => dispatch(toggleShowMemberModal(true)),
        }
      : null,
    deleteFunction: memberDeleteFunction,
    editFunction: memberEditFunction,
    columnHeadings: [
      { text: 'Relationship', display: true },
      { text: 'First Name', display: true },
      { text: 'Last Name', display: true },
      { text: 'Gender', display: true },
      { text: 'DOB', display: true },
      { text: 'Age', display: true },
      { text: 'Actions', display: false },
    ],
  };

  const entitiesTable = {
    heading: 'Legal Entities',
    headingButton: canEdit
      ? {
          text: 'New Entity',
          function: () => dispatch(toggleShowEntityModal(true)),
        }
      : null,
    deleteFunction: entityDeleteFunction,
    editFunction: entityEditFunction,
    columnHeadings: entityColumns,
  };

  const personalTable = {
    heading: 'Personal Relationships',
    headingButton: canEdit
      ? {
          text: 'Add New',
          function: () => {
            SendTrackingEvent(
              invocationId,
              'click',
              'create_personal_relationship',
              'household_members',
              {
                isConsumer: isConsumer,
              }
            );
            dispatch(toggleShowMemberModal(true, false, null, true));
          },
        }
      : null,
    deleteFunction: personalDeleteFunction,
    editFunction: personalEditFunction,
    columnHeadings: [
      { text: 'Relationship', display: true },
      { text: 'First Name', display: true },
      { text: 'Last Name', display: true },
      { text: 'Gender', display: true },
      { text: 'DOB', display: true },
      { text: 'Age', display: true },
      { text: 'Actions', display: false },
    ],
  };

  const otherEntitiesTable = {
    heading: 'Other Entities',
    headingButton: canEdit
      ? {
          text: 'Add New',
          function: () =>
            dispatch(toggleShowEntityModal(true, false, null, true)),
        }
      : null,
    deleteFunction: otherEntityDeleteFunction,
    editFunction: otherEntityEditFunction,
    columnHeadings: initialEntityColumns,
  };

  const professionalsTable = {
    heading: 'Professional Relationships',
    headingButton: canEdit
      ? {
          text: 'Add New',
          function: () => {
            SendTrackingEvent(
              invocationId,
              'click',
              'create_household_professional',
              'household_members',
              {
                isConsumer: isConsumer,
              }
            );
            dispatch(toggleShowProfessionalModal(true));
          },
        }
      : null,
    deleteFunction: professionalDeleteFunction,
    editFunction: professionalEditFunction,
    columnHeadings: [
      { text: 'Relationship', display: true },
      { text: 'Business Name', display: true },
      { text: 'First Name', display: true },
      { text: 'Last Name', display: true },
      { text: 'Actions', display: false },
    ],
  };

  return (
    <>
      <PageContent margin="40px 40px 80px 40px">
        <SmallTable
          tableHeading={membersTable.heading}
          headingButton={membersTable.headingButton}
          columnHeadings={membersTable.columnHeadings}
          type={'members'}
          items={membersList}
          loaded={loadedMembers}
          deleteFunction={canEdit ? membersTable.deleteFunction : null}
          editFunction={membersTable.editFunction}
        />
        <SmallTable
          tableHeading={entitiesTable.heading}
          headingButton={entitiesTable.headingButton}
          columnHeadings={entitiesTable.columnHeadings}
          type={'entities'}
          items={entitiesList}
          loaded={loadedMembers}
          deleteFunction={canEdit ? entitiesTable.deleteFunction : null}
          editFunction={entitiesTable.editFunction}
        />
      </PageContent>
      {(showExcludedMembers || showProfessionals) && (
        <PageHeader content={headerContent.otherMembers} />
      )}
      <PageContent>
        {showExcludedMembers && (
          <>
            <SmallTable
              tableHeading={personalTable.heading}
              headingButton={personalTable.headingButton}
              columnHeadings={personalTable.columnHeadings}
              type={'personal'}
              items={personalList}
              loaded={loadedMembers}
              deleteFunction={canEdit ? personalTable.deleteFunction : null}
              editFunction={personalTable.editFunction}
            />
            <SmallTable
              tableHeading={otherEntitiesTable.heading}
              headingButton={otherEntitiesTable.headingButton}
              columnHeadings={otherEntitiesTable.columnHeadings}
              type={'excludedEntities'}
              items={otherEntitiesList}
              loaded={loadedMembers}
              deleteFunction={
                canEdit ? otherEntitiesTable.deleteFunction : null
              }
              editFunction={otherEntitiesTable.editFunction}
            />
          </>
        )}
        {showProfessionals && (
          <SmallTable
            tableHeading={professionalsTable.heading}
            headingButton={professionalsTable.headingButton}
            columnHeadings={professionalsTable.columnHeadings}
            type={'professionals'}
            items={professionalsList}
            loaded={loadedMembers}
            deleteFunction={canEdit ? professionalsTable.deleteFunction : null}
            editFunction={professionalsTable.editFunction}
          />
        )}
        {isConsumer && (
          <PageFooter footerValue={currentHousehold?.disclaimer} />
        )}
      </PageContent>
    </>
  );
};

HouseholdMembersList.propTypes = {
  isConsumer: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default HouseholdMembersList;
