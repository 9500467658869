/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { InstrumentModal } from '../../components';
import {
  createInstrument,
  createProposalInstrument,
  updateInstrument,
  cloneInstrument,
  toggleShowDeleteModal,
  getInstrumentIntegrationInfo,
  updateProposalInstrument,
  syncHouseholdModal,
  clearInstrumentInfo,
  updateInstrumentStoreValue,
} from '../../store/actions';
import {
  modalTabs,
  integrationOptions,
  CheckHasError,
  GenerateError,
  MatchSelectItem,
  UseGenerateMembersList,
  UseTransformOwnersList,
  UseTransformBeneficiaryList,
  UseTransformContingentBeneficiaryList,
  UseHandleInstrumentError,
  UseFetchInstrumentIntegrationContent,
  UseSetInstrumentIntegration,
  UseHandleInstrumentIntegrationError,
  TransformOwners,
  TransformBeneficiaries,
  TransformContingentBeneficiaries,
  PruneObjectNulls,
  MemberOption,
  BeneficiaryOption,
  ContingentBeneficiaryOption,
  DetectEnterKeyPress,
  UseSyncIntegrationContent,
  DateNow,
  DateFormat,
} from '../../utils';

const AssetModal = ({
  viewOnly,
  hide,
  isNew,
  householdId,
  showProposalMode,
  proposalId,
  defaultTab,
  currency,
  invisible,
  isConsumer,
}) => {
  const dispatch = useDispatch();
  const {
    instrument,
    loadedInstrumentFromAPI,
    instrumentIntegration,
    clonedInstrument,
    clearedInstrumentInfo,
    instrumentIntegrationError,
    instrumentError,
    currentHousehold,
    loadedHouseholdFields,
    assetCategories,
    assetInstrumentChoices,
    assetRiskPreferences,
    assetSubcategories,
    instrumentDateReference,
    preferences,
    memberIntegrationsInfo,
    householdIntegrationsInfo,
    isStaff,
    justSynced,
    modalState,
  } = useSelector((state) => ({
    instrument: state.instruments.instrument,
    loadedInstrumentFromAPI: state.instruments.loadedInstrumentFromAPI,
    instrumentIntegration: state.instruments.instrumentIntegration,
    clonedInstrument: state.instruments.clonedInstrument,
    clearedInstrumentInfo: state.instruments.clearedInstrumentInfo,
    instrumentIntegrationError: state.instruments.instrumentIntegrationError,
    instrumentError: state.instruments.instrumentError,
    currentHousehold: state.households.currentHousehold,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    assetCategories: state.configs.assetCategories,
    assetInstrumentChoices: state.configs.assetInstrumentChoices,
    assetRiskPreferences: state.configs.assetRiskPreferences,
    assetSubcategories: state.configs.assetSubcategories,
    instrumentDateReference: state.configs.instrumentDateReference,
    preferences: state.user.user.preferences,
    memberIntegrationsInfo: state.user?.user?.integrations,
    householdIntegrationsInfo: state.households.currentHousehold.integrations,
    isStaff: state.user.isStaff,
    justSynced: state.households.justSynced,
    modalState: state.households.modalState,
  }));
  const [activeTab, setActiveTab] = useState('essentials');
  const [modalTabOptions, setModalTabOptions] = useState(modalTabs);
  const [loadedInstrument, setLoadedInstrument] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [assetAccountTypes, setAssetAccountTypes] = useState([]);
  const [hasOwners, setHasOwners] = useState(false);
  const [ownersList, setOwnersList] = useState([]);
  const [hasBeneficiary, setHasBeneficiary] = useState(false);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [hasContingentBeneficiary, setHasContingentBeneficiary] =
    useState(false);
  const [contingentBeneficiaryList, setContingentBeneficiaryList] = useState(
    []
  );
  const [isHidden, setIsHidden] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [disabledFields, setDisabledFields] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [integrationContent, setIntegrationContent] = useState();
  const [integrationError, setIntegrationError] = useState();
  const [loadingIntegrationContent, setLoadingIntegrationContent] =
    useState(true);
  const defaultAsset = {
    owners: [],
    beneficiaries: [],
    contingent_beneficiaries: [],
    name: '',
    source: '',
    amount: '',
    date_reference: { label: 'Age', value: 'age' },
    start_date: '',
    start_age: '',
    is_joint: false,
    is_future_value: false,
    is_residence: false,
    is_managed: false,
    is_annuity: false,
    is_roth: false,
    as_of_date: '',
    nickname: '',
    custodian: '',
    risk_preference: { label: '0: Unspecified', value: '0' },
    notes: '',
    annual_contribution: '',
    annual_contribution_start_date_reference: { label: 'Age', value: 'age' },
    annual_contribution_end_date_reference: { label: 'Age', value: 'age' },
    annual_contribution_start_age: '',
    annual_contribution_end_age: '',
    annual_contribution_start_date: '',
    cash_value: '',
    surrender_value: '',
  };
  const [currentAsset, setCurrentAsset] = useState(defaultAsset);
  const [membersList, setMembersList] = useState([]);
  const [showSyncButton, setShowSyncButton] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [lastHouseholdSync, setLastHouseholdSync] = useState();
  const [loadedMembers, setLoadedMembers] = useState(false);

  const getIntegrationInfo = () => {
    return dispatch(
      getInstrumentIntegrationInfo(
        currentHousehold.id,
        currentAsset.id,
        activeTab
      )
    );
  };

  useEffect(() => {
    if (clearedInstrumentInfo) {
      resetInstrument();
      dispatch(updateInstrumentStoreValue('clearedInstrumentInfo', false));
    }
  }, [clearedInstrumentInfo]);

  // useEffect(() => {
  //   if (activeTab) {
  //     if (!modalTabs.map((tab) => tab.value).includes(activeTab)) {
  //       let matchedIntegration = integrationOptions.find(
  //         (int) => int.id === activeTab
  //       );
  //       if (matchedIntegration) {
  //         setSelectedIntegration(matchedIntegration);
  //       }
  //       setLoadingIntegrationContent(true);
  //       dispatch(
  //         getInstrumentIntegrationInfo(
  //           currentHousehold.id,
  //           currentAsset.id,
  //           activeTab
  //         )
  //       );
  //     } else {
  //       setIntegrationContent();
  //     }
  //   }
  // }, [activeTab]);

  useEffect(() => {
    if (assetCategories) {
      setAssetTypes(assetCategories);
    }
  }, [assetCategories]);

  useEffect(() => {
    if (instrumentDateReference) {
      setDateOptions(instrumentDateReference);
    }
  }, [instrumentDateReference]);

  useEffect(() => {
    if (assetSubcategories) {
      const groupOptions = assetSubcategories.reduce((acc, curr) => {
        const split = curr.value.split('.');
        if (split.length === 2) {
          const headerLabel = {
            label: curr?.label,
            key: split[1],
            options: [],
          };
          return [...acc, headerLabel];
        }
        if (split.length === 3) {
          const matchingParent = acc.find((item) => item.key === split[1]);
          matchingParent.options.push(curr);
          return [...acc];
        }
        return acc;
      }, []);
      setAssetAccountTypes(groupOptions);
    }
  }, [assetSubcategories]);

  useEffect(() => {
    if (isNew && loadedHouseholdFields) {
      setLoadedInstrument(true);
      setSelectedType(assetTypes[1]);
      if (membersList && membersList.length !== 0) {
        setCurrentAsset({
          ...defaultAsset,
          owners: [...membersList],
          beneficiaries: [...membersList],
          contingent_beneficiaries: [...membersList],
          risk_preference: assetRiskPreferences[0],
          date_reference: dateOptions[0],
          annual_contribution_start_date_reference: dateOptions[0],
          annual_contribution_end_date_reference: dateOptions[0],
        });
      }
    }
  }, [isNew, membersList, loadedHouseholdFields]);

  const findCustodian = (members) => {
    let matched = members.find((member) => {
      return member.relation === 'custodian';
    });
    if (matched && matched !== undefined) {
      let fullMember = membersList.find((mem) => mem.id === matched.id);
      return fullMember;
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (!isNew && loadedMembers && instrument) {
      if (instrument && instrument.hasOwnProperty('id')) {
        if (instrument?.is_proposal) {
          setModalTabOptions([
            ...modalTabOptions,
            { label: 'Drafts', value: 'proposals' },
          ]);
        } else if (
          instrument.hasOwnProperty('integrations') &&
          !showProposalMode
        ) {
          if (instrument.integrations.hasOwnProperty('available')) {
            const householdIntegrations = instrument.integrations.available;
            let integrationTabs = [];
            if (householdIntegrations.length !== 0 && memberIntegrationsInfo) {
              integrationTabs = householdIntegrations.reduce((acc, curr) => {
                if (memberIntegrationsInfo[curr]) {
                  let integrationName = curr;
                  let matchedIntegration = integrationOptions.find(
                    (int) => int.id === curr
                  );
                  if (matchedIntegration) {
                    integrationName = matchedIntegration.name;
                    setSelectedIntegration(matchedIntegration);
                  }
                  return [
                    ...acc,
                    {
                      label: integrationName,
                      value: curr,
                      icon: matchedIntegration?.icon
                        ? matchedIntegration?.icon
                        : false,
                    },
                  ];
                }
                return acc;
              }, []);
            }
            setModalTabOptions([...modalTabs, ...integrationTabs]);
          }
          if (
            instrument.integrations.hasOwnProperty('read_only_fields') &&
            instrument.integrations.read_only_fields.length !== 0
          ) {
            setDisabledFields(instrument.integrations.read_only_fields);
          } else {
            setDisabledFields([]);
          }
        }
        let startAge = instrument.start_age;
        if (instrument.start_age === null) {
          startAge = '';
        }
        let annualStartAge = instrument.annual_contribution_start_age;
        if (instrument.annual_contribution_start_age === null) {
          annualStartAge = '';
        }
        let annualEndAge = instrument.annual_contribution_end_age;
        if (instrument.annual_contribution_end_age === null) {
          annualEndAge = '';
        }
        setCurrentAsset({
          ...PruneObjectNulls(instrument),
          date_reference: MatchSelectItem(
            dateOptions,
            instrument.date_reference || 'age'
          ),
          annual_contribution_start_date_reference: MatchSelectItem(
            dateOptions,
            instrument.annual_contribution_start_date_reference
          ),
          annual_contribution_end_date_reference: MatchSelectItem(
            dateOptions,
            instrument.annual_contribution_end_date_reference
          ),
          start_age: startAge,
          annual_contribution_start_age: annualStartAge,
          annual_contribution_end_age: annualEndAge,
          risk_preference: MatchSelectItem(
            assetRiskPreferences,
            instrument.risk_preference
          ),
          instrument_choice: MatchSelectItem(
            assetInstrumentChoices,
            instrument.instrument_choice
          ),
          subcategory: MatchSelectItem(
            assetSubcategories,
            instrument.subcategory
          ),
          custodian: findCustodian(instrument.members),
          owners: TransformOwners(membersList, instrument.members),
          beneficiaries: TransformBeneficiaries(
            membersList,
            instrument.members
          ),
          contingent_beneficiaries: TransformContingentBeneficiaries(
            [...membersList],
            [...instrument.members]
          ),
        });
        setLoadedInstrument(loadedInstrumentFromAPI);
        setSelectedType(MatchSelectItem(assetTypes, instrument.category));
        setIsHidden(instrument.is_hidden);
        if (defaultTab) {
          setActiveTab(defaultTab);
        }
      }
    }
  }, [
    instrument,
    memberIntegrationsInfo,
    loadedMembers,
    clearedInstrumentInfo,
  ]);

  const resetInstrument = () => {
    setCurrentAsset(defaultAsset);
    setActiveTab('essentials');
    setSelectedType();
    setModalError('');
    setModalErrors();
    setLoadedInstrument(false);
    setIsHidden(false);
    setHasOwners(false);
    setHasBeneficiary(false);
    setHasContingentBeneficiary(false);
    setModalTabOptions(modalTabs);
    setDisabledFields([]);
    setMembersList(
      membersList.map((member) => {
        member.isSelected = false;
        member.isBeneficiary = false;
        member.isContingentBeneficiary = false;
        delete member.ratio;
        return member;
      })
    );
    setIntegrationContent();
    // setSelectedIntegration();
    // setIntegrationError();
    // setLoadingIntegrationContent(true);
    // setDateOptions([]);
    // setAssetAccountTypes([]);
    // setAssetTypes([]);
    // setButtonLoading(false);
    // setErrorTitle();
    // setShowSyncButton(false);
    // setLastHouseholdSync();
    // setIsSyncing(false);
  };

  UseGenerateMembersList(
    currentHousehold,
    setMembersList,
    true,
    setLoadedMembers
  );
  UseTransformOwnersList(
    currentAsset,
    isNew,
    setOwnersList,
    setHasOwners,
    setModalError,
    setModalErrors
  );
  UseTransformBeneficiaryList(
    currentAsset,
    isNew,
    setBeneficiaryList,
    setHasBeneficiary,
    setModalError,
    setModalErrors
  );
  UseTransformContingentBeneficiaryList(
    currentAsset,
    isNew,
    setContingentBeneficiaryList,
    setHasContingentBeneficiary,
    setModalError,
    setModalErrors
  );
  UseHandleInstrumentError(
    instrumentError,
    setButtonLoading,
    setModalError,
    setModalErrors,
    setErrorTitle,
    setLoadedInstrument
  );
  UseFetchInstrumentIntegrationContent(
    modalTabs,
    activeTab,
    integrationOptions,
    setSelectedIntegration,
    setLoadingIntegrationContent,
    getIntegrationInfo,
    setIntegrationContent
  );
  UseSetInstrumentIntegration(
    instrumentIntegration,
    setLoadingIntegrationContent,
    setIntegrationContent
  );
  UseHandleInstrumentIntegrationError(
    instrumentIntegrationError,
    setLoadingIntegrationContent,
    setIntegrationError
  );
  UseSyncIntegrationContent(
    activeTab,
    setShowSyncButton,
    householdIntegrationsInfo,
    setLastHouseholdSync
  );

  useEffect(() => {
    return () => {
      dispatch(clearInstrumentInfo());
    };
  }, []);

  useEffect(() => {
    if (justSynced) {
      setIsSyncing(false);
    }
  }, [justSynced]);

  const saveAsset = () => {
    setErrorTitle();
    if (selectedType === undefined) {
      setModalErrors({ category: ['Please select a Asset Type.'] });
      return setModalError('Please select a Asset Type.');
    }
    let isJoint = currentAsset.is_joint;
    let members = [];
    if (currentAsset.custodian && currentAsset.custodian.value) {
      let custodian = {
        ratio: 100,
        id: currentAsset.custodian.id,
        relation: 'custodian',
      };
      members.push(custodian);
    }
    let owners = currentAsset.owners.filter((member) => member.isSelected);
    let mappedOwners = owners.map((owner) => {
      return {
        id: owner.id,
        ratio: owner.ratio === '' ? 0 : owner.ratio,
        relation: 'owner',
      };
    });
    if (owners.length === 1) {
      isJoint = false;
    }
    if (mappedOwners.length === 0) {
      setModalErrors({ members: ['Please select an Interested Member.'] });
      return setModalError('Please select an Interested Member.');
    }
    const ownerRatioTotal = mappedOwners.reduce((acc, curr) => {
      return acc + parseInt(curr.ratio);
    }, 0);
    if (ownerRatioTotal > 100) {
      setModalErrors({
        members: ['Percentage cannot be greater than 100.'],
      });
      return setModalError('Percentage cannot be greater than 100.');
    }
    const memberDOB = owners[0]?.date_of_birth;
    const dob_reference = memberDOB;

    let startDate = null;
    if (
      currentAsset.date_reference.value === 'date' &&
      currentAsset.start_date !== '' &&
      currentAsset.start_date !== null
    ) {
      startDate = DateFormat(currentAsset.start_date, 'YYYY-MM-DD');
    }
    let as_of_date = DateFormat(undefined, 'YYYY-MM-DD');
    if (currentAsset.as_of_date !== '') {
      as_of_date = DateFormat(currentAsset.as_of_date, 'YYYY-MM-DD');
    }
    let instrumentChoice = '';
    if (
      currentAsset.instrument_choice &&
      currentAsset.instrument_choice.hasOwnProperty('value')
    ) {
      instrumentChoice = currentAsset.instrument_choice.value;
    }
    let subcategory = '';
    if (
      currentAsset.subcategory &&
      currentAsset.subcategory.hasOwnProperty('value')
    ) {
      subcategory = currentAsset.subcategory.value;
    }
    let start_age = null;
    if (currentAsset.start_age !== '') {
      start_age = parseInt(currentAsset.start_age);
    }
    let annual_contribution_start_age = null;
    if (currentAsset.annual_contribution_start_age !== '') {
      annual_contribution_start_age = parseInt(
        currentAsset.annual_contribution_start_age
      );
    }
    let annual_contribution_end_age = null;
    if (currentAsset.annual_contribution_end_age !== '') {
      annual_contribution_end_age = parseInt(
        currentAsset.annual_contribution_end_age
      );
    }
    let annual_contribution_start_date = null;
    if (
      currentAsset.annual_contribution_start_date !== '' &&
      currentAsset.annual_contribution_start_date !== null
    ) {
      annual_contribution_start_date = DateFormat(
        currentAsset.annual_contribution_start_date,
        'YYYY-MM-DD'
      );
    }
    let annual_contribution_end_date = null;
    if (
      currentAsset.annual_contribution_end_date !== '' &&
      currentAsset.annual_contribution_end_date !== null
    ) {
      annual_contribution_end_date = DateFormat(
        currentAsset.annual_contribution_end_date,
        'YYYY-MM-DD'
      );
    }
    let beneficiaries = currentAsset.beneficiaries.filter(
      (member) => member.isBeneficiary
    );
    let mappedBeneficiaries = beneficiaries.map((member) => {
      return {
        id: member.id,
        ratio: member.beneficiary_ratio === '' ? 0 : member.beneficiary_ratio,
        relation: 'beneficiary',
      };
    });
    if (mappedBeneficiaries.length > 0) {
      const beneficiaryRatioTotal = mappedBeneficiaries.reduce((acc, curr) => {
        return acc + parseInt(curr.ratio);
      }, 0);
      if (beneficiaryRatioTotal > 100) {
        setModalErrors({
          beneficiaries: ['Percentage cannot be greater than 100.'],
        });
        return setModalError('Percentage cannot be greater than 100.');
      }
    }
    const contingentBeneficiaries =
      currentAsset.contingent_beneficiaries.filter(
        (member) => member.isContingentBeneficiary
      );
    let mappedContingentBeneficiaries = contingentBeneficiaries.map(
      (member) => {
        return {
          id: member.id,
          ratio:
            member.contingent_beneficiary_ratio === ''
              ? 0
              : member.contingent_beneficiary_ratio,
          relation: 'contingent_beneficiary',
        };
      }
    );
    if (mappedContingentBeneficiaries.length > 0) {
      const contingentBeneficiaryRatioTotal =
        mappedContingentBeneficiaries.reduce((acc, curr) => {
          return acc + parseInt(curr.ratio);
        }, 0);
      if (contingentBeneficiaryRatioTotal > 100) {
        setModalErrors({
          contingent_beneficiaries: ['Percentage cannot be greater than 100.'],
        });
        return setModalError('Percentage cannot be greater than 100.');
      }
    }
    members = [
      ...members,
      ...mappedOwners,
      ...mappedBeneficiaries,
      ...mappedContingentBeneficiaries,
    ];

    let cash_value = null;
    let surrender_value = null;
    if (currentAsset.is_annuity) {
      cash_value = currentAsset.cash_value;
      surrender_value = currentAsset.surrender_value;
    }

    const assetObject = {
      instrument_type: 'asset',
      category: selectedType.value,
      name: currentAsset.name,
      source: currentAsset.source,
      amount: currentAsset.amount,
      members,
      is_future_value: currentAsset.is_future_value,
      date_reference: currentAsset.date_reference.value,
      dob_reference,
      start_date: startDate,
      start_age,
      as_of_date,
      is_residence: currentAsset.is_residence,
      is_joint: isJoint,
      is_managed: currentAsset.is_managed,
      is_annuity: currentAsset.is_annuity,
      is_roth: currentAsset.is_roth,
      cash_value,
      surrender_value,
      is_hidden: isHidden,
      nickname: currentAsset.nickname,
      risk_preference: currentAsset.risk_preference.value,
      annual_contribution: parseInt(currentAsset.annual_contribution),
      annual_contribution_start_date_reference:
        currentAsset.annual_contribution_start_date_reference.value,
      annual_contribution_end_date_reference:
        currentAsset.annual_contribution_end_date_reference.value,
      annual_contribution_start_age,
      annual_contribution_end_age,
      annual_contribution_start_date,
      annual_contribution_end_date,
      instrument_choice: instrumentChoice,
      subcategory,
      notes: currentAsset.notes,
    };
    setButtonLoading(true);
    if (isNew) {
      return dispatch(
        showProposalMode
          ? createProposalInstrument(
              currentHousehold?.uuid,
              proposalId,
              assetObject,
              'Asset'
            )
          : createInstrument(householdId, assetObject, 'Asset', isConsumer)
      );
    } else {
      return dispatch(
        showProposalMode
          ? updateProposalInstrument(
              currentHousehold?.uuid,
              proposalId,
              assetObject,
              instrument?.uuid,
              'Asset',
              instrument?.proposal_action_data?.id
            )
          : updateInstrument(
              householdId,
              instrument.id,
              assetObject,
              'Asset',
              isConsumer
            )
      );
    }
  };

  const saveOnEnter = () => {
    if (buttonLoading || activeTab === 'notes') {
      return;
    }
    return saveAsset();
  };

  const openDeleteAsset = () => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'asset',
        instrument.name,
        instrument.id,
        true,
        instrument
      )
    );
  };

  const copyInstrument = () => {
    dispatch(cloneInstrument(currentHousehold.id, instrument.id, 'Asset'));
  };

  const syncInstrument = () => {
    setIsSyncing(true);
    dispatch(
      syncHouseholdModal(integrationContent.sync_url, currentHousehold.id)
    );
  };

  const ratioChange = (e, member, type) => {
    setModalError('');
    setModalErrors();
    let value = e.currentTarget.value;
    if (type === 'owner') {
      member.ratio = value;
    }
    if (type === 'beneficiary') {
      member.beneficiary_ratio = value;
    }
    if (type === 'contingent_beneficiary') {
      member.contingent_beneficiary_ratio = value;
    }
    setCurrentAsset({ ...currentAsset });
  };

  const updateLinkedMembers = (e) => {
    setCurrentAsset({
      ...currentAsset,
      owners: currentAsset.owners.map((member) => {
        if (e.id === member.id) {
          member.isSelected = !member.isSelected;
        }
        return member;
      }),
    });
  };

  const updateBeneficiaryMembers = (
    e,
    objKey = 'beneficiaries',
    memberKey = 'isBeneficiary'
  ) => {
    setCurrentAsset({
      ...currentAsset,
      [objKey]: currentAsset.owners.map((member) => {
        if (e.id === member.id) {
          member[memberKey] = !member[memberKey];
        }
        return member;
      }),
    });
  };

  const updateCurrentAsset = (e, isSelect = false, fieldName = null) => {
    setModalError('');
    setModalErrors();
    if (isSelect) {
      setCurrentAsset({
        ...currentAsset,
        [fieldName]: e,
      });
    } else {
      setCurrentAsset({
        ...currentAsset,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const checkVisibility = (field) => {
    if (selectedType && selectedType.value === 'residence') {
      if (field === 'is_residence') {
        return true;
      }
    }
    if (selectedType && selectedType.value !== 'residence') {
      if (
        field === 'is_annuity' ||
        field === 'annual_contribution' ||
        field === 'nickname' ||
        field === 'risk_preference' ||
        field === 'is_annuity' ||
        field === 'annual_contribution_start_date_reference' ||
        field === 'annual_contribution_end_date_reference' ||
        field === 'subcategory'
      ) {
        return true;
      }
      if (
        field === 'annual_contribution_start_date' &&
        currentAsset?.annual_contribution_start_date_reference?.value === 'date'
      ) {
        return true;
      }
      if (
        field === 'annual_contribution_start_age' &&
        currentAsset?.annual_contribution_start_date_reference?.value === 'age'
      ) {
        return true;
      }
      if (
        field === 'annual_contribution_end_date' &&
        currentAsset?.annual_contribution_end_date_reference?.value === 'date'
      ) {
        return true;
      }
      if (
        field === 'annual_contribution_end_age' &&
        currentAsset?.annual_contribution_end_date_reference?.value === 'age'
      ) {
        return true;
      }
    }
    if (selectedType && selectedType.value === 'retirement') {
      if (field === 'is_roth' || field === 'instrument_choice') {
        return true;
      }
      if (field === 'is_joint') {
        return false;
      }
    }
    if (currentAsset.date_reference) {
      if (
        field === 'start_age' &&
        currentAsset.date_reference?.value === 'age'
      ) {
        return true;
      }
      if (
        field === 'start_date' &&
        currentAsset.date_reference?.value === 'date'
      ) {
        return true;
      }
    }
    if (field === 'is_joint') {
      if (currentAsset.owners && currentAsset.owners.length > 1) {
        let selected = currentAsset.owners.filter((owner) => {
          return owner.isSelected;
        });
        if (selected.length > 1) {
          return true;
        }
      }
    }
    if (field === 'cash_value' || field === 'surrender_value') {
      if (currentAsset.is_annuity && isStaff) {
        return true;
      }
    }
    return false;
  };

  const isDisabled = (input) => {
    if (viewOnly) {
      return true;
    } else if (!isNew && disabledFields.length !== 0) {
      return disabledFields.includes(input);
    }
    return false;
  };

  const formatFunction = (values, input) => {
    setModalError('');
    setModalErrors();
    let setValue;
    if (values.hasOwnProperty('floatValue')) {
      setValue = values.floatValue;
    } else if (values.currentTarget?.value) {
      setValue = values.currentTarget.value;
    }

    if (setValue === 'NaN') {
      setValue = '';
    }

    setCurrentAsset({
      ...currentAsset,
      [input.name]: setValue,
      as_of_date: input.name === 'amount' ? DateNow() : currentAsset.as_of_date,
    });
  };

  const essentialInputs = [
    {
      type: 'select',
      label: 'Asset Type',
      name: 'asset_type',
      required: true,
      placeholder: 'Asset Type',
      value: selectedType,
      options: assetTypes,
      width: '100%',
      isVisible: true,
      disabled: isDisabled('asset_type'),
      onChange: (e) => setSelectedType(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'category'),
      errorMessage: GenerateError(modalErrors, 'category'),
    },
    {
      type: 'checkbox',
      label: 'Is Residence',
      name: 'is_residence',
      required: false,
      isVisible: checkVisibility('is_residence'),
      disabled: isDisabled('is_residence'),
      value: currentAsset.is_residence,
      onChange: () =>
        setCurrentAsset({
          ...currentAsset,
          is_residence: !currentAsset.is_residence,
        }),
    },
    {
      type: 'select',
      label: 'Interested Members',
      customSelect: true,
      customComponent: MemberOption,
      name: 'members',
      required: true,
      placeholder: 'Interested members',
      value: { label: 'Select Members', value: '' },
      options: currentAsset.owners,
      showSelected: hasOwners,
      hasSelected: true,
      width: '100%',
      isVisible: true,
      optionOnChange: ratioChange,
      ratioKey: 'ratio',
      memberType: 'owner',
      selectedOptions: ownersList,
      disabled: isDisabled('owner'),
      onChange: (e) => updateLinkedMembers(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'members'),
      errorMessage: GenerateError(modalErrors, 'members'),
    },
    {
      type: 'checkbox',
      label: 'Joint Ownership',
      name: 'is_joint',
      required: false,
      width: '100%',
      value: currentAsset.is_joint,
      isVisible: checkVisibility('is_joint'),
      disabled: isDisabled('is_joint'),
      onChange: () =>
        setCurrentAsset({
          ...currentAsset,
          is_joint: !currentAsset.is_joint,
        }),
      // hasError: layoutNameFieldError,
    },
    {
      type: 'text',
      label: 'Reference Name',
      maxLength: '80',
      name: 'name',
      width: '48%',
      required: true,
      placeholder: 'e.g. IRA, Brokerage',
      isVisible: true,
      disabled: isDisabled('name'),
      value: currentAsset.name,
      onChange: (e) => updateCurrentAsset(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'name'),
      errorMessage: GenerateError(modalErrors, 'name'),
    },
    {
      type: 'text',
      label: 'Location',
      maxLength: '80',
      name: 'source',
      width: '48%',
      required: true,
      placeholder: 'e.g. Bank, Institution, Provider',
      isVisible: true,
      disabled: isDisabled('source'),
      value: currentAsset.source,
      onChange: (e) => updateCurrentAsset(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'source'),
      errorMessage: GenerateError(modalErrors, 'source'),
    },
    {
      type: 'calculatorCurrency',
      label: 'Value',
      maxLength: '80',
      name: 'amount',
      width: '68%',
      required: true,
      placeholder: 'Value',
      value: currentAsset.amount,
      isVisible: true,
      disabled: isDisabled('amount'),
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'amount'),
      errorMessage: GenerateError(modalErrors, 'amount'),
    },
    {
      type: 'checkbox',
      label: 'Future Value',
      name: 'is_future_value',
      required: false,
      width: '30%',
      value: currentAsset.is_future_value,
      isVisible: true,
      disabled: isDisabled('is_future_value'),
      onChange: () =>
        setCurrentAsset({
          ...currentAsset,
          is_future_value: !currentAsset.is_future_value,
        }),
      // hasError: layoutNameFieldError,
    },
    {
      type: 'select',
      label: 'Value as of Age/Date',
      name: 'date_reference',
      width: '48%',
      required: false,
      placeholder: 'Value as of Age/Date',
      value: currentAsset.date_reference,
      options: dateOptions,
      isVisible: true,
      disabled: isDisabled('date_reference'),
      onChange: (e) => updateCurrentAsset(e, true, 'date_reference'),
      onEnter: saveOnEnter,
    },
    {
      type: 'number',
      label: 'Age',
      maxLength: '80',
      name: 'start_age',
      width: '48%',
      required: false,
      placeholder: 'Age',
      value: currentAsset.start_age,
      isVisible: checkVisibility('start_age'),
      disabled: isDisabled('start_age'),
      onChange: (e) => updateCurrentAsset(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'start_age'),
      errorMessage: GenerateError(modalErrors, 'start_age'),
    },
    {
      type: 'date',
      label: 'Date',
      name: 'start_date',
      width: '48%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentAsset.start_date,
      isVisible: checkVisibility('start_date'),
      disabled: isDisabled('start_date'),
      onChange: (e) => updateCurrentAsset(e, true, 'start_date'),
      hasError: CheckHasError(modalErrors, 'start_date'),
      errorMessage: GenerateError(modalErrors, 'start_date'),
    },
    {
      type: 'checkbox',
      label: 'Under My Management',
      name: 'is_managed',
      required: false,
      width: '48%',
      value: currentAsset.is_managed,
      isVisible: true,
      disabled: isDisabled('is_managed'),
      onChange: () =>
        setCurrentAsset({
          ...currentAsset,
          is_managed: !currentAsset.is_managed,
        }),
      // hasError: layoutNameFieldError,
    },
    {
      type: 'checkbox',
      label: 'Deferred Annuity',
      name: 'is_annuity',
      required: false,
      width: '48%',
      value: currentAsset.is_annuity,
      isVisible: checkVisibility('is_annuity'),
      disabled: isDisabled('is_annuity'),
      onChange: () =>
        setCurrentAsset({
          ...currentAsset,
          is_annuity: !currentAsset.is_annuity,
        }),
      // hasError: layoutNameFieldError,
    },
    {
      type: 'checkbox',
      label: 'Roth Features',
      name: 'is_roth',
      required: false,
      width:
        currentAsset?.is_annuity === true &&
        selectedType?.value === 'retirement'
          ? '100%'
          : '48%',
      value: currentAsset.is_roth,
      isVisible: checkVisibility('is_roth'),
      disabled: isDisabled('is_roth'),
      onChange: () =>
        setCurrentAsset({
          ...currentAsset,
          is_roth: !currentAsset.is_roth,
        }),
      // hasError: layoutNameFieldError,
    },
    {
      type: 'calculatorCurrency',
      label: 'Cash Value',
      maxLength: '80',
      name: 'cash_value',
      width: '48%',
      required: false,
      placeholder: 'Cash Value',
      value: currentAsset.cash_value,
      isVisible: checkVisibility('cash_value'),
      disabled: isDisabled('cash_value'),
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'cash_value'),
      errorMessage: GenerateError(modalErrors, 'cash_value'),
    },
    {
      type: 'calculatorCurrency',
      label: 'Surrender Value',
      name: 'surrender_value',
      width: '48%',
      required: false,
      placeholder: 'Surrender Value',
      value: currentAsset.surrender_value,
      isVisible: checkVisibility('surrender_value'),
      disabled: isDisabled('surrender_value'),
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'surrender_value'),
      errorMessage: GenerateError(modalErrors, 'surrender_value'),
    },
  ];

  const detailInputs = [
    {
      type: 'date',
      label: 'Data Collected',
      name: 'as_of_date',
      width: '100%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentAsset.as_of_date,
      isVisible: true,
      disabled: isDisabled('as_of_date'),
      onChange: (e) => updateCurrentAsset(e, true, 'as_of_date'),
      hasError: CheckHasError(modalErrors, 'as_of_date'),
      errorMessage: GenerateError(modalErrors, 'as_of_date'),
    },
    {
      type: 'select',
      label: 'Beneficiary',
      name: 'beneficiaries',
      required: false,
      placeholder: 'Beneficiary',
      value: { label: 'Select Members', value: '' },
      options: currentAsset.beneficiaries,
      width: '100%',
      customSelect: true,
      hasSelected: true,
      customComponent: BeneficiaryOption,
      showSelected: hasBeneficiary,
      optionOnChange: ratioChange,
      ratioKey: 'beneficiary_ratio',
      memberType: 'beneficiary',
      selectedOptions: beneficiaryList,
      isVisible: true,
      onChange: (e) => updateBeneficiaryMembers(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'beneficiaries'),
      errorMessage: GenerateError(modalErrors, 'beneficiaries'),
    },
    {
      type: 'select',
      label: 'Contingent Beneficiary',
      name: 'contingent_beneficiaries',
      required: false,
      placeholder: 'Contingent Beneficiary',
      value: { label: 'Select Members', value: '' },
      options: currentAsset.contingent_beneficiaries,
      width: '100%',
      customSelect: true,
      hasSelected: true,
      customComponent: ContingentBeneficiaryOption,
      showSelected: hasContingentBeneficiary,
      optionOnChange: ratioChange,
      ratioKey: 'contingent_beneficiary_ratio',
      memberType: 'contingent_beneficiary',
      selectedOptions: contingentBeneficiaryList,
      isVisible: true,
      onChange: (e) =>
        updateBeneficiaryMembers(
          e,
          'contingent_beneficiaries',
          'isContingentBeneficiary'
        ),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'contingent_beneficiaries'),
      errorMessage: GenerateError(modalErrors, 'contingent_beneficiaries'),
    },
    {
      type: 'calculatorCurrency',
      label: 'Annual Contribution',
      name: 'annual_contribution',
      width: '100%',
      required: false,
      placeholder: 'Annual Contribution',
      value: currentAsset.annual_contribution,
      isVisible: checkVisibility('annual_contribution'),
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'annual_contribution'),
      errorMessage: GenerateError(modalErrors, 'annual_contribution'),
    },
    {
      type: 'select',
      label: 'Age/Date',
      name: 'annual_contribution_start_date_reference',
      width: '18%',
      required: false,
      placeholder: 'Age/Date',
      value: currentAsset.annual_contribution_start_date_reference,
      options: dateOptions,
      isVisible: checkVisibility('annual_contribution_start_date_reference'),
      onChange: (e) =>
        updateCurrentAsset(e, true, 'annual_contribution_start_date_reference'),
      onEnter: saveOnEnter,
    },
    {
      type: 'text',
      label: 'Contribution Start Age',
      name: 'annual_contribution_start_age',
      width: '30%',
      required: false,
      placeholder: 'Start Age',
      value: currentAsset.annual_contribution_start_age,
      isVisible: checkVisibility('annual_contribution_start_age'),
      onChange: (e) => updateCurrentAsset(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'annual_contribution_start_age'),
      errorMessage: GenerateError(modalErrors, 'annual_contribution_start_age'),
    },
    {
      type: 'date',
      label: 'Contribution Start Date',
      name: 'annual_contribution_start_date',
      width: '30%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentAsset.annual_contribution_start_date,
      isVisible: checkVisibility('annual_contribution_start_date'),
      onChange: (e) =>
        updateCurrentAsset(e, true, 'annual_contribution_start_date'),
      hasError: CheckHasError(modalErrors, 'annual_contribution_start_date'),
      errorMessage: GenerateError(
        modalErrors,
        'annual_contribution_start_date'
      ),
    },
    {
      type: 'select',
      label: 'Age/Date',
      name: 'annual_contribution_end_date_reference',
      width: '18%',
      required: false,
      placeholder: 'Age/Date',
      margin: '0 0 10px 10px',
      value: currentAsset.annual_contribution_end_date_reference,
      options: dateOptions,
      isVisible: checkVisibility('annual_contribution_end_date_reference'),
      onChange: (e) =>
        updateCurrentAsset(e, true, 'annual_contribution_end_date_reference'),
      onEnter: saveOnEnter,
    },
    {
      type: 'text',
      label: 'Contribution End Age',
      name: 'annual_contribution_end_age',
      width: '30%',
      required: false,
      placeholder: 'End Age',
      value: currentAsset.annual_contribution_end_age,
      isVisible: checkVisibility('annual_contribution_end_age'),
      onChange: (e) => updateCurrentAsset(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'annual_contribution_end_age'),
      errorMessage: GenerateError(modalErrors, 'annual_contribution_end_age'),
    },
    {
      type: 'date',
      label: 'Contribution End Date',
      name: 'annual_contribution_end_date',
      width: '30%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentAsset.annual_contribution_end_date,
      isVisible: checkVisibility('annual_contribution_end_date'),
      onChange: (e) =>
        updateCurrentAsset(e, true, 'annual_contribution_end_date'),
      hasError: CheckHasError(modalErrors, 'annual_contribution_end_date'),
      errorMessage: GenerateError(modalErrors, 'annual_contribution_end_date'),
    },
    {
      type: 'select',
      label: 'Grantor or Custodian',
      name: 'custodian',
      required: false,
      placeholder: 'Grantor or Custodian',
      value: currentAsset.custodian,
      options: [{ label: 'None', value: null }, ...membersList],
      width: '100%',
      isVisible: true,
      onChange: (e) => updateCurrentAsset(e, true, 'custodian'),
      hasError: CheckHasError(modalErrors, 'custodian'),
      errorMessage: GenerateError(modalErrors, 'custodian'),
      onEnter: saveOnEnter,
    },
    {
      type: 'select',
      label: 'Account Type',
      name: 'subcategory',
      required: false,
      placeholder: 'Account Type',
      value: currentAsset.subcategory,
      options: assetAccountTypes,
      width: '48%',
      isVisible: checkVisibility('subcategory'),
      onChange: (e) => updateCurrentAsset(e, true, 'subcategory'),
      hasError: CheckHasError(modalErrors, 'subcategory'),
      errorMessage: GenerateError(modalErrors, 'subcategory'),
      onEnter: saveOnEnter,
    },
    {
      type: 'text',
      label: 'Account Nickname',
      name: 'nickname',
      width: '48%',
      required: false,
      placeholder: 'Account Nickname',
      isVisible: checkVisibility('nickname'),
      value: currentAsset.nickname,
      onChange: (e) => updateCurrentAsset(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'nickname'),
      errorMessage: GenerateError(modalErrors, 'nickname'),
    },
    {
      type: 'select',
      label: 'Risk Profile',
      name: 'risk_preference',
      required: false,
      placeholder: 'Risk Profile',
      value: currentAsset.risk_preference,
      options: assetRiskPreferences,
      width: '48%',
      isVisible: checkVisibility('risk_preference'),
      onChange: (e) => updateCurrentAsset(e, true, 'risk_preference'),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'risk_preference'),
      errorMessage: GenerateError(modalErrors, 'risk_preference'),
      onEnter: saveOnEnter,
    },
    {
      type: 'select',
      label: 'Instrument Choice',
      name: 'instrument_choice',
      required: false,
      placeholder: 'Instrument Choice',
      value: currentAsset.instrument_choice,
      options: assetInstrumentChoices,
      width: '48%',
      isVisible: checkVisibility('instrument_choice'),
      onChange: (e) => updateCurrentAsset(e, true, 'instrument_choice'),
      hasError: CheckHasError(modalErrors, 'instrument_choice'),
      errorMessage: GenerateError(modalErrors, 'instrument_choice'),
      onEnter: saveOnEnter,
    },
  ];

  const notesInput = {
    type: 'textarea',
    label: 'Notes',
    name: 'notes',
    showClear: true,
    id: 'asset',
    value: currentAsset.notes,
    onChange: (e) => updateCurrentAsset(e, true, 'notes'),
  };

  return (
    <InstrumentModal
      name="Asset"
      viewOnly={viewOnly}
      isLoading={!loadedInstrument}
      invisible={invisible}
      hide={hide}
      isNew={isNew}
      // showUndo={showProposalMode && instrument?.is_proposal}
      showProposalMode={showProposalMode}
      modalTabOptions={modalTabOptions}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      currency={currency}
      household={currentHousehold}
      essentialInputs={essentialInputs}
      detailInputs={detailInputs}
      notesInput={notesInput}
      onEnter={saveOnEnter}
      buttonContent={{
        isHidden: isHidden,
        justCopied: clonedInstrument,
        updateHidden: setIsHidden,
        deleteFunction: openDeleteAsset,
        copyFunction: copyInstrument,
        saveFunction: saveAsset,
        showLoading: buttonLoading,
        syncFunction: syncInstrument,
        showSync: showSyncButton,
        isSyncing: isSyncing,
      }}
      modalError={modalError}
      errorTitle={errorTitle}
      proposalContent={{
        ...instrument?.proposal_action_data,
        name: instrument?.name,
        type: 'asset',
        membersList: membersList,
      }}
      integrationContent={{
        content: integrationContent,
        loading: loadingIntegrationContent,
        integration: selectedIntegration,
        instrument: currentAsset,
        error: integrationError,
        lastHouseholdSync: lastHouseholdSync,
      }}
      mode={modalState}
    />
  );
};

AssetModal.propTypes = {
  viewOnly: PropTypes.bool,
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  householdId: PropTypes.string,
  showProposalMode: PropTypes.bool,
  proposalId: PropTypes.string,
  defaultTab: PropTypes.string,
  currency: PropTypes.object,
  invisible: PropTypes.bool,
  isConsumer: PropTypes.bool,
};

export default AssetModal;
