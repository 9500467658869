import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import { boxShadows, colors, fonts } from '../../styles/variables';
import { FlexCenterStart } from '../../styles/library/layoutStyles';
import { GenerateAvatarMemberIcon } from '../../utils';

const handleStyle = { backgroundColor: colors.darkGrey };

const MemberNode = ({ data }) => {
  return (
    <>
      <Handle
        type="source"
        position={Position.Top}
        style={handleStyle}
        id="n"
      />
      <Handle
        type="source"
        position={Position.Right}
        style={handleStyle}
        id="e"
      />
      <Handle
        type="source"
        position={Position.Left}
        style={handleStyle}
        id="w"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={handleStyle}
        id="s"
      />
      <MemberNodeContainer>
        {GenerateAvatarMemberIcon(data.category, true)}
        <MemberInfo>
          <MemberName>
            {data.name}
            {/* <MemberAge>{data.age}</MemberAge> */}
          </MemberName>
          <MemberCategory>{data.role}</MemberCategory>
        </MemberInfo>
      </MemberNodeContainer>
    </>
  );
};

const MemberNodeContainer = styled.div`
  border: 1px solid ${colors.darkGrey};
  border-radius: 5px;
  box-shadow: ${boxShadows.boxShadow};
  padding: 10px 20px 10px 10px;
  ${FlexCenterStart}
  width: 150px;
  font-size: 12px;
  color: ${colors.darkGrey};
  text-align: left;
  background: ${colors.white};
  img,
  svg {
    width: 26px;
    height: 26px;
    flex: 0 0 auto;
  }
`;

const MemberInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const MemberName = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 14px;
`;

const MemberCategory = styled.p`
  font-size: 11px;
  color: ${colors.paleGrey};
  text-transform: capitalize;
`;

// const MemberAge = styled.span`
//   font-weight: ${fonts.regular};
// `;

MemberNode.propTypes = {
  data: PropTypes.object,
};

export default MemberNode;
