/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { darken } from 'polished';
import {
  Button,
  TextInput,
  AdvisorInfoBox,
  Error,
  TypeaheadSearch,
  SimpleModal,
  GenerateInput,
  InfoBox,
} from '..';
import {
  timesWhite,
  institutionBlue,
  usersBlue,
  arrowDown,
  check,
  copy as copyIcon,
} from '../../assets';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import {
  boxShadows,
  colors,
  fonts,
  messageColors,
} from '../../styles/variables';
import { ButtonThemes, ErrorThemes, selectThemes } from '../../styles/themes';
import {
  InputLabel,
  SelectContainer,
  TextInputContainer,
  TextInputRow,
} from '../../styles/library/inputStyles';
import {
  authenticateBoBTransfer,
  createBoBTransfer,
  executeExternalBoBTransfer,
  extendBoBTransfer,
  getAdvisorDetails,
  setBoBTransferError,
  toggleShowCancelTransferModal,
  updateUserStoreValue,
} from '../../store/actions';
import {
  AdvisorCustomSearch,
  DateFormatUTC,
  DetectEnterKeyPress,
  SuccessNotification,
} from '../../utils';

const BoBTransferModal = ({
  hide,
  screen = 'select',
  setScreenValue,
  customerId,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {
    showTransferBoBExternal,
    bobTransferType,
    sourceAdvisorDetails,
    targetAdvisorDetails,
    transferDetails,
    createTransferError,
    authTransferError,
    extendTransferError,
    receiveBoBTransferCode,
    receiveBoBTransferPin,
  } = useSelector((state) => ({
    showTransferBoBExternal: state.user.showTransferBoBExternal,
    bobTransferType: state.user.bobTransferType,
    sourceAdvisorDetails: state.user.sourceAdvisorDetails,
    targetAdvisorDetails: state.user.targetAdvisorDetails,
    transferDetails: state.user.transferDetails,
    createTransferError: state.user.createTransferError,
    authTransferError: state.user.authTransferError,
    extendTransferError: state.user.extendTransferError,
    receiveBoBTransferCode: state.user.receiveBoBTransferCode,
    receiveBoBTransferPin: state.user.receiveBoBTransferPin,
  }));
  const [transferType, setTransferType] = useState(null);
  const [query, setQuery] = useState('');
  const [selectedSourceAdvisor, setSelectedSourceAdvisor] = useState(null);
  const [householdCount, setHouseholdCount] = useState(null);
  const [selectedTargetAdvisor, setSelectedTargetAdvisor] = useState(null);
  const [loadedSourceAdvisor, setLoadedSourceAdvisor] = useState(false);
  const [loadedTargetAdvisor, setLoadedTargetAdvisor] = useState(false);
  const [showConfirmTransfer, setShowConfirmTransfer] = useState(false);
  const [countValue, setCountValue] = useState('');
  const [isTransferring, setIsTransferring] = useState(false);
  const [confirmError, setConfirmError] = useState('');
  const [currentTransfer, setCurrentTransfer] = useState({
    link: '',
    code: '',
    pin: '',
    loaded: false,
  });
  const [authTransfer, setAuthTransfer] = useState({
    code: '',
    codeError: false,
    codeErrorMessage: '',
    pin: '',
    pinError: false,
    pinErrorMessage: '',
  });
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unknown Error');

  //RESET ALL VALUES WHEN MODAL CLOSES
  useEffect(() => {
    return () => {
      resetErrorDisplay();
      setScreenValue('select');
      dispatch(updateUserStoreValue('bobTransferType', null));
    };
  }, []);

  //RESET CONFIRM MODAL FIELDS ON CLOSE
  useEffect(() => {
    if (!showConfirmTransfer) {
      setCountValue('');
      setConfirmError('');
    }
  }, [showConfirmTransfer]);

  //IF TRANSFER TYPE IS SET IN STORE USE THAT VALUE
  //This is for when users only have internal BOB available
  useEffect(() => {
    if (bobTransferType) {
      setTransferType('internal');
    }
  }, [bobTransferType]);

  //IF EXTERNAL TRANSFER AND SEARCHING FOR TARGET ADVISOR SET AS AUTH
  useEffect(() => {
    if (screen === 'target_search_external') {
      setTransferType('external');
      setIsAuthenticating(false);
      setIsAuthenticated(true);
    }
  }, [screen]);

  //HOOK TO SET SOURCE ADVISOR DETAILS
  useEffect(() => {
    if (sourceAdvisorDetails) {
      if (sourceAdvisorDetails?.household_count?.total === 0) {
        setShowError(true);
        setErrorMessage(
          `${sourceAdvisorDetails?.name} has no households to transfer`
        );
      } else {
        setSelectedSourceAdvisor(sourceAdvisorDetails);
        setHouseholdCount(sourceAdvisorDetails?.household_count?.total);
        setLoadedSourceAdvisor(true);
        resetErrorDisplay();
        if (screen !== 'share_external') {
          if (transferType === 'internal') {
            setScreenValue('target_search');
          } else {
            if (screen === 'target_search_external') {
              setScreenValue('target_search');
            } else {
              setScreenValue('create_external');
            }
          }
        }
      }
    }
  }, [sourceAdvisorDetails]);

  //HOOK TO SET TARGET ADVISOR DETAILS
  useEffect(() => {
    if (targetAdvisorDetails) {
      setSelectedTargetAdvisor(targetAdvisorDetails);
      setLoadedTargetAdvisor(true);
    }
  }, [targetAdvisorDetails]);

  //HOOK TO SET SPECIFIC TRANSFER DETAILS LOCALLY
  useEffect(() => {
    if (transferDetails) {
      setIsExpired(transferDetails?.is_expired);
      setCurrentTransfer({ ...transferDetails, loaded: true });
    }
  }, [transferDetails]);

  //HOOK TO SET CODE AND PIN VALUES IF REDIRECTED FROM LINK
  useEffect(() => {
    if (receiveBoBTransferCode && receiveBoBTransferPin) {
      setAuthTransfer({
        code: receiveBoBTransferCode,
        codeError: false,
        codeErrorMessage: '',
        pin: receiveBoBTransferPin,
        pinError: false,
        pinErrorMessage: '',
      });
      dispatch(updateUserStoreValue('receiveBoBTransferCode', null));
      dispatch(updateUserStoreValue('receiveBoBTransferPin', null));
    }
  }, [receiveBoBTransferCode, receiveBoBTransferPin]);

  //ERROR HANDLING FOR CREATING A TRANSFER (INTERNAL & EXTERNAL)
  useEffect(() => {
    if (createTransferError) {
      setIsTransferring(false);
      setShowError(true);
      const errorData =
        createTransferError?.error?.data || createTransferError?.data;
      let errorMessage = 'Error processing request';
      if (errorData) {
        const errors = Object.values(errorData);
        errorMessage = errors;
      }

      setErrorMessage(errorMessage);
    }
  }, [createTransferError]);

  //ERROR HANDLING FOR AUTHENTICATING EXTERNAL TRANSFER
  useEffect(() => {
    if (authTransferError) {
      setIsAuthenticating(false);
      setShowError(true);
      setErrorMessage(authTransferError?.data);
    }
  }, [authTransferError]);

  //ERROR HANDLING FOR EXTENDING EXISTING EXTERNAL TRANSFER
  useEffect(() => {
    if (extendTransferError) {
      setShowError(true);
      setErrorMessage(extendTransferError?.data);
    }
  }, [extendTransferError]);

  const selectTransferType = (type) => {
    setTransferType(type);
    setScreenValue('source_search');
  };

  const transferTypes = [
    {
      value: 'internal',
      icon: usersBlue,
      name: 'Internal',
      info: 'Transfer to another advisor inside your organization',
    },
    {
      value: 'external',
      icon: institutionBlue,
      name: 'External',
      info: 'Transfer to an advisor outside your organization',
    },
  ];

  const loadOptions = () => {
    if (query) {
      return AdvisorCustomSearch(customerId, query);
    }
  };

  const selectSourceAdvisor = (advisor) => {
    setSelectedSourceAdvisor(advisor);
    dispatch(getAdvisorDetails(advisor.uuid, 'source'));
  };

  const selectTargetAdvisor = (advisor) => {
    if (advisor.uuid !== selectedSourceAdvisor.uuid) {
      resetErrorDisplay();
      setSelectedTargetAdvisor(advisor);
      dispatch(getAdvisorDetails(advisor.uuid, 'target'));
      setScreenValue('create_transfer');
    } else {
      setShowError(true);
      setErrorMessage('Source Advisor and Target Advisor cannot be the same');
    }
  };

  const resetErrorDisplay = () => {
    setShowError(false);
    setErrorMessage('');
  };

  const closeSourceAdvisor = () => {
    setQuery('');
    setSelectedSourceAdvisor(null);
    setSelectedTargetAdvisor(null);
    setScreenValue('source_search');
    setLoadedSourceAdvisor(false);
    setLoadedTargetAdvisor(false);
    resetErrorDisplay();
  };

  const closeTargetAdvisor = () => {
    setQuery('');
    setSelectedTargetAdvisor(null);
    setScreenValue('target_search');
    setLoadedTargetAdvisor(false);
    resetErrorDisplay();
  };

  const transferOnClick = () => {
    setShowConfirmTransfer(true);
    resetErrorDisplay();
    dispatch(setBoBTransferError(false));
  };

  const checkConfirmTransfer = () => {
    if (parseInt(countValue) === parseInt(householdCount)) {
      if (isAuthenticated) {
        dispatch(
          executeExternalBoBTransfer(
            currentTransfer.id,
            selectedTargetAdvisor.uuid
          )
        );
      } else {
        dispatch(
          createBoBTransfer({
            category: transferType,
            source_member: selectedSourceAdvisor.uuid,
            target_member:
              transferType === 'internal' ? selectedTargetAdvisor.uuid : null,
          })
        );
      }
      setShowConfirmTransfer(false);
      setIsTransferring(true);
    } else {
      setConfirmError('Household count does not match');
    }
  };

  //Confirm transfer is different for external and then for source and then target
  const generateConfirmTransferContent = () => {
    if (showConfirmTransfer) {
      const isInternal = transferType === 'internal';
      const isPlural = householdCount !== 1;
      let text = 'Unknown Error';
      if (isInternal || isAuthenticated) {
        text = `Are you sure you want to transfer <br/><strong>${householdCount} Household${
          isPlural ? 's' : ''
        }</strong><br/>from <strong>${
          selectedSourceAdvisor?.name
        }</strong> to <strong>${selectedTargetAdvisor?.name}</strong>?`;
      } else {
        //If source show this
        text = `Are you sure you want to create a transfer for<br/><strong>${
          selectedSourceAdvisor?.name
        }'s ${householdCount} Household${isPlural ? 's' : ''}</strong>`;
        //If target show this - NEED TO CREATE
      }
      return {
        heading: 'Confirm Transfer',
        text: text,
        infoBox:
          isInternal || isAuthenticated
            ? null
            : {
                message: `Once the transfer is accepted by the recipient you will lose of this advisor's book of business.`,
                fontSize: '11px',
                margin: '8px 0 15px 0',
              },
        marginTop: '12vh',
        minWidth: '335px',
        inputs: [
          {
            type: 'number',
            name: 'count',
            required: true,
            label: 'Type amount of households to confirm transfer',
            placeholder: 'Number of households to transfer',
            value: countValue,
            onChange: (e) => setCountValue(e.currentTarget.value),
            onKeyPress: (e) => DetectEnterKeyPress(e, checkConfirmTransfer),
            hasError: confirmError !== '',
          },
        ],
        buttons: [
          {
            text: 'Close',
            function: () => setShowConfirmTransfer(false),
            theme: ButtonThemes.cancel,
          },
          {
            text: 'Confirm Transfer',
            function: () => checkConfirmTransfer(),
            theme: ButtonThemes.primary,
          },
        ],
      };
    }
  };

  const copyValue = (transfer, type) => {
    if (
      !currentTransfer.linkCopied &&
      !currentTransfer.codeCopied &&
      !currentTransfer.pinCopied
    ) {
      copy(transfer[type]);
      setCurrentTransfer({
        ...currentTransfer,
        [`${type}Copied`]: true,
      });
      const displayValue = type.charAt(0).toUpperCase() + type.slice(1);
      SuccessNotification(`${displayValue} Copied`, 2.5, 'bottom-left', {
        size: ' 5px',
        style: 'solid',
      });
      setTimeout(() => {
        setCurrentTransfer({ ...currentTransfer, [`${type}Copied`]: false });
      }, 2500);
    }
  };

  const validateTransfer = () => {
    const errorObj = {
      codeError: false,
      codeErrorMessage: null,
      pinError: false,
      pinErrorMessage: null,
    };
    if (authTransfer.code.length === 0) {
      errorObj.codeError = true;
      errorObj.codeErrorMessage = 'Code field cannot be empty';
    } else if (authTransfer.code.length < 32) {
      errorObj.codeError = true;
      errorObj.codeErrorMessage = 'Code value is not correct length';
    }
    if (authTransfer.pin.length === 0) {
      errorObj.pinError = true;
      errorObj.pinErrorMessage = 'PIN field cannot be empty';
    } else if (authTransfer.pin.length !== 6) {
      errorObj.pinError = true;
      errorObj.pinErrorMessage = 'PIN value is not correct length';
    }
    if (errorObj.codeError || errorObj.pinError) {
      setShowError(true);
      setErrorMessage('Please fix errors in order to continue.');
      setAuthTransfer({
        ...authTransfer,
        codeError: errorObj.codeError,
        codeErrorMessage: errorObj.codeErrorMessage,
        pinError: errorObj.pinError,
        pinErrorMessage: errorObj.pinErrorMessage,
      });
    } else {
      setIsAuthenticating(true);
      dispatch(
        authenticateBoBTransfer({
          code: authTransfer.code,
          pin: authTransfer.pin,
        })
      );
      resetErrorDisplay();
    }
  };

  const createTransferTypeDisplay = () => {
    if (transferType) {
      const transferObj = transferTypes.find(
        (transfer) => transfer.value === transferType
      );
      return (
        <TransferTypeLabel>
          <TransferTypeImageContainer width="30px">
            <TransferTypeImage
              src={transferObj?.icon}
              alt={transferObj?.name}
              width="15px"
            />
          </TransferTypeImageContainer>
          <TransferLabelText>{transferObj?.name} Transfer</TransferLabelText>
        </TransferTypeLabel>
      );
    }
  };

  return ReactDOM.createPortal(
    <>
      {showConfirmTransfer && (
        <SimpleModal
          hide={() => setShowConfirmTransfer(false)}
          content={generateConfirmTransferContent()}
          error={confirmError}
        />
      )}
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>Transfer Book of Business</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            {showTransferBoBExternal && createTransferTypeDisplay()}
            {screen === 'select' && (
              <SelectBobTypeContainer>
                <InfoText>
                  {`Where would you like to transfer a member's Book of
                      Business?`}
                </InfoText>
                {transferTypes.map((type, index) => {
                  return (
                    <TransferTypeBox
                      key={index}
                      onClick={() => selectTransferType(type.value)}
                    >
                      <TransferTypeImageContainer>
                        <TransferTypeImage src={type.icon} alt={type.name} />
                      </TransferTypeImageContainer>
                      <TransferTypeTextContainer>
                        <TransferTypeText>{type.name}</TransferTypeText>
                        <TransferTypeInfoText>{type.info}</TransferTypeInfoText>
                      </TransferTypeTextContainer>
                    </TransferTypeBox>
                  );
                })}
              </SelectBobTypeContainer>
            )}
            {screen === 'source_search' && (
              <div>
                <InfoText>
                  Select the advisor whose book of business you want to transfer
                </InfoText>
                <SelectContainer margin={'0 0 15px 0'}>
                  <InputLabel fontSize="13px">Source Advisor</InputLabel>
                  <TypeaheadSearch
                    showSearchIcon={true}
                    largeIcon={false}
                    value={selectedSourceAdvisor}
                    loadOptions={loadOptions}
                    onChange={(e) => setQuery(e)}
                    placeholder={'Search for advisor by last name or email'}
                    isMulti={false}
                    styles={selectThemes.inputSearch}
                    onSelectChange={(e) => selectSourceAdvisor(e)}
                  />
                </SelectContainer>
              </div>
            )}
            {(screen === 'target_search' ||
              screen === 'target_search_external') && (
              <div>
                <div>
                  <InputLabel fontSize="13px">Source Advisor</InputLabel>
                  <AdvisorInfoBox
                    loaded={loadedSourceAdvisor}
                    advisor={selectedSourceAdvisor}
                    showHouseholdDetails={true}
                    showClose={transferType === 'internal'}
                    closeOnClick={
                      transferType === 'internal' ? closeSourceAdvisor : null
                    }
                  />
                </div>
                <DownArrow src={arrowDown} alt="arrow-down" />
                <InfoText margin="8px 0 15px 0">
                  Select which advisor you want to receive the book of business.
                </InfoText>
                <SelectContainer margin={'0 0 15px 0'}>
                  <InputLabel fontSize="13px">Target Advisor</InputLabel>
                  <TypeaheadSearch
                    showSearchIcon={true}
                    largeIcon={false}
                    value={selectedTargetAdvisor}
                    loadOptions={loadOptions}
                    onChange={(e) => setQuery(e)}
                    placeholder={'Search for advisor by last name or email'}
                    isMulti={false}
                    styles={selectThemes.inputSearch}
                    onSelectChange={(e) => selectTargetAdvisor(e)}
                  />
                </SelectContainer>
              </div>
            )}
            {screen === 'create_transfer' && (
              <div>
                <div>
                  <InputLabel fontSize="13px">Source Advisor</InputLabel>
                  <AdvisorInfoBox
                    loaded={loadedSourceAdvisor}
                    advisor={selectedSourceAdvisor}
                    showHouseholdDetails={true}
                    showClose={transferType === 'internal'}
                    closeOnClick={
                      transferType === 'internal'
                        ? isTransferring
                          ? null
                          : closeSourceAdvisor
                        : null
                    }
                  />
                </div>
                <DownArrow src={arrowDown} alt="arrow-down" />
                <div>
                  <InputLabel fontSize="13px">Target Advisor</InputLabel>
                  <AdvisorInfoBox
                    loaded={loadedTargetAdvisor}
                    advisor={selectedTargetAdvisor}
                    showHouseholdDetails={false}
                    showClose={true}
                    closeOnClick={isTransferring ? null : closeTargetAdvisor}
                  />
                </div>
                <ButtonsRowContainer>
                  <ButtonContainer
                    primaryButtonWidth={isTransferring ? '130px' : '110px'}
                  >
                    <Button
                      text={'Cancel'}
                      onClick={() => hide()}
                      theme={ButtonThemes.cancel}
                    />
                    <Button
                      text={'Transfer'}
                      onClick={() => transferOnClick()}
                      showLoading={isTransferring}
                      loadingText={'Transferring'}
                    />
                  </ButtonContainer>
                </ButtonsRowContainer>
              </div>
            )}
            {screen === 'create_external' && (
              <div>
                <div>
                  <InputLabel fontSize="13px">Source Advisor</InputLabel>
                  <AdvisorInfoBox
                    loaded={loadedSourceAdvisor}
                    advisor={selectedSourceAdvisor}
                    showHouseholdDetails={true}
                    showClose={true}
                    closeOnClick={isTransferring ? null : closeSourceAdvisor}
                  />
                </div>
                <InfoBox
                  message={`Creating a transfer will allow another user to take
                        control of this advisor's book of business.`}
                />
                <ButtonsRowContainer>
                  <ButtonContainer primaryButtonWidth={'130px'}>
                    <Button
                      text={'Cancel'}
                      onClick={() => hide()}
                      theme={ButtonThemes.cancel}
                    />
                    <Button
                      text={'Create Transfer'}
                      onClick={() => transferOnClick()}
                      showLoading={isTransferring}
                      loadingText={'Transferring'}
                    />
                  </ButtonContainer>
                </ButtonsRowContainer>
              </div>
            )}
            {screen === 'share_external' && (
              <div>
                <div>
                  <InputLabel fontSize="13px">Source Advisor</InputLabel>
                  <AdvisorInfoBox
                    loaded={loadedSourceAdvisor}
                    advisor={selectedSourceAdvisor}
                    showHouseholdDetails={true}
                    showClose={false}
                    closeOnClick={null}
                  />
                </div>
                {isExpired ? (
                  <Error
                    errorMessage="This transfer has expired. Please
                          click <strong>Reactivate Transfer</strong> to keep this transfer active."
                    theme={{ ...ErrorThemes.inverted, margin: '15px 0' }}
                  />
                ) : (
                  <InfoBox
                    margin="15px 0"
                    message={`Please send the link or the code and PIN to the receiving Team Manager to complete the transfer.`}
                  />
                )}
                <div>
                  <TextInputContainer padding="9.1px 30px 9.1px 12px">
                    <InputLabel>
                      Link
                      {isExpired ? (
                        <DateExpired>Expired</DateExpired>
                      ) : (
                        <ExpiryDate>
                          Valid Until{' '}
                          {DateFormatUTC(
                            currentTransfer.expires_utc,
                            `MMM D, YYYY h:mma`,
                            true
                          )}
                        </ExpiryDate>
                      )}
                    </InputLabel>
                    <TextInput
                      type={'text'}
                      name={'link'}
                      onChange={() => null}
                      placeholder={`Link`}
                      value={currentTransfer.link}
                      withIcon={true}
                      iconName={currentTransfer.linkCopied ? 'check' : 'copy'}
                      iconSrc={currentTransfer.linkCopied ? check : copyIcon}
                      onClick={
                        isExpired
                          ? null
                          : () => copyValue(currentTransfer, 'link')
                      }
                      onFocus={isExpired ? null : (e) => e.target.select()}
                      disabled={isExpired}
                    />
                  </TextInputContainer>
                  <TextInputRow>
                    <TextInputContainer
                      width="75%"
                      padding="9.1px 30px 9.1px 12px"
                    >
                      <InputLabel>Code</InputLabel>
                      <TextInput
                        type={'text'}
                        name={'code'}
                        onChange={() => null}
                        placeholder={`Code`}
                        value={currentTransfer.code}
                        withIcon={true}
                        iconName={currentTransfer.codeCopied ? 'check' : 'copy'}
                        iconSrc={currentTransfer.codeCopied ? check : copyIcon}
                        onClick={
                          isExpired
                            ? null
                            : () => copyValue(currentTransfer, 'code')
                        }
                        onFocus={isExpired ? null : (e) => e.target.select()}
                        disabled={isExpired}
                      />
                    </TextInputContainer>
                    <TextInputContainer
                      width="25%"
                      margin="0 0 10px 10px"
                      padding="9.1px 30px 9.1px 12px"
                    >
                      <InputLabel>PIN</InputLabel>
                      <TextInput
                        type={'text'}
                        name={'pin'}
                        onChange={() => null}
                        placeholder={`PIN`}
                        value={currentTransfer.pin}
                        withIcon={true}
                        iconName={currentTransfer.pinCopied ? 'check' : 'copy'}
                        iconSrc={currentTransfer.pinCopied ? check : copyIcon}
                        onClick={
                          isExpired
                            ? null
                            : () => copyValue(currentTransfer, 'pin')
                        }
                        onFocus={isExpired ? null : (e) => e.target.select()}
                        disabled={isExpired}
                      />
                    </TextInputContainer>
                  </TextInputRow>
                </div>
                <ButtonsRowContainer>
                  <ButtonContainer primaryButtonWidth={'155px'}>
                    <Button
                      text={'Close'}
                      onClick={() => hide()}
                      theme={ButtonThemes.cancel}
                    />
                    <Button
                      text={
                        isExpired ? 'Reactivate Transfer' : 'Cancel Transfer'
                      }
                      onClick={() =>
                        isExpired
                          ? dispatch(extendBoBTransfer(currentTransfer.id))
                          : dispatch(toggleShowCancelTransferModal(true))
                      }
                      theme={
                        isExpired ? ButtonThemes.primary : ButtonThemes.error
                      }
                    />
                  </ButtonContainer>
                </ButtonsRowContainer>
              </div>
            )}
            {screen === 'auth_external' && (
              <div>
                <InfoText margin="8px 0 15px 0">
                  To receive a Book of Business from another customer, please
                  enter the code and pin below:
                </InfoText>
                <div>
                  <TextInputRow>
                    {GenerateInput({
                      isVisible: true,
                      width: '75%',
                      label: 'Code',
                      type: 'text',
                      value: authTransfer.code,
                      placeholder: 'Code',
                      hasError: authTransfer.codeError,
                      errorMessage: authTransfer.codeErrorMessage,
                      onChange: (e) =>
                        setAuthTransfer({
                          ...authTransfer,
                          code: e.currentTarget.value,
                          codeError: false,
                          codeErrorMessage: '',
                        }),
                    })}
                    {GenerateInput({
                      isVisible: true,
                      width: '25%',
                      margin: '0 0 10px 10px',
                      label: 'PIN',
                      type: 'number',
                      value: authTransfer.pin,
                      placeholder: 'PIN',
                      hasError: authTransfer.pinError,
                      errorMessage: authTransfer.pinErrorMessage,
                      onChange: (e) =>
                        setAuthTransfer({
                          ...authTransfer,
                          pin: e.currentTarget.value,
                          pinError: false,
                          pinErrorMessage: '',
                        }),
                    })}
                  </TextInputRow>
                </div>
                <ButtonsRowContainer>
                  <ButtonContainer
                    primaryButtonWidth={isAuthenticating ? '150px' : '130px'}
                  >
                    <Button
                      text={'Close'}
                      onClick={() => hide()}
                      theme={ButtonThemes.cancel}
                    />
                    <Button
                      text={'Start Transfer'}
                      onClick={validateTransfer}
                      showLoading={isAuthenticating}
                      loadingText={'Authenticating'}
                    />
                  </ButtonContainer>
                </ButtonsRowContainer>
              </div>
            )}
          </ModalContentContainer>
          {showError && <Error errorMessage={errorMessage} />}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const SelectBobTypeContainer = styled.div``;

const InfoText = styled.p`
  margin-top: 5px;
  text-align: left;
  color: ${colors.darkGrey};
  font-size: 13px;
  margin: ${(props) => (props.margin ? props.margin : '5px 0 20px 0')};
`;

const TransferTypeBox = styled.div`
  padding: 10px 15px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 6px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 10px 0;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
    box-shadow: ${boxShadows.boxShadowLight};
    border: ${() => `1px solid ${darken(0.05, colors.lightGrey)}`};
  }
`;

const TransferTypeImageContainer = styled.div`
  height: ${(props) => (props.width ? props.width : '60px')};
  width: ${(props) => (props.width ? props.width : '60px')};
  border-radius: 100%;
  background: rgba(83, 181, 205, 0.15);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const TransferTypeImage = styled.img`
  height: ${(props) => (props.width ? props.width : '30px')};
  width: ${(props) => (props.width ? props.width : '30px')};
`;

const TransferTypeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  text-align: left;
  margin-left: 20px;
`;

const TransferTypeText = styled.p`
  color: ${colors.darkGrey};
  font-size: 16px;
  font-weight: ${fonts.semiBold};
  margin-bottom: 2px;
`;

const TransferTypeInfoText = styled.p`
  color: ${colors.paleGrey};
  font-size: 12px;
  font-weight: ${fonts.regular};
`;

const DownArrow = styled.img`
  width: 14px;
  height: 14px;
  margin-top: 10px;
`;

const ExpiryDate = styled.span`
  text-align: right;
  flex: 1 1 auto;
  color: ${colors.paleGrey};
`;

const DateExpired = styled(ExpiryDate)`
  color: ${messageColors.error};
  text-transform: uppercase;
`;

const TransferTypeLabel = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: -5px 0 10px 0;
  /* margin: -5px 0 15px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.lightGrey}; */
`;

const TransferLabelText = styled.p`
  margin-left: 10px;
  font-weight: ${fonts.semiBold};
  /* color: ${colors.lightBlue}; */
  font-size: 15px;
`;

BoBTransferModal.propTypes = {
  hide: PropTypes.func,
  customerId: PropTypes.string,
  screen: PropTypes.string,
  setScreenValue: PropTypes.func,
};

export default BoBTransferModal;
