/* eslint-disable react-hooks/rules-of-hooks */
import { formatMoney } from 'accounting-js';
import {
  textFilterOptions,
  integerFilterOptions,
  DateFormatUTC,
  DateFromNowUTC,
} from '.';
import { DateDiff } from '.';
import {
  briefcase,
  lock,
  unlocked,
  institution,
  folderIcon,
  aviIcon,
  mp4Icon,
  mp3Icon,
  jpgIcon,
  pngIcon,
  gifIcon,
  docIcon,
  pptIcon,
  xlsIcon,
  unknownIcon,
  csvIcon,
  htmlIcon,
  jsIcon,
  xmlIcon,
  zipIcon,
  jsonIcon,
  txtIcon,
  rtfIcon,
  svgIcon,
  pdfIcon,
} from '../assets';
import { colors, messageColors, targetMapColors } from '../styles/variables';
import { StatusLabel } from '../styles/library/fontStyles';

//TRANSFORMS ARRAY TO HAVE VALUES NEEDED FOR SELECT DROPDOWN
export const TransformSelectOptions = (list, label, value) => {
  if (list) {
    return list.map((item) => {
      item.label = item[label];
      item.value = item[value];
      return item;
    });
  }
};

//TAKES ARRAYS AND RETURNS A SINGLE UNIQUE ARRAY
export const MergeArrays = (...arrays) => {
  let jointArray = [];

  arrays.forEach((array) => {
    jointArray = [...jointArray, ...array];
  });
  return [...new Set([...jointArray])];
};

//TRANSFORMS CURRENCY LIST FOR SELECT DROPDOWN DISPLAY
export const GetCurrencies = (currencies) => {
  if (currencies && currencies.length >= 1) {
    return currencies.map((item) => {
      let obj = {};
      obj.value = item.code;
      obj.label = item.name;
      return obj;
    });
  }
};

//TRANSFORMS COUNTRY LIST FOR SELECT DROPDOWN DISPLAY
export const GetCountries = (countries) => {
  if (countries && countries.length >= 1) {
    return countries.map((item) => {
      let obj = {};
      obj.value = item.code;
      obj.label = item.name;
      return obj;
    });
  }
};

//TRANSFORMS STATES LIST FOR SELECT DROPDOWN DISPLAY
export const GetStates = (states) => {
  return states.map((item) => {
    let obj = {};
    obj.value = item.abbreviation;
    obj.label = item.name;
    return obj;
  });
};

//CALCULATES AGE FROM DOB
export const GenerateAge = (dob, dateFormat) => {
  if (dateFormat === undefined) {
    dateFormat = 'MM/DD/YYYY';
  }
  let age = DateDiff(undefined, dob);
  return age;
};

//DETERMINES DISPLAY GENDER
export const TransformGender = (gender) => {
  switch (gender) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    default:
      return 'Not Specified';
  }
};

//DETERMINES INTERVAL FROM INTEGER
export const TransformInterval = (interval) => {
  switch (interval) {
    case 1:
      return 'Annual';
    case 2:
      return 'Semi-Annual';
    case 4:
      return 'Quarter';
    case 6:
      return 'Bi-month';
    case 12:
      return 'Month';
    case 24:
      return 'Semi-Month';
    case 26:
      return 'Bi-week';
    case 52:
      return 'Week';
    case 365:
      return 'Daily';
    default:
      return 'N/A';
  }
};

//CREATES DISPLAY VALUES FOR ENTITIES
export const GenerateEntityLabel = (value, categories = null) => {
  if (typeof value === 'object' && value !== null) {
    value = value.key;
  }
  if (categories) {
    let entityCategory = value;
    const category = categories.find((cat) => cat.value === value);
    if (category?.label) {
      entityCategory = category.label;
    }
    return entityCategory;
  }
  switch (value) {
    case 'corp':
      return 'Corporation - C Corp';
    case 'scorp':
      return 'Corporation - S Corp';
    case 'llc':
      return 'Corporation - LLC';
    case 'trust':
      return 'Trust - Irrevocable';
    case 'revtrust':
      return 'Trust - Revocable';
    case 'pubcharity':
      return 'Charity - Public';
    case 'corpother':
      return 'Other';
    case 'foundation':
      return 'Private Foundation';
    case 'sole':
      return 'Sole Proprietorship';
    case 'estate':
      return 'Estate';
    case 'buspart':
      return 'Business Partner';
    default:
      return value;
  }
};

//RETURNS ICON FOR ENTITIES
export const GenerateEntityIcon = (value) => {
  if (typeof value === 'object' && value !== null) {
    value = value.key;
  }
  switch (value) {
    case 'corp':
    case 'scorp':
    case 'llc':
    case 'sole':
    case 'corpother':
    case 'partnership':
      return briefcase;
    case 'trust':
      return lock;
    case 'revtrust':
      return unlocked;
    case 'charity':
    case 'estate':
    case 'pubcharity':
    case 'foundation':
    case 'institution':
      return institution;
    default:
      return briefcase;
  }
};

//RETURNS ICON FOR ENTITIES
export const GenerateAvatarMemberIcon = (role, isMember = false) => {
  if (typeof role === 'object' && role !== null) {
    role = role.key;
  }
  if (isMember) {
    return (
      <svg
        width="175px"
        height="200px"
        viewBox="0 0 175 200"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        data-image={`${role}-icon`}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={DetermineRoleColor(role)} fillRule="nonzero">
            <path d="M122.5,112.5 C111.289063,112.5 105.898438,118.75 87.5,118.75 C69.1015625,118.75 63.75,112.5 52.5,112.5 C23.515625,112.5 0,136.015625 0,165 L0,181.25 C0,191.601562 8.3984375,200 18.75,200 L156.25,200 C166.601562,200 175,191.601562 175,181.25 L175,165 C175,136.015625 151.484375,112.5 122.5,112.5 Z M162.5,181.25 C162.5,184.6875 159.6875,187.5 156.25,187.5 L18.75,187.5 C15.3125,187.5 12.5,184.6875 12.5,181.25 L12.5,165 C12.5,142.929688 30.4296875,125 52.5,125 C60.15625,125 67.7734375,131.25 87.5,131.25 C107.1875,131.25 114.84375,125 122.5,125 C144.570312,125 162.5,142.929688 162.5,165 L162.5,181.25 Z M87.5,100 C115.117188,100 137.5,77.6171875 137.5,50 C137.5,22.3828125 115.117188,0 87.5,0 C59.8828125,0 37.5,22.3828125 37.5,50 C37.5,77.6171875 59.8828125,100 87.5,100 Z M87.5,12.5 C108.164062,12.5 125,29.3359375 125,50 C125,70.6640625 108.164062,87.5 87.5,87.5 C66.8359375,87.5 50,70.6640625 50,50 C50,29.3359375 66.8359375,12.5 87.5,12.5 Z" />
          </g>
        </g>
      </svg>
    );
  }
  switch (role) {
    case 'corp':
    case 'scorp':
    case 'llc':
    case 'sole':
    case 'corpother':
    case 'partnership':
      return (
        <svg
          width="278"
          height="244"
          viewBox="0 0 278 244"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-image={`${role}-icon`}
        >
          <path
            d="M251.938 52.2857H191.125V13.0714C191.125 5.84946 185.294 0 178.094 0H99.9062C92.7065 0 86.875 5.84946 86.875 13.0714V52.2857H26.0625C11.6684 52.2857 0 63.9901 0 78.4286V217.857C0 232.296 11.6684 244 26.0625 244H251.938C266.332 244 278 232.296 278 217.857V78.4286C278 63.9901 266.332 52.2857 251.938 52.2857ZM104.25 17.4286H173.75V52.2857H104.25V17.4286ZM260.625 217.857C260.625 222.661 256.726 226.571 251.938 226.571H26.0625C21.2735 226.571 17.375 222.661 17.375 217.857V139.429H104.25V161.214C104.25 168.431 110.087 174.286 117.281 174.286H160.719C167.913 174.286 173.75 168.431 173.75 161.214V139.429H260.625V217.857ZM121.625 156.857V139.429H156.375V156.857H121.625ZM260.625 122H17.375V78.4286C17.375 73.6248 21.2735 69.7143 26.0625 69.7143H251.938C256.726 69.7143 260.625 73.6248 260.625 78.4286V122Z"
            fill={DetermineRoleColor(role)}
          />
        </svg>
      );
    case 'trust':
      return (
        <svg
          width="213"
          height="244"
          viewBox="0 0 213 244"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-image={`${role}-icon`}
        >
          <path
            d="M190.179 106.75H182.571V76.9651C182.571 34.8371 148.767 0.143414 106.738 0.000445777C64.6607 -0.142523 30.4286 34.1223 30.4286 76.2503V106.75H22.8214C10.2221 106.75 0 116.996 0 129.625V221.125C0 233.754 10.2221 244 22.8214 244H190.179C202.778 244 213 233.754 213 221.125V129.625C213 116.996 202.778 106.75 190.179 106.75ZM45.6429 76.2503C45.6429 42.6051 72.9335 15.2504 106.5 15.2504C140.067 15.2504 167.357 42.6051 167.357 76.2503V106.75H45.6429V76.2503ZM190.179 228.75H22.8214C18.6375 228.75 15.2143 225.319 15.2143 221.125V129.625C15.2143 125.431 18.6375 122 22.8214 122H190.179C194.363 122 197.786 125.431 197.786 129.625V221.125C197.786 225.319 194.363 228.75 190.179 228.75Z"
            fill={DetermineRoleColor(role)}
          />
        </svg>
      );
    case 'revtrust':
      return (
        <svg
          width="193"
          height="257"
          viewBox="0 0 193 257"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-image={`${role}-icon`}
        >
          <path
            d="M168.875 128.5H48.25V80.3128C48.25 44.875 61.0161 16.0629 96.5 16.0629C131.984 16.0629 144.75 44.875 144.75 80.3128V90.3519C144.75 93.6648 147.464 96.3753 150.781 96.3753H154.802C158.119 96.3753 160.833 93.6648 160.833 90.3519V81.0658C160.833 36.6932 141.182 0.151055 96.7513 0.000469528C52.2708 -0.150116 32.1667 35.9402 32.1667 80.3128V128.5H24.125C10.806 128.5 0 139.292 0 152.594V232.906C0 246.208 10.806 257 24.125 257H168.875C182.194 257 193 246.208 193 232.906V152.594C193 139.292 182.194 128.5 168.875 128.5ZM176.917 232.906C176.917 237.323 173.298 240.938 168.875 240.938H24.125C19.7021 240.938 16.0833 237.323 16.0833 232.906V152.594C16.0833 148.177 19.7021 144.563 24.125 144.563H168.875C173.298 144.563 176.917 148.177 176.917 152.594V232.906Z"
            fill={DetermineRoleColor(role)}
          />
        </svg>
      );
    case 'charity':
    case 'pubcharity':
    case 'foundation':
    case 'institution':
    case 'estate':
      return (
        <svg
          width="293"
          height="257"
          viewBox="0 0 293 257"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-image={`${role}-icon`}
        >
          <path
            d="M283.844 238.643H274.688V215.696C274.688 208.093 268.539 201.929 260.953 201.929H238.062V100.964H219.75V201.929H183.125V100.964H164.812V201.929H128.188V100.964H109.875V201.929H73.25V100.964H54.9375V201.929H32.0469C24.4615 201.929 18.3125 208.093 18.3125 215.696V238.643H9.15625C4.09914 238.643 2.17373e-08 242.752 2.17373e-08 247.821V252.411C2.17373e-08 253.628 0.482337 254.795 1.3409 255.656C2.19947 256.516 3.36393 257 4.57812 257H288.422C289.636 257 290.801 256.516 291.659 255.656C292.518 254.795 293 253.628 293 252.411V247.821C293 242.752 288.901 238.643 283.844 238.643ZM36.625 220.286H256.375V238.643H36.625V220.286ZM288.575 59.6607L155.186 1.41293C149.548 -0.470977 143.452 -0.470977 137.814 1.41293L4.42533 59.6607C3.12393 60.157 2.00376 61.0381 1.21342 62.1872C0.423072 63.3363 -0.000110741 64.6991 2.17373e-08 66.0949V71.1339C2.17373e-08 74.9356 3.07478 78.0179 6.86719 78.0179H18.3125V84.9018C18.3125 88.7034 21.3873 91.7857 25.1797 91.7857H267.82C271.613 91.7857 274.688 88.7034 274.688 84.9018V78.0179H286.133C289.925 78.0179 293 74.9356 293 71.1339V66.0949C293 63.2369 291.239 60.6767 288.575 59.6607ZM256.375 73.4286H36.625V65.6233L144.178 18.6572C145.7 18.2573 147.3 18.2573 148.822 18.6572L256.375 65.6233V73.4286Z"
            fill={DetermineRoleColor(role)}
          />
        </svg>
      );
    default:
      return (
        <svg
          width="278"
          height="244"
          viewBox="0 0 278 244"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-image={`${role}-icon`}
        >
          <path
            d="M251.938 52.2857H191.125V13.0714C191.125 5.84946 185.294 0 178.094 0H99.9062C92.7065 0 86.875 5.84946 86.875 13.0714V52.2857H26.0625C11.6684 52.2857 0 63.9901 0 78.4286V217.857C0 232.296 11.6684 244 26.0625 244H251.938C266.332 244 278 232.296 278 217.857V78.4286C278 63.9901 266.332 52.2857 251.938 52.2857ZM104.25 17.4286H173.75V52.2857H104.25V17.4286ZM260.625 217.857C260.625 222.661 256.726 226.571 251.938 226.571H26.0625C21.2735 226.571 17.375 222.661 17.375 217.857V139.429H104.25V161.214C104.25 168.431 110.087 174.286 117.281 174.286H160.719C167.913 174.286 173.75 168.431 173.75 161.214V139.429H260.625V217.857ZM121.625 156.857V139.429H156.375V156.857H121.625ZM260.625 122H17.375V78.4286C17.375 73.6248 21.2735 69.7143 26.0625 69.7143H251.938C256.726 69.7143 260.625 73.6248 260.625 78.4286V122Z"
            fill={DetermineRoleColor(role)}
          />
        </svg>
      );
  }
};

//CREATES CATEGORY DISPLAY VALUE  FOR INSTRUMENTS
export const GenerateInstrumentType = (type, instrumentCategories) => {
  const matchedCategory = instrumentCategories.find(
    (cat) => cat.value === type
  );
  if (matchedCategory?.label) {
    return matchedCategory?.label;
  }

  return type;
};

//TAKES ARRAY OF NOTIFICATIONS AND ADDS TWO TRANSFORMED DATE OPTIONS
export const TransformNotificationDate = (notifications) => {
  return notifications.map((notification) => {
    notification.date = DateFormatUTC(notification.created_utc, 'LLL', true);
    notification.relative = DateFromNowUTC(notification.created_utc);
    return notification;
  });
};

/*
  TURNS OBJECT OF OBJECTS INTO ARRAY OF OBJECTS
  - saveKey <boolean> - if true sets object key to fieldName in new object
 */
export const ConvertObjectToArray = (object, saveKey = false) => {
  return Object.entries(object).map((e) => {
    if (saveKey) {
      return { fieldName: e[0], ...e[1] };
    } else {
      return e[1];
    }
  });
};

//TURNS SIGNALS OBJECT OF OBJECTS INTO ARRAY OF OBJECTS
export const ConvertSignalsObjectToArray = (object) => {
  if (object) {
    let newObject = {
      calculated_utc: object?.calculated_utc,
      signals: [],
    };
    newObject.signals = Object.entries(object).reduce((acc, curr) => {
      if (curr[1].hasOwnProperty('score')) {
        return [...acc, { name: curr[0], ...curr[1] }];
      }
      return acc;
    }, []);
    return newObject;
  } else {
    return false;
  }
};

//CREATES CATEGORY DISPLAY VALUE  FOR INSTRUMENTS
// const GenerateInstrumentTypeOld = (type, instrumentCategories) => {
//   if (instrumentCategories) {
//     let matchedObj = instrumentCategories[type];
//     if (matchedObj) {
//       return matchedObj.name;
//     }
//   } else {
//     return type;
//   }
// };

//TURNS ENCODED TEXT INTO HTML THEN INTO STRING
export const HtmlToString = (string) => {
  return new DOMParser()
    .parseFromString(string, 'text/html')
    .querySelector('html').textContent;
};

//REPLACES NULL VALUES WITH EMPTY STRING
export const PruneObjectNulls = (object) => {
  let updatedObject = {};
  for (const [key, value] of Object.entries(object)) {
    if (value === null) {
      updatedObject[key] = '';
    } else {
      updatedObject[key] = value;
    }
  }
  return updatedObject;
};

//TAKES AN ARRAY AND SETS THE PASSED IN FIELD NAME TO THE PASSED IN VALUE
export const SetObjectFieldValue = (list, field, value = false) => {
  return list.map((item) => {
    item[field] = value;
    return item;
  });
};

//DETERMINES COLOR FOR MEMBER DISPLAY
export const DetermineRoleColorOld = (role) => {
  switch (role) {
    case 'primary':
      return colors.blue;
    case 'spouse':
      return colors.orange;
    case 'partner':
      return colors.pink;
    case 'dependent':
      return colors.lightBlue;
    case 'family':
      return colors.teal;
    case 'friend':
      return colors.orange;
    case 'trustee':
      return colors.darkGrey;
    case 'buspart':
      return colors.green;
    case 'employee':
      return colors.rock;
    case 'employer':
      return colors.golden;
    case 'accountant':
      return colors.teal;
    case 'attorney':
      return colors.wedge;
    case 'other':
      return colors.palePink;
    default:
      return colors.paleGrey;
  }
};

//DETERMINES COLOR FOR MEMBER DISPLAY
export const DetermineRoleColor = (role) => {
  switch (role) {
    case 'primary':
      return colors.blue;
    case 'spouse':
      return colors.orange;
    case 'partner':
      return colors.green;
    case 'dependent':
      return colors.lightBlue;
    case 'family':
    case 'parent':
    case 'child':
    case 'sibling':
    case 'grandparent':
    case 'grandchild':
    case 'pet':
      return colors.pink;
    case 'friend':
      return colors.darkGrey;
    case 'trustee':
      return colors.teal;
    case 'buspart':
    case 'busprof':
      return colors.wedge;
    case 'employee':
      return colors.rock;
    case 'employer':
      return colors.golden;
    case 'accountant':
      return colors.rose;
    case 'attorney':
      return colors.avocado;
    case 'other':
      return colors.palePink;
    case 'corp':
      return colors.blue;
    case 'scorp':
      return colors.orange;
    case 'llc':
      return colors.green;
    case 'sole':
      return colors.lightBlue;
    case 'corpother':
      return colors.pink;
    case 'partnership':
      return colors.darkGrey;
    case 'trust':
      return colors.wedge;
    case 'revtrust':
      return colors.teal;
    case 'charity':
      return colors.golden;
    case 'pubcharity':
    case 'estate':
      return colors.rose;
    case 'foundation':
      return colors.avocado;
    case 'institution':
      return colors.rock;
    default:
      return colors.paleGrey;
  }
};

//DETERMINES COLOR FOR TARGET-MAP BAR & CIRCLE DISPLAY
export const DetermineTargetMapColor = (type) => {
  switch (type) {
    case 'death':
      return targetMapColors.death;
    case 'disability':
      return targetMapColors.disability;
    case 'ltc':
      return targetMapColors.ltc;
    case 'education':
      return targetMapColors.education;
    case 'retirement':
      return targetMapColors.retirement;
    case 'custom':
      return targetMapColors.custom;
    case 'user':
      return targetMapColors.user;
    default:
      return targetMapColors.other;
  }
};

// DETERMINES DROPDOWN OPTIONS FOR FILTER MODAL
export const DetermineOptions = (type) => {
  switch (type) {
    case 'integerFormatter':
      return integerFilterOptions;
    case 'currencyFormatter':
      return integerFilterOptions;
    case 'ratioFormatter':
      return integerFilterOptions;
    case 'textFormatter':
      return textFilterOptions;
    case 'linkedFormatter':
    case 'capitalizedTextFormatter':
    case 'iconFormatter':
      return [
        { label: 'Is', value: 'is', not: false },
        { label: 'Is Not', value: 'is_not', not: true },
      ];
    case 'dateFormatter':
      return [
        {
          label: 'Not Available',
          value: 'none',
        },
      ];
    case 'booleanFormatter':
      return [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ];
    case 'status':
      return [
        { label: 'Client', value: 'client' },
        { label: 'Prospective', value: 'prospect' },
        { label: 'Sample', value: 'sample' },
        { label: 'Stencil', value: 'stencil' },
      ];
    case 'signal_ltc_state':
    case 'signal_ltd_state':
    case 'signal_liability_state':
    case 'signal_liquidity_state':
    case 'signal_longevity_state':
    case 'signal_life_state':
      return [
        { label: 'Green', value: 'green' },
        { label: 'Yellow', value: 'yellow' },
        { label: 'Red', value: 'red' },
        // { label: 'Snoozed', value: 'snoozed' },
        { label: 'None', value: 'none' },
      ];
    default:
      return [
        {
          label: 'Not Available',
          value: 'none',
        },
      ];
  }
};

//TRANSFORMS COLUMN ALIGNMENT FOR HHI
export const DetermineTextAlign = (position) => {
  switch (position) {
    case 'right':
      return 'flex-end';
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    default:
      return 'flex-end';
  }
};

//TARGETMAP AMOUNT PLACEHOLDER TEXT FOR AMOUNT IF DIFFERENT
export const CreateAmountPlaceholder = (
  instrumentInfo,
  currency,
  placeholder = 'Amount'
) => {
  if (instrumentInfo && instrumentInfo.hasOwnProperty('default_amount')) {
    return FormatCurrency(instrumentInfo?.default_amount, currency);
  } else {
    return placeholder;
  }
};

//CHECK IF TARGETMAP VALUE DIFFERS FROM INSTRUMENT
export const CheckOverwritten = (isNew, instrumentInfo) => {
  if (isNew) return false;
  if (instrumentInfo && instrumentInfo.hasOwnProperty('default_amount')) {
    if (instrumentInfo?.amount !== instrumentInfo?.default_amount) {
      return true;
    }
    return false;
  }
  return false;
};

export const FormatPhoneNumber = (number) => {
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
};

//RETURNS CURRENCY SYMBOL FROM CURRENCY OBJECT
export const TransformCurrencySymbol = (currency) => {
  if (currency) {
    if (currency.code === 'EUR') {
      return '€';
    }
    if (currency.code === 'CNY' || currency.code === 'JPY') {
      return '¥';
    }
    if (currency.code === 'GHS') {
      return '₵';
    }
    if (
      currency.code === 'GBP' ||
      currency.code === 'EGP' ||
      currency.code === 'FKP' ||
      currency.code === 'JEP' ||
      currency.code === 'GIP' ||
      currency.code === 'GGP' ||
      currency.code === 'LBP' ||
      currency.code === 'SHP' ||
      currency.code === 'IMP' ||
      currency.code === 'SYP'
    ) {
      return '£';
    } else {
      return currency.symbol;
    }
  } else {
    return '';
  }
};

//TRANSFORMS NUMBER TO CURRENCY, MUST PROVIDE VALUE AND MATCHING CURRENCY OBJECT
export const FormatCurrency = (value, currency, precision = 0) => {
  let prefixFormat = { pos: '%s %v', neg: '(%s %v)', zero: '%s %v' };
  const suffixFormat = { pos: '%v %s', neg: '(%v %s)', zero: '%v %s' };
  if (currency && currency.hasOwnProperty('symbol_location')) {
    if (currency.code === 'EUR') {
      currency.symbol = '€';
    }
    if (currency.code === 'CNY' || currency.code === 'JPY') {
      currency.symbol = '¥';
    }
    if (currency.code === 'GHS') {
      currency.symbol = '₵';
    }
    if (
      currency.code === 'GBP' ||
      currency.code === 'EGP' ||
      currency.code === 'FKP' ||
      currency.code === 'JEP' ||
      currency.code === 'GIP' ||
      currency.code === 'GGP' ||
      currency.code === 'LBP' ||
      currency.code === 'SHP' ||
      currency.code === 'IMP' ||
      currency.code === 'SYP'
    ) {
      currency.symbol = '£';
    }
    if (currency.code === 'USD') {
      prefixFormat = { pos: '%s%v', neg: '(%s%v)', zero: '%s%v' };
    }
    return formatMoney(value, {
      symbol: currency.symbol,
      precision,
      decimal: currency.decimal,
      thousand: currency.thousand_separator,
      format:
        currency.symbol_location === 'suffix' ? suffixFormat : prefixFormat,
    });
  } else {
    return formatMoney(value, {
      symbol: '$',
      precision,
      decimal: '.',
      thousand: ',',
      format: prefixFormat,
    });
  }
};

//RETURNS INSTRUMENT PRIORITY TO BE USED IN SORTING
export const AddInstrumentPriority = (instrument) => {
  switch (instrument.category) {
    case 'income.earned.w2':
      return 102;
    case 'income.earned.1099':
      return 103;
    case 'income.earned.other':
      return 104;
    case 'income.unearned.annuity':
      return 106;
    case 'income.unearned.distributions':
      return 107;
    case 'income.unearned.pension':
      return 108;
    case 'income.unearned.rental':
      return 109;
    case 'income.unearned.revmortgage':
      return 110;
    case 'income.unearned.socialsecurity':
      return 111;
    case 'income.unearned.other':
      return 112;
    case 'income.expense.taxes':
      return 141;
    case 'income.expense.savings':
      return 142;
    case 'income.expense.premiums':
      return 143;
    case 'income.expense.living':
      return 144;
    case 'income.expense.gifts':
      return 145;
    case 'income.expense.other':
      return 146;
    case 'retirement':
      return 201;
    case 'nonretirement':
      return 202;
    case 'residence':
      return 203;
    case 'creditcard':
      return 301;
    case 'homeequity':
      return 302;
    case 'creditline':
      return 303;
    case 'loan':
      return 304;
    case 'mortgage':
      return 305;
    case 'schoolloan':
      return 306;
    case 'otherliability':
      return 307;
    case 'lifeterm':
      return 401;
    case 'lifeul':
      return 402;
    case 'lifewhole':
      return 403;
    case 'lifevul':
      return 404;
    case 'disability':
      return 405;
    case 'ltc':
      return 406;
    case 'propcas':
      return 407;
    default:
      return 500;
  }
};

//RETURNS MEMBER PRIORITY TO BE USED IN SORTING
export const AddMemberPriority = (member) => {
  switch (member.category) {
    case 'primary':
      return 1;
    case 'spouse':
      return 2;
    case 'partner':
      return 3;
    case 'dependent':
      return 4;
    case 'family':
      return 5;
    case 'friend':
      return 6;
    case 'trustee':
      return 7;
    case 'buspart':
      return 8;
    case 'employee':
      return 9;
    case 'employer':
      return 10;
    case 'accountant':
      return 11;
    case 'attorney':
      return 12;
    case 'other':
      return 13;
    case 'corp':
      return 50;
    case 'llc':
      return 51;
    case 'scorp':
      return 52;
    case 'partnership':
      return 53;
    case 'sole':
      return 54;
    case 'revtrust':
      return 55;
    case 'trust':
      return 56;
    case 'charity':
      return 57;
    case 'pubcharity':
      return 58;
    case 'foundation':
      return 59;
    case 'institution':
      return 60;
    case 'corpother':
      return 61;
    default:
      return 100;
  }
};

//SORTS MEMBERS TO MATCH BACKEND NATURAL SORTING ORDER
export const SortMembers = (members) => {
  // eslint-disable-next-line array-callback-return
  return members.sort((a, b) => {
    const firstDate = new Date(a.date_of_birth);
    const secondDate = new Date(b.date_of_birth);
    if (a.priority > b.priority) return 1;
    if (a.priority < b.priority) return -1;
    if (firstDate > secondDate) return 1;
    if (firstDate < secondDate) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.created_utc > b.created_utc) return 1;
    if (a.created_utc < b.created_utc) return -1;
  });
};

//SORTS PROFESSIONALS TO MATCH BACKEND NATURAL SORTING ORDER
export const SortProfessionals = (members) => {
  // eslint-disable-next-line array-callback-return
  return members.sort((a, b) => {
    const firstDate = new Date(a.date_of_birth);
    const secondDate = new Date(b.date_of_birth);
    if (a.priority > b.priority) return 1;
    if (a.priority < b.priority) return -1;
    if (a.business_name.toLowerCase() > b.business_name.toLowerCase()) return 1;
    if (a.business_name.toLowerCase() < b.business_name.toLowerCase())
      return -1;
    if (firstDate > secondDate) return 1;
    if (firstDate < secondDate) return -1;
    if (a.created_utc > b.created_utc) return 1;
    if (a.created_utc < b.created_utc) return -1;
  });
};

//SORTS INSTRUMENTS TO MATCH BACKEND NATURAL SORTING ORDER
export const SortInstrumentPriority = (instruments) => {
  // eslint-disable-next-line array-callback-return
  return instruments.sort((a, b) => {
    if (a.priority > b.priority) return 1;
    if (a.priority < b.priority) return -1;
    if (a.source.toLowerCase() > b.source.toLowerCase()) return 1;
    if (a.source.toLowerCase() < b.source.toLowerCase()) return -1;
    if (a.amount < b.amount) return 1;
    if (a.amount > b.amount) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  });
};

//TAKES CURRENT HOUSEHOLD AND DATA RETURNED FROM API AND UPDATES CURRENT HOUSEHOLD TO USE RETURNED INFO AND SET IN STORE
export const UpdateCurrentHousehold = (data, currentHousehold, categories) => {
  let copiedHousehold = { ...currentHousehold };
  if (data && currentHousehold.hasOwnProperty([data.key])) {
    if (data?.action === 'update') {
      copiedHousehold[data.key] = copiedHousehold[data.key].map((x) =>
        x.id === data.item.id ? data.item : x
      );
    }
    if (data?.action === 'create') {
      copiedHousehold[data.key] = [...copiedHousehold[data.key], data.item];
    }
    if (data?.action === 'delete') {
      copiedHousehold[data.key] = copiedHousehold[data.key].filter(
        (x) => x.id !== data.item.passSubId
      );
    }
    if (data?.key === 'householdmembers') {
      copiedHousehold[data.key] = copiedHousehold[data.key].map((member) => {
        const matchingCategory = categories[member.category];
        member.priority =
          matchingCategory?.priority || AddMemberPriority(member);
        return member;
      });
      copiedHousehold[data.key] = SortMembers(copiedHousehold[data.key]);
    }
    if (data?.key === 'excludedHouseholdMembers') {
      copiedHousehold[data.key] = copiedHousehold[data.key].map((member) => {
        const matchingCategory = categories[member.category];
        member.priority =
          matchingCategory?.priority || AddMemberPriority(member);
        return member;
      });
      copiedHousehold[data.key] = SortMembers(copiedHousehold[data.key]);
      console.log('inside household members', copiedHousehold);
    }
    if (data?.key === 'householdprofessionals') {
      copiedHousehold[data.key] = copiedHousehold[data.key].map((pro) => {
        const matchingCategory = categories[pro.category];
        pro.priority = matchingCategory?.priority || AddMemberPriority(pro);
        return pro;
      });
      copiedHousehold[data.key] = SortProfessionals(copiedHousehold[data.key]);
    }
    if (data?.key === 'instruments') {
      copiedHousehold[data.key] = copiedHousehold[data.key].map(
        (instrument) => {
          const matchingCategory = categories[instrument.category];
          instrument.priority =
            matchingCategory?.priority || AddInstrumentPriority(instrument);
          return instrument;
        }
      );
      copiedHousehold[data.key] = SortInstrumentPriority(
        copiedHousehold[data.key]
      );
    }
  }
  return copiedHousehold;
};

//RETURNS A FILE ICON FROM STRING MIMETYPE
export const GenerateFileIcon = (object, keyValue) => {
  if (object?.type === 'folder') {
    return folderIcon;
  }
  switch (object[keyValue]) {
    case 'video/avi':
    case 'video/x-msvideo':
      return aviIcon;
    case 'video/mp4':
      return mp4Icon;
    case 'audio':
    case 'audio/mpeg':
      return mp3Icon;
    case 'image/jpeg':
      return jpgIcon;
    case 'image/png':
      return pngIcon;
    case 'image/gif':
      return gifIcon;
    case 'image/svg':
    case 'image/svg+xml':
      return svgIcon;
    case 'application/pdf':
      return pdfIcon;
    case 'application/zip':
      return zipIcon;
    case 'application/json':
      return jsonIcon;
    case 'text/plain':
      return txtIcon;
    case 'text/rtf':
    case 'application/rtf':
      return rtfIcon;
    case 'text/csv':
    case 'application/csv':
      return csvIcon;
    case 'text/html':
    case 'application/html':
      return htmlIcon;
    case 'text/javascript':
    case 'application/javascript':
      return jsIcon;
    case 'text/xml':
    case 'application/xml':
      return xmlIcon;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return docIcon;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return pptIcon;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return xlsIcon;
    default:
      return unknownIcon;
  }
};

//MERGES INTEGRATION MEMBER INFO AND MERGES WITHOUT HOUSEHOLD MEMBER INFO
export const TransformIntegrationMembers = (list, householdmembers) => {
  return list.map((member) => {
    const matchedMember = householdmembers.find((mem) => mem.id === member.id);
    if (matchedMember) {
      matchedMember.name = HtmlToString(matchedMember.name);
      return {
        ...member,
        ...matchedMember,
      };
    } else {
      return member;
    }
  });
};

//CHECKS IF RESULT IS IN HOUSEHOLD AND SETS NAME VALUE
export const TransformSalesforceSearchResults = (results, householdMembers) => {
  return results.map((result) => {
    result.is_linked = householdMembers.find((mem) => mem.partner_data);
    if (!result?.name) {
      result.name = result?.first_name
        ? `${result.first_name} ${result.last_name}`
        : result.last_name;
    }
    return result;
  });
};

//TRANSFORMS LINKED SALESFORCE INSTRUMENTS
export const TransformSalesforceLinkedInstruments = (list, value) => {
  if (list && list.length !== 0) {
    return list.map((instrument) => {
      instrument.action = value;
      if (value === 'new') {
        instrument.message = 'OK';
      }
      return instrument;
    });
  }
  return [];
};

export const CapitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

//TAKES SNAKE CASE AND CONVERTS TO CAPITAL (test_error - Test Error)
export const TransformObjectKey = (key) => {
  return key
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());
};

//GENERATE MEMBER CONNECT STATUS DISPLAY
export const GenerateStatusDisplay = (status) => {
  let labelText = status;
  let textColor = colors.darkGrey;
  let backgroundColor = colors.lightGrey;
  let borderColor = null;
  switch (status) {
    case 'active':
      labelText = 'active';
      textColor = colors.green;
      backgroundColor = messageColors.successLabelBg;
      break;
    case 'inactive':
      labelText = 'inactive';
      textColor = colors.midGrey;
      backgroundColor = 'transparent';
      borderColor = colors.midGrey;
      break;
    case 'pending':
      labelText = 'pending';
      textColor = messageColors.warning;
      backgroundColor = messageColors.warningLabelBg;
      break;
    case 'rejected':
      labelText = 'rejected';
      textColor = messageColors.error;
      backgroundColor = messageColors.errorLabelBg;
      break;
    default:
      labelText = status;
  }
  return (
    <StatusLabel
      color={textColor}
      background={backgroundColor}
      borderColor={borderColor}
    >
      {labelText}
    </StatusLabel>
  );
};

//CREATES CATEGORY DISPLAY VALUE  FOR INSTRUMENTS
export const GenerateLegalType = (type, legalCategories) => {
    const matchedCategory = legalCategories.find(
      (cat) => cat.key === type
    );
    if (matchedCategory?.name) {
      return matchedCategory?.name;
    }
  
    return type;
  };