/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  PageHeader,
  EmptyImage,
  SignalCommentsModal,
  SignalDetailsModal,
  PageFooter,
} from '../../components';
import { noSignals, commentBubble, noSettings } from '../../assets';
import {
  createSignalComment,
  getSignalComments,
  toggleShowSignalCommentsModal,
  toggleShowSignalDetailsModal,
  toggleShowDeleteModal,
  getHouseholdKFFs,
  toggleSnoozeSignal,
} from '../../store/actions';
import {
  UpdatePageTitle,
  headerContent,
  signalLabels,
  // GenerateRiskText,
  DetermineSignalColor,
  GenerateSignalIcon,
  GenerateSignalColorIcon,
  DownloadReport,
  SendTrackingEvent,
  DateFormatUTC,
} from '../../utils';
import { colors, boxShadows, maxDevice, fonts } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';
import { PageContent } from '../../styles/library/layoutStyles';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import { useParams } from 'react-router-dom';

const HouseholdSignals = ({ isConsumer, canEdit, showPDF }) => {
  UpdatePageTitle('Signals');
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params.id;
  const snoozeDurations = [
    { label: '1 Month', value: 30 },
    { label: '3 Months', value: 90 },
    { label: '1 Year', value: 365 },
    { label: 'Indefinitely (Until Wake)', value: 0 },
  ];
  const {
    currentHousehold,
    householdSignals,
    signalComments,
    showingSignalCommentsModal,
    showingSignalDetailsModal,
    isStencil,
    preferences,
    signalCommentsError,
    updatedComments,
    selectedSignal,
    hasSignalsSnooze,
    signalMuteError,
    invocationId,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    householdSignals: state.households.householdSignals,
    signalComments: state.households.signalComments,
    showingSignalCommentsModal: state.households.showingSignalCommentsModal,
    showingSignalDetailsModal: state.households.showingSignalDetailsModal,
    isStencil: state.households.isStencil,
    preferences: state.user.user.preferences,
    signalCommentsError: state.households.signalCommentsError,
    updatedComments: state.households.updatedComments,
    selectedSignal: state.households.selectedSignal,
    hasSignalsSnooze: state.user.hasSignalsSnooze,
    signalMuteError: state.households.signalMuteError,
    invocationId: state.configs.invocationId,
  }));
  const [loadingSignals, setLoadingSignals] = useState(true);
  const [signals, setSignals] = useState([]);
  const [currentSignal, setCurrentSignal] = useState();
  const [signalComment, setSignalComment] = useState('');
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [snoozeDuration, setSnoozeDuration] = useState(snoozeDurations[3]);
  const [isSnooze, setIsSnooze] = useState(false);
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const [signalsError, setSignalsError] = useState('');

  useEffect(() => {
    if (householdSignals) {
      let signalOptions = householdSignals?.signals.map((signal) => {
        const matching = signalLabels.find((ind) => ind.key === signal.name);
        matching.snoozed = signal?.snooze?.snoozed || signal?.snooze?.muted;
        matching.muted = signal?.snooze?.muted;
        matching.snoozed_utc = DateFormatUTC(
          signal?.snooze?.utc,
          preferences ? preferences.date_format : 'MM/DD/YYYY'
        );
        return { ...signal, ...matching };
      });
      signalOptions = signalOptions.filter((signal) => signal.enabled);
      signalOptions.sort((a, b) => a.order - b.order);
      setLoadingSignals(false);
      setSignals(signalOptions);
    }
  }, [householdSignals]);

  useEffect(() => {
    if (signalComments) {
      setShowLoadingButton(false);
      setSignalComment('');
      setSignalsError('');
      setIsLoadingComments(false);
      const formattedComments = signalComments.map((comment) => {
        comment.value = comment?.comment;
        comment.date = DateFormatUTC(
          comment.created_utc,
          preferences.date_format
        );
        return comment;
      });
      setCurrentSignal({ ...currentSignal, commentsList: formattedComments });
    }
  }, [signalComments]);

  useEffect(() => {
    if (!showingSignalCommentsModal) {
      setShowLoadingButton(false);
      setSignalComment('');
      setSignalsError('');
      setIsLoadingComments(false);
      setSnoozeDuration(snoozeDurations[3]);
    }
  }, [showingSignalCommentsModal]);

  useEffect(() => {
    if (updatedComments) {
      dispatch(getHouseholdKFFs(householdId));
    }
  }, [updatedComments]);

  useEffect(() => {
    if (signalCommentsError) {
      setShowLoadingButton(false);
      let errors = [];
      const errorData = signalCommentsError?.data;
      if (errorData) {
        for (const [key, value] of Object.entries(errorData)) {
          errors.push({ field: key, message: value });
        }
        const errorMessages = errors.map((error) => {
          const fieldName = error.field.replaceAll('_', ' ');
          return `${fieldName}: ${error.message}`;
        });
        return setSignalsError(errorMessages);
      }
      setSignalsError('Error processing request');
    }
  }, [signalCommentsError]);

  useEffect(() => {
    if (signalMuteError) {
      setShowLoadingButton(false);
      let errors = [];
      const errorData = signalMuteError?.data;
      if (errorData) {
        for (const [key, value] of Object.entries(errorData)) {
          errors.push({ field: key, message: value });
        }
        const errorMessages = errors.map((error) => {
          const fieldName = error.field.replaceAll('_', ' ');
          return `${fieldName}: ${error.message}`;
        });
        return setSignalsError(errorMessages);
      }
      setSignalsError('Error processing request');
    }
  }, [signalMuteError]);

  const validateSignalModal = () => {
    if (isSnooze) {
      const isMute = snoozeDuration.value === 0;
      let snoozeDate = null;
      if (!isMute) {
        const today = new Date();
        snoozeDate = new Date(
          new Date().setDate(today.getDate() + snoozeDuration.value)
        );
        snoozeDate = snoozeDate.toISOString();
      }
      const signalObj = {
        signal: currentSignal.key,
        action: isMute ? 'mute' : 'snooze',
        [isMute ? 'duration' : 'snooze_utc']: snoozeDate,
        state_snoozed: currentSignal.calculated,
        // comment: signalComment,
      };
      setShowLoadingButton(true);
      dispatch(toggleSnoozeSignal(householdId, signalObj, true));
    } else {
      if (signalComment !== '') {
        setShowLoadingButton(true);
        dispatch(
          createSignalComment(householdId, {
            signal_type: currentSignal.key,
            comment: signalComment,
          })
        );
      } else {
        setSignalsError('Comment cannot be blank');
      }
    }
  };

  const generateSignalModalContent = () => {
    if (showingSignalCommentsModal) {
      return {
        buttons: [
          {
            text: 'Cancel',
            function: () => dispatch(toggleShowSignalCommentsModal(false)),
            theme: ButtonThemes.cancel,
          },
          {
            text: isSnooze ? 'Snooze' : 'Add Comment',
            loadingText: isSnooze ? 'Snoozing' : 'Adding',
            showLoading: showLoadingButton,
            function: () => validateSignalModal(),
          },
        ],
      };
    }
  };

  const actionOnClick = (signal, isSnooze = false) => {
    setIsSnooze(isSnooze);
    if (!isSnooze) {
      setIsLoadingComments(true);
      dispatch(getSignalComments(householdId, signal.key));
    }
    setCurrentSignal(signal);
    dispatch(toggleShowSignalCommentsModal(true));
  };

  const signalInputs = [
    {
      type: 'select',
      label: 'Snooze Duration',
      name: 'duration',
      placeholder: 'Select Duration',
      value: snoozeDuration,
      options: snoozeDurations,
      width: '100%',
      isVisible: isSnooze,
      onChange: (e) => setSnoozeDuration(e),
      // disabled: isDisabled('category'),
      // hasError: CheckHasError(modalErrors, 'role'),
      // errorMessage: GenerateError(modalErrors, 'role'),
    },
    {
      type: 'textarea',
      label: 'Comment',
      name: 'comment',
      showClear: true,
      id: 'signals',
      isVisible: !isSnooze,
      height: isSnooze ? '120px' : '100px',
      value: signalComment,
      onChange: (e) => setSignalComment(e),
    },
  ];

  const deleteComment = (comment) => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'signalComment',
        'this comment',
        comment.id,
        comment.signal_type
      )
    );
  };

  const openDetailsModal = (signal) => {
    SendTrackingEvent(invocationId, 'click', 'show_signal_details', null, {
      signal,
    });
    return dispatch(toggleShowSignalDetailsModal(true, signal));
  };

  const wakeSignal = (signal) => {
    return dispatch(
      toggleSnoozeSignal(householdId, { signal: signal?.key }, false)
    );
  };

  const onClickPDF = () => {
    SendTrackingEvent(
      invocationId,
      'click',
      'create_pdf_report',
      'household_signals',
      {
        isConsumer: isConsumer,
      }
    );
    return DownloadReport({
      value: 'signals',
      hasOptions: false,
    });
  };

  return (
    <>
      {showingSignalCommentsModal && (
        <SignalCommentsModal
          signal={currentSignal}
          snooze={isSnooze}
          isLoading={isLoadingComments}
          inputs={signalInputs.filter((input) => input.isVisible)}
          content={generateSignalModalContent()}
          deleteOnClick={deleteComment}
          errors={signalsError}
          hide={() =>
            dispatch(toggleShowSignalCommentsModal(!showingSignalCommentsModal))
          }
        />
      )}
      {showingSignalDetailsModal && (
        <SignalDetailsModal
          signal={selectedSignal}
          hide={() =>
            dispatch(toggleShowSignalDetailsModal(!showingSignalDetailsModal))
          }
        />
      )}
      <PageContainer>
        <PageHeader
          content={{
            ...headerContent.householdSignals,
            showButton: !isStencil && showPDF,
          }}
          buttonFunction={onClickPDF}
        />
        <PageContent>
          {isStencil ? (
            <>
              <EmptyImage
                imageSrc={noSettings}
                imageName="No Signals"
                text="Signals are not available for Stencil Households"
              />
            </>
          ) : loadingSignals ? (
            <SignalsContainer>
              {[0, 1, 2, 3, 4, 5].map((item) => {
                return (
                  <SignalContainer key={item} showBottom={canEdit}>
                    <TopContent>
                      <IconContainer>
                        <LoadingPlaceholder
                          round="true"
                          width="60px"
                          height="60px"
                        />
                        <ColorStatusContainer />
                      </IconContainer>
                      <SignalName>
                        <LoadingPlaceholder width="120px" height="20px" />
                      </SignalName>
                      <SignalDetails>
                        <LoadingPlaceholder width="180px" />
                      </SignalDetails>
                    </TopContent>
                    {canEdit && (
                      <BottomContent style={{ paddingTop: '10px' }}>
                        <LeftActionContainer>
                          <ActionContainer>
                            {hasSignalsSnooze && (
                              <LoadingPlaceholder
                                round="true"
                                width="18px"
                                height="18px"
                                margin="0 5px 0 0"
                              />
                            )}
                            <LoadingPlaceholder
                              round="true"
                              width="18px"
                              height="18px"
                              margin="0"
                            />
                          </ActionContainer>
                        </LeftActionContainer>
                        <RightActionContainer>
                          <ActionContainer>
                            <SignalStatus>
                              <LoadingPlaceholder
                                height="16px"
                                width="60px"
                                margin="0"
                              />
                            </SignalStatus>
                          </ActionContainer>
                        </RightActionContainer>
                      </BottomContent>
                    )}
                  </SignalContainer>
                );
              })}
            </SignalsContainer>
          ) : signals.length !== 0 ? (
            <SignalsContainer>
              {signals.map((signal, index) => {
                return (
                  <SignalContainer
                    key={index}
                    snoozed={signal?.snoozed}
                    showBottom={canEdit}
                  >
                    <TopContent>
                      <IconContainer
                        style={{
                          border: `2px solid ${DetermineSignalColor(
                            signal?.state,
                            signal?.snoozed
                          )}`,
                        }}
                      >
                        {GenerateSignalIcon(signal?.name, colors.darkGrey)}
                        <ColorStatusContainer>
                          {GenerateSignalColorIcon(
                            signal?.calculated,
                            signal?.snoozed
                          )}
                        </ColorStatusContainer>
                      </IconContainer>
                      <SignalName>{signal?.label}</SignalName>
                      {/* <SignalScore>{signal?.score}</SignalScore> */}
                      <SignalDetails>{signal?.details}</SignalDetails>
                      {signal?.snoozed && (
                        <>
                          <SignalSnoozed>Snoozed</SignalSnoozed>
                          <SnoozeDate>
                            {signal?.muted
                              ? 'Indefinitely'
                              : `Until ${signal?.snoozed_utc}`}
                          </SnoozeDate>
                        </>
                      )}
                    </TopContent>
                    {canEdit && (
                      <BottomContent>
                        <LeftActionContainer>
                          {hasSignalsSnooze && (
                            <ActionContainer style={{ marginRight: '10px' }}>
                              <ActionButton
                                onClick={() => {
                                  signal.snoozed
                                    ? wakeSignal(signal)
                                    : actionOnClick(signal, true);
                                }}
                              >
                                {signal.snoozed ? 'Wake' : 'Snooze'}
                              </ActionButton>
                            </ActionContainer>
                          )}
                          <ActionContainer>
                            {signal?.comments && signal.comments !== 0 ? (
                              <CommentCount>{signal.comments}</CommentCount>
                            ) : null}
                            {/* {signal?.comments && <CommentCircle />} */}
                            <CommentIcon
                              src={commentBubble}
                              alt="message"
                              title="Comments"
                              data-image={`comments-${signal.key}`}
                              onClick={() => actionOnClick(signal, false)}
                            />
                          </ActionContainer>
                        </LeftActionContainer>
                        <RightActionContainer>
                          <ActionContainer>
                            {/* <SignalStatus>
                            {GenerateRiskText(signal?.calculated)}
                          </SignalStatus> */}
                            <SignalDetailsLink
                              onClick={() => openDetailsModal(signal)}
                            >
                              Details
                            </SignalDetailsLink>
                          </ActionContainer>
                        </RightActionContainer>
                      </BottomContent>
                    )}
                  </SignalContainer>
                );
              })}
            </SignalsContainer>
          ) : (
            <EmptyContainer>
              <NotFoundImage
                src={noSignals}
                alt="No Signals"
                data-image="no-signals"
              />
              <NotFoundText>
                All signal types have been disabled by your team manager.
              </NotFoundText>
            </EmptyContainer>
          )}
          {isConsumer && (
            <PageFooter footerValue={currentHousehold?.disclaimer} />
          )}
        </PageContent>
      </PageContainer>
    </>
  );
};

const ActionContainer = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  img {
    width: 14px;
    height: 14px;
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
  svg {
    margin-left: 7px;
  }
`;

const CommentIcon = styled.img`
  margin-right: 10px;
`;

const CommentCount = styled.span`
  position: absolute;
  font-size: 8px;
  top: -4px;
  right: -1px;
  width: 8px;
  color: ${colors.paleGrey};
`;

// const CommentCircle = styled.span`
//   position: absolute;
//   top: -3px;
//   right: 6px;
//   width: 7px;
//   height: 7px;
//   background: ${colors.paleGrey};
//   border-radius: 25px;
// `;

const ActionButton = styled.button`
  color: ${colors.lightBlue};
  font-size: 11px;
  text-align: center;
  cursor: pointer;
`;

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const SignalsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -6px;
`;

const TopContent = styled.div`
  /* margin-bottom: 40px; */
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const BottomContent = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid ${colors.lightGrey};
  width: 100%;
  /* height: 20px; */
  padding-top: 13px;
`;

const LeftActionContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 1 0 auto;
`;

const RightActionContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
`;

const IconContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  flex: 0 0 auto;
  margin: 10px 0;
`;

const ColorStatusContainer = styled.div`
  position: absolute;
  top: -4px;
  right: -9px;
`;

const SignalName = styled.p`
  font-size: 16px;
  font-weight: ${fonts.semiBold};
  text-align: center;
  margin-bottom: 5px;
`;

const SignalSnoozed = styled.p`
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  text-align: center;
  margin: 5px 0;
  text-transform: uppercase;
  color: ${colors.paleGrey};
`;

const SnoozeDate = styled.p`
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  text-align: center;
  margin-top: -5px;
  color: ${colors.paleGrey};
`;

// const SignalScore = styled.p`
//   color: ${colors.paleGrey};
//   font-weight: ${fonts.regular};
//   margin-left: 5px;
// `;

const SignalDetails = styled.p`
  color: ${colors.paleGrey};
  font-size: 10px;
  text-align: center;
`;

const SignalDetailsLink = styled.p`
  color: ${colors.lightBlue};
  font-size: 11px;
  text-align: center;
  cursor: pointer;
`;

const SignalStatus = styled.p`
  /* color: ${colors.paleGrey}; */
  font-size: 10px;
  text-align: center;
`;

const SignalContainer = styled.div`
  @media ${maxDevice.sideNav} {
    max-width: 100%;
  }
  border: 1px solid ${colors.lightGrey};
  box-shadow: ${boxShadows.boxShadowSoft};
  /* box-shadow: ${(props) =>
    props.snoozed ? null : boxShadows.boxShadowSoft};
  background: ${(props) => (props.snoozed ? colors.lighterGrey : null)};
  opacity: ${(props) => (props.snoozed ? 0.7 : null)}; */
  border-radius: 5px;
  padding: 15px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1 1 auto;
  max-width: 190px;
  min-width: 170px;
  height: ${(props) => (props.showBottom ? '240px' : '190px')};
  margin: 0 6px 12px 6px;
  /* ${BottomContent} {
    border-top: ${(props) =>
    props.snoozed ? '1px solid transparent' : `1px solid` + colors.lightGrey};
  }
  ${SignalName} {
    color: ${(props) => (props.snoozed ? colors.paleGrey : colors.darkGrey)};
  } */
`;

const EmptyContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const NotFoundImage = styled.img`
  max-height: 420px;
  width: 100%;
  margin: 10px;
`;

const NotFoundText = styled.p`
  @media ${maxDevice.tablet} {
    margin: 20px;
    font-size: 24px;
  }
  margin: 20px 40px 20px 40px;
  font-size: 28px;
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;

HouseholdSignals.propTypes = {
  isConsumer: PropTypes.bool,
  canEdit: PropTypes.bool,
  showPDF: PropTypes.bool,
};

export default HouseholdSignals;
