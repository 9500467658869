import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'react-pluralize';
import styled from 'styled-components';
import {
  user,
  lock,
  unlocked,
  institution,
  briefcase,
  link,
  home,
  check,
} from '../../assets';
import {
  assetMapColors,
  boxShadows,
  colors,
  fonts,
} from '../../styles/variables';
import { DateFormat, GenerateEntityLabel } from '../../utils';

const AssetMapMember = ({
  groupedDisplay = false,
  groupedType,
  selectedMembers,
  householdName,
  member,
  index,
  preferences,
  onClick,
  categories,
  usingNewAvatar,
  activeModalMember,
}) => {
  const [isAllEntity, setIsAllEntity] = useState(false);

  useEffect(() => {
    if (groupedDisplay && selectedMembers) {
      setIsAllEntity(
        selectedMembers.every((mem) => mem.member_type === 'entity')
      );
    } else {
      setIsAllEntity(false);
    }
  }, [selectedMembers, groupedDisplay]);

  const hasRiskalyze =
    member?.signpost_selected &&
    member?.signpost_selected === 'riskalyze' &&
    (preferences?.amr?.signpost === 'riskalyze' ||
      (Array.isArray(preferences?.amr?.signpost) &&
        preferences?.amr?.signpost.includes('riskalyze')));
  const hasMAW =
    member?.signpost_selected &&
    member?.signpost_selected === 'msaws' &&
    (preferences?.amr?.signpost === 'msaws' ||
      (Array.isArray(preferences?.amr?.signpost) &&
        preferences?.amr?.signpost.includes('msaws')));
  let rightImage = false;
  let leftImage = false;
  let belowImage = false;
  if (hasRiskalyze) {
    if (index === 0) {
      rightImage = member?.signpost?.riskalyze[1];
      leftImage = member?.signpost?.riskalyze[0];
    } else {
      rightImage = member?.signpost?.riskalyze[0];
      leftImage = member?.signpost?.riskalyze[1];
    }
  }
  if (hasMAW) {
    belowImage = member?.signpost?.msaws[0];
  }

  const selectIcon = (member) => {
    if (window.location.host === 'localhost:3000') {
      if (member.member_type === 'member') {
        return user;
      }
      if (member.member_type === 'entity') {
        switch (member.category) {
          case 'trust':
            return lock;
          case 'revtrust':
            return unlocked;
          case 'charity':
          case 'pubcharity':
          case 'foundation':
          case 'institution':
            return institution;
          default:
            return briefcase;
        }
      }
      return user;
    } else {
      return member.avatar.url;
    }
  };

  //GENERATES DATA IMAGE ATTRIBUTE FOR TESTING (Returns which avatar set is being used)
  const generateDataTag = (member) => {
    if (member?.avatar?.url) {
      const urlArr = member?.avatar?.url.split('/');
      const setValue = urlArr.find((val) => val.includes('set-'));
      if (setValue) {
        return setValue;
      }
    }
    return 'old';
  };

  const generateImageAlt = () => {
    if (selectedMembers) {
      if (isAllEntity) {
        return 'some-entity';
      } else {
        return 'house-some-members';
      }
    }
    switch (groupedType) {
      case 'none':
        return 'empty-house';
      case 'all':
        return 'house-all-members';
      default:
        return `${groupedType} Members Selected`;
    }
  };

  const generateSubtitle = () => {
    if (selectedMembers) {
      const entities = selectedMembers.filter(
        (mem) => mem.member_type === 'entity'
      );
      const members = selectedMembers.filter(
        (mem) => mem.member_type === 'member'
      );
      return (
        <>
          {members.length !== 0 && (
            <Pluralize singular={'Member'} count={members.length} />
          )}
          {members.length !== 0 && entities.length !== 0 && ', '}
          {entities.length !== 0 && (
            <Pluralize
              singular={'Entity'}
              plural={'Entities'}
              count={entities.length}
            />
          )}
        </>
      );
    } else {
      switch (groupedType) {
        case 'none':
          return 'No Members Selected';
        case 'all':
          return 'All Members Selected';
        default:
          return `${groupedType} Members Selected`;
      }
    }
  };

  return groupedDisplay ? (
    <HouseholdMemberContainer>
      <MemberImage
        src={isAllEntity ? institution : home}
        alt={isAllEntity ? 'entity' : 'house'}
        data-image={generateImageAlt()}
        style={{ opacity: groupedType === 'none' ? 0.5 : 1 }}
      />
      <HouseholdName>{householdName}</HouseholdName>
      <MemberSubtitle>{generateSubtitle()}</MemberSubtitle>
    </HouseholdMemberContainer>
  ) : (
    <MemberSection>
      <MemberContainer
        key={index}
        onClick={(e) => onClick(e, member, 'member')}
      >
        <MemberImageContainer hasMAW>
          {hasRiskalyze && leftImage && (
            <RiskalyzeImage
              src={leftImage}
              alt="Nitrogen score"
              data-image="riskalyze-score"
            />
          )}
          <MemberImage
            src={selectIcon(member)}
            alt={member.name}
            $newAvatar={usingNewAvatar && member.id === activeModalMember}
            data-image={generateDataTag(member)}
          />
          {hasRiskalyze && rightImage && (
            <RiskalyzeImage
              src={rightImage}
              alt="Nitrogen score"
              data-image="riskalyze-score"
            />
          )}
          {member.id === activeModalMember && (
            <SelectedIconContainer
              title="Showing Member Modal"
              data-selected={`selected-${member.id}`}
            >
              <img src={check} alt="selected" />
            </SelectedIconContainer>
          )}
        </MemberImageContainer>
        <MemberNameContainer>
          {member.is_linked && (
            <LinkedImage src={link} alt="linked" data-image="member-linked" />
          )}
          <MemberName>{member.name}</MemberName>
        </MemberNameContainer>
        {member.member_type === 'member' &&
          preferences &&
          preferences?.amr?.member_subtitle !== 'none' && (
            <MemberSubtitle
              style={{
                fontStyle: member.date_of_birth_estimated ? 'italic' : null,
              }}
            >
              {preferences?.amr?.member_subtitle === 'age' && preferences
                ? member.age
                : DateFormat(
                    member.date_of_birth,
                    preferences ? preferences?.date_format : 'MM/DD/YYYY'
                  )}
              {member.date_of_birth_estimated && ' (e)'}
            </MemberSubtitle>
          )}
        {member.member_type === 'entity' && (
          <EntitySubtitle>
            {GenerateEntityLabel(member.category, categories)}
          </EntitySubtitle>
        )}
        {hasMAW && belowImage && (
          <MAWImageBelow
            src={belowImage}
            alt="Morningstar Advisor Workstation score"
            data-image="maw-score"
          />
        )}
      </MemberContainer>
    </MemberSection>
  );
};

const SelectedIconContainer = styled.div`
  position: absolute;
  z-index: 4;
  right: 25px;
  top: 5px;
  background: white;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px solid ${colors.green};
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 9px;
    height: 9px;
  }
`;

const HouseholdMemberContainer = styled.div`
  font-size: 14px;
  padding: 5px;
  text-align: center;
  margin: 10px;
`;

const HouseholdName = styled.p`
  font-weight: ${fonts.semiBold};
  margin-top: 5px;
  font-size: 16px;
`;

const MemberSection = styled.div`
  flex-direction: column;
  display: flex;
`;

const MemberContainer = styled.div`
  position: relative;
  font-size: 14px;
  width: 132px;
  min-width: 132px;
  max-width: 132px;
  /* height: 102px; */
  padding: 5px;
  text-align: center;
  margin: 10px 5px;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
`;

const MemberSubtitle = styled.p`
  font-weight: ${fonts.regular};
  color: ${assetMapColors.expected};
  font-size: 13px;
`;

const MemberImage = styled.img`
  max-height: 120px;
  max-width: 80px;
  border-radius: ${(props) => (props.$newAvatar ? '100%' : 0)};
  background: ${(props) => (props.$newAvatar ? colors.paleBlue : null)};
  box-shadow: ${(props) =>
    props.$newAvatar ? boxShadows.boxShadowAMRSelected : null};
`;

const MemberNameContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    margin-top: 5px;
  }
`;

const MemberName = styled.p`
  font-weight: ${fonts.semiBold};
  margin-top: 5px;
  font-size: 13px;
`;

const LinkedImage = styled.img`
  width: 10px;
  max-height: 10px;
  margin-right: 3px;
  opacity: 0.8;
`;

const EntitySubtitle = styled(MemberSubtitle)`
  text-transform: capitalize;
`;

const MemberImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-content: center;
  position: relative;
`;

const RiskalyzeImage = styled.img`
  height: 33px;
`;

const MAWImageBelow = styled.img`
  margin-top: 3px;
  height: 50px;
  align-self: center;
`;

AssetMapMember.propTypes = {
  groupedDisplay: PropTypes.bool,
  groupedType: PropTypes.string,
  selectedMembers: PropTypes.array,
  householdName: PropTypes.string,
  member: PropTypes.object,
  index: PropTypes.number,
  preferences: PropTypes.object,
  onClick: PropTypes.func,
  categories: PropTypes.array,
  usingNewAvatar: PropTypes.bool,
  activeModalMember: PropTypes.string,
};

export default AssetMapMember;
