import styled from 'styled-components';
import { Button } from './';
import { info, multiSelect, pdf, timesDarkGrey } from '../assets';
import { colors, maxDevice } from '../styles/variables';
import {
  ButtonLink,
  HeaderSubContent,
  PrimaryHeading,
} from '../styles/library/fontStyles';
import { IconContainer } from '../styles/library/imageStyles';
import PropTypes from 'prop-types';
import { FlexCenterAll } from '../styles/library/layoutStyles';

const PageHeader = ({
  content,
  buttonFunction,
  multiSelectFunction,
  isSelecting = false,
  margin,
  showViews = false,
  toggleView,
  views,
  activeView = 'list',
}) => {
  const {
    heading,
    superscript,
    subcontent,
    showButton = false,
    buttonLink,
    buttonText,
    isExternal = false,
    isPDF = false,
    noBottomBorder = false,
  } = content;

  const generateButton = () => {
    return isPDF ? (
      <IconContainer onClick={buttonFunction}>
        <img src={pdf} alt="PDF" data-image="heading-pdf" />
      </IconContainer>
    ) : (
      <ButtonContent>
        {buttonFunction ? (
          <Button
            text={buttonText}
            bigButton={false}
            onClick={buttonFunction}
          />
        ) : isExternal ? (
          <>
            <IconLinkContainer
              href={buttonLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={info}
                alt="info"
                title="Tutorial"
                data-image="heading-info"
              />
            </IconLinkContainer>
            {/* <ButtonLinkExternal
              href={buttonLink}
              target="_blank"
              rel="noopener noreferrer"
              theme={ButtonThemes.cancel}
            >
              {buttonText}
            </ButtonLinkExternal> */}
          </>
        ) : (
          <ButtonLink to={buttonLink}>{buttonText}</ButtonLink>
        )}
      </ButtonContent>
    );
  };

  return (
    <HeaderContainer noBottomBorder={noBottomBorder} margin={margin}>
      <HeaderContent>
        <PrimaryHeading
          data-heading={heading.toLowerCase().replace(/\s+/g, '-')}
          margin={subcontent ? '0 0 15px 0' : '0'}
        >
          {heading}
          {superscript && <sup>{superscript}</sup>}
        </PrimaryHeading>
        {subcontent ? (
          <HeaderSubContent>
            {subcontent.map((content, index) => {
              return (
                <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
              );
            })}
          </HeaderSubContent>
        ) : subcontent ? (
          <div style={{ height: '10px' }}></div>
        ) : null}
      </HeaderContent>
      {showViews && (
        <ViewsContainer>
          {views.map((view) => {
            return (
              <ViewOption
                onClick={() => toggleView(view.value)}
                $isActive={activeView === view.value}
                title={view.label}
                key={view.value}
              >
                <img
                  src={view.image}
                  alt={view.value}
                  data-image={view.dataTag}
                />
              </ViewOption>
            );
          })}
        </ViewsContainer>
      )}
      {showButton ? generateButton() : null}
      {multiSelectFunction && (
        <SelectIconContainer onClick={multiSelectFunction}>
          <img
            src={isSelecting ? timesDarkGrey : multiSelect}
            alt="select"
            title={
              isSelecting
                ? 'Close Search & Select (esc)'
                : 'Search & Select (ctrl+shift+s)'
            }
            data-image={isSelecting ? 'heading-select-close' : 'heading-select'}
            style={isSelecting ? { width: '12px', height: '12px' } : null}
          />
        </SelectIconContainer>
      )}
    </HeaderContainer>
  );
};

const ViewsContainer = styled.div`
  border: 2px solid ${colors.lightGrey};
  ${FlexCenterAll};
  margin-right: 20px;
  height: 31px;
  background: ${colors.lightGrey};
  border-radius: 5px;
`;

const ViewOption = styled.button`
  background: ${(props) => (props.$isActive ? colors.white : colors.lightGrey)};
  height: 100%;
  border-radius: 3px;
  width: 35px;
  ${FlexCenterAll};
  transition: all 0.2s ease;
  img {
    width: 18px;
    height: 18px;
    opacity: ${(props) => (props.$isActive ? 1 : 0.5)};
  }
`;

export const SelectIconContainer = styled.div`
  margin-left: 8px;
  flex: 0 0 auto;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  ${FlexCenterAll}
  background: ${colors.lightGrey};
  cursor: pointer;
  img {
    width: 15px;
    height: 15px;
  }
  &:hover {
    transform: scale(1.01);
    background: ${colors.hoverLightGrey};
    /* background: ${colors.lightGrey}; */
  }
`;

const HeaderContainer = styled.div`
  @media ${maxDevice.sideNav} {
    margin: ${(props) => (props.margin ? props.margin : '20px 20px')};
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  text-align: left;
  margin: ${(props) => (props.margin ? props.margin : '25px 40px')};
  border-bottom: ${(props) =>
    props.noBottomBorder
      ? '1px solid transparent'
      : `1px solid ${colors.lightGrey}`};
`;

const HeaderContent = styled.div`
  flex: 1 1 auto;
`;

const ButtonContent = styled.div`
  margin-left: 20px;
  flex: 0 0 auto;
`;

const IconLinkContainer = styled.a`
  flex: 0 0 auto;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGrey};
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
  &:hover {
    transform: scale(1.01);
    background: ${colors.hoverLightGrey};
  }
`;

PageHeader.propTypes = {
  content: PropTypes.object,
  superscript: PropTypes.string,
  margin: PropTypes.string,
  header: PropTypes.string,
  showViews: PropTypes.bool,
  toggleView: PropTypes.func,
  view: PropTypes.string,
  multiSelectFunction: PropTypes.func,
  isSelecting: PropTypes.bool,
  subcontent: PropTypes.array,
  buttonLink: PropTypes.string,
  buttonFunction: PropTypes.func,
  buttonText: PropTypes.string,
  isExternal: PropTypes.bool,
  isPDF: PropTypes.bool,
  noBottomBorder: PropTypes.bool,
};

export default PageHeader;
