/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PageHeader } from '../../components';
import { HouseholdFamilyMap, HouseholdMembersList } from '../../containers';
import {
  UpdatePageTitle,
  headerContent,
  DownloadReport,
  SendTrackingEvent,
  ErrorNotification,
} from '../../utils';
import { bars, cardGrid } from '../../assets';
import { useParams } from 'react-router-dom';

const HouseholdMembers = ({ isConsumer, canEdit = true, showPDF }) => {
  UpdatePageTitle('Members');
  const params = useParams();
  const householdId = params.id;
  const { loadedUser, hasFamilyMap, invocationId } = useSelector((state) => ({
    loadedUser: state.user.loadedUser,
    hasFamilyMap: state.user.hasFamilyMap,
    invocationId: state.configs.invocationId,
  }));
  const [activeView, setActiveView] = useState('list');

  useEffect(() => {
    const savedView = localStorage.getItem('membersView');
    setActiveView(savedView);
    if (loadedUser) {
      const savedView = localStorage.getItem('membersView');
      if (savedView && hasFamilyMap) {
        setActiveView(savedView);
      } else {
        localStorage.setItem('membersView', 'list');
        setActiveView('list');
      }
    }
  }, [loadedUser]);

  const onClickPDF = () => {
    if (activeView === 'card') {
      return ErrorNotification('PDF only available for list view');
    } else {
      SendTrackingEvent(
        invocationId,
        'click',
        'create_pdf_report',
        'household_members',
        {
          isConsumer: isConsumer,
        }
      );
      return DownloadReport({
        value: 'members',
        hasOptions: false,
      });
    }
  };

  const viewOptions = [
    {
      value: 'list',
      label: 'List View',
      image: bars,
      dataTag: 'list-view',
    },
    {
      value: 'card',
      label: 'Family Map',
      image: cardGrid,
      dataTag: 'family-map-view',
    },
  ];

  return (
    <PageContainer>
      <PageHeader
        content={{
          ...(activeView === 'list'
            ? headerContent.members
            : headerContent.family),
          showButton: showPDF,
        }}
        buttonFunction={onClickPDF}
        showViews={hasFamilyMap}
        activeView={activeView}
        views={viewOptions}
        toggleView={(value) => {
          localStorage.setItem('membersView', value);
          setActiveView(value);
        }}
      />
      {activeView === 'card' ? (
        <HouseholdFamilyMap
          isConsumer={isConsumer}
          canEdit={canEdit}
          householdId={householdId}
        />
      ) : (
        <HouseholdMembersList
          isConsumer={isConsumer}
          canEdit={canEdit}
          householdId={householdId}
        />
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

HouseholdMembers.propTypes = {
  isConsumer: PropTypes.bool,
  canEdit: PropTypes.bool,
  showPDF: PropTypes.bool,
};

export default HouseholdMembers;
