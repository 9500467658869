import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { colors, fonts } from '../../styles/variables';
import { timesWhite } from '../../assets';
import {
  DetermineSignalColor,
  GenerateSignalIcon,
  // GenerateRiskText,
  GenerateSignalColorIcon,
} from '../../utils';
import { toggleShowNewTargetMapModal } from '../../store/actions';
import {
  ModalBackground,
  ModalOverlay,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  SmallerModalContainer,
  ModalContentContainer,
} from '../../styles/library/modalStyles';

const SignalDetailsModal = ({ signal, hide }) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const signalDetails = {
    liquidity: {
      name: 'Liquidity',
      details: `This Signal is based on the availability of assets to self-fund an unexpected expense equal to 3 months of total gross income. It calculates the effective emergency reserves of primary members' non-retirement assets relative to primary members' annual earned income.`,
      considerations: [
        {
          text: `Determine an ideal emergency reserve balance and location for these funds, discuss household expenses, and confirm a more accurate reserve level.`,
        },
        {
          text: `Consider other non-obvious resources that may be available in a liquidity crunch.`,
        },
        { text: `Identify a specific account as the liquidity reserve.` },
      ],
    },
    ltd: {
      name: 'Long-Term Disability',
      details: `This Signal is based on the ability to replace lost gross earned income at key ratio thresholds in the event of disability. It calculates the replacement ratio comparing the total primary members' household annual disability income insurance coverage relative to household annual earned income.`,
      additional: `Assets, Inflation and time value of money are not considered in this calculation.`,
      considerations: [
        {
          text: `Determine earned income dependency, discuss available employer benefits, and clarify core living expenses.`,
        },
        {
          text: `Examine existing or potential disability insurance and consider its definition, features and durations of coverage.`,
        },
        { text: `Run a Disability Target-Map`, targetMapType: 'disability' },
      ],
    },
    life: {
      name: 'Loss of Life',
      details: `This Signal is based on the ability to replace lost gross earned income at key ratio thresholds in the event of premature death. It calculates the replacement ratio of primary members' household life insurance coverage relative to their remaining aggregate, pre-retirement annual earned income.`,
      additional: `Assets, Inflation and time value of money are not considered in this calculation.`,
      considerations: [
        {
          text: `Determine earned income dependency for survivors, discuss available employer benefits, and clarify core living expenses.`,
        },
        {
          text: `Examine existing or potential life insurance and consider duration, owner, beneficiaries, and other potential dependencies on earned income potential.`,
        },
        { text: `Run a Loss-of-Life Target-Map`, targetMapType: 'death' },
      ],
    },
    ltc: {
      name: 'Long-Term Care',
      details: `This Signal is based on the ability to fund the total cost of care at key ratio thresholds. It calculates the funding ratio of primary members' existing household long-term care insurance coverage, plus their net worth (less home equity), relative to an average aggregate cost of care for each primary member(s).`,
      additional: `Inflation and time value of money are not considered in this calculation.`,
      considerations: [
        {
          text: `Determine reasonable cost of care locally, the desired outcome and any existing plans.`,
        },
        {
          text: `Examine existing or potential LTC insurance for policy benefit, duration, features, home health care options, and desire to retain an estate.`,
        },
        {
          text: `Run a Long-term Care Target-Map`,
          targetMapType: 'ltc',
        },
      ],
    },
    longevity: {
      name: 'Longevity',
      details: `This Signal is based on the ability to fund current income beyond average mortality ranges. It calculates the funding ratio by totaling the primary members' household expected unearned income in retirement, plus net worth less residence home equity relative to the remaining aggregate current income through age 95.`,
      additional: `Inflation and time value of money are not considered in this calculation.`,
      considerations: [
        {
          text: `Determine all sources of income available during retirement years, discuss pension election availability, and clarify core living expenses in retirement.`,
        },
        {
          text: `Examine existing or potential sources of income that are sustainable and consider payment options within government issued retirement programs.`,
        },
        { text: `Run a Retirement Target-Map`, targetMapType: 'retirement' },
      ],
    },
    liability: {
      name: 'Legal Liability',
      details: `This Signal is based on the ability to protect current attachable net worth from a legal attack on assets at key ratio thresholds. It calculates the exposure ratio by totaling the primary members' household liability coverage relative to net worth (excluding retirement assets).`,
      additional: `Inflation and time value of money are not considered in this calculation.`,
      considerations: [
        {
          text: `Determine if the household is in a high risk liability profession or other target, and discuss asset protection strategies applicable to the relevant jurisdiction.`,
        },
        {
          text: `Examine existing or potential sources of coverage and consider removing exposures.`,
        },
        {
          text: `Run a Custom Target-Map illustrating a liability event`,
          targetMapType: 'custom',
        },
      ],
    },
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <SmallerModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>{signal?.label} Details</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <StatusContainer>
              <IconContainer
                style={{
                  border: `2px solid ${DetermineSignalColor(
                    signal?.state,
                    signal?.snoozed
                  )}`,
                }}
              >
                {GenerateSignalIcon(signal?.name, colors.darkGrey)}
                <ColorStatusContainer>
                  {GenerateSignalColorIcon(signal?.calculated, signal?.snoozed)}
                </ColorStatusContainer>
              </IconContainer>
              {/* <SignalStatus>
                {GenerateRiskText(signal?.calculated, true)}
              </SignalStatus> */}
            </StatusContainer>
            <SignalDetails>
              {signalDetails[signal?.name]?.details || 'Error: Missing Details'}
              <SignalAdditional>
                {' '}
                {signalDetails[signal?.name]?.additional}
              </SignalAdditional>
            </SignalDetails>
            <SignalConsiderations>
              <ConsiderationsHeading>Considerations</ConsiderationsHeading>
              <ConsiderationsList>
                {signalDetails[signal?.name]?.considerations?.map(
                  (consideration, index) => {
                    return (
                      <li key={index}>
                        {consideration?.targetMapType ? (
                          <ConsiderationLink
                            onClick={() => {
                              dispatch(
                                toggleShowNewTargetMapModal(
                                  true,
                                  consideration?.targetMapType
                                )
                              );
                            }}
                          >
                            {consideration?.text}
                          </ConsiderationLink>
                        ) : (
                          consideration?.text
                        )}
                      </li>
                    );
                  }
                )}
              </ConsiderationsList>
            </SignalConsiderations>
          </ModalContentContainer>
        </SmallerModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const ConsiderationLink = styled.span`
  color: ${colors.lightBlue};
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  flex: 0 0 auto;
  margin: 5px 0 15px 0;
`;

const ColorStatusContainer = styled.div`
  position: absolute;
  top: -4px;
  right: -9px;
`;

// const SignalStatus = styled.p`
//   font-size: 16px;
//   font-weight: ${fonts.semiBold};
//   text-align: center;
//   margin-bottom: 5px;
// `;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const SignalDetails = styled.div`
  font-size: 12px;
  font-weight: ${fonts.semiBold}
  color: ${colors.darkGrey};
  text-align: center;
  margin: 0 0 10px 0;
`;

const SignalAdditional = styled.span`
  font-style: italic;
  font-weight: ${fonts.regular};
`;

const SignalConsiderations = styled.div`
  font-size: 11px;
  color: ${colors.darkGrey};
  text-align: left;
  margin: 25px 0 7px 0;
`;

const ConsiderationsHeading = styled.p`
  font-size: 12px;
  color: ${colors.darkGrey};
  font-weight: ${fonts.semiBold};
  margin-bottom: 5px;
`;

const ConsiderationsList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  li {
    margin-bottom: 3px;
  }
`;

export default SignalDetailsModal;
