/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { HouseholdSharingModal, SmallTable } from '../../../components';
import {
  getHouseholdSharingInvite,
  getHouseholdSharingList,
  getMemberInfo,
  toggleShowCancelSharingModal,
  toggleShowMemberModal,
  toggleShowShareHouseholdModal,
  updateUserStoreValue,
  toggleShowConnectPreviewModal,
} from '../../../store/actions';
import {
  share,
  pencil,
  launchLink,
  //  stop,
  cancel,
} from '../../../assets';
import { PageContent } from '../../../styles/library/layoutStyles';

const HouseholdSharing = ({ householdId }) => {
  const dispatch = useDispatch();
  const {
    currentHousehold,
    householdSharingList,
    createdSharingInvite,
    showingShareHousehold,
    memberAction,
    memberId,
    hasConnectPreview,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    householdSharingList: state.households.householdSharingList,
    createdSharingInvite: state.households.createdSharingInvite,
    showingShareHousehold: state.households.showingShareHousehold,
    memberAction: state.members.memberAction,
    memberId: state.members.memberId,
    hasConnectPreview: state.user.hasConnectPreview,
  }));
  const [sharingList, setSharingList] = useState([]);
  const [loadedSharingList, setLoadedSharingList] = useState(false);

  const tableHeadings = [
    {
      text: 'Name',
      display: true,
    },
    {
      text: 'Role',
      display: true,
    },
    {
      text: 'Email',
      display: true,
    },
    {
      text: 'Permission Set',
      display: true,
    },
    {
      text: 'Last Login',
      display: true,
    },
    {
      text: 'Status',
      align: 'center',
      display: true,
    },
    { text: 'Actions', display: false },
  ];

  //HOOK TO FETCH SHARING LIST ON CONTAINER LOAD
  // useEffect(() => {
  //   if (currentHousehold?.uuid) {
  //     dispatch(getHouseholdSharingList(currentHousehold.uuid));
  //   }
  // }, [currentHousehold?.uuid]);

  //HOOK TO SET SHARING LIST FROM API DATA
  useEffect(() => {
    if (householdSharingList) {
      const transformedList = householdSharingList.map((item) => {
        return {
          ...item.householdmember,
          email: item?.consumerinvite?.email || item?.householdmember?.email,
          status: item?.consumerinvite?.status || 'inactive',
          last_login: item?.consumerinvite?.last_login || null,
          role: item?.consumerinvite?.role?.name || null,
        };
      });
      setSharingList(transformedList);
      setLoadedSharingList(true);
    }
  }, [householdSharingList]);

  //FETCH HOUSEHOLD LIST AFTER ACTION TAKEN ON SHARE MODAL
  useEffect(() => {
    if (memberAction && memberAction !== 'read') {
      dispatch(getHouseholdSharingList(currentHousehold.uuid));
    }
  }, [memberAction, memberId]);

  //FETCH HOUSEHOLD LIST AFTER CREATING INVITE
  useEffect(() => {
    if (createdSharingInvite) {
      dispatch(updateUserStoreValue('createdSharingInvite', false));
      dispatch(getHouseholdSharingList(currentHousehold.uuid));
    }
  }, [createdSharingInvite]);

  //API CALL TO SHARE HOUSEHOLD AND SHOW DETAILS MODAL
  const shareHousehold = (member) => {
    dispatch(getHouseholdSharingInvite(currentHousehold?.uuid, member?.uuid));
    dispatch(toggleShowShareHouseholdModal(true, member));
  };

  //FUNCTION TO SHOW STOP SHARING SIMPLE MODAL
  const stopSharing = (member) => {
    dispatch(toggleShowCancelSharingModal(true, member));
  };

  //GET MEMBER DETAILS AND OPEN MODAL ON CLICK
  const memberEditFunction = (member) => {
    dispatch(getMemberInfo(householdId, member.id, false));
    dispatch(toggleShowMemberModal(true, true));
  };

  const editPermissions = (member) => {
    dispatch(getHouseholdSharingInvite(currentHousehold?.uuid, member?.uuid));
    dispatch(toggleShowShareHouseholdModal(true, member));
  };

  // const resumeSharing = (member) => {
  //   console.log('resume sharing', member);
  //   dispatch(toggleShowShareHouseholdModal(true, 'resume'));
  // };

  // const modifySharing = (member, type) => {
  //   console.log(`${type} sharing`, member);
  //   setSharingModalContent({
  //     showing: true,
  //     type: type,
  //     item: member,
  //     error: '',
  //     isLoading: false,
  //   });
  // };

  const togglePreviewMode = (item) => {
    dispatch(toggleShowConnectPreviewModal(true, item));
    // dispatch(
    //   showClientPreviewMode(
    //     currentHousehold?.uuid,
    //     window.location.href
    //   )
    // );
  };

  //LIST OF ACTIONS THAT CAN BE TAKE ON SHARE
  const actionsList = [
    {
      icon: launchLink,
      label: 'Enter Client Preview',
      value: 'preview',
      onClick: togglePreviewMode,
      hide: !hasConnectPreview,
    },
    {
      icon: share,
      label: 'Client Portal Invite',
      value: 'share',
      onClick: shareHousehold,
    },
    {
      icon: pencil,
      label: 'Edit Permissions',
      value: 'edit',
      onClick: editPermissions,
    },
    // {
    //   icon: pause,
    //   label: 'Pause Sharing',
    //   value: 'pause',
    //   onClick: modifySharing,
    // },
    // {
    //   icon: play,
    //   label: 'Resume Sharing',
    //   value: 'resume',
    //   onClick: resumeSharing,
    // },
    {
      icon: cancel,
      label: 'Stop Sharing',
      value: 'stop',
      onClick: stopSharing,
    },
  ];

  return (
    <>
      {showingShareHousehold && (
        <HouseholdSharingModal
          showModal={true}
          householdId={householdId}
          hide={() => {
            dispatch(toggleShowShareHouseholdModal(false, null));
          }}
        />
      )}
      <PageContent>
        <SmallTable
          columnHeadings={tableHeadings}
          type="household_sharing"
          customEmptyResults={true}
          items={sharingList}
          loaded={loadedSharingList}
          actionsList={actionsList.filter((action) => !action.hide)}
          editFunction={memberEditFunction}
        />
      </PageContent>
    </>
  );
};

HouseholdSharing.propTypes = {
  householdId: PropTypes.string,
};

export default HouseholdSharing;
