/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  SimpleModal,
  MemberModal,
  EntityModal,
  ProfessionalModal,
  AdvisorModal,
  NewTargetMapModal,
  StencilModal,
  ReportDetailsModal,
  ReportShareModal,
  FileUploadModal,
  TargetMapTemplateModal,
  Button,
  ImagePreviewModal,
  LegalModal,
} from '../../components';
import { checkCircle, errorCircleRed } from '../../assets';
import { messageColors } from '../../styles/variables';
import {
  AssetModal,
  CashFlowModal,
  LiabilityModal,
  InsuranceModal,
  SideNavContainer,
} from '../../containers';
import {
  getHouseholdPermission,
  getHouseholdInfo,
  updateLocalHousehold,
  getHouseholdAssetMapImage,
  refreshHouseholdAssetMapImage,
  getIntegrations,
  getHouseholdKFFs,
  getTargetMaps,
  getHouseholdNotes,
  getBeneficiaryReport,
  setKffPanelValues,
  removeHouseholdInfo,
  setTempHousehold,
  setCurrencyObj,
  toggleShowMemberModal,
  toggleShowEntityModal,
  toggleShowProfessionalModal,
  toggleShowMilestoneModal,
  toggleShowAdvisorModal,
  toggleShowDeleteModal,
  deleteMember,
  deleteInstrument,
  deleteTargetMap,
  deleteTargetMapInstrument,
  deleteAssetMapLayout,
  deleteSignalComment,
  deleteLegal,
  toggleShowAssetModal,
  toggleShowCashFlowModal,
  toggleShowLiabilityModal,
  toggleShowInsuranceModal,
  toggleShowNewTargetMapModal,
  toggleShowStencilModal,
  updateHouseholdMilestone,
  dismissHouseholdMilestone,
  getCustomUserPreferences,
  toggleShowUploadFileModal,
  getHouseholdIntegrationFolder,
  getMemberIntegrationInfo,
  getInstrumentIntegrationInfo,
  toggleShowReportDetailsModal,
  toggleShowReportShareModal,
  toggleShowTargetMapNotes,
  updateTargetMapNotes,
  toggleShowTargetMapTemplateModal,
  getHouseholdFields,
  deleteTargetMapTemplate,
  takeBulkInstrumentAction,
  toggleShowBulkModal,
  toggleShowCloneStencilModal,
  setHasHouseholdPermission,
  setHouseholdMilestones,
  deleteAssetMapProposal,
  deleteProposalInstrument,
  deleteProposalAction,
  getHouseholdSharingList,
  syncHouseholdIntegrations,
  delayAutoSyncIntegrations,
  cloneStencil,
  updateHouseholdStoreValue,
  deleteHouseholdSharingInvite,
  toggleShowShareHouseholdModal,
  toggleShowConnectPreviewModal,
  enterClientPreviewMode,
  setUserToggleModal,
  unsetBulkSync,
  updateTargetMapStoreValue,
  deleteProfessional,
  toggleShowLegalModal,
  getLegals,
} from '../../store/actions';
import {
  Page,
  CenteredContainerRow,
  LeftNavOutlet,
} from '../../styles/library/layoutStyles';
import { ButtonThemes } from '../../styles/themes';
import {
  CloseDropdowns,
  SendTrackingEvent,
  InfoNotification,
  LoadingNotification,
  SuccessNotification,
  ErrorNotification,
  integrationOptions,
  TransformObjectKey,
} from '../../utils';
import styled from 'styled-components';

const Household = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const householdId = params?.id;
  CloseDropdowns();
  const {
    invocationId,
    sideNavWidth,
    householdPermission,
    currentHousehold,
    hasHouseholdPermission,
    showHouseholdSharing,
    showTargetMaps,
    currentTargetMap,
    householdCurrency,
    amrImage,
    refreshedImage,
    householdKffs,
    kffPreferences,
    householdMilestones,
    indexFields,
    currency,
    currencies,
    milestonesOptions,
    householdIndex,
    modifiedInstrument,
    showingMemberModal,
    memberDefaultTab,
    showingProfessionalModal,
    professionalDefaultTab,
    showingMilestoneModal,
    userOpenedMilestoneModal,
    showingEntityModal,
    entityDefaultTab,
    showingAdvisorModal,
    showingAssetModal,
    assetDefaultTab,
    showingCashFlowModal,
    cashFlowDefaultTab,
    showingLiabilityModal,
    liabilityDefaultTab,
    showingInsuranceModal,
    insuranceDefaultTab,
    showingNewTargetMapModal,
    hasTargetMapTemplates,
    newTargetMapType,
    createdTargetMap,
    createdTargetMapId,
    showingStencilModal,
    showingUploadFileModal,
    showingReportDetailsModal,
    showingReportShareModal,
    selectedReport,
    deleteModal,
    bulkModal,
    cloneStencilModal,
    cloneStencilError,
    isNewMember,
    isPersonal,
    isNewEntity,
    isExcluded,
    isNewProfessional,
    isNewAdvisor,
    isNewAsset,
    isNewCashFlow,
    isNewLiability,
    isNewInsurance,
    instrumentAction,
    instrumentId,
    updatedInstrument,
    clonedInstrument,
    memberAction,
    memberId,
    updatedMember,
    professionalAction,
    professionalId,
    updatedProfessional,
    fileUploadIntegration,
    justUnlinkedFileStorage,
    justLinkedFileStorage,
    uploadFolderId,
    uploadedFiles,
    uploadFileLocation,
    showingTargetMapNotesModal,
    targetMapNotesError,
    showingTargetMapTemplateModal,
    isNewTargetMapTemplate,
    targetMapTemplateRules,
    existingTemplate,
    targetMapTemplateError,
    isNotesPage,
    updatedComments,
    currentProposal,
    showProposalMode,
    pageLimit,
    showMilestonesElite,
    autoSync,
    currentUser,
    advisors,
    bulkSync,
    integrations,
    justSynced,
    syncAllResults,
    cloningStencil,
    showingCancelSharingModal,
    updatedInvite,
    inviteDetails,
    inviteUpdated,
    updateInviteError,
    showingStencilImagePreview,
    connectPreviewModal,
    enteringPreviewMode,
    connectPreviewError,
    targetMapSummaryError,
    systemMemberCategories,
    systemProfessionalCategories,
    instrumentCategories,
    hasLegal,
    showingLegalModal,
    isNewLegal,
  } = useSelector((state) => ({
    invocationId: state.configs.invocationId,
    sideNavWidth: state.user.sideNavWidth,
    householdPermission: state.households.householdPermission,
    currentHousehold: state.households.currentHousehold,
    hasHouseholdPermission: state.households.hasHouseholdPermission,
    showHouseholdSharing: state.user.showHouseholdSharing,
    showTargetMaps: state.user.showTargetMaps,
    currentTargetMap: state.targetMaps.currentTargetMap,
    householdCurrency: state.households.householdCurrency,
    amrImage: state.households.amrImage,
    refreshedImage: state.households.refreshedImage,
    householdKffs: state.households.householdKffs,
    kffPreferences: state.user.user.kff,
    householdMilestones: state.households.householdMilestones,
    showReportHistory: state.user.showReportHistory,
    indexFields: state.configs.indexFields,
    currency: state.households.currentHousehold.currency,
    currencies: state.configs.currencies,
    milestonesOptions: state.configs.milestonesOptions,
    householdIndex: state.households.householdIndex.results,
    modifiedInstrument: state.instruments.modifiedInstrument,
    showingMemberModal: state.members.showingMemberModal,
    memberDefaultTab: state.members.memberDefaultTab,
    showingProfessionalModal: state.members.showingProfessionalModal,
    professionalDefaultTab: state.members.professionalDefaultTab,
    showingMilestoneModal: state.households.showingMilestoneModal,
    userOpenedMilestoneModal: state.households.userOpenedMilestoneModal,
    showingEntityModal: state.members.showingEntityModal,
    entityDefaultTab: state.members.entityDefaultTab,
    showingAdvisorModal: state.members.showingAdvisorModal,
    showingAssetModal: state.instruments.showingAssetModal,
    assetDefaultTab: state.instruments.assetDefaultTab,
    showingCashFlowModal: state.instruments.showingCashFlowModal,
    cashFlowDefaultTab: state.instruments.cashFlowDefaultTab,
    showingLiabilityModal: state.instruments.showingLiabilityModal,
    liabilityDefaultTab: state.instruments.liabilityDefaultTab,
    showingInsuranceModal: state.instruments.showingInsuranceModal,
    insuranceDefaultTab: state.instruments.insuranceDefaultTab,
    showingNewTargetMapModal: state.targetMaps.showingNewTargetMapModal,
    hasTargetMapTemplates: state.user.hasTargetMapTemplates,
    newTargetMapType: state.targetMaps.newTargetMapType,
    createdTargetMap: state.targetMaps.createdTargetMap,
    createdTargetMapId: state.targetMaps.createdTargetMapId,
    showingStencilModal: state.households.showingStencilModal,
    showingUploadFileModal: state.households.showingUploadFileModal,
    showingReportDetailsModal: state.households.showingReportDetailsModal,
    showingReportShareModal: state.households.showingReportShareModal,
    selectedReport: state.households.selectedReport,
    deleteModal: state.households.deleteModal,
    bulkModal: state.households.bulkModal,
    cloneStencilModal: state.households.cloneStencilModal,
    cloneStencilError: state.households.cloneStencilError,
    isNewMember: state.members.isNewMember,
    isPersonal: state.members.isPersonal,
    isNewEntity: state.members.isNewEntity,
    isExcluded: state.members.isExcluded,
    isNewProfessional: state.members.isNewProfessional,
    isNewAdvisor: state.members.isNewAdvisor,
    isNewAsset: state.instruments.isNewAsset,
    isNewCashFlow: state.instruments.isNewCashFlow,
    isNewLiability: state.instruments.isNewLiability,
    isNewInsurance: state.instruments.isNewInsurance,
    instrumentAction: state.instruments.instrumentAction,
    instrumentId: state.instruments.instrumentId,
    updatedInstrument: state.instruments.updatedInstrument,
    clonedInstrument: state.instruments.clonedInstrument,
    memberAction: state.members.memberAction,
    memberId: state.members.memberId,
    updatedMember: state.members.updatedMember,
    professionalAction: state.members.professionalAction,
    professionalId: state.members.professionalId,
    updatedProfessional: state.members.updatedProfessional,
    fileUploadIntegration: state.households.fileUploadIntegration,
    justUnlinkedFileStorage: state.households.justUnlinkedFileStorage,
    justLinkedFileStorage: state.households.justLinkedFileStorage,
    uploadFolderId: state.households.uploadFolderId,
    uploadedFiles: state.households.uploadedFiles,
    uploadFileLocation: state.households.uploadFileLocation,
    showingTargetMapNotesModal: state.targetMaps.showingTargetMapNotesModal,
    targetMapNotesError: state.targetMaps.targetMapNotesError,
    showingTargetMapTemplateModal:
      state.targetMaps.showingTargetMapTemplateModal,
    isNewTargetMapTemplate: state.targetMaps.isNewTargetMapTemplate,
    targetMapTemplateRules: state.targetMaps.targetMapTemplateRules,
    existingTemplate: state.targetMaps.existingTemplate,
    targetMapTemplateError: state.targetMaps.targetMapTemplateError,
    isNotesPage: state.targetMaps.isNotesPage,
    updatedComments: state.households.updatedComments,
    currentProposal: state.households.currentProposal,
    showProposalMode: state.households.showProposalMode,
    pageLimit: state.configs?.pagination?.default_limit || 25,
    showMilestonesElite: state.user?.showMilestonesElite,
    autoSync: state.households.currentHousehold.integrations?.auto_sync,
    currentUser: state.user.user.uuid,
    advisors: state.households.currentHousehold.advisors,
    bulkSync: state.households.bulkSync,
    integrations: state.households.currentHousehold.integrations,
    justSynced: state.households.justSynced,
    syncAllResults: state.households.syncAllResults,
    cloningStencil: state.households.cloningStencil,
    showingCancelSharingModal: state.households.showingCancelSharingModal,
    updatedInvite: state.households.updatedInvite,
    inviteDetails: state.households.inviteDetails,
    inviteUpdated: state.households.inviteUpdated,
    updateInviteError: state.households.updateInviteError,
    showingStencilImagePreview: state.households.showingStencilImagePreview,
    connectPreviewModal: state.households.connectPreviewModal,
    enteringPreviewMode: state.user.enteringPreviewMode,
    connectPreviewError: state.user.connectPreviewError,
    targetMapSummaryError: state.targetMaps.targetMapSummaryError,
    systemMemberCategories: state.configs.systemMemberCategories,
    systemProfessionalCategories: state.configs.systemProfessionalCategories,
    instrumentCategories: state.configs.instrumentCategories,
    hasLegal: state.user.hasLegal,
    showingLegalModal: state.households.showingLegalModal,
    isNewLegal: state.households.isNewLegal,
  }));

  const [updatedTargetMap, setUpdatedTargetMap] = useState({
    notes: 'Loading Notes...',
  });
  const [isSavingTargetMap, setIsSavingTargetMap] = useState(false);
  const [isDeletingComment, setIsDeletingComment] = useState(false);
  const [assignedMilestone, setAssignedMilestone] = useState({
    number: false,
    comment: '',
  });
  const [milestonesModalError, setMilestonesModalError] = useState();
  const [sharingModalContent, setSharingModalContent] = useState({
    showing: false,
    type: '',
    item: null,
    error: '',
    isLoading: false,
  });
  const [syncingList, setSyncingList] = useState();
  const [autoSyncingList, setAutoSyncingList] = useState();
  const [cancelAutoSync, setCancelAutoSync] = useState();
  const [visitorIsLead, setVisitorIsLead] = useState();
  const [toSync, setToSync] = useState(false);
  const [modalHasBeenOpened, setModalHasBeenOpened] = useState();
  const [notesModalError, setNotesModalError] = useState('');
  const toSyncRef = useRef(toSync);
  toSyncRef.current = toSync;

  const setCurrency = (currency, currencies) => {
    let matchedCurrency = currencies.find((cur) => {
      return cur.code === currency;
    });
    if (matchedCurrency?.decimal === '') {
      matchedCurrency.decimal = '.';
    }
    dispatch(setCurrencyObj(matchedCurrency));
  };

  useEffect(() => {
    return () => {
      dispatch(unsetBulkSync());
      stopAutoSync();
    };
  }, []);

  useEffect(() => {
    if (
      showingMemberModal ||
      showingCashFlowModal ||
      showingAssetModal ||
      showingLiabilityModal ||
      showingInsuranceModal ||
      showingEntityModal
    ) {
      setModalHasBeenOpened(true);
      dispatch(setUserToggleModal(true));
    } else {
      if (modalHasBeenOpened) {
        dispatch(setUserToggleModal(false));
        setModalHasBeenOpened(false);
      }
    }
  }, [
    showingMemberModal,
    showingCashFlowModal,
    showingAssetModal,
    showingLiabilityModal,
    showingInsuranceModal,
    showingEntityModal,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (householdIndex) {
      let selectedHousehold = householdIndex.filter((hh) => {
        return hh.id === householdId;
      });
      dispatch(setTempHousehold(selectedHousehold[0]));
    }
    // return () => {
    //   dispatch(removeHouseholdInfo(householdId));
    // };
  }, [householdIndex]);

  useEffect(() => {
    if (householdPermission) {
      if (householdPermission === 'write' || householdPermission === 'read') {
        dispatch(setHasHouseholdPermission(true));
        dispatch(getHouseholdInfo(householdId));
        dispatch(getHouseholdAssetMapImage(householdId));
        dispatch(getIntegrations());
        dispatch(getHouseholdKFFs(householdId));
        dispatch(getHouseholdNotes(householdId));
        dispatch(getBeneficiaryReport(householdId));
        dispatch(getCustomUserPreferences());
      } else if (householdPermission === 'subscription') {
        window.location = `${window.location.origin}/subscription`;
      } else {
        dispatch(setHasHouseholdPermission(false));
      }
    }
  }, [householdPermission]);

  useEffect(() => {
    hasLegal && dispatch(getLegals(householdId));
  }, [hasLegal]);

  useEffect(() => {
    if (showTargetMaps) {
      dispatch(getTargetMaps(householdId));
    }
  }, [showTargetMaps, householdId]);

  useEffect(() => {
    if (currentHousehold?.uuid && showHouseholdSharing) {
      dispatch(getHouseholdSharingList(currentHousehold?.uuid));
    }
  }, [showHouseholdSharing, currentHousehold?.uuid]);

  useEffect(() => {
    if (updatedInvite) {
      dispatch(getHouseholdSharingList(currentHousehold?.uuid));
      dispatch(updateHouseholdStoreValue('updatedInvite', false));
    }
  }, [updatedInvite]);

  // useEffect(() => {
  //   if (currentHousehold?.language) {
  //     dispatch(getHouseholdFields(currentHousehold?.language));
  //   }
  // }, [currentHousehold]);

  useEffect(() => {
    dispatch(getHouseholdPermission(householdId));
    dispatch(getHouseholdFields(householdId));
    return () => {
      dispatch(removeHouseholdInfo(householdId));
      dispatch(setHouseholdMilestones(false));
    };
  }, [householdId]);

  useEffect(() => {
    if (currentTargetMap) {
      setUpdatedTargetMap({ ...currentTargetMap });
    }
  }, [currentTargetMap, showingTargetMapNotesModal]);

  useEffect(() => {
    if (createdTargetMap && createdTargetMapId) {
      const inCompare = pathname.includes('targetmap/compare');
      if (!inCompare) {
        navigate(`/household/${householdId}/targetmap/${createdTargetMapId}`);
      }
    }
  }, [createdTargetMap, createdTargetMapId]);

  useEffect(() => {
    if (showingTargetMapNotesModal) {
      setIsSavingTargetMap(false);
    }
    if (!showingTargetMapNotesModal) {
      setNotesModalError();
      setUpdatedTargetMap({ notes: 'Loading Notes...' });
    }
  }, [showingTargetMapNotesModal]);

  useEffect(() => {
    if (targetMapNotesError) {
      setIsSavingTargetMap(false);
      const errorData = targetMapNotesError?.data;
      const errorKeys = Object.keys(errorData);
      const errorKeyNames = errorKeys.map((key) => {
        return TransformObjectKey(key);
      });
      let errorMessage = 'Please fix errors and save again';
      if (errorKeys.length) {
        errorMessage = errorKeyNames.reduce((acc, curr, index) => {
          const isLast = index === errorKeyNames.length - 1;
          curr = `<strong>${curr}</strong>: ${errorData[errorKeys[index]]}.${!isLast ? `<br/>` : ''}`;
          return acc + curr;
        }, '');
      }
      setNotesModalError(errorMessage);
      dispatch(updateTargetMapStoreValue('targetMapNotesError', null));
    }
  }, [targetMapNotesError]);

  useEffect(() => {
    if (refreshedImage === false && amrImage) {
      if (
        !amrImage.hasOwnProperty('refresh') &&
        (!amrImage.fresh || amrImage.is_error)
      ) {
        dispatch(
          refreshHouseholdAssetMapImage(
            householdId,
            amrImage.is_error ? 'force' : true
          )
        );
      }
    }
  }, [refreshedImage, amrImage]);

  useEffect(() => {
    if (modifiedInstrument) {
      dispatch(getHouseholdKFFs(householdId));
    }
  }, [modifiedInstrument]);

  useEffect(() => {
    if (clonedInstrument) {
      dispatch(getHouseholdInfo(householdId));
    }
  }, [clonedInstrument]);

  useEffect(() => {
    if (justUnlinkedFileStorage) {
      dispatch(getHouseholdInfo(householdId));
    }
  }, [justUnlinkedFileStorage]);

  useEffect(() => {
    if (justLinkedFileStorage) {
      dispatch(getHouseholdInfo(householdId));
      //Load folder??
    }
  }, [justLinkedFileStorage]);

  useEffect(() => {
    if (updatedComments) {
      setIsDeletingComment(false);
    }
  }, [updatedComments]);

  useEffect(() => {
    if (
      householdKffs !== null &&
      kffPreferences &&
      kffPreferences.household.length !== 0 &&
      indexFields &&
      indexFields.length !== 0
    ) {
      let matchedKffs = kffPreferences.household.map((kff) => {
        let matchingIndex = indexFields.find(
          (field) => field.fieldName === kff
        );
        let value = householdKffs[kff];
        return { ...matchingIndex, value: value };
      });
      dispatch(setKffPanelValues(matchedKffs));
    }
  }, [householdKffs, kffPreferences, indexFields]);

  useEffect(() => {
    if (currency && currencies) {
      setCurrency(currency, currencies);
    }
  }, [currency, currencies]);

  useEffect(() => {
    if (targetMapSummaryError) {
      let errorMessage = 'Error Updating Target-Map';
      if (targetMapSummaryError?.data) {
        let type = Object.keys(targetMapSummaryError.data)[0];
        if (type) {
          errorMessage = targetMapSummaryError?.data[type];
        }
      }
      dispatch(updateTargetMapStoreValue('targetMapSummaryError', null));
      ErrorNotification(errorMessage, 5);
    }
  }, [targetMapSummaryError]);

  useEffect(() => {
    if (instrumentAction && instrumentId) {
      SendTrackingEvent(
        invocationId,
        'action',
        `instrument_${instrumentAction}`,
        null,
        {
          household: householdId,
          instrument: instrumentId,
        }
      );
    }
  }, [instrumentAction, instrumentId]);

  useEffect(() => {
    if (memberAction && memberId) {
      SendTrackingEvent(
        invocationId,
        'action',
        `householdmember_${memberAction}`,
        null,
        {
          household: householdId,
          householdmember: memberId,
        }
      );
    }
  }, [memberAction, memberId]);

  useEffect(() => {
    if (professionalAction && professionalId) {
      SendTrackingEvent(
        invocationId,
        'action',
        `householdmember_${professionalAction}`,
        null,
        {
          household: householdId,
          householdmember: professionalId,
        }
      );
    }
  }, [professionalAction, professionalId]);

  useEffect(() => {
    if (updatedMember) {
      const object = {
        key: 'householdmembers',
        action: memberAction,
        item: updatedMember,
      };
      dispatch(updateLocalHousehold(object, systemMemberCategories));
      const personalObj = {
        key: 'excludedHouseholdMembers',
        action: memberAction,
        item: updatedMember,
      };
      dispatch(updateLocalHousehold(personalObj, systemMemberCategories));
    }
    if (updatedProfessional) {
      const object = {
        key: 'householdprofessionals',
        action: professionalAction,
        item: updatedProfessional,
      };
      dispatch(updateLocalHousehold(object, systemProfessionalCategories));
    }
    if (updatedInstrument) {
      const object = {
        key: 'instruments',
        action: instrumentAction,
        item: updatedInstrument,
      };
      dispatch(updateLocalHousehold(object, instrumentCategories));
    }
  }, [updatedMember, updatedInstrument, updatedProfessional]);

  //IF SHOWING CANCEL SHARING MODAL SET MODAL CONTENT
  useEffect(() => {
    if (showingCancelSharingModal) {
      setSharingModalContent({
        showing: true,
        type: inviteDetails?.status === 'active' ? 'delete' : 'stop',
        item: inviteDetails,
        error: '',
        isLoading: false,
      });
      dispatch(updateHouseholdStoreValue('showingCancelSharingModal', false));
    }
  }, [showingCancelSharingModal]);

  //IF INVITE UPDATED - CLOSE SHARING SIMPLE MODAL
  useEffect(() => {
    if (inviteUpdated) {
      setSharingModalContent({
        showing: false,
        type: '',
        item: null,
        error: '',
        isLoading: false,
      });
      dispatch(toggleShowShareHouseholdModal(false));
      dispatch(getHouseholdSharingList(currentHousehold?.uuid));
    }
  }, [inviteUpdated]);

  //DISPLAY ERROR FOR MODIFYING SHARING INVITE
  useEffect(() => {
    if (updateInviteError) {
      let errorMessage = 'Unknown Error';
      if (updateInviteError?.statusText) {
        errorMessage = updateInviteError?.statusText;
      }
      setSharingModalContent({
        ...sharingModalContent,
        isLoading: false,
        error: errorMessage,
      });
      dispatch(updateHouseholdStoreValue('updateInviteError', false));
    }
  }, [updateInviteError]);

  const sendDeleteSignalComment = () => {
    if (!isDeletingComment) {
      setIsDeletingComment(true);
      dispatch(
        deleteSignalComment(householdId, deleteModal.id, deleteModal.subId)
      );
    }
  };

  const generateDeleteModalContent = () => {
    let yesButton = {
      text: 'Yes',
      theme: ButtonThemes.primary,
    };
    let displayType = deleteModal?.typeObject?.displayText;
    const group = deleteModal?.typeObject?.group;
    if (group === 'member') {
      yesButton.function = () =>
        dispatch(deleteMember(householdId, deleteModal.id, displayType));
    }
    if (group === 'professional') {
      yesButton.function = () =>
        dispatch(deleteProfessional(householdId, deleteModal.id));
    }
    if (group === 'instrument') {
      if (showProposalMode) {
        if (deleteModal?.data?.is_proposal) {
          yesButton.function = () =>
            dispatch(
              deleteProposalAction(
                currentHousehold?.uuid,
                currentProposal?.id,
                deleteModal?.data?.proposal_action_data?.id,
                displayType
              )
            );
        } else {
          yesButton.function = () =>
            dispatch(
              deleteProposalInstrument(
                currentHousehold?.uuid,
                currentProposal?.id,
                deleteModal?.data?.uuid,
                displayType
              )
            );
        }
      } else {
        yesButton.function = () =>
          dispatch(deleteInstrument(householdId, deleteModal.id, displayType));
      }
    }
    if (deleteModal.type === 'tm_template') {
      displayType = 'Target-Map Template';
      yesButton = {
        text: 'Yes',
        function: () => dispatch(deleteTargetMapTemplate(deleteModal.id)),
        theme: ButtonThemes.primary,
      };
    }
    if (deleteModal.isTargetMap) {
      yesButton = {
        text: 'Yes',
        function: () => dispatch(deleteTargetMap(householdId, deleteModal.id)),
        theme: ButtonThemes.primary,
      };
    }
    if (group === 'signal-comment') {
      yesButton.function = () => sendDeleteSignalComment();
      yesButton.showLoading = isDeletingComment;
      yesButton.loadingText = 'Deleting';
    }
    if (group === 'amr-layout') {
      yesButton.function = () =>
        dispatch(deleteAssetMapLayout(householdId, deleteModal.id));
    }
    if (group === 'amr-proposal') {
      yesButton.function = () =>
        dispatch(
          deleteAssetMapProposal(currentHousehold?.uuid, deleteModal.id)
        );
    }
    if (group === 'targetmap') {
      yesButton.function = () =>
        dispatch(deleteTargetMap(householdId, deleteModal.id));
    }
    if (group === 'targetmap-instrument') {
      yesButton.function = () =>
        dispatch(
          deleteTargetMapInstrument(
            householdId,
            deleteModal.id,
            deleteModal.typeObject?.apiValue,
            deleteModal.subId
          )
        );
    }
    if (group === 'legal') {
      yesButton.function = () =>
        dispatch(deleteLegal(householdId, deleteModal.id));
    }
    return {
      heading: `Delete ${displayType}?`,
      text: `Are you sure you want to delete<br/><strong>${deleteModal.name}</strong>?<br/> This cannot be undone.`,
      buttons: [
        {
          text: 'No',
          function: () => dispatch(toggleShowDeleteModal(false)),
          theme: ButtonThemes.cancel,
        },
        yesButton,
      ],
    };
  };

  const generateBulkModalContent = () => {
    const displayType = bulkModal?.typeObject?.label;
    const actionName = bulkModal?.actionObject?.label;
    const actionValue = bulkModal?.actionObject?.value;
    const count = bulkModal?.values?.length;
    return {
      heading: `${actionName} ${displayType}?`,
      text: `Are you sure you want to ${actionName}<br/><strong>${count} Selected ${displayType}</strong>?<br/>`,
      buttons: [
        {
          text: 'No',
          function: () => dispatch(toggleShowBulkModal(false)),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes',
          theme: ButtonThemes.primary,
          function: () =>
            dispatch(
              takeBulkInstrumentAction(
                householdId,
                bulkModal.values,
                actionValue
              )
            ),
        },
      ],
    };
  };

  const generateCloneStencilModalContent = () => {
    const stencilName = cloneStencilModal?.data?.label;
    const stencilId = cloneStencilModal?.data?.id;
    return {
      heading: `Add Stencil?`,
      text: `Are you sure you want to add<br/><strong>${stencilName}</strong> to your Personal Library?<br/>This will create a copy of this household and add it to your Household Index.`,
      buttons: [
        {
          text: 'Cancel',
          function: () => dispatch(toggleShowCloneStencilModal(false)),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Add',
          theme: ButtonThemes.primary,
          loadingText: 'Adding',
          showLoading: cloningStencil,
          function: () => dispatch(cloneStencil(stencilId, false)),
        },
      ],
    };
  };

  const generateConnectPreviewContent = () => {
    const { name, uuid, role, status } = connectPreviewModal?.data;
    const passRole = status === 'inactive' && role;
    return {
      heading: `Enter Client Preview?`,
      minWidth: '375px',
      text: `Would you like to preview how <strong>${name}</strong> will see this household in their account?`,
      infoBox: {
        message:
          'While in preview mode you will not be able to access your normal advisor features, you must exit the preview mode or logout to return to your account.',
        fontSize: '11px',
      },
      buttons: [
        {
          text: 'Cancel',
          function: () => dispatch(toggleShowConnectPreviewModal(false)),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Preview',
          theme: ButtonThemes.primary,
          loadingText: 'Previewing',
          showLoading: enteringPreviewMode,
          function: () =>
            dispatch(
              enterClientPreviewMode(
                currentHousehold?.uuid,
                uuid,
                name,
                passRole ? role : false
              )
            ),
        },
      ],
    };
  };

  useEffect(() => {
    if (showingMilestoneModal === false) {
      setAssignedMilestone({
        number: false,
        comment: '',
      });
    }
  }, [showingMilestoneModal]);

  const setMilestone = (value) => {
    let limit = pageLimit;
    let source = 'milestone_history';
    if (window.location.pathname.includes('summary')) {
      limit = 5;
      source = 'household_summary';
    }
    if (!showMilestonesElite) {
      dispatch(
        updateHouseholdMilestone(householdId, { number: value }, { limit })
      );
      return;
    }
    if (assignedMilestone.number === false) {
      setMilestonesModalError('Please select a milestone');
      return;
    }
    dispatch(
      updateHouseholdMilestone(householdId, assignedMilestone, { limit })
    );
    SendTrackingEvent(invocationId, 'click', 'milestone_create', source, {
      household: householdId,
      user_opened: userOpenedMilestoneModal,
      assigned_milestone: assignedMilestone,
    });
    setMilestonesModalError();
  };

  const updateMilestone = (value, key) => {
    if (key === 'number') {
      setAssignedMilestone({
        ...assignedMilestone,
        number: value.value,
      });
    }
    if (key === 'comment') {
      setAssignedMilestone({
        ...assignedMilestone,
        comment: value.target.value,
      });
    }
  };

  const generateMilestoneContent = () => {
    if (showingMilestoneModal) {
      let disableDismiss = false;
      if (
        householdMilestones &&
        householdMilestones.hasOwnProperty('disable_dismiss')
      ) {
        disableDismiss = householdMilestones.disable_dismiss;
      }
      let buttonList = [];
      if (milestonesOptions) {
        buttonList = milestonesOptions.map((milestone) => {
          return {
            id: milestone[0],
            name: milestone[1],
            onClick: setMilestone,
          };
        });
      } else {
        buttonList = [
          {
            id: null,
            name: 'No Milestone options provided',
          },
        ];
      }
      return {
        heading: `Assign Milestone`,
        text: `Which milestone are you working on today?`,
        disableDismiss: userOpenedMilestoneModal ? false : disableDismiss,
        afterText: userOpenedMilestoneModal ? null : 'Why am I seeing this?',
        toolTipText: userOpenedMilestoneModal
          ? null
          : 'This popup displays automatically if a milestone has not been set on a household within a certain amount of time.',
        buttonList,
      };
    }
  };

  const generateMilestoneEliteContent = () => {
    if (showingMilestoneModal) {
      let disableDismiss = false;
      if (
        householdMilestones &&
        householdMilestones.hasOwnProperty('disable_dismiss')
      ) {
        disableDismiss = householdMilestones.disable_dismiss;
      }
      let optionsList = [];
      if (!milestonesOptions) {
        return {
          heading: `Assign Milestone`,
          text: `Which milestone are you working on today?`,
          disableDismiss: disableDismiss,
          buttonList: [
            {
              id: null,
              name: 'No Milestone options provided',
            },
          ],
        };
      }
      optionsList = milestonesOptions.map((milestone) => {
        return {
          value: milestone[0],
          label: milestone[1],
        };
      });
      return {
        heading: `Assign Milestone`,
        minWidth: '320px',
        text: `Which milestone are you working on today?`,
        disableDismiss: userOpenedMilestoneModal ? false : disableDismiss,
        afterText: userOpenedMilestoneModal ? null : 'Why am I seeing this?',
        toolTipText: userOpenedMilestoneModal
          ? null
          : 'This popup displays automatically if a milestone has not been set on a household within a certain amount of time.',
        inputs: [
          {
            type: 'select',
            placeholder: 'Select a Milestone',
            options: optionsList,
            value: assignedMilestone.number,
            onChange: (e) => updateMilestone(e, 'number'),
            required: true,
            id: 'number',
          },
          {
            type: 'textarea',
            maxLength: '250',
            name: 'comment',
            placeholder: '250 character limit',
            label: 'Add an optional comment',
            value: assignedMilestone.comment,
            onChange: (e) => updateMilestone(e, 'comment'),
            required: false,
            id: 'comment',
            height: '135px',
          },
        ],
        buttons: [
          {
            text: 'Assign Milestone',
            function: setMilestone,
          },
        ],
      };
    }
  };

  const dismissMilestoneModal = () => {
    if (!userOpenedMilestoneModal) {
      dispatch(dismissHouseholdMilestone(householdId));
    }
    dispatch(toggleShowMilestoneModal(!showingMilestoneModal));
  };

  //SIMPLE MODAL FOR STOP AND DELETE SHARING INVITE
  const generateSimpleSharingModalContent = () => {
    let headingText = '';
    let buttonText = '';
    let loadingText = '';
    let text = '';
    let infoBoxText = '';
    let minWidth = '330px';
    const { name, uuid, status } = sharingModalContent.item;
    const isDelete =
      status === 'pending' || status === 'rejected' || status === 'expired';
    if (isDelete) {
      headingText = 'Delete Invite?';
      loadingText = 'Deleting';
      buttonText = 'Delete';
      text = `Are you sure you want to <strong>DELETE</strong> the Client Portal invite to <strong>${name}</strong>?`;
      infoBoxText = `The shareable link will no longer be active. You will need to create a new invite to have an active link.`;
      minWidth = '360px';
    } else {
      headingText = 'Stop Sharing?';
      loadingText = 'Stopping';
      buttonText = 'Stop';
      text = `Are you sure you want to <strong>STOP</strong> sharing this household with <strong>${name}</strong>?`;
      infoBoxText =
        'This user will lose <strong>all</strong> access to this household.';
    }
    return {
      heading: headingText,
      text: text,
      minWidth: minWidth,
      infoBox: {
        message: infoBoxText,
        fontSize: '11px',
      },
      buttons: [
        {
          text: 'Close',
          function: () =>
            setSharingModalContent({
              ...sharingModalContent,
              showing: !sharingModalContent.showing,
            }),
          theme: ButtonThemes.cancel,
        },
        {
          text: `Yes, ${buttonText}`,
          function: () => {
            dispatch(
              deleteHouseholdSharingInvite(
                currentHousehold?.uuid,
                uuid,
                isDelete
              )
            );
            setSharingModalContent({
              ...sharingModalContent,
              isLoading: true,
            });
          },
          theme: ButtonThemes.primary,
          showLoading: sharingModalContent.isLoading,
          loadingText: loadingText,
        },
      ],
    };
  };

  const closeFileUploadModal = () => {
    if (uploadFileLocation) {
      let modalLocation = uploadFileLocation.toLowerCase();
      if (modalLocation === 'household') {
        if (uploadedFiles) {
          dispatch(
            getHouseholdIntegrationFolder(
              currentHousehold?.uuid,
              fileUploadIntegration,
              uploadFolderId
            )
          );
        }
      }
      if (modalLocation === 'member' || modalLocation === 'entity') {
        dispatch(
          getMemberIntegrationInfo(householdId, memberId, fileUploadIntegration)
        );
      }
      if (
        modalLocation === 'cash flow' ||
        modalLocation === 'asset' ||
        modalLocation === 'liability' ||
        modalLocation === 'insurance'
      ) {
        dispatch(
          getInstrumentIntegrationInfo(
            householdId,
            instrumentId,
            fileUploadIntegration
          )
        );
      }
    }
    dispatch(toggleShowUploadFileModal(false, null, null));
  };

  const saveEditedTargetMap = () => {
    setIsSavingTargetMap(true);
    dispatch(
      updateTargetMapNotes(
        currentHousehold.id,
        currentTargetMap.id,
        { notes: updatedTargetMap?.notes },
        isNotesPage
      )
    );
  };

  const targetMapNotesModalContent = {
    heading: 'Target-Map Notes',
    minWidth: '400px',
    inputs: [
      {
        type: 'textarea',
        name: 'notes',
        required: true,
        placeholder: 'Add Notes',
        useEditor: true,
        showClear: true,
        id: 'targetmap',
        value: updatedTargetMap?.notes,
        onChange: (e) => setUpdatedTargetMap({ ...updatedTargetMap, notes: e }),
      },
    ],
    buttons: [
      {
        text: 'Cancel',
        function: () => dispatch(toggleShowTargetMapNotes(false)),
        theme: ButtonThemes.cancel,
      },
      {
        text: 'Save',
        loadingText: 'Saving',
        showLoading: isSavingTargetMap,
        function: () => saveEditedTargetMap(),
        theme: ButtonThemes.primary,
      },
    ],
  };

  // BULK SYNCING
  useEffect(() => {
    if (bulkSync && bulkSync.runBulkSync) {
      callSyncAll();
    }
  }, [bulkSync]);

  useEffect(() => {
    if (syncAllResults && justSynced) {
      let syncReport = (
        <SyncReport>
          <SyncReportTitle>Sync Results:</SyncReportTitle>
          {generateSyncReport(syncAllResults)}
          {generateSyncReportError(syncAllResults)}
        </SyncReport>
      );
      if (Object.values(syncAllResults).every((v) => v === true)) {
        SuccessNotification(syncReport);
      } else if (Object.values(syncAllResults).every((v) => v === false)) {
        ErrorNotification(syncReport);
      } else {
        InfoNotification(syncReport);
      }
    }
  }, [syncAllResults]);

  // AUTO SYNC
  useEffect(() => {
    if (toSync === true) {
      setTimeout(() => callAutoSync(toSyncRef.current), 10000);
    }
  }, [toSync]);

  useEffect(() => {
    if (
      typeof visitorIsLead !== 'undefined' &&
      autoSync &&
      autoSync.length !== 0 &&
      !cancelAutoSync
    ) {
      if (toSync !== true) {
        setToSync(true);
        InfoNotification(
          <AutoSyncNotification>
            <AutoSyncIcons>
              {integrationOptions.map((integration, index) => {
                if (autoSync.includes(integration['id'])) {
                  return generateIntegrationIcon(integration, index);
                }
                return <></>;
              })}
            </AutoSyncIcons>
            <AutoSyncText>Integration data is about to be synced.</AutoSyncText>
            {visitorIsLead && (
              <Button text={"Don't sync"} onClick={() => stopAutoSync(true)} />
            )}
          </AutoSyncNotification>,
          10
        );
      }
    }
  }, [autoSync, visitorIsLead]);

  useEffect(() => {
    if (currentUser && advisors) {
      const lead = advisors.filter((advisor) => advisor.type === 'lead')[0];
      setVisitorIsLead(lead?.id === currentUser);
    }
  }, [currentUser, advisors]);

  useEffect(() => {
    if (syncingList) {
      generateBulkSyncNotification(syncingList);
      SendTrackingEvent(
        invocationId,
        'action',
        'integration_sync_all',
        bulkSync?.location || 'household_amr',
        {
          household: householdId,
        }
      );
    }
  }, [syncingList]);

  useEffect(() => {
    if (autoSyncingList) {
      generateBulkSyncNotification(autoSyncingList);
      SendTrackingEvent(
        invocationId,
        'action',
        'integration_auto_sync_all',
        null,
        {
          household: householdId,
          integrations: autoSync,
        }
      );
    }
  }, [autoSyncingList]);

  const generateSyncReportError = (results) => {
    let bools = Object.values(results);
    if (!bools.every((v) => v === true)) {
      return (
        <SyncReportError>
          There were issues with syncing one or more of your integrations.
          Please check your active integration&apos; settings.
        </SyncReportError>
      );
    }
    return <></>;
  };

  const generateSyncReport = (results) => {
    let resultsArray = Object.entries(results);
    return resultsArray.map((entry, index) => {
      let synced = entry[1];
      let integration = integrationOptions.find(
        (integration) => integration.id === entry[0]
      );
      return (
        <SyncReportItem key={index}>
          {generateIntegrationIcon(integration, index)}
          <div style={{ marginRight: '10px' }}>{integration.name}</div>
          <SyncIconContainer>
            {synced ? (
              <img
                src={checkCircle}
                alt="check"
                data-image={`check-${index}`}
              />
            ) : (
              <img
                src={errorCircleRed}
                alt="error"
                data-image={`check-${index}`}
              />
            )}
          </SyncIconContainer>
        </SyncReportItem>
      );
    });
  };

  const generateIntegrationIcon = (integration, index) => {
    return (
      <AutoSyncIcon
        index={index}
        src={integration['icon']}
        alt={integration['id']}
        title={integration['id']}
        data-image={integration['id']}
      />
    );
  };

  const generateBulkSyncNotification = (syncList) => {
    return LoadingNotification(
      <AutoSyncNotification>
        <AutoSyncIcons>
          {integrationOptions.map((integration, index) => {
            if (syncList.includes(integration['id'])) {
              return generateIntegrationIcon(integration, index);
            }
            return <></>;
          })}
        </AutoSyncIcons>
        <AutoSyncText>Syncing Integrations</AutoSyncText>
      </AutoSyncNotification>
    );
  };

  const callSyncAll = () => {
    let syncList = [];
    for (const [name, integration] of Object.entries(integrations.partners)) {
      if (integration?.enabled) {
        if (integration.sync?.available) {
          syncList.push(name);
        }
      }
    }
    setSyncingList(syncList);
    return dispatch(syncHouseholdIntegrations(householdId, syncList));
  };

  const stopAutoSync = (delay = false) => {
    setCancelAutoSync(true);
    setToSync(false);
    const element = document.querySelector('.ct-toast');
    if (element) {
      element.style['transition'] = '0.3s all ease-out';
      element.style['opacity'] = 0;
    }
    if (delay === true) {
      dispatch(delayAutoSyncIntegrations(householdId));
    }
  };

  const callAutoSync = (toSyncRef) => {
    if (toSyncRef === false) {
      return false;
    }
    setAutoSyncingList(autoSync);
    return dispatch(syncHouseholdIntegrations(householdId, autoSync));
  };

  return (
    <>
      <>
        {deleteModal?.showModal && (
          <SimpleModal
            hide={() => dispatch(toggleShowDeleteModal(!deleteModal.showModal))}
            content={generateDeleteModalContent()}
          />
        )}
        {bulkModal?.showModal && (
          <SimpleModal
            hide={() => dispatch(toggleShowBulkModal(!bulkModal.showModal))}
            content={generateBulkModalContent()}
          />
        )}
        {sharingModalContent?.showing && (
          <SimpleModal
            error={sharingModalContent.error}
            hide={() =>
              setSharingModalContent({
                ...sharingModalContent,
                showing: !sharingModalContent.showing,
              })
            }
            content={generateSimpleSharingModalContent()}
          />
        )}
        {cloneStencilModal?.showModal && (
          <SimpleModal
            hide={() =>
              dispatch(
                toggleShowCloneStencilModal(!cloneStencilModal.showModal)
              )
            }
            content={generateCloneStencilModalContent()}
            error={cloneStencilError}
          />
        )}
        {connectPreviewModal?.showModal && (
          <SimpleModal
            hide={() => dispatch(toggleShowConnectPreviewModal(false))}
            content={generateConnectPreviewContent()}
            error={connectPreviewError}
          />
        )}
        {showingStencilImagePreview && <ImagePreviewModal />}
        {showingMemberModal && (
          <MemberModal
            defaultTab={memberDefaultTab}
            isNew={isNewMember}
            isPersonal={isPersonal}
            hide={() => dispatch(toggleShowMemberModal(!showingMemberModal))}
            householdId={householdId}
            invisible={showingUploadFileModal}
          />
        )}
        {showingEntityModal && (
          <EntityModal
            defaultTab={entityDefaultTab}
            isNew={isNewEntity}
            isExcluded={isExcluded}
            hide={() => dispatch(toggleShowEntityModal(!showingEntityModal))}
            householdId={householdId}
            invisible={showingUploadFileModal}
          />
        )}
        {showingProfessionalModal && (
          <ProfessionalModal
            defaultTab={professionalDefaultTab}
            isNew={isNewProfessional}
            hide={() =>
              dispatch(toggleShowProfessionalModal(!showingProfessionalModal))
            }
            householdId={householdId}
            invisible={showingUploadFileModal}
          />
        )}
        {showingAdvisorModal && (
          <AdvisorModal
            isNew={isNewAdvisor}
            hide={() => dispatch(toggleShowAdvisorModal(!showingAdvisorModal))}
            householdId={householdId}
          />
        )}
        {showingCashFlowModal && (
          <CashFlowModal
            defaultTab={cashFlowDefaultTab}
            isNew={isNewCashFlow}
            hide={() =>
              dispatch(toggleShowCashFlowModal(!showingCashFlowModal))
            }
            householdId={householdId}
            showProposalMode={showProposalMode}
            proposalId={currentProposal?.id}
            currency={householdCurrency}
            invisible={showingUploadFileModal}
          />
        )}
        {showingAssetModal && (
          <AssetModal
            defaultTab={assetDefaultTab}
            isNew={isNewAsset}
            hide={() => dispatch(toggleShowAssetModal(!showingAssetModal))}
            householdId={householdId}
            showProposalMode={showProposalMode}
            proposalId={currentProposal?.id}
            currency={householdCurrency}
            invisible={showingUploadFileModal}
          />
        )}
        {showingLiabilityModal && (
          <LiabilityModal
            defaultTab={liabilityDefaultTab}
            isNew={isNewLiability}
            hide={() =>
              dispatch(toggleShowLiabilityModal(!showingLiabilityModal))
            }
            householdId={householdId}
            showProposalMode={showProposalMode}
            proposalId={currentProposal?.id}
            currency={householdCurrency}
            invisible={showingUploadFileModal}
          />
        )}
        {showingInsuranceModal && (
          <InsuranceModal
            defaultTab={insuranceDefaultTab}
            isNew={isNewInsurance}
            hide={() =>
              dispatch(toggleShowInsuranceModal(!showingInsuranceModal))
            }
            householdId={householdId}
            showProposalMode={showProposalMode}
            proposalId={currentProposal?.id}
            currency={householdCurrency}
            invisible={showingUploadFileModal}
          />
        )}
        {showingLegalModal && (
          <LegalModal
            isNew={isNewLegal}
            hide={() => dispatch(toggleShowLegalModal(!showingLegalModal))}
            householdId={householdId}
          />
        )}

        {showingStencilModal && (
          <StencilModal
            hide={() => dispatch(toggleShowStencilModal(!showingStencilModal))}
            householdId={householdId}
          />
        )}
        {showingReportDetailsModal && (
          <ReportDetailsModal
            report={selectedReport}
            hide={() =>
              dispatch(toggleShowReportDetailsModal(!showingReportDetailsModal))
            }
          />
        )}
        {showingReportShareModal && (
          <ReportShareModal
            report={selectedReport}
            hide={() =>
              dispatch(toggleShowReportShareModal(!showingReportShareModal))
            }
            householdId={householdId}
          />
        )}
        {showingUploadFileModal && (
          <FileUploadModal
            hide={() => closeFileUploadModal()}
            fileUploadIntegration={fileUploadIntegration}
            householdId={householdId}
            uploadFolderId={uploadFolderId}
          />
        )}
        {showingMilestoneModal && (
          <SimpleModal
            hide={() => dismissMilestoneModal()}
            content={
              showMilestonesElite === true
                ? generateMilestoneEliteContent()
                : generateMilestoneContent()
            }
            disableClickOutside={true}
            dismissFunction={() =>
              dispatch(dismissHouseholdMilestone(householdId))
            }
            error={milestonesModalError}
          />
        )}
        {showingNewTargetMapModal && (
          <NewTargetMapModal
            selectedType={newTargetMapType}
            hide={() =>
              dispatch(toggleShowNewTargetMapModal(!showingNewTargetMapModal))
            }
            householdId={householdId}
            hasTemplates={hasTargetMapTemplates}
            invisible={showingTargetMapTemplateModal}
          />
        )}
        {showingTargetMapNotesModal && (
          <SimpleModal
            hide={() =>
              dispatch(toggleShowTargetMapNotes(!showingTargetMapNotesModal))
            }
            content={targetMapNotesModalContent}
            disableClickOutside={true}
            error={notesModalError}
          />
        )}
        {showingTargetMapTemplateModal && (
          <TargetMapTemplateModal
            hide={() =>
              dispatch(
                toggleShowTargetMapTemplateModal(!showingTargetMapTemplateModal)
              )
            }
            isNew={isNewTargetMapTemplate}
            templateRules={targetMapTemplateRules}
            existingTemplate={existingTemplate}
            error={targetMapTemplateError}
          />
        )}
      </>
      <Page>
        <CenteredContainerRow>
          <SideNavContainer />
          <LeftNavOutlet style={{ marginLeft: sideNavWidth }}>
            <Outlet context={{ hasHouseholdPermission }} />
          </LeftNavOutlet>
        </CenteredContainerRow>
      </Page>
      <form
        method="post"
        action={`/generate/comprehensive/pdf/${householdId}/`}
        id="generate_pdf_form"
        target="_blank"
        style={{ display: 'none' }}
      />
    </>
  );
};

const AutoSyncText = styled.div`
  margin: 0px 10px;
`;

const AutoSyncNotification = styled.div`
  display: flex;
  width: max-content;
  align-items: center;
`;

const AutoSyncIcons = styled.div`
  display: flex;
`;

const AutoSyncIcon = styled.img`
  max-width: 20px;
  max-height: 20px;
  margin-right: 10px;
`;

const SyncReportTitle = styled.div`
  font-weight: 600;
  padding-bottom: 10px;
`;

const SyncReport = styled.div`
  max-width: 200px;
`;

const SyncIconContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  img {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }
`;

const SyncReportItem = styled.div`
  padding: 2px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SyncReportError = styled.div`
  padding-top: 5px;
  font-size: 10px;
  color: ${messageColors.error};
`;

export default Household;
