/* eslint-disable array-callback-return */
export const GenerateRegionTotal = (instruments, roundingValue) => {
  return instruments.reduce((acc, curr) => {
    if (!curr.is_hidden && curr.status !== 'stencil') {
      let currentValue = curr.amount;
      let linkedTotal = 0;
      //LINKED LIABILITY CHANGES
      const hasCalc =
        curr.hasOwnProperty('linkedCalculated') && curr.linkedCalculated !== 0;
      //LINKED LIABILITY CHANGES
      if (
        curr.hasOwnProperty('linked_items') &&
        curr.linked_items.length !== 0 &&
        !hasCalc &&
        !curr.is_rollup_parent
      ) {
        const total = curr.linked_items.reduce((total, item) => {
          let amount = item.amount;
          if (item.calculated || item.calculated === 0) {
            amount = item.calculated;
          }
          if (item.owned_by_entity) {
            amount = 0;
          }
          if (item.category === 'propcas') {
            amount = 0;
          }
          if (item?.is_proposal && item?.proposal_action === 'delete') {
            amount = 0;
          }
          if (
            item.instrument_type === 'liability' &&
            !item.is_expected &&
            item?.proposal_action !== 'delete'
          ) {
            return total + amount * -1;
          }
          return total + amount;
        }, 0);
        linkedTotal = total;
      }

      if (curr.hasOwnProperty('calculated')) {
        currentValue = curr.calculated;
      }
      if (
        curr.instrument_type === 'liability' &&
        !curr.is_expected &&
        !curr.owned_by_another &&
        !curr.owned_by_entity &&
        curr.proposal_action !== 'delete'
      ) {
        return acc + currentValue * -1;
      }
      //LINKED LIABILITY CHANGES
      if (hasCalc) {
        if (curr.is_expected) {
          currentValue = 0;
        }
        linkedTotal = curr.linkedCalculated;
        //LINKED LIABILITY CHANGES
      }

      if (curr.is_expected || curr.owned_by_another || curr.owned_by_entity) {
        currentValue = 0;
      }
      if (curr?.is_proposal && curr?.proposal_action === 'delete') {
        currentValue = 0;
      }
      // if (curr.use_calculated) {
      //   currentValue = curr.amount;
      // }
      return RoundValues(roundingValue, acc + currentValue + linkedTotal);
    } else {
      return RoundValues(roundingValue, acc);
    }
  }, 0);
};

export const CalculateValues = (instruments, memberId) => {
  return instruments.map((instrument) => {
    let updated = { ...instrument };
    const matchingMember = instrument.members.find(
      (member) => member.id === memberId && member.relation === 'owner'
    );
    const matchingCustodian = instrument.members.find(
      (member) => member.id === memberId && member.relation === 'custodian'
    );
    let linkedTotal = 0;

    if (matchingCustodian) {
      updated.calculated = 0;
      if (
        instrument.hasOwnProperty('linked_items') &&
        instrument.linked_items.length !== 0
      ) {
        linkedTotal = generateLinkedTotal(instrument, memberId);
      }
    }
    if (matchingMember) {
      if (
        instrument.hasOwnProperty('linked_items') &&
        instrument.linked_items.length !== 0
      ) {
        linkedTotal = generateLinkedTotal(instrument, memberId);
      }
      if (instrument.is_joint) {
        const totalRatio = instrument.members.reduce((acc, curr) => {
          if (curr?.relation === 'owner') {
            acc = acc + curr?.ratio;
          }
          return acc;
        }, 0);
        updated.split_percent = totalRatio;
      } else {
        updated.split_percent = matchingMember.ratio;
        if (matchingMember.ratio === 0) {
          updated.split_percent = 0;
        }
      }
      updated.calculated = generateRatio(
        instrument.amount,
        updated.split_percent,
        false
      );
      if (
        instrument.owned_by_another &&
        instrument.instrument_type === 'asset'
      ) {
        updated.calculated = 0;
      }
      if (
        instrument.owned_by_entity &&
        instrument.instrument_type === 'asset'
      ) {
        updated.calculated = 0;
      }

      if (instrument.cash_value && instrument.instrument_type === 'insurance') {
        updated.split_percent = matchingMember.ratio;
        if (matchingMember.ratio === 0) {
          updated.split_percent = 0;
        }
        updated.calculated = generateRatio(
          instrument.cash_value,
          matchingMember.ratio,
          false
        );
        updated.show_cash_value = true;
      }
      if (instrument.category === 'propcas') {
        updated.split_percent = null;
        updated.calculated = instrument.amount;
      }
      updated.ignore_joint = false;
      updated.ignore_cash_value = false;
    }
    updated.linkedCalculated = linkedTotal;
    updated.show_joint = updated.is_joint;
    return updated;
  });
};

export const CalculateNorthValues = (
  instruments,
  notOwned = false,
  notEntity = false
) => {
  return instruments.map((instrument) => {
    let updated = { ...instrument };
    updated.ignore_joint = true;
    updated.ignore_cash_value = true;
    updated.calculated = instrument.amount;
    updated.split_percent = null;
    if (notOwned) {
      updated.owned_by_another = false;
    }
    if (notEntity) {
      updated.owned_by_entity = false;
    }
    return updated;
  });
};

export const CalculateMultipleOwnerValues = (
  selectedMembers,
  instruments,
  relation = 'owner',
  isCashValue = false
) => {
  const selectedMembersIds = selectedMembers.map((mem) => mem.id);
  return instruments.map((instrument) => {
    let updated = { ...instrument };
    const ownersList = instrument.members.filter(
      (mem) => mem.relation === relation
    );
    const visibleOwners = ownersList.filter((owner) => {
      if (selectedMembersIds.includes(owner.id)) {
        return owner;
      }
    });
    let totalRatio = visibleOwners.reduce((acc, curr) => {
      return acc + curr.ratio;
    }, 0);
    updated.calculated = generateRatio(instrument.amount, totalRatio);
    if (instrument.owned_by_entity) {
      updated.calculated = instrument.amount;
      totalRatio = null;
    }
    if (isCashValue) {
      updated.ignore_joint = true;
      updated.ignore_cash_value = false;
      updated.show_cash_value = true;
      updated.calculated = generateRatio(instrument.cash_value, totalRatio);
    }
    if (instrument.category === 'propcas') {
      totalRatio = null;
      updated.calculated = instrument.amount;
    }
    updated.split_percent = totalRatio;
    return updated;
  });
};

export const CalculateMultipleCustodianValues = (instruments) => {
  return instruments.map((instrument) => {
    let updated = { ...instrument };
    const ownersList = instrument.members.filter(
      (mem) => mem.relation === 'owner'
    );
    let totalRatio = ownersList.reduce((acc, curr) => {
      return acc + curr.ratio;
    }, 0);

    updated.use_calculated = true;
    updated.calculated = generateRatio(instrument.amount, totalRatio);
    if (instrument.category === 'propcas') {
      totalRatio = null;
      updated.calculated = instrument.amount;
    }
    updated.split_percent = totalRatio;
    return updated;
  });
};

export const RoundValues = (roundingValue, numberValue) => {
  const rounding = roundingValue;
  if (rounding) {
    if (numberValue > 500) {
      if (rounding === 'thousand') {
        return Math.round(numberValue / 1000) * 1000;
      }
    }
    if (numberValue > 50) {
      if (rounding === 'hundred') {
        return Math.round(numberValue / 100) * 100;
      }
    }
    if (numberValue > 5) {
      if (rounding === 'ten') {
        return Math.round(numberValue / 10) * 10;
      }
    }
  }
  return numberValue;
};

//Function used by other functions
const generateLinkedTotal = (instrument, memberId) => {
  return instrument.linked_items.reduce((acc, curr) => {
    const matchedMember = curr.members.find(
      (member) => member.id === memberId && member.relation === 'owner'
    );
    let value = 0;
    if (matchedMember && curr.category !== 'propcas') {
      value = generateRatio(curr.amount, matchedMember.ratio, curr.is_joint);
      if (
        curr.instrument_type === 'liability' &&
        curr.proposal_action !== 'delete'
      ) {
        value *= -1;
      }
    }
    return acc + value;
  }, 0);
};

//Function used by other functions
const generateRatio = (amount, memberRatio, isJoint) => {
  let ratio = 1;
  isJoint ? (ratio = 1) : (ratio = memberRatio / 100);
  return Math.round(amount * ratio);
};
