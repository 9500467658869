/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  EmptyImage,
  SortButtons,
  Pagination,
  Filter,
  SurveyListItem,
} from '../../components';
import {
  getSurveyInstance,
  toggleShowSurveyResponsesModal,
  getSurveyHistory,
} from '../../store/actions';
import { noSurveys, clipboardCheck, pdf, ballotCheck } from '../../assets';
import { colors, maxDevice, fonts } from '../../styles/variables';
import { FilterContainer } from '../../components/Table/Filter';
import { DateFormat } from '../../utils';

// local filters state
const filtersReducer = (filters, action) => {
  if (action.type === 'update_surveys') {
    for (const [value, label] of Object.entries(action.payload)) {
      filters[0].options.push({ label: label, value: value });
    }
    return filters;
  }

  const updatedFilters = filters.map((f) => {
    if (f.key === action.type) {
      f.selectedOption = action.payload;
      if (action.type === 'survey') {
        f.param = action.payload.value;
      }
      if (action.type.includes('created_utc')) {
        f.param = action.payload
          ? DateFormat(action.payload, 'YYYY-MM-DD')
          : '';
      }
    }
    return f;
  });
  return updatedFilters;
};

const ordersReducer = (orders, action) => {
  const [field, direction] = action.type.split('-');
  const updatedOrders = orders.map((o) => {
    if (o.value === field) {
      o.direction = direction;
      o.param = field;
      if (direction === 'descend') {
        o.param = '-' + field;
      }
    } else {
      o.direction = '';
      o.param = '';
    }
    return o;
  });
  return updatedOrders;
};

const pagesReducer = (pages, action) => {
  if (action.type === 'update_count') {
    pages.count = action.payload;
  }
  if (action.type === 'update_limit') {
    pages.limit = action.payload;
  }
  if (action.type === 'update_offset') {
    pages.offset = action.payload;
  }
  return pages;
};

const HouseholdSurveysHistory = ({ householdId }) => {
  const { surveys, surveyHistory, surveyInstance, limit } = useSelector(
    (state) => ({
      surveys: state.households.surveys,
      surveyHistory: state.households.surveyHistory,
      surveyInstance: state.households.surveyInstance,
      limit: state.configs?.pagination?.default_limit || 25,
    })
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [queryParams, setQueryParams] = useState();
  const [surveyList, setSurveyList] = useState([]);
  const [actionState, setActionState] = useState(false);

  // sortable components
  const createdDate = (order) => {
    return (
      <CreatedDate key={order.value}>
        <SortButtons
          name={order.label}
          clickAscend={() => dispatchOrders({ type: 'created_utc-ascend' })}
          clickDescend={() => dispatchOrders({ type: 'created_utc-descend' })}
          direction={order.direction}
        />
      </CreatedDate>
    );
  };

  const modifiedDate = (order) => {
    return (
      <ModifiedDate key={order.value}>
        <SortButtons
          name={order.label}
          clickAscend={() => dispatchOrders({ type: 'modified_utc-ascend' })}
          clickDescend={() => dispatchOrders({ type: 'modified_utc-descend' })}
          direction={order.direction}
        />
      </ModifiedDate>
    );
  };

  const percentComplete = (order) => {
    return (
      <PercentComplete key={order.value}>
        <SortButtons
          name={order.label}
          clickAscend={() =>
            dispatchOrders({ type: 'percent_complete-ascend' })
          }
          clickDescend={() =>
            dispatchOrders({ type: 'percent_complete-descend' })
          }
          direction={order.direction}
        />
      </PercentComplete>
    );
  };

  const initSurveyHistoryHeadings = [
    {
      label: 'Name',
      value: 'name',
      width: '20%',
    },
    // {
    //   label: 'Version',
    //   value: 'version',
    //   width: '8%',
    // },
    {
      label: 'Sent',
      value: 'created_utc',
      width: '15%',
      placeholderWidth: '100px',
      component: createdDate,
      param: '-created_utc',
      direction: 'descend',
    },
    {
      label: 'Last Response',
      value: 'modified_utc',
      width: '15%',
      component: modifiedDate,
      param: '',
      direction: '',
    },
    {
      label: '% Complete',
      value: 'percent_complete',
      width: '15%',
      component: percentComplete,
      param: '',
      direction: '',
    },
    {
      label: 'Description',
      value: 'description',
      width: '20%',
    },
    {
      label: 'Actions',
      value: 'actions',
      align: 'right',
      width: '15%',
    },
  ];

  const actionOnClick = (type, survey) => {
    setActionState(type);
    if (type === 'instance_complete') {
      dispatch(getSurveyInstance(survey.id));
    } else if (type === 'instance_review') {
      dispatch(toggleShowSurveyResponsesModal(true, survey));
    } else if (type === 'instance_pdf') {
      const link = `/api/v3/household/${householdId}/survey/${survey.survey_id}/instance/${survey.id}/pdf`;
      window.open(link, '_blank');
    }
  };

  const actionsList = [
    {
      label: 'Complete Now',
      disabledLabel: 'Questionnaire is already complete',
      value: 'instance_complete',
      icon: ballotCheck,
    },
    {
      label: 'View Responses',
      disabledLabel: 'Cannot view responses',
      value: 'instance_review',
      icon: clipboardCheck,
    },
    {
      label: 'Download Responses as PDF',
      disabledLabel: 'PDF is not available',
      value: 'instance_pdf',
      icon: pdf,
    },
  ];

  useEffect(() => {
    if (surveyHistory && surveys) {
      setLoading(false);
      const transformedSurveys = surveyHistory?.results.map((survey) => {
        const parent = surveys?.results.find(
          (s) => s?.id === survey?.survey_id
        );
        if (parent) {
          survey.pdf_available = parent?.pdf_available;
        } else {
          survey.pdf_available = false;
        }
        return survey;
      });
      setSurveyList(transformedSurveys);
      if (surveyHistory.count !== pagesState.count) {
        dispatchPages({
          type: 'update_count',
          payload: surveyHistory.count,
        });
      }
    }
  }, [surveyHistory, surveys]);

  useEffect(() => {
    if (surveyInstance) {
      if (actionState === 'instance_complete') {
        window.open(`${window.location.origin}/survey/${surveyInstance.id}`);
      }
    }
  }, [surveyInstance]);

  const selectFilterChange = (e, key) => {
    // set local state of filters
    (async () => {
      dispatchPages({ type: 'update_offset', payload: 0 });
      dispatchFilters({ type: key, payload: e });
      // set local state of parameters, which will trigger the reload to get new hhs
    })().then(() => updateParams());
  };

  const initFiltersState = [
    {
      filterType: 'select',
      key: 'survey',
      label: 'Questionnaires',
      placeholder: 'All Questionnaires',
      options: [
        {
          label: 'All Questionnaires',
          value: '',
        },
      ],
      style: { width: '215px' },
      isVisible: true,
      selectedOption: { label: 'All Questionnaires', value: '' },
      onChangeCallback: selectFilterChange,
    },
    {
      filterType: 'datepicker',
      key: 'created_utc_after',
      placeholder: 'Start Date',
      label: 'Start Date',
      isVisible: true,
      onChangeCallback: selectFilterChange,
    },
    {
      filterType: 'datepicker',
      key: 'created_utc_before',
      placeholder: 'End Date',
      label: 'End Date',
      isVisible: true,
      onChangeCallback: selectFilterChange,
    },
  ];
  const [filtersState, dispatchFilters] = useReducer(
    filtersReducer,
    initFiltersState
  );

  const [surveyHistoryHeadings, dispatchOrders] = useReducer(
    ordersReducer,
    initSurveyHistoryHeadings
  );

  const initPagesState = {
    limit: limit,
    offset: 0,
    count: 0,
  };
  const [pagesState, dispatchPages] = useReducer(pagesReducer, initPagesState);

  useEffect(() => {
    if (surveys) {
      let surveyObj = {};
      surveys.results.map((s) => (surveyObj[s.id] = s.name));
      dispatchFilters({
        type: 'update_surveys',
        payload: surveyObj,
      });
    }
  }, [surveys]);

  useEffect(() => {
    updateParams();
  }, [surveyHistoryHeadings, pagesState]);

  // every time the filters or ordering changes update the reports.
  useEffect(() => {
    if (queryParams) {
      dispatch(getSurveyHistory(householdId, queryParams));
    }
  }, [queryParams]);

  /**
   * Handling Events
   */
  // this triggers the query params state change which triggers api call to get reports based on params
  const updateParams = () => {
    const params = filtersState.reduce(
      (o, key) => ({ ...o, [key.key]: key.param }),
      {}
    );
    params.offset = pagesState.offset;
    params.ordering = surveyHistoryHeadings.reduce((o, key) => {
      return key.param ? o + key.param : o;
    }, '');

    setQueryParams(params);
  };

  const nextPage = () => {
    dispatchPages({
      type: 'update_offset',
      payload: pagesState.offset + pagesState.limit,
    });
    updateParams();
  };

  const prevPage = () => {
    dispatchPages({
      type: 'update_offset',
      payload: pagesState.offset - pagesState.limit,
    });
    updateParams();
  };

  const goToPage = (page) => {
    dispatchPages({
      type: 'update_offset',
      payload: pagesState.limit * (page - 1),
    });
    updateParams();
  };

  return (
    <PageContent>
      <ActionRow>
        <FiltersContainer>
          {filtersState.map((filterState, ind) => {
            return (
              filterState?.isVisible && (
                <Filter
                  data={filterState}
                  type={filterState.filterType}
                  key={ind}
                />
              )
            );
          })}
        </FiltersContainer>
      </ActionRow>
      <SurveyListContainer>
        <SurveyListHeadings>
          {surveyHistoryHeadings.map((heading, index) => {
            return (
              <HeadingContainer
                key={index}
                width={heading.width}
                align={heading.align}
              >
                {heading.component ? heading.component(heading) : heading.label}
              </HeadingContainer>
            );
          })}
        </SurveyListHeadings>
        {loading ? (
          [0, 1, 2, 3].map((item, index) => {
            return (
              <SurveyListItem
                key={item}
                surveyNumber={2}
                isHistory={true}
                isLoading={true}
                survey={null}
                actions={actionsList}
              />
            );
          })
        ) : (
          <SurveyList>
            {surveyList && surveyList.length !== 0 ? (
              surveyList.map((survey, index) => {
                return (
                  <SurveyListItem
                    key={index}
                    isHistory={true}
                    survey={survey}
                    actions={actionsList}
                    actionOnClick={actionOnClick}
                  />
                );
              })
            ) : (
              <EmptyImage
                imageSrc={noSurveys}
                imageName="No Questionnaires"
                text="No Available Questionnaires"
              />
            )}
          </SurveyList>
        )}
        {pagesState?.count > pagesState?.limit && (
          <Pagination
            clickPrev={prevPage}
            clickNext={nextPage}
            goToPage={goToPage}
            limit={pagesState.limit}
            offset={pagesState.offset}
            count={pagesState.count}
          />
        )}
      </SurveyListContainer>
    </PageContent>
  );
};

const HeadingContainer = styled.div`
  flex: 1 1 auto;
  width: ${(props) => (props.width ? props.width : '100%')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  padding: 0 5px;
`;

const SurveyListHeadings = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  border-bottom: 1px solid ${colors.darkGrey};
`;

const SurveyListContainer = styled.div`
  padding: 15px 0;
  margin-bottom: 60px;
`;

const SurveyList = styled.div`
  min-height: 180px;
`;

// const SurveyType = styled.div`
//   width: 40%;
//   padding: 0 10px;
//   display: flex;
//   align-content: center;
//   justify-content: flex-start;
//   align-items: center;
//   img {
//     width: 20px;
//     height: 20px;
//     margin-right: 15px;
//   }
//   span {
//     margin-right: 10px;
//   }
// `;

const CreatedDate = styled.div`
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const ModifiedDate = styled.div`
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const PercentComplete = styled.div`
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: -20px 20px 50px 20px;
  }
  margin: -25px 40px 30px 40px;
`;

const FiltersContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${FilterContainer} {
    @media (max-width: 800px) {
      margin-bottom: 8px;
    }
  }
`;

const ActionRow = styled.div`
  @media ${maxDevice.sideNav} {
    button {
      font-size: 10px;
    }
  }
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 12px 0 14px 0;
  border-bottom: 1px solid ${colors.lightGrey};
`;

HouseholdSurveysHistory.propTypes = {
  householdId: PropTypes.string,
};

export default HouseholdSurveysHistory;
