import styled from 'styled-components';
import { inputColors, colors, fonts } from '../styles/variables';
import { user, lock, unlocked, institution, briefcase, users } from '../assets';
import PropTypes from 'prop-types';
import { LoadingPlaceholder } from '../styles/library/fontStyles';

const ShazamBar = ({ items, onClick, loaded }) => {
  const selectedStyle = {
    background: colors.lightGrey,
    border: `1px solid ${inputColors.border}`,
  };

  const determineIcon = (item) => {
    if (item.member_type === 'all') {
      return users;
    }

    if (window.location.host === 'localhost:3000') {
      if (item.member_type === 'member') {
        return user;
      }
      if (item.member_type === 'entity') {
        if (item.category === 'trust') {
          return lock;
        }
        if (item.category === 'revtrust') {
          return unlocked;
        }
        if (
          item.category === 'charity' ||
          item.category === 'pubcharity' ||
          item.category === 'foundation' ||
          item.category === 'institution'
        ) {
          return institution;
        }
        return briefcase;
      }
      return user;
    } else {
      if (item.hasOwnProperty('avatar')) {
        return item.avatar.thumbnail;
      }
    }
  };

  //GENERATES DATA IMAGE ATTRIBUTE FOR TESTING (Returns which avatar set is being used)
  const generateDataTag = (item) => {
    if (item?.avatar?.url) {
      const urlArr = item?.avatar?.thumbnail.split('/');
      const setValue = urlArr.find((val) => val.includes('set-'));
      if (setValue) {
        return setValue;
      }
    }
    return 'old';
  };

  return (
    <ShazamContainer>
      {loaded
        ? items.map((item, index) => {
            return (
              <ItemContainer
                style={item.is_selected ? { ...selectedStyle } : null}
                key={index}
                data-value={item.id}
                onClick={() => onClick(item)}
              >
                <ItemIcon
                  src={determineIcon(item)}
                  alt="user"
                  data-image={`shazam-${generateDataTag(item)}`}
                />
                <ItemName>{item.name}</ItemName>
                {item.member_type === 'member' && (
                  <ItemAge>
                    ({item.age}
                    {item.date_of_birth_estimated && 'e'})
                  </ItemAge>
                )}
              </ItemContainer>
            );
          })
        : [0, 1, 2].map((index) => {
            return (
              <ItemContainer key={index}>
                <LoadingPlaceholder
                  round={true}
                  width="18px"
                  margin="0 4px 0 0"
                />
                <LoadingPlaceholder margin="0" />
                <LoadingPlaceholder width="18px" margin="0 0 0 4px" />
              </ItemContainer>
            );
          })}
    </ShazamContainer>
  );
};

export const ShazamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const ItemContainer = styled.div`
  border: 1px solid transparent;
  margin: 5px;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const ItemIcon = styled.img`
  max-width: 12px;
  width: 100%;
  max-height: 12px;
  height: 100%;
  margin-right: 4px;
  flex: 0 0 auto;
`;

const ItemName = styled.p`
  flex: 0 0 auto;
`;

const ItemAge = styled.span`
  margin-left: 4px;
  font-size: 11px;
  opacity: 0.6;
  font-weight: ${fonts.light};
  flex: 0 0 auto;
`;

ShazamBar.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
  loaded: PropTypes.bool,
};

export default ShazamBar;
