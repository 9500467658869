import { apiAction, getConsumerHouseholds } from './';

export const UserTypes = {
  //UTIL ACTIONS
  UPDATE_USER_STORE_VALUE: 'UPDATE_USER_STORE_VALUE',

  //AUTH ACTIONS
  LOG_IN: 'LOG_IN',
  TOGGLE_REMEMBER_ME: 'TOGGLE_REMEMBER_ME',
  REMOVE_LOGIN_ERROR: 'REMOVE_LOGIN_ERROR',
  SHOW_LOGIN_SCREEN: 'SHOW_LOGIN_SCREEN',
  SHOW_LOGIN_HELP_SCREEN: 'SHOW_LOGIN_HELP_SCREEN',
  SHOW_PASSWORD_RECOVERY_SCREEN: 'SHOW_PASSWORD_RECOVERY_SCREEN',
  SEND_RESET_PASSWORD: 'SEND_RESET_PASSWORD',
  SHOW_SENT_RESET_PASSWORD_SCREEN: 'SHOW_SENT_RESET_PASSWORD_SCREEN',
  USER_MULTISESSION_LOGOUT: 'USER_MULTISESSION_LOGOUT',
  LOG_OUT: 'LOG_OUT',

  //TWO FACTOR AUTH
  SHOW_AUTH_CODE_SCREEN: 'SHOW_AUTH_CODE_SCREEN',
  SUBMIT_AUTH_CODE: 'SUBMIT_AUTH_CODE',
  CLEAR_AUTH_CODE_ERROR: 'CLEAR_AUTH_CODE_ERROR',
  SHOW_RESEND_AUTH_CODE_SCREEN: 'SHOW_RESEND_AUTH_CODE_SCREEN',
  RESEND_AUTH_CODE: 'RESEND_AUTH_CODE',
  SHOW_AUTH_CODE_SENT_SCREEN: 'SHOW_AUTH_CODE_SENT_SCREEN',

  //DELEGATE/IMPERSONATION ACTIONS
  TOGGLE_SHOW_DELEGATE_MODAL: 'TOGGLE_SHOW_DELEGATE_MODAL',
  GET_DELEGATE_ADVISOR_LIST: 'GET_DELEGATE_ADVISOR_LIST',
  SET_DELEGATE_ADVISOR: 'SET_DELEGATE_ADVISOR',
  LOG_OUT_CUSTOMER: 'LOG_OUT_CUSTOMER',

  //PROFILE & USER ACTIONS
  GET_USER_INFO: 'GET_USER_INFO',
  GET_CONSUMER_INFO: 'GET_CONSUMER_INFO',
  GET_AUTH_STATUS: 'GET_AUTH_STATUS',
  SHOW_CLIENT_PREVIEW_MODE: 'SHOW_CLIENT_PREVIEW_MODE',
  EXIT_CLIENT_PREVIEW_MODE: 'EXIT_CLIENT_PREVIEW_MODE',
  ENTER_CLIENT_PREVIEW_MODE: 'ENTER_CLIENT_PREVIEW_MODE',
  GET_INVITE_DETAILS: 'GET_INVITE_DETAILS',
  GET_INVITE_DETAILS_ERROR: 'GET_INVITE_DETAILS_ERROR',
  SHOW_INVITE_MODAL: 'SHOW_INVITE_MODAL',
  GET_REGISTRATION_DETAILS: 'GET_REGISTRATION_DETAILS',
  REGISTER_CONSUMER_ACCOUNT: 'REGISTER_CONSUMER_ACCOUNT',
  REGISTER_CONSUMER_ACCOUNT_ERROR: 'REGISTER_CONSUMER_ACCOUNT_ERROR',
  REGISTERED_CONSUMER_ACCOUNT: 'REGISTERED_CONSUMER_ACCOUNT',
  RESEND_VERIFY_EMAIL: 'RESEND_VERIFY_EMAIL',
  RESENT_VERIFY_EMAIL: 'RESENT_VERIFY_EMAIL',
  VERIFY_EMAIL_CODE: 'VERIFY_EMAIL_CODE',
  CREATED_CONSUMER_ACCOUNT: 'CREATED_CONSUMER_ACCOUNT',
  ACCEPT_SHARING_INVITE: 'ACCEPT_SHARING_INVITE',
  DECLINE_SHARING_INVITE: 'DECLINE_SHARING_INVITE',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  SKIP_PASSWORD_RESET: 'SKIP_PASSWORD_RESET',
  RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
  GET_PROFILE_PICTURE: 'GET_PROFILE_PICTURE',
  SET_UPLOADED_PROFILE_PICTURE: 'SET_UPLOADED_PROFILE_PICTURE',
  UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
  REMOVE_PROFILE_PICTURE: 'REMOVE_PROFILE_PICTURE',
  UPDATE_APPLICATION_SETTINGS: 'UPDATE_APPLICATION_SETTINGS',
  CHECK_HAS_SUPPORT_ACCESS: 'CHECK_HAS_SUPPORT_ACCESS',
  TOGGLE_GRANT_SUPPORT_ACCESS: ' TOGGLE_GRANT_SUPPORT_ACCESS',
  UPDATE_NOTIFICATION_SETTINGS: 'UPDATE_NOTIFICATION_SETTINGS',

  //SECURITY SETTINGS
  UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
  UPDATED_USER_PASSWORD: 'UPDATED_USER_PASSWORD',
  RESET_PASSWORD_FIELDS: 'RESET_PASSWORD_FIELDS',
  GET_AUTH_FACTORS: 'GET_AUTH_FACTORS',
  ADD_AUTH_FACTOR: 'ADD_AUTH_FACTOR',
  CONFIRM_AUTH_FACTOR: 'CONFIRM_AUTH_FACTOR',
  DISABLE_AUTH_FACTOR: 'DISABLE_AUTH_FACTOR',
  RESET_AUTH_FACTOR: 'RESET_AUTH_FACTOR',
  GET_AUTH_DEVICES: 'GET_AUTH_DEVICES',
  REMOVE_AUTH_DEVICE: 'REMOVE_AUTH_DEVICE',

  //USER CUSTOM SETTINGS
  GET_CUSTOM_USER_PREFERENCES: 'GET_CUSTOM_USER_PREFERENCES',
  SET_CUSTOM_LOGO: 'SET_CUSTOM_LOGO',
  UPDATE_CUSTOM_USER_PREFERENCES: 'UPDATE_CUSTOM_USER_PREFERENCES',
  REMOVE_CUSTOM_LOGO_USER_PREFERENCES: 'REMOVE_CUSTOM_LOGO_USER_PREFERENCES',

  //TEAM MANAGER ACTIONS
  GET_TEAM_SETTINGS: 'GET_TEAM_SETTINGS',
  GET_DISCOVERY_TEAM_SETTINGS: 'GET_TEAM_SETTINGS',
  UPDATE_TEAM_SETTINGS: 'UPDATE_TEAM_SETTINGS',
  DELETE_TEAM_LOGO: 'DELETE_TEAM_LOGO',
  UPDATE_DISCOVERY_TEAM_SETTINGS: 'UPDATE_DISCOVERY_TEAM_SETTINGS',
  UPDATE_DISCOVERY_CONSENT_SETTINGS: 'UPDATE_DISCOVERY_CONSENT_SETTINGS',
  SET_ORG_LOGO: 'SET_ORG_LOGO',
  GET_MANAGER_INTEGRATIONS: 'GET_MANAGER_INTEGRATIONS',
  UPDATE_MANAGER_INTEGRATION: 'UPDATE_MANAGER_INTEGRATION',
  GET_CUSTOMER_MEMBERS_LIST: 'GET_CUSTOMER_MEMBERS_LIST',
  DELETE_CUSTOMER_MEMBER: 'DELETE_CUSTOMER_MEMBER',
  DEACTIVATE_CUSTOMER_MEMBER_MFA: 'DEACTIVATE_CUSTOMER_MEMBER_MFA',
  RESET_CUSTOMER_MEMBER_PASSWORD: 'RESET_CUSTOMER_MEMBER_PASSWORD',
  GET_CUSTOMER_DELEGATE_LIST: 'GET_CUSTOMER_DELEGATE_LIST',
  GET_CUSTOMER_PENDING_LIST: 'GET_CUSTOMER_PENDING_LIST',
  SEND_INVITE: 'SEND_INVITE',
  DELETE_INVITE: 'DELETE_INVITE',
  RESEND_INVITE: 'RESEND_INVITE',
  UPDATE_MEMBER_ROLE: 'UPDATE_MEMBER_ROLE',
  UPDATE_CUSTOMER_DELEGATE: 'UPDATE_CUSTOMER_DELEGATE',
  GET_LICENSES_COUNT: 'GET_LICENSES_COUNT',
  UPDATE_SEAT_CONFIG: 'UPDATE_SEAT_CONFIG',
  SHOW_INVITE_MEMBER_MODAL: 'SHOW_INVITE_MEMBER_MODAL',
  SHOW_DELETE_MEMBER_MODAL: 'SHOW_DELETE_MEMBER_MODAL',
  SHOW_EDIT_DELEGATE_MODAL: 'SHOW_EDIT_DELEGATE_MODAL',
  SHOW_ADD_LICENSES_MODAL: 'SHOW_ADD_LICENSES_MODAL',
  SHOW_CONFIRM_LICENSES_MODAL: 'SHOW_CONFIRM_LICENSES_MODAL',
  GET_BOB_TRANSFERS: 'GET_BOB_TRANSFERS',
  SHOW_BOB_TRANSFER_MODAL: 'SHOW_BOB_TRANSFER_MODAL',
  SHOW_RECEIVE_BOB_TRANSFER_MODAL: 'SHOW_RECEIVE_BOB_TRANSFER_MODAL',
  SHOW_CANCEL_BOB_TRANSFER_MODAL: 'SHOW_CANCEL_BOB_TRANSFER_MODAL',
  GET_BOB_TRANSFER: 'GET_BOB_TRANSFER',
  CREATE_BOB_TRANSFER_INTERNAL: 'CREATE_BOB_TRANSFER_INTERNAL',
  CREATE_BOB_TRANSFER_EXTERNAL: 'CREATE_BOB_TRANSFER_EXTERNAL',
  EXECUTE_EXTERNAL_BOB_TRANSFER: 'EXECUTE_EXTERNAL_BOB_TRANSFER',
  CREATE_BOB_TRANSFER_ERROR: 'CREATE_BOB_TRANSFER_ERROR',
  UPDATE_BOB_TRANSFER: 'UPDATE_BOB_TRANSFER',
  UNDO_BOB_TRANSFER: 'UNDO_BOB_TRANSFER',
  AUTHENTICATE_BOB_TRANSFER: 'AUTHENTICATE_BOB_TRANSFER',
  AUTH_BOB_TRANSFER_ERROR: 'AUTH_BOB_TRANSFER_ERROR',
  EXTEND_BOB_TRANSFER: 'EXTEND_BOB_TRANSFER',
  GET_ADVISOR_DETAILS: 'GET_ADVISOR_DETAILS',
  GET_CUSTOMER_STENCIL_LIBRARY: 'GET_CUSTOMER_STENCIL_LIBRARY',
  MOVE_CUSTOMER_STENCIL: 'MOVE_CUSTOMER_STENCIL',
  GET_CUSTOMER_SCOPES: 'GET_CUSTOMER_SCOPES',
  UPDATE_CUSTOMER_SCOPES: 'UPDATE_CUSTOMER_SCOPES',

  //BILLING MANAGER ACTIONS
  GET_BILLING_INFO: 'GET_BILLING_INFO',
  UPDATE_BILLING_INFO: 'UPDATE_BILLING_INFO',
  GET_INVOICES: 'GET_INVOICES',
  GET_BILLING_SUBSCRIPTION: 'GET_BILLING_SUBSCRIPTION',
  GET_SUBSCRIPTION_OPTIONS: 'GET_SUBSCRIPTION_OPTIONS',
  RESUBSCRIBE_PLAN: 'RESUBSCRIBE_PLAN',
  REENABLE_SUBSCRIPTION: 'REENABLE_SUBSCRIPTION',

  //QUICKSIGHT
  GET_QUICKSIGHT_URL: 'GET_QUICKSIGHT_URL',

  //TARGET-MAP PREFERENCES
  GET_TM_PREFERENCES: 'GET_TM_PREFERENCES',
  UPDATE_TM_PREFERENCES: 'UPDATE_TM_PREFERENCES',
  TM_PREFERENCES_ERROR: 'TM_PREFERENCES_ERROR',

  //KFF SETTINGS
  UPDATE_KFF_SETTINGS: 'UPDATE_KFF_SETTINGS',

  //TERMS
  GET_TERMS: 'GET_TERMS',
  UPDATE_TERMS: 'UPDATE_TERMS',

  //HOUSEHOLD ACTIONS
  TOGGLE_EXPAND_SIDE_NAV: 'TOGGLE_EXPAND_SIDE_NAV',
  TOGGLE_SHOW_HIDDEN_FINANCIALS: 'TOGGLE_SHOW_HIDDEN_FINANCIALS',
  TOGGLE_SHOW_CASH_VALUE_COLUMN: 'TOGGLE_SHOW_CASH_VALUE_COLUMN',
  TOGGLE_SHOW_MULTI_SELECT_FINANCIALS: 'TOGGLE_SHOW_MULTI_SELECT_FINANCIALS',
  TOGGLE_SHOW_MULTI_SELECT_AMR: 'TOGGLE_SHOW_MULTI_SELECT_AMR',
  TOGGLE_SHOW_STENCIL_HELPER_MODAL: 'TOGGLE_SHOW_STENCIL_HELPER_MODAL',
  GET_USER_GROUPS: 'GET_USER_GROUPS',
  GET_USER_ROLES: 'GET_USER_ROLES',
  GET_ROLE_PERMISSIONS: 'GET_ROLE_PERMISSIONS',
  GET_DEFAULT_ROLE_PERMISSIONS: 'GET_DEFAULT_ROLE_PERMISSIONS',
  CREATE_USER_ROLE: 'CREATE_USER_ROLE',
  UPDATE_USER_ROLE: 'UPDATE_USER_ROLE',
  CLONE_USER_ROLE: 'CLONE_USER_ROLE',
  DELETE_USER_ROLE: 'DELETE_USER_ROLE',

  //AMR ACTIONS
  TOGGLE_SHOW_AMR_MEMBERS: 'TOGGLE_SHOW_AMR_MEMBERS',
  TOGGLE_SHOW_AMR_LEGEND: 'TOGGLE_SHOW_AMR_LEGEND',
  TOGGLE_SHOW_AMR_KFFS: 'TOGGLE_SHOW_AMR_KFFS',
  TOGGLE_SHOW_AMR_HIDDEN: 'TOGGLE_SHOW_AMR_HIDDEN',
  TOGGLE_SHOW_AMR_CUSTODIAN: 'TOGGLE_SHOW_AMR_CUSTODIAN',
  TOGGLE_SHOW_AMR_ENTITY: 'TOGGLE_SHOW_AMR_ENTITY',
  TOGGLE_SHOW_AMR_PROPCAS: 'TOGGLE_SHOW_AMR_PROPCAS',
  TOGGLE_SHOW_AMR_RISKALYZE: 'TOGGLE_SHOW_AMR_RISKALYZE',
  TOGGLE_SHOW_AMR_MAW: 'TOGGLE_SHOW_AMR_MAW',
  TOGGLE_SHOW_ENTITY_ROLLUP: 'TOGGLE_SHOW_ENTITY_ROLLUP',
  TOGGLE_SHOW_GLASS_TRAY: 'TOGGLE_SHOW_GLASS_TRAY',
  GET_GLASS_APP_LIST: 'GET_GLASS_APP_LIST',

  //REPORT LAYOUTS
  GET_REPORT_LAYOUTS: 'GET_REPORT_LAYOUTS',
  CREATE_REPORT_LAYOUT: 'CREATE_REPORT_LAYOUT',
  UPDATE_REPORT_LAYOUT: 'UPDATE_REPORT_LAYOUT',
  DELETE_REPORT_LAYOUT: 'DELETE_REPORT_LAYOUT',

  //SIGNAL SETTINGS
  TOGGLE_SHOW_SIGNALS_PANEL: 'TOGGLE_SHOW_SIGNALS_PANEL',
  GET_CUSTOMER_SIGNAL_SETTINGS: 'GET_CUSTOMER_SIGNAL_SETTINGS',
  TOGGLE_SIGNAL_ENABLED: 'TOGGLE_SIGNAL_ENABLED',

  //INTEGRATIONS
  GET_INTEGRATIONS: 'GET_INTEGRATIONS',
  ENABLE_INTEGRATION: 'ENABLE_INTEGRATION',
  DISABLE_INTEGRATION: 'DISABLE_INTEGRATION',
  TOGGLE_INTEGRATION_FINANCIALS: 'TOGGLE_INTEGRATION_FINANCIALS',
  SEARCH_INTEGRATION: 'SEARCH_INTEGRATION',
  GET_INTEGRATION_MEMBERS: 'GET_INTEGRATION_MEMBERS',
  GET_INTEGRATION_MEMBERS_ERROR: 'GET_INTEGRATION_MEMBERS_ERROR',
  CREATE_HH_INTEGRATION: 'CREATE_HH_INTEGRATION',

  // GLASS
  SET_GLASS_MODALS_DISABLE: 'SET_GLASS_MODALS_DISABLE',
  USER_TOGGLE_MODAL: 'USER_TOGGLE_MODAL',
};

/*
  GENERIC FUNCTION USED TO CHANGE A MODIFY SINGLE VALUE IN USER STORE
  - fieldName <string>: field name in redux store to modify
  - value <any>: new value desired for field
*/
export const updateUserStoreValue = (fieldName, value) => ({
  type: UserTypes.UPDATE_USER_STORE_VALUE,
  payload: { fieldName, value },
});

let url;
process.env.NODE_ENV === 'development'
  ? (url = 'http://localhost:8000')
  : (url = window.location.origin);

//ACTION TO USER LOGGED IN, TYPE AND TOKENS <object>
export const setUserLoggedIn = (data) => ({
  type: UserTypes.LOG_IN,
  payload: data,
});

/*
  API CALL TO LOGIN TO APPLICATION (AMPUX & CONNECT)
  - data <object>: user info needed to login
*/
export const logUserIn = (data) => {
  const user = { username: data.email, password: data.password };
  return apiAction({
    path: `/member/login`,
    method: 'POST',
    data: user,
    onSuccess: setUserLoggedIn,
    label: UserTypes.LOG_IN,
  });
};

//ACTION TO TOGGLE REMEMBER ME CHECKBOX ON LOGIN MODAL
export const toggleRememberMe = (rememberMe) => ({
  type: UserTypes.TOGGLE_REMEMBER_ME,
  payload: rememberMe,
});

//ACTION TO REMOVE LOGIN ERROR ON LOGIN MODAL
export const removeLoginError = () => ({
  type: UserTypes.REMOVE_LOGIN_ERROR,
});

//ACTION TO SHOW LOGIN SCREEN IN LOGIN CONTAINER
export const showLoginScreen = () => ({
  type: UserTypes.SHOW_LOGIN_SCREEN,
});

//ACTION TO SHOW LOGIN HELP SCREEN IN LOGIN CONTAINER
export const showLoginHelpScreen = () => ({
  type: UserTypes.SHOW_LOGIN_HELP_SCREEN,
});

//ACTION TO SHOW PASSWORD RECOVERY SCREEN IN LOGIN CONTAINER
export const showPasswordRecoveryScreen = () => ({
  type: UserTypes.SHOW_PASSWORD_RECOVERY_SCREEN,
});

//ACTION TO TOGGLE REMEMBER ME CHECKBOX ON LOGIN MODAL
export const toggleShowResetPasswordModal = (showModal) => ({
  type: UserTypes.TOGGLE_REMEMBER_ME,
  payload: showModal,
});

/*
  API CALL TO SENT REQUEST TO RESET PASSWORD VIA EMAIL
  - email <string>: email address to send reset password to
*/
export const sendResetPassword = (email, isManager = false) => {
  return apiAction({
    path: `/member/send-password-reset`,
    method: 'PUT',
    data: { email: email },
    onSuccess: showSentPasswordResetScreen,
    passData: true,
    successMessage: isManager && `Password reset email sent`,
    label: UserTypes.SEND_RESET_PASSWORD,
  });
};

//ACTION TO SHOW PASSWORD RECOVERY RESET EMAIL SENT SCREEN IN LOGIN CONTAINER
export const showSentPasswordResetScreen = (email) => ({
  type: UserTypes.SHOW_SENT_RESET_PASSWORD_SCREEN,
  payload: email,
});

//ACTION TO SET USER AS LOGGED OUT IN STORE
export const setUserLoggedOut = () => ({
  type: UserTypes.LOG_OUT,
});

//API CALL TO LOG USER OUT OF APP AND SHUT DOWN INTERCOM SUPPORT
export const logUserOut = () => {
  window.Intercom('shutdown');
  return apiAction({
    path: `/member/logout`,
    method: 'GET',
    onSuccess: setUserLoggedOut,
    label: UserTypes.LOG_OUT,
  });
};

//ACTION TO SHOW MFA AUTH CODE SCREEN IN LOGIN CONTAINER/SETTINGS
export const showAuthCodeScreen = () => ({
  type: UserTypes.SHOW_AUTH_CODE_SCREEN,
});

//ACTION TO SET USER SUBMITTED AUTH CODE IN STORE
export const setSubmittedAuthCode = (data) => ({
  type: UserTypes.SUBMIT_AUTH_CODE,
  payload: data,
});

/*
  API CALL TO SUBMIT MFA AUTH CODE
  - mfaId <string>: id of mfa challenged associated with user
  - data <object>: data need to verify user and mfa
*/
export const submitAuthCode = (mfaId, data) => {
  return apiAction({
    path: `/member/auth/challenge/${mfaId}/`,
    method: 'PUT',
    data: data,
    onSuccess: setSubmittedAuthCode,
    label: UserTypes.SUBMIT_AUTH_CODE,
  });
};

//ACTION TO REMOVE AUTH CODE ERROR ON LOGIN MODAL/SETTINGS
export const clearAuthCodeError = () => ({
  type: UserTypes.CLEAR_AUTH_CODE_ERROR,
});

//ACTION TO SHOW RESEND MFA AUTH CODE SCREEN IN LOGIN CONTAINER/SETTINGS
export const showResendAuthCodeScreen = () => ({
  type: UserTypes.SHOW_RESEND_AUTH_CODE_SCREEN,
});

//ACTION TO SET THAT AUTH CODE HAS BEEN SENT IN STORE
export const sentAuthCode = () => ({
  type: UserTypes.RESEND_AUTH_CODE,
});

/*
  API CALL TO RESEND MFA AUTH CODE
  - mfaId <string>: id of mfa challenged associated with user
  - isAdd <object>: if true this is adding of mfa and setting first code
*/
export const resendAuthCode = (mfaId, isAdd) => {
  return apiAction({
    path: `/member/auth/${isAdd ? 'factor' : 'challenge'}/${mfaId}/resend/`,
    method: 'POST',
    onSuccess: sentAuthCode,
    label: UserTypes.RESEND_AUTH_CODE,
  });
};

//ACTION TO SHOW MFA AUTH CODE SENT SCREEN IN LOGIN CONTAINER/SETTINGS
export const showAuthCodeSentScreen = () => ({
  type: UserTypes.SHOW_AUTH_CODE_SENT_SCREEN,
});

//ACTION TO TOGGLE DISPLAY OF DELEGATE MODAL WHICH PROMPTS WHICH ADVISOR TO IMPERSONATE
export const toggleShowDelegateModal = (data) => ({
  type: UserTypes.TOGGLE_SHOW_DELEGATE_MODAL,
  payload: data,
});

//ACTION TO SET LIST OF ADVISORS DELEGATE CAN IMPERSONATE IN STORE
export const setDelegateAdvisorList = (advisors) => ({
  type: UserTypes.GET_DELEGATE_ADVISOR_LIST,
  payload: advisors,
});

//API CALL TO GET LIST OF ADVISORS DELEGATE CAN IMPERSONATE
export const getDelegateAdvisorList = () => {
  return apiAction({
    path: `/member/role`,
    method: 'GET',
    onSuccess: setDelegateAdvisorList,
    label: UserTypes.GET_DELEGATE_ADVISOR_LIST,
  });
};

/*
  API CALL TO SET WHICH ADVISOR DELEGATE WANTS TO IMPERSONATE
  - user <object>: user object of data they want to impersonate
*/
export const setDelegateAdvisor = (user) => {
  return apiAction({
    path: `/member/role`,
    method: 'POST',
    data: { id: user.id },
    onSuccess: getUserInfo,
    successMessage: `Logged in as ${user.name}`,
    label: UserTypes.SET_DELEGATE_ADVISOR,
  });
};

//API CALL TO LOGOUT OF DELEGATE IMPERSONATION
export const logoutDelegate = () => {
  return apiAction({
    path: `/member/role/logout`,
    method: 'POST',
    onSuccess: getUserInfo,
    label: UserTypes.SET_DELEGATE_ADVISOR,
  });
};

/*
  API CALL TO LOGOUT OF CUSTOMER WHILE IMPERSONATING
  - customer <object>: customer object
*/
export const logoutOfCustomer = (customer) => {
  return apiAction({
    path: `/customer/${customer?.id}/support_manager/disable`,
    method: 'GET',
    onSuccess: getUserInfo,
    successMessage: `Logged out of ${customer?.name}`,
    label: UserTypes.LOG_OUT_CUSTOMER,
  });
};

//ACTION TO SET USER INFO IN STORE
const setUserInfo = (user) => ({
  type: UserTypes.GET_USER_INFO,
  payload: user,
});

//API CALL TO GET USER INFO
export const getUserInfo = () => {
  return apiAction({
    path: `/member/info`,
    method: 'GET',
    onSuccess: setUserInfo,
    label: UserTypes.GET_USER_INFO,
  });
};

//ACTION TO SET CONSUMER INFO IN STORE
const setConsumerInfo = (consumer) => ({
  type: UserTypes.GET_CONSUMER_INFO,
  payload: consumer,
});

//API CALL TO GET CONSUMER INFO
export const getConsumerInfo = () => {
  return apiAction({
    path: `/info`,
    isConsumer: true,
    method: 'GET',
    onSuccess: setConsumerInfo,
    label: UserTypes.GET_CONSUMER_INFO,
  });
};

//ACTION TO SET USER AUTH STATUS IN STORE
const setAuthStatus = (status) => ({
  type: UserTypes.GET_AUTH_STATUS,
  payload: status,
});

//API CALL TO GET USER AUTH STATUS
export const getAuthStatus = () => {
  return apiAction({
    path: `/member/auth/status`,
    method: 'GET',
    onSuccess: setAuthStatus,
    label: UserTypes.GET_AUTH_STATUS,
  });
};

//ACTION TO SET CLIENT PREVIEW MODE IN STORE
const setClientPreviewMode = (data, { householdId, memberName, memberId }) => ({
  type: UserTypes.ENTER_CLIENT_PREVIEW_MODE,
  payload: { ...data, householdId, memberName, memberId },
});

/*
  API CALL TO ENTER CONNECT USER PREVIEW MODE
  - householdId <string>: uuid of household to preview
  - memberId <string>: uuid of household member to preview
  - memberName <string>: name of member to preview
  - role <string>: key or role, only passed in when no active invite
*/
export const enterClientPreviewMode = (
  householdId,
  memberId,
  memberName,
  role
) => {
  return apiAction({
    path: `/household/${householdId}/member/${memberId}/preview${
      role ? `/${role}` : ''
    }`,
    isConsumer: true,
    method: 'GET',
    passValue: { householdId, memberName, memberId },
    showStart: true,
    onSuccess: setClientPreviewMode,
    label: UserTypes.ENTER_CLIENT_PREVIEW_MODE,
  });
};

//ACTION TO SET EXIT CLIENT PREVIEW MODE IN STORE
const setExitClientPreviewMode = (data) => ({
  type: UserTypes.EXIT_CLIENT_PREVIEW_MODE,
  payload: data,
});

/*
  API CALL TO EXIT CONNECT USER PREVIEW MODE
  - householdId <string>: uuid of household to exit preview
  - memberId <string>: uuid of household member to exit preview
*/
export const exitClientPreviewMode = (householdId, memberId) => {
  return apiAction({
    path: `/household/${householdId}/member/${memberId}/exit/preview`,
    isConsumer: true,
    method: 'GET',
    onSuccess: setExitClientPreviewMode,
    errorMessage: 'Error Exiting Preview Mode',
    label: UserTypes.EXIT_CLIENT_PREVIEW_MODE,
  });
};

/*
  ACTION TO PREVIEW CLIENT MODE
  - householdId <string>: id of household to preview
  - location <string>: where the user was when they did preview
*/
// export const showClientPreviewMode = (householdId, location) => ({
//   type: UserTypes.SHOW_CLIENT_PREVIEW_MODE,
//   payload: { householdId, location },
// });

// //ACTION TO EXIT CLIENT PREVIEW MODE
// export const exitClientPreviewMode = (details) => ({
//   type: UserTypes.EXIT_CLIENT_PREVIEW_MODE,
//   payload: details,
// });

//ACTION TO SET CONSUMER INVITE INFO IN STORE
const setInviteDetails = (details) => ({
  type: UserTypes.GET_INVITE_DETAILS,
  payload: details,
});

//ACTION TO SET CONSUMER INVITE ERROR IN STORE
const setInviteDetailsError = (details) => ({
  type: UserTypes.GET_INVITE_DETAILS_ERROR,
  payload: details,
});

/*
  API CALL TO CONSUMER INVITE DETAILS
  - tokenId <string>: id of invite
*/
export const getInviteDetails = (tokenId) => {
  return apiAction({
    path: `/invite/${tokenId}`,
    isConsumer: true,
    method: 'GET',
    onSuccess: setInviteDetails,
    onFailure: setInviteDetailsError,
    label: UserTypes.GET_INVITE_DETAILS,
  });
};

//ACTION TO TOGGLE DISPLAY OF INVITE MODAL <boolean>
export const toggleShowInviteModal = (showModal, screen = null) => {
  return {
    type: UserTypes.SHOW_INVITE_MODAL,
    payload: { showModal, screen },
  };
};

//ACTION TO SET CONSUMER REGISTRATION DETAILS IN STORE
const setRegistrationDetails = (details) => ({
  type: UserTypes.GET_REGISTRATION_DETAILS,
  payload: details,
});

/*
  API CALL TO GET CONSUMER REGISTRATION DETAILS
  - tokenId <string>: id of invite
*/
export const getRegistrationDetails = (tokenId) => {
  return apiAction({
    path: `/invite/${tokenId}/register`,
    isConsumer: true,
    method: 'GET',
    onSuccess: setRegistrationDetails,
    label: UserTypes.GET_REGISTRATION_DETAILS,
  });
};

//ACTION TO SET CONSUMER REGISTER ACCOUNT ERROR IN STORE
const setRegisterConsumerAccountError = (error) => ({
  type: UserTypes.REGISTER_CONSUMER_ACCOUNT_ERROR,
  payload: error,
});

//ACTION TO SET THAT CONSUMER REGISTERED AN ACCOUNT
const setRegisteredConsumerAccount = (data) => ({
  type: UserTypes.REGISTERED_CONSUMER_ACCOUNT,
  payload: data,
});

/*
  API CALL TO REGISTER CONSUMER ACCOUNT
  - tokenId <string>: id of invite
  - data <object>: account details
*/
export const registerConsumerAccount = (tokenId, data) => {
  return apiAction({
    path: `/invite/${tokenId}/register`,
    isConsumer: true,
    method: 'POST',
    data: data,
    onSuccess: setRegisteredConsumerAccount,
    onFailure: setRegisterConsumerAccountError,
    label: UserTypes.REGISTER_CONSUMER_ACCOUNT,
  });
};

//ACTION TO SET THAT VERIFY EMAIL WAS RESENT
const setVerifyEmailResent = (data) => ({
  type: UserTypes.RESENT_VERIFY_EMAIL,
  payload: data,
});

/*
  API CALL TO RESEND CONSUMER VERIFY EMAIL
  - tokenId <string>: id of invite
*/
export const resendVerifyEmail = (tokenId) => {
  return apiAction({
    path: `/invite/${tokenId}/reset`,
    isConsumer: true,
    method: 'POST',
    onSuccess: setVerifyEmailResent,
    label: UserTypes.RESEND_VERIFY_EMAIL,
  });
};

//ACTION TO SET THAT VERIFY WAS SUCCESSFUL AND CREATE ACCOUNT
const setCreatedConsumerAccount = (data) => ({
  type: UserTypes.CREATED_CONSUMER_ACCOUNT,
  payload: data,
});

/*
  API CALL TO VERIFY CONSUMER EMAIL CODE
  - tokenId <string>: id of invite
  - code <string>: 6 digit email code
*/
export const verifyEmailCode = (tokenId, code) => {
  return apiAction({
    path: `/invite/${tokenId}/verify`,
    isConsumer: true,
    method: 'POST',
    data: code,
    onSuccess: setCreatedConsumerAccount,
    label: UserTypes.VERIFY_EMAIL_CODE,
  });
};

/*
  API CALL TO ACCEPT SHARING OF HOUSEHOLD
  - tokenId <string>: id of invite
*/
export const acceptSharingInvite = (tokenId) => {
  return apiAction({
    path: `/invite/${tokenId}/accept`,
    isConsumer: true,
    method: 'PUT',
    data: { accepted: true },
    onSuccess: getConsumerHouseholds,
    successMessage: 'Household Connected',
    label: UserTypes.ACCEPT_SHARING_INVITE,
  });
};

//ACTION TO SET INVITE AS DECLINED
const declinedSharingInvite = () => ({
  type: UserTypes.DECLINE_SHARING_INVITE,
});

/*
  API CALL TO REJECT SHARING OF HOUSEHOLD
  - tokenId <string>: id of invite
*/
export const declineSharingInvite = (tokenId) => {
  return apiAction({
    path: `/invite/${tokenId}/decline`,
    isConsumer: true,
    method: 'PUT',
    onSuccess: declinedSharingInvite,
    successMessage: 'Household Rejected',
    label: UserTypes.DECLINE_SHARING_INVITE,
  });
};

/*
  API CALL TO UPDATE USER INFO
  - user <object>: user object of data to update
*/
export const updateUserInfo = (user) => {
  return apiAction({
    path: `/member/info`,
    method: 'PUT',
    data: user,
    successMessage: 'Contact Info Updated',
    onSuccess: getUserInfo,
    label: UserTypes.UPDATE_USER_INFO,
  });
};

/*
  API CALL TO SKIP PASSWORD RESET PROMPT
  - user <object>: user object of data who wants to skip
*/
export const skipPasswordReset = (user) => {
  return apiAction({
    path: `/member/skip-password-reset`,
    method: 'PUT',
    data: user,
    onSuccess: getUserInfo,
    successMessage: `Skip Used`,
    label: UserTypes.SKIP_PASSWORD_RESET,
  });
};

/*
  API CALL TO RESET USER PASSWORD FROM RESET PASSWORD MODAL
  - password <object>: contains token and new password
*/
export const resetUserPassword = (password) => {
  return apiAction({
    path: `/member/reset-password`,
    method: 'PUT',
    data: password,
    successMessage: 'Password Updated',
    onSuccess: updatedUserPassword,
    label: UserTypes.RESET_USER_PASSWORD,
  });
};

//ACTION TO SET USER PROFILE PICTURE IN STORE
export const setUserProfilePicture = (avatar) => ({
  type: UserTypes.GET_PROFILE_PICTURE,
  payload: avatar,
});

//API CALL TO GET USER PROFILE PICTURE
export const getUserProfilePicture = () => {
  return apiAction({
    path: `/member/avatar`,
    method: 'GET',
    onSuccess: setUserProfilePicture,
    label: UserTypes.GET_PROFILE_PICTURE,
  });
};

//ACTION TO SET UPLOADED USER PROFILE PICTURE IN STORE
export const setUploadedProfilePicture = (data) => ({
  type: UserTypes.SET_UPLOADED_PROFILE_PICTURE,
  payload: data,
});

/*
  API CALL TO UPDATE USER PROFILE PICTURE
  - picture <object>: data object with picture data and crop params
*/
export const updateProfilePicture = (picture) => {
  return apiAction({
    path: `/member/avatar`,
    method: 'POST',
    data: picture,
    successMessage: 'Profile Picture Updated',
    onSuccess: getUserInfo,
    label: UserTypes.UPDATE_PROFILE_PICTURE,
  });
};

//API CALL TO REMOVE USER PROFILE PICTURE
export const removeProfilePicture = () => {
  return apiAction({
    path: `/member/avatar`,
    method: 'DELETE',
    successMessage: 'Profile Picture Removed',
    onSuccess: getUserInfo,
    label: UserTypes.REMOVE_PROFILE_PICTURE,
  });
};

/*
  API CALL TO UPDATE APPLICATION SETTINGS
  - settings <object>: object of settings to update
*/
export const updateApplicationSettings = (settings) => {
  return apiAction({
    path: `/member/prefs/application`,
    method: 'PUT',
    data: settings,
    onSuccess: getUserInfo,
    successMessage: 'Application Settings Updated',
    label: UserTypes.UPDATE_APPLICATION_SETTINGS,
  });
};

//ACTION TO SET WHETHER SUPPORT HAS ACCESS IN STORE
export const setHasSupportAccess = (data) => ({
  type: UserTypes.CHECK_HAS_SUPPORT_ACCESS,
  payload: data,
});

//API CALL TO CHECK WHETHER SUPPORT HAS ACCESS TO USER'S ACCOUNT
export const checkHasSupportAccess = () => {
  return apiAction({
    path: `/member/support-access`,
    method: 'GET',
    onSuccess: setHasSupportAccess,
    label: UserTypes.CHECK_HAS_SUPPORT_ACCESS,
  });
};

/*
  API CALL TO TOGGLE SUPPORT ACCESS TO USER ACCOUNT
  - noAccess <boolean>: if no access makes request to grant, if access makes request to revoke
*/
export const toggleGrantSupportAccess = (noAccess) => {
  if (noAccess) {
    return apiAction({
      path: `/member/support-access`,
      method: 'PUT',
      data: { enabled: true },
      successMessage: 'Support Access Granted',
      onSuccess: checkHasSupportAccess,
      label: UserTypes.TOGGLE_GRANT_SUPPORT_ACCESS,
    });
  } else {
    return apiAction({
      path: `/member/support-access`,
      method: 'DELETE',
      successMessage: 'Support Access Revoked',
      onSuccess: checkHasSupportAccess,
      label: UserTypes.TOGGLE_GRANT_SUPPORT_ACCESS,
    });
  }
};

/*
  API CALL TO UPDATE NOTIFICATION PREFERENCES
  - settings <object>: settings object of data to update
*/
export const updateNotificationPreferences = (settings) => {
  return apiAction({
    path: `/member/notification/silence`,
    method: 'PUT',
    data: settings,
    successMessage: 'Notification Settings Updated',
    onSuccess: getUserInfo,
    label: UserTypes.UPDATE_NOTIFICATION_SETTINGS,
  });
};

/*
  API CALL TO UPDATE USER'S EXISTING PASSWORD
  - password <object>: object of existing and new password
*/
export const updateExistingUserPassword = (password) => {
  return apiAction({
    path: `/member/set-password`,
    method: 'PUT',
    data: password,
    successMessage: 'Password Updated',
    onSuccess: updatedUserPassword,
    label: UserTypes.UPDATE_USER_PASSWORD,
  });
};

//ACTION TO SET THAT USER HAS UPDATED PASSWORD IN STORE
export const updatedUserPassword = () => ({
  type: UserTypes.UPDATED_USER_PASSWORD,
});

//ACTION TO RESET PASSWORD FIELDS IN STORE - CLEAR AND REMOVE ERRORS
export const resetPasswordFields = () => ({
  type: UserTypes.RESET_PASSWORD_FIELDS,
});

//ACTION TO SET AUTH FACTORS IN STORE <array>
export const setAuthFactors = (authFactors) => ({
  type: UserTypes.GET_AUTH_FACTORS,
  payload: authFactors,
});

//API CALL TO GET AVAILABLE/ENABLED AUTH FACTORS (TEXT/EMAIL)
export const getAuthFactors = () => {
  return apiAction({
    path: `/member/auth/factor/`,
    method: 'GET',
    onSuccess: setAuthFactors,
    label: UserTypes.GET_AUTH_FACTORS,
  });
};

/*
  API CALL TO ADD A NEW AUTH FACTOR (TEXT/EMAIL)
  - authFactor <object>: contains type (sms/email) and value (email/number)
*/
export const addAuthFactor = (authFactor) => {
  return apiAction({
    path: `/member/auth/factor/`,
    method: 'POST',
    data: authFactor,
    onSuccess: getAuthFactors,
    label: UserTypes.ADD_AUTH_FACTOR,
  });
};

/*
  API CALL TO CONFIRM AUTH FACTOR
  - id <string>: id of auth code to confirm/create
  - token <string/object>: contains token to confirm and if new uses is_remembered boolean
*/
export const confirmAuthFactor = (id, token) => {
  return apiAction({
    path: `/member/auth/factor/${id}/confirm/`,
    method: 'POST',
    data: { token },
    onSuccess: getAuthFactors,
    successMessage: `MFA Enabled`,
    label: UserTypes.CONFIRM_AUTH_FACTOR,
  });
};

/*
  API CALL TO DISABLED ENABLED AUTH FACTOR
  - id <string>: id of auth factor to disabled
  - type <string>: which auth factor (text/email)
*/
export const disableAuthFactor = (id, type, showToast = true) => {
  return apiAction({
    path: `/member/auth/factor/${id}/`,
    method: 'DELETE',
    onSuccess: getAuthFactors,
    successMessage: showToast && `${type} MFA Disabled`,
    label: UserTypes.DISABLE_AUTH_FACTOR,
  });
};

//ACTION TO RESET AUTH FACTOR DISPLAY AND ACTIONS IN STORE
export const resetAuthFactors = () => ({
  type: UserTypes.RESET_AUTH_FACTOR,
});

//ACTION TO SET AUTHORIZED DEVICES LIST IN STORE <array>
export const setAuthDevices = (devices) => ({
  type: UserTypes.GET_AUTH_DEVICES,
  payload: devices,
});

//API CALL TO GET LIST OF AUTH DEVICES
export const getAuthDevices = () => {
  return apiAction({
    path: `/member/auth/device/`,
    method: 'GET',
    onSuccess: setAuthDevices,
    label: UserTypes.GET_AUTH_DEVICES,
  });
};

/*
  API CALL TO REMOVE A SAVED AUTH DEVICE
  - id <string>: id of auth device to be removed
*/
export const removeAuthDevice = (id) => {
  return apiAction({
    path: `/member/auth/device/${id}/`,
    method: 'DELETE',
    onSuccess: getAuthDevices,
    successMessage: `Device Removed`,
    label: UserTypes.REMOVE_AUTH_DEVICE,
  });
};

//ACTION TO CUSTOM USER PREFERENCES IN THE STORE <object>
export const customerUserPreferences = (preferences) => ({
  type: UserTypes.GET_CUSTOM_USER_PREFERENCES,
  payload: preferences,
});

//API CALL TO UPDATE CUSTOM USER PREFERENCES
export const getCustomUserPreferences = () => {
  return apiAction({
    path: `/member/prefs/ldd`,
    method: 'GET',
    onSuccess: customerUserPreferences,
    label: UserTypes.GET_CUSTOM_USER_PREFERENCES,
  });
};

/*
  API CALL TO UPDATE CUSTOM USER PREFERENCES
  - preferences <object>: object of all preferences to update
*/
export const updateCustomUserPreferences = (preferences) => {
  return apiAction({
    path: `/member/prefs/ldd`,
    method: 'PUT',
    data: preferences,
    successMessage: 'Custom Preferences Updated',
    onSuccess: getCustomUserPreferences,
    label: UserTypes.UPDATE_CUSTOM_USER_PREFERENCES,
  });
};

//ACTION TO SET UPLOADED CUSTOM LOGO IN STORE <object>
export const setCustomLogo = (logo) => ({
  type: UserTypes.SET_CUSTOM_LOGO,
  payload: logo,
});

/*
  API CALL TO UPDATE CUSTOM USER LOGO
  - logo <object>: object with logo base64 content
*/
export const updateCustomUserLogo = (logo) => {
  return apiAction({
    path: `/member/prefs/ldd`,
    method: 'PUT',
    data: logo,
    successMessage: 'Logo Updated',
    onSuccess: getCustomUserPreferences,
    label: UserTypes.UPDATE_CUSTOM_USER_PREFERENCES,
  });
};

//API CALL TO REMOVE USER CUSTOM USER LOGO
export const removeCustomUserLogo = () => {
  return apiAction({
    path: `/member/prefs/ldd/logo`,
    method: 'DELETE',
    successMessage: 'Logo Removed',
    onSuccess: getCustomUserPreferences,
    label: UserTypes.REMOVE_CUSTOM_LOGO_USER_PREFERENCES,
  });
};

//ACTION TO SET TEAM SETTINGS IN STORE <object>
export const setTeamSettings = (settings) => ({
  type: UserTypes.GET_TEAM_SETTINGS,
  payload: settings,
});

/*
  API CALL TO GET TEAM SETTINGS
  - id <string>: customer id used to find settings
*/
export const getTeamSettings = (id) => {
  return apiAction({
    path: `/customer/${id}`,
    method: 'GET',
    onSuccess: setTeamSettings,
    label: UserTypes.GET_TEAM_SETTINGS,
  });
};

/*
  API CALL TO UPDATE TEAM SETTINGS
  - id <string>: id of customer to update
  - settings <object>: object of settings to update
*/
export const updateTeamSettings = (id, settings, updateLogo = false) => {
  return apiAction({
    path: `/customer/${id}`,
    method: 'PUT',
    data: settings,
    onSuccess: setTeamSettings,
    successMessage: updateLogo
      ? 'Organization Logo Updated'
      : 'Team Settings Updated',
    label: UserTypes.UPDATE_TEAM_SETTINGS,
  });
};

/*
   API CALL TO DELETE TEAM LOGO
   - id <string>: id of customer to delete
 */
export const deleteTeamLogo = (id) => {
  return apiAction({
    path: `/customer/${id}/logo`,
    method: 'DELETE',
    onSuccess: getTeamSettings,
    passId: id,
    successMessage: 'Organization Logo Deleted',
    label: UserTypes.DELETE_TEAM_LOGO,
  });
};

//ACTION TO SET TEAM DISCOVERY SETTINGS IN STORE <object>
export const setDiscoveryTeamSettings = (settings) => ({
  type: UserTypes.GET_DISCOVERY_TEAM_SETTINGS,
  payload: settings,
});

/*
  API CALL TO GET DISCOVERY TEAM SETTINGS
  - id <string>: customer id used to find settings
*/
export const getDiscoveryTeamSettings = (id) => {
  return apiAction({
    path: `/customer/${id}/discovery/teamsettings`,
    method: 'GET',
    onSuccess: setDiscoveryTeamSettings,
    label: UserTypes.GET_DISCOVERY_TEAM_SETTINGS,
  });
};

/*
  API CALL TO UPDATE DISCOVERY TEAM SETTINGS
  - id <string>: id of customer to update
  - settings <object>: object of settings to update
*/
export const updateDiscoveryTeamSettings = (id, settings) => {
  return apiAction({
    path: `/customer/${id}/discovery/teamsettings`,
    method: 'PUT',
    passId: id,
    data: settings,
    onSuccess: getTeamSettings,
    successMessage: 'Discovery Settings Updated',
    label: UserTypes.UPDATE_DISCOVERY_TEAM_SETTINGS,
  });
};

/*
  API CALL TO CREATE CUSTOMER DISCOVERY CONSENT SETTINGS
  - customerId <string>: id of customer
  - settings <object>: object of settings to update
*/
export const createDiscoveryConsentSettings = (customerId, settings) => {
  return apiAction({
    path: `/interview/term`,
    method: 'POST',
    passId: customerId,
    data: settings,
    onSuccess: getTeamSettings,
    successMessage: 'Discovery Consent Created',
    errorMessage: 'Error Creating Discovery Consent',
    label: UserTypes.UPDATE_DISCOVERY_CONSENT_SETTINGS,
  });
};

/*
  API CALL TO UPDATE CUSTOMER DISCOVERY CONSENT SETTINGS
  - customerId <string>: id of customer
  - id <string>: uuid of Discovery Consent Object (if it exists)
  - settings <object>: object of settings to update
*/
export const updateDiscoveryConsentSettings = (customerId, id, settings) => {
  return apiAction({
    path: `/interview/term/${id}/detail`,
    method: 'PUT',
    passId: customerId,
    data: settings,
    onSuccess: getTeamSettings,
    successMessage: 'Discovery Consent Updated',
    errorMessage: 'Error Updating Discovery Consent',
    label: UserTypes.UPDATE_DISCOVERY_CONSENT_SETTINGS,
  });
};

//ACTION TO SET ORG LOGO IN STORE <object>
export const setOrgLogo = (logo) => ({
  type: UserTypes.SET_ORG_LOGO,
  payload: logo,
});

//ACTION TO SET MANAGER'S INTEGRATIONS IN STORE <object>
export const setManagerIntegrations = (integrations) => ({
  type: UserTypes.GET_MANAGER_INTEGRATIONS,
  payload: integrations,
});

//API CALL TO LIST OF MANAGER'S INTEGRATIONS
export const getManagerIntegrations = (customerId) => {
  return apiAction({
    path: `/customer/${customerId}/integrations`,
    method: 'GET',
    onSuccess: setManagerIntegrations,
    errorMessage: 'Error getting integrations',
    label: UserTypes.GET_MANAGER_INTEGRATIONS,
  });
};

/*
//API CALL TO UPDATE AVAILABILITY OF AN INTEGRATION
  - customerId <string>: id of customer
  - integration <object>: integration object to update
*/
export const updateManagerIntegration = (customerId, integration) => {
  const data = { [integration.key]: !integration.disabled };
  const integrationName = integration?.name || 'Integration';
  const status = integration.disabled ? 'Enabled' : 'Disabled';
  return apiAction({
    path: `/customer/${customerId}/integrations`,
    method: 'PUT',
    data: data,
    passId: customerId,
    onSuccess: getManagerIntegrations,
    successMessage: `${integrationName} ${status}`,
    label: UserTypes.UPDATE_MANAGER_INTEGRATION,
  });
};

//ACTION TO SET CUSTOMER MEMBERS LISTS IN STORE <object>
export const setCustomerMembersList = (members) => ({
  type: UserTypes.GET_CUSTOMER_MEMBERS_LIST,
  payload: members,
});

/*
  API CALL TO GET CUSTOMER MEMBERS
  - id <string>: id of customer
  - order <string>: field name to sort results by
  - offset <integer>: value to offset results for pagination
*/
export const getCustomerMembersList = (id, order = 'last_name', offset = 0) => {
  return apiAction({
    path: `/customer/${id}/teamsettings_members_list?offset=${offset}&ordering=${order}`,
    method: 'GET',
    onSuccess: setCustomerMembersList,
    label: UserTypes.GET_CUSTOMER_MEMBERS_LIST,
  });
};

/*
  API CALL TO DELETE MEMBER FROM CUSTOMER
  - customerId <string>: id of customer
  - memberId <string>: id of member to delete
*/
export const deleteCustomerMember = (customerId, memberId) => {
  return apiAction({
    path: `/member/${memberId}`,
    method: 'DELETE',
    passId: customerId,
    onSuccess: getCustomerMembersList,
    successMessage: 'Member Deleted',
    label: UserTypes.DELETE_CUSTOMER_MEMBER,
  });
};

/*
  API CALL TO DEACTIVATE MEMBER'S MFA
  - customerUUID <string>: uuid of customer
  - memberId <string>: id of member to reset
  - customerId <string>: hash id of customer (used for onSuccess)
*/
export const deactivateCustomerMemberMFA = (
  customerUUID,
  memberId,
  customerId
) => {
  return apiAction({
    path: `/customer/${customerUUID}/mfa/deactivate_member_mfa`,
    method: 'PUT',
    data: { member_uuid: memberId },
    passId: customerId,
    onSuccess: getCustomerMembersList,
    successMessage: 'MFA Reset',
    label: UserTypes.DEACTIVATE_CUSTOMER_MEMBER_MFA,
  });
};

/*
  API CALL TO RESET MEMBER'S PASSWORD
  - customerUUID <string>: uuid of customer
  - memberId <string>: id of member to reset
  - customerId <string>: hash id of customer (used for onSuccess)
*/
// export const resetCustomerMemberPassword = (
//   customerUUID,
//   memberId,
//   customerId
// ) => {
//   return apiAction({
//     path: `customer/${customerUUID}/auth/request_member_password_reset`,
//     method: 'PUT',
//     data: { member_uuid: memberId },
//     passId: customerId,
//     onSuccess: getCustomerMembersList,
//     successMessage: 'Password Reset',
//     label: UserTypes.RESET_CUSTOMER_MEMBER_PASSWORD,
//   });
// };

//ACTION TO SET CUSTOMER'S DELEGATE LIST IN STORE <object>
export const setCustomerDelegateList = (delegates) => ({
  type: UserTypes.GET_CUSTOMER_DELEGATE_LIST,
  payload: delegates,
});

/*
  API CALL TO GET CUSTOMER'S DELEGATE LIST
  - id <string>: id of customer
  - order <string>: field name to sort results by
  - offset <integer>: value to offset results for pagination
*/
export const getCustomerDelegateList = (
  id,
  order = 'last_name',
  offset = 0
) => {
  return apiAction({
    path: `/customer/${id}/member_list?offset=${offset}&ordering=${order}&is_delegate=true`,
    method: 'GET',
    onSuccess: setCustomerDelegateList,
    label: UserTypes.GET_CUSTOMER_DELEGATE_LIST,
  });
};

//ACTION TO SET CUSTOMER PENDING INVITE LIST IN STORE <object>
export const setCustomerPendingList = (pendingList) => ({
  type: UserTypes.GET_CUSTOMER_PENDING_LIST,
  payload: pendingList,
});

/*
  API CALL TO GET CUSTOMER'S PENDING LIST
  - id <string>: id of customer
  - order <string>: field name to sort results by
  - offset <integer>: value to offset results for pagination
*/
export const getCustomerPendingList = (
  id,
  order = '-modified_utc',
  offset = 0
) => {
  return apiAction({
    path: `/customer/${id}/invite?offset=${offset}&ordering=${order}`,
    method: 'GET',
    onSuccess: setCustomerPendingList,
    label: UserTypes.GET_CUSTOMER_PENDING_LIST,
  });
};

//ACTION TO SET THAT INVITE HAS BEEN SENT SUCCESSFULLY IN STORE
export const inviteMemberSuccess = () => ({
  type: UserTypes.SEND_INVITE,
});

/*
  API CALL TO SEND AN INVITE FOR A MEMBER
  - id <string>: id of customer
  - data <object>: invite object of who to send to
*/
export const sendInvite = (id, data) => {
  return apiAction({
    path: `/customer/${id}/invite`,
    method: 'POST',
    data: data,
    passId: id,
    onSuccess: inviteMemberSuccess,
    successMessage: 'Invite Sent',
    label: UserTypes.SEND_INVITE,
    showStart: true,
  });
};

/*
  API CALL TO DELETE A PENDING INVITE
  - customerId <string>: id of customer
  - inviteId <string>: id of invite to delete
  - sortValue <string>: current sort value to pass to success
*/
export const deletePendingInvite = (customerId, inviteId, sortValue) => {
  return apiAction({
    path: `/invite/${inviteId}`,
    method: 'DELETE',
    passId: customerId,
    passSubId: sortValue,
    onSuccess: getCustomerPendingList,
    successMessage: 'Invite Deleted',
    label: UserTypes.DELETE_INVITE,
  });
};

/*
  API CALL TO RESEND A SENT INVITE
  - customerId <string>: id of customer
  - inviteId <string>: id of invite to resend
*/
export const resendInvite = (customerId, inviteId) => {
  return apiAction({
    path: `/invite/${inviteId}/resend`,
    method: 'GET',
    passId: customerId,
    onSuccess: getCustomerPendingList,
    successMessage: 'Invite Resent',
    label: UserTypes.RESEND_INVITE,
  });
};

/*
  API CALL TO UPDATE CURRENT MEMBER'S ROLE(S)
  - customerId <string>: id of customer
  - memberId <string>: id of member to update
  - data <object>: contains what value of member to update
  - sortValue <string>: current sort value to pass on success
*/
export const updateMemberRole = (customerId, memberId, data, sortValue) => {
  return apiAction({
    path: `/member/${memberId}`,
    method: 'PUT',
    data: { [data.key]: data.value },
    passId: customerId,
    passSubId: sortValue,
    onSuccess: getCustomerMembersList,
    successMessage: 'Member Updated',
    label: UserTypes.UPDATE_MEMBER_ROLE,
  });
};

/*
  API CALL TO UPDATE A DELEGATE'S SETTINGS FOR CUSTOMER
  - customerId <string>: id of customer
  - memberId <string>: id of delegate member to update
  - advisors <array>: contains what advisors are allowed for delegate
  - sortValue <string>: current sort value to pass on success
*/
export const updateCustomerDelegate = (
  customerId,
  memberId,
  advisors,
  sortValue
) => {
  return apiAction({
    path: `/member/${memberId}/delegator/`,
    method: 'PUT',
    data: { advisors },
    passId: customerId,
    passSubId: sortValue,
    onSuccess: getCustomerDelegateList,
    successMessage: 'Member Updated',
    label: UserTypes.UPDATE_CUSTOMER_DELEGATE,
  });
};

//ACTION TO SET CUSTOMER LICENSE COUNTS IN STORE <object>
export const setLicensesCount = (count) => ({
  type: UserTypes.GET_LICENSES_COUNT,
  payload: count,
});

/*
  API CALL TO GET LICENSE COUNTS
  - id <string>: id of customer to get license count
*/
export const getLicensesCount = (id) => {
  return apiAction({
    path: `/customer/${id}/member_usage`,
    method: 'GET',
    onSuccess: setLicensesCount,
    label: UserTypes.GET_LICENSES_COUNT,
  });
};

/*
  API CALL TO UPDATE ADD ADDITIONAL LICENSES
  - id <string>: id of customer to update
  - data <object>: object of license count and type
*/
export const updateSeatConfiguration = (id, data) => {
  return apiAction({
    path: `/customer/${id}/seats`,
    method: 'POST',
    passId: id,
    data,
    successMessage: 'Licenses Added',
    onSuccess: getLicensesCount,
    label: UserTypes.UPDATE_SEAT_CONFIG,
  });
};

//ACTION TO TOGGLE DISPLAY OF INVITE MEMBER MODAL IN STORE <boolean>
export const toggleShowInviteMemberModal = (showModal) => {
  return {
    type: UserTypes.SHOW_INVITE_MEMBER_MODAL,
    payload: showModal,
  };
};

//ACTION TO TOGGLE DISPLAY OF DELETE MEMBER MODAL IN STORE <boolean>
export const toggleShowDeleteMemberModal = (showModal) => {
  return {
    type: UserTypes.SHOW_DELETE_MEMBER_MODAL,
    payload: showModal,
  };
};

//ACTION TO TOGGLE DISPLAY OF EDIT DELEGATE MODAL IN STORE <boolean>
export const toggleShowEditDelegateModal = (showModal) => {
  return {
    type: UserTypes.SHOW_EDIT_DELEGATE_MODAL,
    payload: showModal,
  };
};

//ACTION TO TOGGLE DISPLAY OF ADD LICENSES MODAL IN STORE <boolean>
export const toggleShowAddLicensesModal = (showModal) => {
  return {
    type: UserTypes.SHOW_ADD_LICENSES_MODAL,
    payload: showModal,
  };
};

//ACTION TO TOGGLE DISPLAY OF CONFIRM LICENSES MODAL IN STORE <boolean>
export const toggleShowConfirmLicensesModal = (showModal) => {
  return {
    type: UserTypes.SHOW_CONFIRM_LICENSES_MODAL,
    payload: showModal,
  };
};

//ACTION TO SET CUSTOMER BOB TRANSFERS IN STORE <object>
export const setBoBTransfers = (transfers) => ({
  type: UserTypes.GET_BOB_TRANSFERS,
  payload: transfers,
});

//API CALL TO GET CUSTOMER'S BOB TRANSFERS
export const getBoBTransfers = () => {
  return apiAction({
    path: `/transfer/list`,
    method: 'GET',
    onSuccess: setBoBTransfers,
    label: UserTypes.GET_BOB_TRANSFERS,
  });
};

//ACTION TO SET SPECIFIC BOB TRANSFER IN STORE <object>
export const setBoBTransfer = (transfer) => ({
  type: UserTypes.GET_BOB_TRANSFER,
  payload: transfer,
});

//ACTION TO TOGGLE DISPLAY OF TRANSFER BOB MODAL <boolean>
export const toggleShowBoBTransferModal = (
  showModal,
  screen = null,
  type = null
) => {
  return {
    type: UserTypes.SHOW_BOB_TRANSFER_MODAL,
    payload: { showModal, screen, type },
  };
};

//ACTION TO TOGGLE DISPLAY OF RECEIVE TRANSFER BOB MODAL <boolean>
export const toggleShowReceiveTransferModal = (
  showModal,
  code = null,
  pin = null
) => {
  return {
    type: UserTypes.SHOW_RECEIVE_BOB_TRANSFER_MODAL,
    payload: { showModal, code, pin },
  };
};

//ACTION TO TOGGLE DISPLAY OF CANCEL TRANSFER BOB MODAL <boolean>
export const toggleShowCancelTransferModal = (showModal) => {
  return {
    type: UserTypes.SHOW_CANCEL_BOB_TRANSFER_MODAL,
    payload: showModal,
  };
};

/*
  API CALL TO GET SPECIFIC BOB TRANSFER
  - id <string>: id of transfer
*/
export const getBoBTransfer = (id) => {
  return apiAction({
    path: `/transfer/${id}`,
    method: 'GET',
    onSuccess: setBoBTransfer,
    label: UserTypes.GET_BOB_TRANSFER,
  });
};

/*
  API CALL TO CREATE A NEW BOB TRANSFER
  - data <object>: data to create transfer
*/
export const createBoBTransfer = (data) => {
  const { category } = data;
  return apiAction({
    path: `/transfer/list`,
    method: 'POST',
    data,
    onSuccess: category === 'external' ? setBoBTransfer : getBoBTransfers,
    onFailure: setBoBTransferError,
    successMessage:
      category === 'internal' ? 'Transfer Completed' : 'Transfer Created',
    label:
      category === 'external'
        ? UserTypes.CREATE_BOB_TRANSFER_EXTERNAL
        : UserTypes.CREATE_BOB_TRANSFER_INTERNAL,
  });
};

/*
  API CALL TO EXECUTE AN EXISTING EXTERNAL BOB TRANSFER
  - id <string>: id of existing external transfer object
  - memberId <string>: id of member to transfer bob to
*/
export const executeExternalBoBTransfer = (id, memberId) => {
  return apiAction({
    path: `/transfer/${id}/external/${memberId}`,
    method: 'POST',
    onSuccess: getBoBTransfers,
    onFailure: setBoBTransferError,
    successMessage: 'Transfer Completed',
    label: UserTypes.EXECUTE_EXTERNAL_BOB_TRANSFER,
  });
};

//ACTION TO SET BOB TRANSFER ERROR IN STORE <object>
export const setBoBTransferError = (error) => ({
  type: UserTypes.CREATE_BOB_TRANSFER_ERROR,
  payload: error,
});

/*
  API CALL TO UPDATE SPECIFIC BOB TRANSFER
  - id <string>: id of transfer
  - action <string>: action to take on transfer (cancel/undo)
*/
export const updateBoBTransfer = (id, action) => {
  return apiAction({
    path: `/transfer/${id}/${action}`,
    method: 'POST',
    onSuccess: getBoBTransfers,
    successMessage: action === 'undo' ? 'Transfer Undone' : 'Transfer Canceled',
    label: UserTypes.UPDATE_BOB_TRANSFER,
  });
};

/*
  API CALL TO GET AUTHENTICATE BOB TRANSFER
  - data <object>: code/PIN to authenticate transfer
*/
//--If successful then need to set results in store and change modal to select target
//--If error display error message on modal
export const authenticateBoBTransfer = (data) => {
  return apiAction({
    path: `/transfer/authenticate`,
    method: 'POST',
    data,
    onSuccess: setBoBTransfer,
    onFailure: setBoBAuthError,
    label: UserTypes.AUTHENTICATE_BOB_TRANSFER,
  });
};

//ACTION TO SET BOB AUTH ERROR IN STORE <object>
export const setBoBAuthError = (error) => ({
  type: UserTypes.AUTH_BOB_TRANSFER_ERROR,
  payload: error,
});

/*
  API CALL TO EXTEND EXPIRED BOB TRANSFER
  - id <string>: id of transfer
*/
export const extendBoBTransfer = (id) => {
  return apiAction({
    path: `/transfer/${id}/extend`,
    method: 'POST',
    passId: id,
    onSuccess: getBoBTransfer,
    successMessage: 'Transfer Reactivated',
    label: UserTypes.EXTEND_BOB_TRANSFER,
  });
};

//ACTION TO SET ADVISOR DETAILS IN STORE <object>
export const setAdvisorDetails = (advisor, type) => ({
  type: UserTypes.GET_ADVISOR_DETAILS,
  payload: { advisor, type },
});

/*
  API CALL TO GET ADVISOR DETAILS
  - id <string>: id of advisor
  - type <string>: source/target
*/
export const getAdvisorDetails = (id, type) => {
  return apiAction({
    path: `/transfer/source_select/${id}`,
    method: 'GET',
    passValue: type,
    onSuccess: setAdvisorDetails,
    label: UserTypes.GET_ADVISOR_DETAILS,
  });
};

//ACTION TO SET STENCILS IN THE STORE <array>
export const setCustomerStencilLibrary = (stencils) => ({
  type: UserTypes.GET_CUSTOMER_STENCIL_LIBRARY,
  payload: stencils,
});

/*
  API CALL TO GET CUSTOMER ASSET MAP LIBRARY STENCILS
  - library <string>: which library to fetch (assetmap/assetmap+/shared/personal)
  - order <string>: field name to sort results by
  - offset <integer>: value to offset results for pagination
*/
export const getCustomerStencilLibrary = (
  library = 'assetmap',
  order = '-last_modified_by_utc',
  offset = 0
) => {
  return apiAction({
    path: `/customer/stencil_list/${library}?offset=${offset}&ordering=${order}`,
    method: 'GET',
    onSuccess: setCustomerStencilLibrary,
    label: UserTypes.GET_CUSTOMER_STENCIL_LIBRARY,
  });
};

/*
  API CALL TO MOVE ASSET MAP STENCIL
  - stencilId <string>: id of stencil to clone
  - isRemove <boolean>: whether stencil is being removed from shared or not
  - library <string>: library user is currently on
*/
export const moveCustomerStencil = (
  stencilId,
  isRemove,
  library = 'personal'
) => {
  return apiAction({
    path: `/customer/stencil/${stencilId}/move`,
    method: 'POST',
    data: { is_remove: isRemove },
    passId: library,
    onSuccess: getCustomerStencilLibrary,
    successMessage: `Stencil ${isRemove ? 'Removed' : 'Moved'}`,
    showStart: true,
    label: UserTypes.MOVE_CUSTOMER_STENCIL,
  });
};

//ACTION TO SET CUSTOMER SCOPES IN STORE <object>
export const setCustomerScopes = (scopes) => ({
  type: UserTypes.GET_CUSTOMER_SCOPES,
  payload: scopes,
});

/*
  API CALL TO GET CUSTOMER PERMISSION SCOPES
  - id <string>: id of customer
*/
export const getCustomerScopes = (id) => {
  return apiAction({
    path: `/customer/${id}/scopes?available=true`,
    method: 'GET',
    onSuccess: setCustomerScopes,
    label: UserTypes.GET_CUSTOMER_SCOPES,
  });
};

/*
  API CALL TO GET UPDATE SELECTED PERMISSION SCOPES
  - id <string>: id of customer
  - scopes <array>: list of scopes with selected value
*/
export const updateCustomerScopes = (id, scopes) => {
  return apiAction({
    path: `/customer/${id}/scopes`,
    method: 'PUT',
    data: scopes,
    passId: id,
    onSuccess: getCustomerScopes,
    successMessage: 'Permissions updated',
    label: UserTypes.UPDATE_CUSTOMER_SCOPES,
  });
};

//ACTION TO SET CUSTOMER BILLING INFO IN STORE <object>
export const setBillingInfo = (info) => ({
  type: UserTypes.GET_BILLING_INFO,
  payload: info,
});

/*
  API CALL TO GET CUSTOMER'S BILLING INFO
  - id <string>: id of customer
*/
export const getBillingInfo = (id) => {
  return apiAction({
    path: `/customer/${id}/billing/info`,
    method: 'GET',
    onSuccess: setBillingInfo,
    label: UserTypes.GET_BILLING_INFO,
  });
};

/*
  API CALL TO UPDATE CUSTOMER'S BILLING INFO
  - id <string>: id of customer
  - token <string>: recurly token needed to update billing info
*/
export const updateBillingInfo = (id, token) => {
  return apiAction({
    path: `/customer/${id}/billing/process_recurly_token`,
    method: 'POST',
    data: { token },
    passId: id,
    onSuccess: getBillingInfo,
    successMessage: 'Billing Info Updated',
    label: UserTypes.UPDATE_BILLING_INFO,
  });
};

//ACTION TO SET CUSTOMER INVOICES IN STORE <array>
export const setInvoices = (invoices) => ({
  type: UserTypes.GET_INVOICES,
  payload: invoices,
});

/*
  API CALL TO GET CUSTOMER'S INVOICE HISTORY
  - id <string>: id of customer
*/
export const getInvoices = (id) => {
  return apiAction({
    path: `/customer/${id}/billing/list_of_invoices`,
    method: 'GET',
    onSuccess: setInvoices,
    label: UserTypes.GET_INVOICES,
  });
};

//ACTION TO SET CUSTOMER'S CURRENT SUBSCRIPTION IN STORE <object>
export const setBillingSubscription = (subscription) => ({
  type: UserTypes.GET_BILLING_SUBSCRIPTION,
  payload: subscription,
});

/*
  API CALL TO GET CUSTOMER'S CURRENT SUBSCRIPTION INFO
  - id <string>: id of customer
*/
export const getBillingSubscription = (id) => {
  return apiAction({
    path: `/customer/${id}/billing/subscriptions`,
    method: 'GET',
    onSuccess: setBillingSubscription,
    label: UserTypes.GET_BILLING_SUBSCRIPTION,
  });
};

//ACTION TO SET SUBSCRIPTION OPTIONS IN STORE <object>
export const setSubscriptionOptions = (options) => ({
  type: UserTypes.GET_SUBSCRIPTION_OPTIONS,
  payload: options,
});

/*
  API CALL TO GET RESUBSCRIBE SUBSCRIPTION OPTIONS
  - id <string>: id of customer
  - advisors <integer>: amount of advisors desired for new subscription
  - delegates <integer>: amount of delegates desired for new subscription
*/
export const getSubscriptionOptions = (id, advisors = 1, delegates = 0) => {
  return apiAction({
    path: `/customer/${id}/subscription/options?advisors=${advisors}&delegates=${delegates}`,
    method: 'GET',
    onSuccess: setSubscriptionOptions,
    label: UserTypes.GET_SUBSCRIPTION_OPTIONS,
  });
};

/*
  API CALL FOR CUSTOMER TO RESUBSCRIBE TO A PROVIDED PLAN
  - id <string>: id of customer
  - planCode <string>: value of plan code for subscription
  - advisors <integer>: amount of advisors desired for new subscription
  - delegates <integer>: amount of delegates desired for new subscription
*/
export const resubscribePlan = (id, planCode, advisors, delegates) => {
  return apiAction({
    path: `/customer/${id}/subscription/subscribe`,
    method: 'POST',
    data: {
      plan_code: planCode,
      advisors: advisors,
      delegates: delegates,
    },
    passId: id,
    onSuccess: getBillingSubscription,
    successMessage: `Subscription Successful`,
    errorMessage: `Error Subscribing`,
    label: UserTypes.RESUBSCRIBE_PLAN,
  });
};

/*
  API CALL TO UPDATE CUSTOMER'S BILLING INFO
  - apiUrl <string>: url sent from API to be passed for resubscription
  - id <string>: customer id to be passed on success
*/
export const reenableSubscription = (apiUrl, id) => {
  return apiAction({
    path: `${url}${apiUrl}`,
    method: 'PUT',
    absolute: true,
    passId: id,
    onSuccess: getBillingSubscription,
    successMessage: `Subscription Re-enabled`,
    label: UserTypes.REENABLE_SUBSCRIPTION,
  });
};

//ACTION TO SET TARGET MAP PREFERENCES IN STORE <object>
export const setTargetMapPreferences = (preferences) => ({
  type: UserTypes.GET_TM_PREFERENCES,
  payload: preferences,
});

//API CALL TO GET TARGET MAP PREFERENCES
export const getTargetMapPreferences = () => {
  return apiAction({
    path: `/member/prefs/targetmap`,
    method: 'GET',
    onSuccess: setTargetMapPreferences,
    label: UserTypes.GET_TM_PREFERENCES,
  });
};

/*
  API CALL TO UPDATE USER'S TM PREFERENCES
  - preferences <object>: object preferences to update
*/
export const updateTargetMapPreferences = (preferences) => {
  return apiAction({
    path: `/member/prefs/targetmap`,
    method: 'PUT',
    data: preferences,
    successMessage: 'Preferences Updated',
    onSuccess: getTargetMapPreferences,
    onFailure: setTargetMapPreferencesError,
    errorMessage: 'Error Saving',
    label: UserTypes.UPDATE_TM_PREFERENCES,
  });
};

//ACTION TO SET QUICKSIGHT URL IN STORE <object>
export const setQuicksightUrl = (url) => ({
  type: UserTypes.GET_QUICKSIGHT_URL,
  payload: url,
});

//API CALL TO GET QUICKSIGHT EMBED URL
export const getQuicksightUrl = () => {
  return apiAction({
    path: `/member/data/quicksight`,
    method: 'GET',
    onSuccess: setQuicksightUrl,
    label: UserTypes.GET_QUICKSIGHT_URL,
  });
};

//ACTION TO SET ERROR RETURNED WHILE UPDATING TM PREFERENCES IN STORE <object>
export const setTargetMapPreferencesError = (error) => ({
  type: UserTypes.TM_PREFERENCES_ERROR,
  payload: error,
});

/*
  API CALL TO UPDATE USER'S KFF SETTINGS
  - settings <object>: object of kffs to enable
*/
export const updateKFFSettings = (settings) => {
  return apiAction({
    path: `/member/kff`,
    method: 'PUT',
    data: settings,
    successMessage: 'Settings Updated',
    onSuccess: getUserInfo,
    label: UserTypes.UPDATE_KFF_SETTINGS,
  });
};

//ACTION TO SET TERMS AND CONDITIONS IN STORE <object>
export const setTermsAndConditions = (terms) => ({
  type: UserTypes.GET_TERMS,
  payload: terms,
});

//API CALL TO GET CURRENT TERMS AND CONDITIONS
export const getTermsAndConditions = (isConsumer = false) => {
  return apiAction({
    path: `/member/terms`,
    method: 'GET',
    isConsumer: isConsumer,
    onSuccess: setTermsAndConditions,
    label: UserTypes.GET_TERMS,
  });
};

//API CALL TO UPDATE ACCEPTANCE OF TERMS AND CONDITIONS
export const updateTermsAndConditions = (isConsumer = false) => {
  return apiAction({
    path: `/member/terms/accept`,
    method: 'PUT',
    isConsumer: isConsumer,
    successMessage: 'Terms of Service Updated',
    onSuccess: getTermsAndConditions,
    label: UserTypes.UPDATE_TERMS,
  });
};

//ACTION TO SET IF HOUSEHOLD SIDE NAV IS EXPANDED & WIDTH IN STORE <boolean>
export const toggleExpandSideNav = (sideNavExpanded) => ({
  type: UserTypes.TOGGLE_EXPAND_SIDE_NAV,
  payload: {
    sideNavWidth: !sideNavExpanded ? '220px' : '58px',
    sideNavExpanded: !sideNavExpanded,
  },
});

//ACTION TO TOGGLE THE DISPLAY OF HIDDEN FINANCIALS IN STORE <boolean>
export const toggleShowHiddenFinancials = (show) => ({
  type: UserTypes.TOGGLE_SHOW_HIDDEN_FINANCIALS,
  payload: !show,
});

//ACTION TO TOGGLE THE DISPLAY OF CASH VALUE IN FINANCIALS TABLE IN STORE <boolean>
export const toggleShowCashValueColumn = (show) => ({
  type: UserTypes.TOGGLE_SHOW_CASH_VALUE_COLUMN,
  payload: !show,
});

//ACTION TO TOGGLE THE DISPLAY OF EDIT FINANCIALS IN STORE <boolean>
export const toggleShowMultiSelectFinancials = (show) => ({
  type: UserTypes.TOGGLE_SHOW_MULTI_SELECT_FINANCIALS,
  payload: !show,
});

//ACTION TO TOGGLE THE DISPLAY OF EDIT FINANCIALS IN STORE <boolean>
export const toggleShowMultiSelectAMR = (show) => ({
  type: UserTypes.TOGGLE_SHOW_MULTI_SELECT_AMR,
  payload: !show,
});

/*
  API CALL TO UPDATE WHETHER STENCIL HELPER MODAL SHOULD SHOW
  - dismiss <integer>: if true, hide modal for future, if false show modal
*/
export const toggleShowStencilHelperModal = (dismiss) => {
  let message, toggleValue;
  dismiss
    ? (message = 'Stencil Helper will no longer appear')
    : (message = 'Stencil Helper will continue to appear');
  dismiss ? (toggleValue = 0) : (toggleValue = 1);

  return apiAction({
    path: `/member/toggle/is_stencil_helper/${toggleValue}`,
    method: 'PUT',
    successMessage: message,
    onSuccess: getUserInfo,
    label: UserTypes.TOGGLE_SHOW_STENCIL_HELPER_MODAL,
  });
};

//ACTION TO SET USER'S GROUPS IN STORE <array>
export const setUserGroups = (groups) => ({
  type: UserTypes.GET_USER_GROUPS,
  payload: groups,
});

//API CALL TO GET USER'S GROUPS
export const getUserGroups = () => {
  return apiAction({
    path: `/member/groups`,
    method: 'GET',
    onSuccess: setUserGroups,
    label: UserTypes.GET_USER_GROUPS,
  });
};

//ACTION TO SET USER'S ROLES IN STORE <array>
export const setUserRoles = (roles) => ({
  type: UserTypes.GET_USER_ROLES,
  payload: roles,
});

/*
  API CALL TO GET USER'S ROLES
  - id <string>: id of user to fetch
  - systemOnly <boolean>: whether to only fetch system roles
*/
export const getUserRoles = (memberId, systemOnly = false) => {
  return apiAction({
    path: `/member/${memberId}/connect/roles${
      systemOnly ? '?system=true' : ''
    }`,
    method: 'GET',
    onSuccess: setUserRoles,
    label: UserTypes.GET_USER_ROLES,
  });
};

//ACTION TO SET ROLE'S PERMISSIONS ROLES IN STORE <object>
export const setRolePermissions = (permissions) => ({
  type: UserTypes.GET_ROLE_PERMISSIONS,
  payload: permissions,
});

/*
  API CALL TO GET PERMISSIONS FOR SELECTED ROLE
  - id <string>: user id
  - role <string>: key value of the role for cloning
*/
export const getUserRolePermissions = (memberId, role) => {
  return apiAction({
    path: `/member/${memberId}/connect/roles/${role}?available=true`,
    method: 'GET',
    onSuccess: setRolePermissions,
    label: UserTypes.GET_ROLE_PERMISSIONS,
  });
};

//ACTION TO SET DEFAULT ROLE PERMISSIONS ROLES IN STORE <object>
export const setDefaultRolePermissions = (permissions) => ({
  type: UserTypes.GET_DEFAULT_ROLE_PERMISSIONS,
  payload: permissions,
});

/*
  API CALL TO GET DEFAULT PERMISSIONS PERMISSIONS TO CREATE ROLE
  - id <string>: user id
*/
export const getDefaultUserRolePermissions = (memberId) => {
  return apiAction({
    path: `/member/${memberId}/connect/roles/new?available=true`,
    method: 'GET',
    onSuccess: setDefaultRolePermissions,
    label: UserTypes.GET_DEFAULT_ROLE_PERMISSIONS,
  });
};

/*
  API CALL TO CREATE A USER ROLE
  - id <string>: user id
  - role <object>: data about the role
*/
export const createUserRole = (memberId, role) => {
  return apiAction({
    path: `/member/${memberId}/connect/roles`,
    method: 'POST',
    data: role,
    passId: memberId,
    onSuccess: getUserRoles,
    successMessage: 'Permission set created',
    label: UserTypes.CREATE_USER_ROLE,
  });
};

/*
  API CALL TO UPDATE A USER ROLE
  - id <string>: user id
  - role <object>: data about the role
*/
export const updateUserRole = (memberId, role) => {
  return apiAction({
    path: `/member/${memberId}/connect/roles/${role?.value}`,
    method: 'PUT',
    data: role,
    passId: memberId,
    onSuccess: getUserRoles,
    successMessage: 'Permission set updated',
    label: UserTypes.UPDATE_USER_ROLE,
  });
};

//ACTION TO SET DEFAULT ROLE PERMISSIONS ROLES IN STORE <object>
export const setClonedRole = (permissions) => ({
  type: UserTypes.CLONE_USER_ROLE,
  payload: permissions,
});

/*
  API CALL TO CLONE A USER ROLE
  - id <string>: user id
  - role <string>: key value of the role for cloning
*/
export const cloneUserRole = (memberId, role) => {
  return apiAction({
    path: `/member/${memberId}/connect/roles/${role}/clone?available=true`,
    method: 'GET',
    onSuccess: setClonedRole,
    successMessage: 'Permission set cloned',
    label: UserTypes.CLONE_USER_ROLE,
  });
};

/*
  API CALL TO DELETE A USER ROLE
  - id <string>: user id
  - role <string>: key value of the role for delete
*/
export const deleteUserRole = (memberId, role) => {
  return apiAction({
    path: `/member/${memberId}/connect/roles/${role}`,
    method: 'DELETE',
    passId: memberId,
    onSuccess: getUserRoles,
    successMessage: 'Permission set deleted',
    label: UserTypes.DELETE_USER_ROLE,
  });
};

//ACTION TO TOGGLE THE DISPLAY OF MEMBER SHAZAM BAR ON AMR <boolean>
export const toggleShowAmrMembers = (show) => ({
  type: UserTypes.TOGGLE_SHOW_AMR_MEMBERS,
  payload: !show,
});

//ACTION TO TOGGLE THE DISPLAY OF AMR LEGEND ON AMR <boolean>
export const toggleShowAmrLegend = (show) => ({
  type: UserTypes.TOGGLE_SHOW_AMR_LEGEND,
  payload: !show,
});

//ACTION TO TOGGLE THE DISPLAY OF HOUSEHOLDS KFFS ON AMR <boolean>
export const toggleShowAmrKffs = (show) => ({
  type: UserTypes.TOGGLE_SHOW_AMR_KFFS,
  payload: !show,
});

//ACTION TO TOGGLE THE DISPLAY OF HIDDEN INSTRUMENTS ON AMR <boolean>
export const toggleShowAmrHidden = (show) => ({
  type: UserTypes.TOGGLE_SHOW_AMR_HIDDEN,
  payload: !show,
});

//ACTION TO TOGGLE THE DISPLAY OF CUSTODIAN INSTRUMENTS ON AMR <boolean>
export const toggleShowAmrCustodian = (show) => ({
  type: UserTypes.TOGGLE_SHOW_AMR_CUSTODIAN,
  payload: !show,
});

//ACTION TO TOGGLE THE DISPLAY OF ENTITY OWNED INSTRUMENTS ON AMR <boolean>
export const toggleShowAmrEntity = (show) => ({
  type: UserTypes.TOGGLE_SHOW_AMR_ENTITY,
  payload: !show,
});

/*
  API CALL TO TOGGLE DISPLAY OF PROPERTY & CASUALTY INSURANCE
  - show <boolean>: whether to show or hide propcas, value is inverse of what is sent
*/
export const toggleShowAmrPropCas = (show) => {
  let toggleValue;
  show ? (toggleValue = '0') : (toggleValue = '1');
  return apiAction({
    path: `/member/toggle/is_display_property_casualty/${toggleValue}`,
    method: 'PUT',
    onSuccess: getUserInfo,
    label: UserTypes.TOGGLE_SHOW_AMR_PROPCAS,
  });
};

/*
  API CALL TO TOGGLE DISPLAY OF RISKALYZE SIGNPOSTS ON THE AMR
  - value <boolean/string>: whether to show or hide signposts, value is inverse of what is sent
*/
export const toggleShowAmrRiskalyze = (value) => {
  let toggleValue;
  value === 'riskalyze' || (Array.isArray(value) && value.includes('riskalyze'))
    ? (toggleValue = '0')
    : (toggleValue = '1');
  return apiAction({
    path: `/member/toggle/riskalyze_display_scores/${toggleValue}`,
    method: 'PUT',
    onSuccess: getUserInfo,
    label: UserTypes.TOGGLE_SHOW_AMR_RISKALYZE,
  });
};

/*
  API CALL TO TOGGLE DISPLAY OF RISKALYZE SIGNPOSTS ON THE AMR
  - value <boolean/string>: whether to show or hide signposts, value is inverse of what is sent
*/
export const toggleShowAmrMAW = (value) => {
  let toggleValue;
  value === 'msaws' || (Array.isArray(value) && value.includes('msaws'))
    ? (toggleValue = '0')
    : (toggleValue = '1');
  return apiAction({
    path: `/member/toggle/msaws_display_score/${toggleValue}`,
    method: 'PUT',
    onSuccess: getUserInfo,
    label: UserTypes.TOGGLE_SHOW_AMR_MAW,
  });
};

/*
  API CALL TO TOGGLE DISPLAY OF ENTITY ROLLUP ON AMR
  - showRollup <boolean>: whether to show or hide rollup display
*/
export const toggleShowEntityRollup = (showRollup) => {
  let toggleValue = 'enable';
  if (showRollup) {
    toggleValue = 'disable';
  }
  return apiAction({
    path: `/member/rollup/display/${toggleValue}`,
    method: 'GET',
    successMessage: `Entity Rollup ${showRollup ? 'Hidden' : 'Enabled'}`,
    onSuccess: getUserInfo,
    label: UserTypes.TOGGLE_SHOW_ENTITY_ROLLUP,
  });
};

/*
  API CALL TO TOGGLE DISPLAY OF GLASS TRAY ON AMR
  - showTray <boolean>: whether to show or hide glass tray arrow
*/
export const toggleShowGlassTray = (showTray) => {
  return apiAction({
    path: `/member/glass/tray/display/${showTray ? 'enable' : 'disable'}`,
    method: 'GET',
    onSuccess: getUserInfo,
    successMessage: `Sidebar ${showTray ? 'Enabled' : 'Hidden'}`,
    label: UserTypes.TOGGLE_SHOW_GLASS_TRAY,
  });
};

//ACTION TO SET USER'S REPORT LAYOUTS IN STORE <array>
export const setGlassAppList = (apps) => ({
  type: UserTypes.GET_GLASS_APP_LIST,
  payload: apps,
});

// API CALL TO GET LIST OF GLASS APPS
export const getGlassAppList = () => {
  return apiAction({
    path: `/member/glass/tray`,
    method: 'GET',
    onSuccess: setGlassAppList,
    label: UserTypes.GET_GLASS_APP_LIST,
  });
};

//ACTION TO SET USER'S REPORT LAYOUTS IN STORE <array>
export const setReportLayouts = (layouts) => ({
  type: UserTypes.GET_REPORT_LAYOUTS,
  payload: layouts,
});

//API CALL TO GET USER'S REPORT LAYOUT OPTIONS
export const getReportLayouts = () => {
  return apiAction({
    path: `/member/layouts/report`,
    method: 'GET',
    onSuccess: setReportLayouts,
    errorMessage: 'Error Fetching Report Layouts',
    label: UserTypes.GET_REPORT_LAYOUTS,
  });
};

/*
  API CALL TO CREATE A REPORT LAYOUT FOR A USER
  - layout <object>: layout data to be created
*/
export const createReportLayout = (layout) => {
  return apiAction({
    path: `/member/layouts/report`,
    method: 'POST',
    data: layout,
    onSuccess: getReportLayouts,
    successMessage: 'Report Layout Created',
    label: UserTypes.CREATE_REPORT_LAYOUT,
  });
};

/*
  API CALL TO UPDATE A REPORT LAYOUT FOR A USER
  - id <string>: layout id to be updated
  - layout <object>: data to be updated
*/
export const updateReportLayout = (id, layout) => {
  return apiAction({
    path: `/member/layouts/report/${id}`,
    method: 'PUT',
    data: layout,
    onSuccess: getReportLayouts,
    successMessage: 'Report Layout Updated',
    errorMessage: 'Error Updating Report Layout',
    label: UserTypes.UPDATE_REPORT_LAYOUT,
  });
};

/*
  API CALL TO DELETE A USER'S REPORT LAYOUT
  - id <string>: id of layout to delete
*/
export const deleteReportLayout = (id) => {
  return apiAction({
    path: `/member/layouts/report/${id}`,
    method: 'DELETE',
    data: { id },
    onSuccess: getReportLayouts,
    successMessage: 'Report Layout Deleted',
    errorMessage: 'Error Deleting Report Layout',
    label: UserTypes.DELETE_REPORT_LAYOUT,
  });
};

/*
  API CALL TO TOGGLE DISPLAY OF SIGNALS PANEL ON HOUSEHOLD SUMMARY
  - showPanel <boolean>: whether to show or hide summary panel
*/
export const toggleShowSignalsPanel = (showPanel) => {
  let toggleValue = 'enable';
  if (showPanel) {
    toggleValue = 'disable';
  }
  return apiAction({
    path: `/member/signals/display/${toggleValue}`,
    method: 'GET',
    successMessage: `Signals Panel ${showPanel ? 'Hidden' : 'Enabled'}`,
    onSuccess: getUserInfo,
    label: UserTypes.TOGGLE_SHOW_SIGNALS_PANEL,
  });
};

//ACTION TO SET CUSTOMER'S SIGNAL SETTINGS IN STORE <object>
export const setCustomerSignalsSettings = (settings) => ({
  type: UserTypes.GET_CUSTOMER_SIGNAL_SETTINGS,
  payload: settings,
});

/*
  API CALL TO GET CUSTOMER SIGNAL SETTINGS
  - id <string>: customer id to change signals
*/
export const getCustomerSignalsSettings = (id) => {
  return apiAction({
    path: `/customer/${id}/signals`,
    method: 'GET',
    onSuccess: setCustomerSignalsSettings,
    label: UserTypes.GET_CUSTOMER_SIGNAL_SETTINGS,
  });
};

/*
  API CALL TO TOGGLE WHETHER SIGNAL IS ACTIVE FOR CUSTOMER
  - id <string>: customer id
  - signal <object>: signal object to updated
*/
export const toggleEnableSignal = (id, signal) => {
  let toggleValue = 'enable';
  if (signal?.value) {
    toggleValue = 'disable';
  }
  return apiAction({
    path: `/customer/${id}/signals/${toggleValue}?signal_type=${signal?.name}`,
    method: 'GET',
    passId: id,
    successMessage: `Signal ${signal?.value ? 'Disabled' : 'Enabled'}`,
    onSuccess: getCustomerSignalsSettings,
    label: UserTypes.TOGGLE_SIGNAL_ENABLED,
  });
};

//ACTION TO SET MEMBER'S INTEGRATIONS IN STORE <array>
export const setIntegrations = (integrations) => ({
  type: UserTypes.GET_INTEGRATIONS,
  payload: integrations,
});

//API CALL TO LIST OF MEMBER'S INTEGRATIONS
export const getIntegrations = () => {
  return apiAction({
    path: `/member/integration`,
    method: 'GET',
    onSuccess: setIntegrations,
    label: UserTypes.GET_INTEGRATIONS,
  });
};

/*
  API CALL TO ENABLE A SPECIFIC INTEGRATION
  - integration <object>: integration info to be enabled
  - data <object>: info for enabling integration
*/
export const enableIntegration = (integration, data) => {
  return apiAction({
    path: `/member/integration/${integration.id}/enable`,
    method: 'POST',
    data: data,
    passId: integration.id,
    successMessage: `${integration.name} Enabled`,
    onSuccess: getIntegrations,
    label: UserTypes.ENABLE_INTEGRATION,
  });
};

/*
  API CALL TO DISABLE AN INDIVIDUAL INTEGRATION
  - integration <object>: integration object to be updated
*/
export const disableIntegration = (integration) => {
  return apiAction({
    path: `/member/integration/${integration.id}/disable`,
    method: 'DELETE',
    successMessage: `${integration.name} Deactivated`,
    errorMessage: `Error Deactivating`,
    onSuccess: getIntegrations,
    label: UserTypes.DISABLE_INTEGRATION,
  });
};

/* For integrations with separate control for pulling financial data for households */
export const toggleFinancials = (integration, toggle) => {
  let name = integration.charAt(0).toUpperCase() + integration.slice(1);
  let successMessage = `${name} Financials Disabled`;
  if (toggle) {
    successMessage = `${name} Financials Enabled`;
  }
  return apiAction({
    path: `/member/integration/${integration}/toggle/financials`,
    method: 'POST',
    successMessage: successMessage,
    onSuccess: getIntegrations,
    passId: integration,
    passSubId: toggle,
    label: UserTypes.TOGGLE_INTEGRATION_FINANCIALS,
  });
};

//ACTION TO SET MEMBER'S INTEGRATION SEARCH RESULTS IN STORE <object>
export const setIntegrationSearchResults = (results) => ({
  type: UserTypes.SEARCH_INTEGRATION,
  payload: results,
});

/*
  API CALL TO SEARCH A MEMBER'S INTEGRATION
  - integration <string>: integration key value
  - searchValue <string>: text string to search for
*/
export const searchIntegration = (integration, searchValue) => {
  return apiAction({
    path: `/member/integration/${integration}/search?q=${searchValue}`,
    method: 'GET',
    onSuccess: setIntegrationSearchResults,
    label: UserTypes.SEARCH_INTEGRATION,
  });
};

//ACTION TO SET INTEGRATION'S LINKED MEMBERS IN STORE <object>
export const setIntegrationMembersError = (error) => ({
  type: UserTypes.GET_INTEGRATION_MEMBERS_ERROR,
  payload: error,
});

export const setIntegrationMembers = (members) => ({
  type: UserTypes.GET_INTEGRATION_MEMBERS,
  payload: members,
});

/*
  API CALL TO GET LIST OF MEMBERS LINKED TO INTEGRATION
  - apiUrl <string>: API URL value passed back from integration
*/
export const getIntegrationMembers = (apiUrl) => {
  return apiAction({
    path: `${url}${apiUrl}`,
    method: 'GET',
    absolute: true,
    onSuccess: setIntegrationMembers,
    onFailure: setIntegrationMembersError,
    errorMessage: 'Error getting integration members',
    label: UserTypes.GET_INTEGRATION_MEMBERS,
  });
};

//ACTION TO SET NEWLY CREATED HOUSEHOLD FROM INTEGRATION IN STORE <object>
export const createdHouseholdIntegration = (household) => ({
  type: UserTypes.CREATE_HH_INTEGRATION,
  payload: household,
});

/*
  API CALL TO CREATE A NEW HOUSEHOLD FROM INTEGRATION MEMBER(S)
  - apiUrl <string>: API URL value passed back from integration
*/
export const createHouseholdIntegration = (apiUrl) => {
  return apiAction({
    path: `${url}${apiUrl}`,
    absolute: true,
    method: 'POST',
    onSuccess: createdHouseholdIntegration,
    successMessage: 'Household Created',
    errorMessage: 'Creating Household Failed',
    label: UserTypes.CREATE_HH_INTEGRATION,
  });
};

//ACTION TO SET GLASS MODALS IN STORE <object>
export const setGlassModalsDisable = (disable) => ({
  type: UserTypes.SET_GLASS_MODALS_DISABLE,
  payload: disable,
});

export const setUserToggleModal = (value) => ({
  type: UserTypes.USER_TOGGLE_MODAL,
  payload: value,
})
