import { apiAction, getHouseholdNotes } from '.';

export const TargetMapTypes = {
  UPDATE_TARGET_MAP_STORE_VALUE: 'UPDATE_TARGET_MAP_STORE_VALUE',
  GET_TARGET_MAPS: 'GET_TARGET_MAPS',
  UPDATE_TARGET_MAPS_ORDER: 'UPDATE_TARGET_MAPS_ORDER',
  GET_TARGET_MAP_INFO: 'GET_TARGET_MAP_INFO',
  REMOVE_TARGET_MAP_INFO: 'REMOVE_TARGET_MAP_INFO',
  GET_TARGET_MAP_CASH_FLOW: 'GET_TARGET_MAP_CASH_FLOW',
  CREATE_TARGET_MAP: 'CREATE_TARGET_MAP',
  CLONE_TARGET_MAP: 'CLONE_TARGET_MAP',
  UPDATE_TARGET_MAP: 'UPDATE_TARGET_MAP',
  UPDATE_TARGET_MAP_SUMMARY: 'UPDATE_TARGET_MAP_SUMMARY',
  UPDATE_TARGET_MAP_SUMMARY_ERROR: 'UPDATE_TARGET_MAP_SUMMARY_ERROR',
  UPDATE_TARGET_MAP_ERROR: 'UPDATE_TARGET_MAP_ERROR',
  UPDATE_TARGET_MAP_NOTES: 'UPDATE_TARGET_MAP_NOTES',
  DELETE_TARGET_MAP: 'DELETE_TARGET_MAP',
  CREATE_TARGET_MAP_INSTRUMENT: 'CREATE_TARGET_MAP_INSTRUMENT',
  UPDATE_TARGET_MAP_INSTRUMENT: 'UPDATE_TARGET_MAP_INSTRUMENT',
  TOGGLE_IS_SELECTED_TARGET_MAP: 'TOGGLE_IS_SELECTED_TARGET_MAP',
  DELETE_TARGET_MAP_INSTRUMENT: 'DELETE_TARGET_MAP_INSTRUMENT',
  CLONE_TARGETMAP_WANT: 'CLONE_TARGETMAP_WANT',
  UPDATE_TARGET_MAP_INSTRUMENTS_ORDER: 'UPDATE_TARGET_MAP_INSTRUMENTS_ORDER',
  TOGGLE_SHOW_NEW_TARGET_MAP_MODAL: 'TOGGLE_SHOW_NEW_TARGET_MAP_MODAL',
  TOGGLE_SHOW_EDIT_TARGET_MAP_MODAL: 'TOGGLE_SHOW_EDIT_TARGET_MAP_MODAL',
  TOGGLE_SHOW_TARGET_MAP_NOTES_MODAL: 'TOGGLE_SHOW_TARGET_MAP_NOTES_MODAL',
  TOGGLE_SHOW_TARGET_MAP_TEMPLATE_MODAL:
    'TOGGLE_SHOW_TARGET_MAP_TEMPLATE_MODAL',
  TOGGLE_SHOW_DESIRED_CASH_FLOW_MODAL: 'TOGGLE_SHOW_DESIRED_CASH_FLOW_MODAL',
  TOGGLE_SHOW_CUSTOM_CASH_FLOW_MODAL: 'TOGGLE_SHOW_CUSTOM_CASH_FLOW_MODAL',
  TOGGLE_SHOW_CUSTOM_ASSET_MODAL: 'TOGGLE_SHOW_CUSTOM_ASSET_MODAL',
  TOGGLE_SHOW_CUSTOM_INSURANCE_MODAL: 'TOGGLE_SHOW_CUSTOM_INSURANCE_MODAL',
  GET_TARGET_MAP_TEMPLATES: 'GET_TARGET_MAP_TEMPLATES',
  CREATE_TARGET_MAP_TEMPLATE: 'CREATE_TARGET_MAP_TEMPLATE',
  UPDATE_TARGET_MAP_TEMPLATE: 'UPDATE_TARGET_MAP_TEMPLATE',
  DELETE_TARGET_MAP_TEMPLATE: 'DELETE_TARGET_MAP_TEMPLATE',
};

/*
  GENERIC FUNCTION USED TO CHANGE A MODIFY SINGLE VALUE IN TARGETMAP STORE
  - fieldName <string>: field name in redux store to modify
  - value <any>: new value desired for field
*/
export const updateTargetMapStoreValue = (fieldName, value) => ({
  type: TargetMapTypes.UPDATE_TARGET_MAP_STORE_VALUE,
  payload: { fieldName, value },
});

//ACTION TO SET TARGET MAPS LIST IN STORE <array>
export const setTargetMaps = (targetMaps) => ({
  type: TargetMapTypes.GET_TARGET_MAPS,
  payload: targetMaps,
});

/*
  API CALL TO GET TARGET MAPS LIST
  - householdId <string>: id of household
*/
export const getTargetMaps = (householdId) => {
  return apiAction({
    path: `/household/${householdId}/targetmap`,
    method: 'GET',
    onSuccess: setTargetMaps,
    label: TargetMapTypes.GET_TARGET_MAPS,
  });
};

/*
  API CALL TO UPDATE SINGLE TARGET MAP NOTES
  - householdId <string>: id of household
  - targetMaps <array>: list of target maps with new ordering
*/
export const updateTargetMapsOrder = (householdId, targetMaps) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/order`,
    method: 'PUT',
    data: targetMaps,
    passId: householdId,
    successMessage: `Order Updated`,
    onSuccess: getTargetMaps,
    label: TargetMapTypes.UPDATE_TARGET_MAPS_ORDER,
  });
};

//ACTION TO SET TARGET MAP INFO IN STORE <object>
export const setTargetMapInfo = (targetMap) => ({
  type: TargetMapTypes.GET_TARGET_MAP_INFO,
  payload: targetMap,
});

/*
  API CALL TO GET SINGLE TARGET MAP INFO
  - householdId <string>: id of household
  - targetMapId <string>: id of target map
*/
export const getTargetMapInfo = (householdId, targetMapId) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}`,
    method: 'GET',
    onSuccess: setTargetMapInfo,
    label: TargetMapTypes.GET_TARGET_MAP_INFO,
  });
};

//ACTION TO REMOVE TARGET MAP INFO FROM STORE <string>
export const removeTargetMapInfo = () => ({
  type: TargetMapTypes.REMOVE_TARGET_MAP_INFO,
});

//ACTION TO SET TARGET MAP CASHFLOW INFO IN STORE <object>
export const setTargetMapCashFlow = (cashFlow) => ({
  type: TargetMapTypes.GET_TARGET_MAP_CASH_FLOW,
  payload: cashFlow,
});

/*
  API CALL TO GET TARGET MAP CASH FLOW INFO
  - householdId <string>: id of household
  - targetMapId <string>: id of target map
*/
export const getTargetMapCashFlow = (householdId, targetMapId) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}/cashflow`,
    method: 'GET',
    onSuccess: setTargetMapCashFlow,
    label: TargetMapTypes.GET_TARGET_MAP_CASH_FLOW,
  });
};

/*
  API CALL TO CREATE TARGET MAP(S)
  - householdId <string>: id of household
  - targetMaps <object>: contains list of target maps to be created
*/
export const createTargetMap = (householdId, targetMaps) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/template`,
    method: 'POST',
    data: targetMaps,
    passId: householdId,
    successMessage: `Created Target-Map`,
    onSuccess: getTargetMaps,
    label: TargetMapTypes.CREATE_TARGET_MAP,
  });
};

/*
  API CALL TO CLONE SINGLE TARGET MAP
  - householdId <string>: id of household
  - targetMapId <string>: id of target map to be cloned
*/
export const cloneTargetMap = (householdId, targetMapId) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}/copy`,
    method: 'GET',
    onSuccess: getTargetMaps,
    successMessage: `Target-Map Cloned`,
    passId: householdId,
    label: TargetMapTypes.CLONE_TARGET_MAP,
  });
};

//ACTION TO SET UPDATE TARGET MAP ERROR IN STORE
const setUpdateTargetMapError = (household, targetmap, error) => ({
  type: TargetMapTypes.UPDATE_TARGET_MAP_ERROR,
  payload: { household, targetmap, error },
});

/*
  API CALL TO UPDATE SINGLE TARGET MAP
  - householdId <string>: id of household
  - targetMapId <string>: id of target map to be updated
  - targetMap <object>: target map object to be updated
  - showToast <boolean>: used to determine whether to display toast
  - isNotes <boolean>: used to determine label value
*/
export const updateTargetMap = (
  householdId,
  targetMapId,
  targetMap,
  showToast = true,
  isNotes = false
) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}`,
    method: 'PUT',
    data: targetMap,
    passId: householdId,
    passSubId: targetMapId,
    successMessage: showToast ? `Target-Map Updated` : null,
    onSuccess: getTargetMapInfo,
    onFailure: setUpdateTargetMapError,
    label: isNotes
      ? TargetMapTypes.UPDATE_TARGET_MAP_NOTES
      : TargetMapTypes.UPDATE_TARGET_MAP,
  });
};

//ACTION TO SET UPDATE TARGET MAP ERROR IN STORE
const setUpdateTargetMapSummaryError = (household, error) => ({
  type: TargetMapTypes.UPDATE_TARGET_MAP_SUMMARY_ERROR,
  payload: { household, error },
});

/*
  API CALL TO UPDATE TARGET MAP SUMMARY VALUES (PIN/ACTIVE)
  - householdId <string>: id of household
  - targetMapId <string>: id of target map to be updated
  - data <object>: field and value
  - toastDisplay <string>: Success message to display
*/
export const updateTargetMapSummaryValue = (
  householdId,
  targetMapId,
  data,
  toastDisplay
) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}`,
    method: 'PUT',
    data: data,
    passId: householdId,
    successMessage: toastDisplay,
    onSuccess: getTargetMaps,
    onFailure: setUpdateTargetMapSummaryError,
    label: TargetMapTypes.UPDATE_TARGET_MAP_SUMMARY,
  });
};

/*
  API CALL TO UPDATE SINGLE TARGET MAP NOTES
  - householdId <string>: id of household
  - targetMapId <string>: id of target map to be updated
  - targetMap <object>: target map object with notes value to be updated
  - isNotesPage <boolean>: used to determine what to run on success
*/
export const updateTargetMapNotes = (
  householdId,
  targetMapId,
  targetMap,
  isNotesPage = false
) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}`,
    method: 'PUT',
    data: targetMap,
    passId: householdId,
    passSubId: targetMapId,
    successMessage: isNotesPage
      ? 'Target-Map Notes Updated'
      : `Target-Map Updated`,
    onSuccess: isNotesPage ? getHouseholdNotes : getTargetMapInfo,
    label: TargetMapTypes.UPDATE_TARGET_MAP_NOTES,
  });
};

/*
  API CALL TO DELETE SINGLE TARGET MAP
  - householdId <string>: id of household
  - targetMapId <string>: id of target map to be deleted
*/
export const deleteTargetMap = (householdId, targetMapId) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}`,
    method: 'DELETE',
    successMessage: 'Target-Map Deleted',
    passId: householdId,
    onSuccess: getTargetMaps,
    label: TargetMapTypes.DELETE_TARGET_MAP,
  });
};

/*
  API CALL TO CREATE TARGET MAP INSTRUMENT
  - householdId <string>: id of household
  - targetMapId <string>: id of target map
  - type <string>: used for display text and api call (want/income/asset)
  - instrument <object>: content to create instrument
*/
export const createTargetMapInstrument = (
  householdId,
  targetMapId,
  type,
  instrument
) => {
  let displayText = 'Cash Flow';
  type === 'asset' ? (displayText = 'Asset') : (displayText = 'Cash Flow');
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}/${type}`,
    method: 'POST',
    data: instrument,
    passId: householdId,
    passSubId: targetMapId,
    onSuccess: getTargetMapInfo,
    successMessage: `${displayText} Created`,
    label: TargetMapTypes.CREATE_TARGET_MAP_INSTRUMENT,
  });
};

/*
  API CALL TO UPDATE TARGET MAP INSTRUMENT
  - householdId <string>: id of household
  - targetMapId <string>: id of target map
  - type <string>: used for display text and api call (want/income/asset/insurance)
  - instrumentId <string>: if of target map instrument to be updated
  - instrument <object>: content to create instrument
*/
export const updateTargetMapInstrument = (
  householdId,
  targetMapId,
  type,
  instrumentId,
  instrument
) => {
  let displayText = 'Cash Flow';
  type === 'asset'
    ? (displayText = 'Asset')
    : type === 'insurance'
      ? (displayText = 'Insurance')
      : (displayText = 'Cash Flow');
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}/${type}/${instrumentId}`,
    method: 'PUT',
    data: instrument,
    passId: householdId,
    passSubId: targetMapId,
    onSuccess: getTargetMapInfo,
    successMessage: `${displayText} Updated`,
    label: TargetMapTypes.UPDATE_TARGET_MAP_INSTRUMENT,
  });
};

/*
  API CALL TO TOGGLE SELECT OF TARGET MAP INSTRUMENT
  - householdId <string>: id of household
  - targetMapId <string>: id of target map
  - type <string>: instrument type (want/income/asset/insurance)
  - instrument <object>: object of instrument to be toggled
*/
export const toggleIsSelectedTargetMap = (
  householdId,
  targetMapId,
  type,
  instrument
) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}/${type}/${instrument.id}`,
    method: 'PUT',
    data: { is_active: !instrument.is_active },
    passId: householdId,
    passSubId: targetMapId,
    onSuccess: getTargetMapInfo,
    label: TargetMapTypes.TOGGLE_IS_SELECTED_TARGET_MAP,
  });
};

/*
  API CALL TO DELETE TARGET MAP INSTRUMENT
  - householdId <string>: id of household
  - targetMapId <string>: id of target map
  - type <string>: instrument type (want/income/asset/insurance)
  - instrumentId <string>: id of instrument to be deleted
*/
export const deleteTargetMapInstrument = (
  householdId,
  targetMapId,
  type,
  instrumentId
) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}/${type}/${instrumentId}`,
    method: 'DELETE',
    passId: householdId,
    passSubId: targetMapId,
    onSuccess: getTargetMapInfo,
    successMessage: 'Item Deleted',
    label: TargetMapTypes.DELETE_TARGET_MAP_INSTRUMENT,
  });
};

/*
  API CALL TO UPDATE ORDER OF TARGET MAP INSTRUMENTS (WANTS/HAVES)
  - householdId <string>: id of household
  - targetMapId <string>: id of target map to be updated
  - type <string>: type of list to update (wants, incomes, assets, insurances)
  - list <array>: list of targetmap instruments with new order

*/
export const updateTargetInstrumentsOrder = (
  householdId,
  targetMapId,
  type = 'wants',
  list
) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}/${type}/order`,
    method: 'PUT',
    data: list,
    passId: householdId,
    passSubId: targetMapId,
    successMessage: `Order Updated`,
    onSuccess: getTargetMapInfo,
    label: TargetMapTypes.UPDATE_TARGET_MAP_INSTRUMENTS_ORDER,
  });
};

/*
  ACTION TO SET TOGGLE DISPLAY OF NEW TARGET MAP MODAL
  - showModal <boolean>: whether to show modal or not
  - targetMapType <string>: optional, if passed will auto select that category in modal
  - isRedirect <boolean>: optional, if true will redirect to newly created target-map
*/
export const toggleShowNewTargetMapModal = (
  showModal,
  targetMapType = null,
  isRedirect = true
) => {
  return {
    type: TargetMapTypes.TOGGLE_SHOW_NEW_TARGET_MAP_MODAL,
    payload: { showModal, targetMapType, isRedirect },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF EDIT TARGET MAP MODAL
  - showModal <boolean>: whether to show modal or not
*/
export const toggleShowEditTargetMapModal = (showModal) => {
  return {
    type: TargetMapTypes.TOGGLE_SHOW_EDIT_TARGET_MAP_MODAL,
    payload: showModal,
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF TARGET MAP NOTES MODAL
  - showModal <boolean>: whether to show modal or not
  - isNotesPage <boolean>: whether being displayed on notes page or target map
  - notes <string>: option passing of notes if pre fetched
*/
export const toggleShowTargetMapNotes = (
  showModal,
  isNotesPage = false,
  notes = null
) => {
  return {
    type: TargetMapTypes.TOGGLE_SHOW_TARGET_MAP_NOTES_MODAL,
    payload: { showModal, isNotesPage, notes },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF TARGET MAP (NEW/EDIT) TEMPLATE MODAL
  - showModal <boolean>: whether to show modal or not
  - isNew <boolean>: whether creating a new template or editing existing
  - templateRules <array>: list of template rules from tm or API
  - existingTemplate <object>: existing template object (name/id) used for modal and API call
*/
export const toggleShowTargetMapTemplateModal = (
  showModal,
  isNew = true,
  templateRules = [],
  existingTemplate = null
) => {
  return {
    type: TargetMapTypes.TOGGLE_SHOW_TARGET_MAP_TEMPLATE_MODAL,
    payload: { showModal, isNew, templateRules, existingTemplate },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF TARGET MAP DESIRED CASH FLOW MODAL
  - showModal <boolean>: whether to show modal or not
  - newInstrument <boolean>: whether modal or for new or existing instrument
*/
export const toggleShowDesiredCashFlowModal = (
  showModal,
  newInstrument = false
) => {
  return {
    type: TargetMapTypes.TOGGLE_SHOW_DESIRED_CASH_FLOW_MODAL,
    payload: { showModal: showModal, newInstrument: !newInstrument },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF TARGET MAP CUSTOM CASH FLOW MODAL
  - showModal <boolean>: whether to show modal or not
  - newInstrument <boolean>: whether modal or for new or existing instrument
*/
export const toggleShowCustomCashFlowModal = (
  showModal,
  newInstrument = false
) => {
  return {
    type: TargetMapTypes.TOGGLE_SHOW_CUSTOM_CASH_FLOW_MODAL,
    payload: { showModal: showModal, newInstrument: !newInstrument },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF TARGET MAP CUSTOM ASSET MODAL
  - showModal <boolean>: whether to show modal or not
  - newInstrument <boolean>: whether modal or for new or existing instrument
*/
export const toggleShowCustomAssetModal = (
  showModal,
  newInstrument = false
) => {
  return {
    type: TargetMapTypes.TOGGLE_SHOW_CUSTOM_ASSET_MODAL,
    payload: { showModal: showModal, newInstrument: !newInstrument },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF TARGET MAP CUSTOM INSURANCE MODAL
  - showModal <boolean>: whether to show modal or not
  - newInstrument <boolean>: whether modal or for new or existing instrument
*/
export const toggleShowCustomInsuranceModal = (
  showModal,
  newInstrument = false
) => {
  return {
    type: TargetMapTypes.TOGGLE_SHOW_CUSTOM_INSURANCE_MODAL,
    payload: { showModal: showModal, newInstrument: !newInstrument },
  };
};

/*
  ACTION TO CLONE A TARGETMAP WANT ON A GIVEN TARGETMAP
  - householdId <ampid>: id of household for targetmap
  - targetMapId <ampid>: id of targetmap
  - tmWantId <uuid>  : uuid of targetmap want to clone
*/
export const cloneTargetMapWant = (householdId, targetMapId, tmWantId) => {
  return apiAction({
    path: `/household/${householdId}/targetmap/${targetMapId}/want/${tmWantId}/clone`,
    method: 'PUT',
    passId: householdId,
    passSubId: targetMapId,
    onSuccess: getTargetMapInfo,
    successMessage: 'Want Cloned',
    errorMessage: 'Error Cloning',
    label: TargetMapTypes.CLONE_TARGETMAP_WANT,
  });
};

//ACTION TO SET TARGET MAP TEMPLATES IN STORE <object>
export const setTargetMapTemplates = (templates) => ({
  type: TargetMapTypes.GET_TARGET_MAP_TEMPLATES,
  payload: templates,
});

//API CALL TO GET EXISTING TARGET MAP TEMPLATES
export const getTargetMapTemplates = () => {
  return apiAction({
    path: `/member/layouts/targetmap/template`,
    method: 'GET',
    onSuccess: setTargetMapTemplates,
    label: TargetMapTypes.GET_TARGET_MAP_TEMPLATES,
  });
};

/*
  API CALL TO CREATE NEW TARGET MAP TEMPLATE
  - data <object>: data needed to create new template
*/
export const createTargetMapTemplate = (data) => {
  return apiAction({
    path: `/member/layouts/targetmap/template`,
    method: 'POST',
    data: data,
    successMessage: `Template Created`,
    onSuccess: getTargetMapTemplates,
    label: TargetMapTypes.CREATE_TARGET_MAP_TEMPLATE,
  });
};

/*
  API CALL TO UPDATE TARGET MAP TEMPLATE
  - templateId <string>: id of template to delete
  - data <object>: data needed to update existing template
*/
export const updateTargetMapTemplate = (templateId, data) => {
  return apiAction({
    path: `/member/layouts/targetmap/template/${templateId}`,
    method: 'PUT',
    data: data,
    successMessage: `Template Updated`,
    onSuccess: getTargetMapTemplates,
    label: TargetMapTypes.UPDATE_TARGET_MAP_TEMPLATE,
  });
};

/*
  API CALL TO DELETE TARGET MAP TEMPLATE
  - templateId <string>: id of template to delete
*/
export const deleteTargetMapTemplate = (templateId) => {
  return apiAction({
    path: `/member/layouts/targetmap/template/${templateId}`,
    method: 'DELETE',
    successMessage: `Template Deleted`,
    onSuccess: getTargetMapTemplates,
    label: TargetMapTypes.DELETE_TARGET_MAP_TEMPLATE,
  });
};
