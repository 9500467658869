/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button,
  SimpleModal,
  Select,
  SmallTable,
  SalesforceSearchModal,
} from '../../../components';
import {
  searchSalesforceIntegration,
  alignHouseholdSalesforce,
} from '../../../store/actions';
import { ButtonThemes } from '../../../styles/themes';
import { inputColors, fonts } from '../../../styles/variables';
import {
  GenerateEntityLabel,
  TransformIntegrationMembers,
  TransformSalesforceSearchResults,
} from '../../../utils';

const HouseholdSalesforceAlign = ({ householdId, selectedIntegration }) => {
  const dispatch = useDispatch();
  const {
    currentHousehold,
    allMemberCategories,
    loadedHouseholdFields,
    linkedMembers,
    integrationResults,
    integrationResultsError,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    allMemberCategories: state.configs.allMemberCategories,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    linkedMembers: state.households.linkedMembers,
    integrationResults: state.households.integrationResults,
    integrationResultsError: state.households.integrationResultsError,
  }));
  const [alignMembersList, setAlignMembersList] = useState([]);
  const [activityType, setActivityType] = useState({
    label: 'Asset-Map - In person',
    value: 'person',
  });
  const [memberToAlign, setMemberToAlign] = useState();
  const [showSalesforceSearchModal, setShowSalesforceSearchModal] =
    useState(false);
  const [searchUrl, setSearchUrl] = useState('');
  const [salesforceSearchType, setSalesforceSearchType] = useState('member');
  const [salesforceSearchValue, setSalesforceSearchValue] = useState('');
  const [missingSalesforceSearchValue, setMissingSalesforceSearchValue] =
    useState(false);
  const [salesforceSearchFirstName, setSalesforceSearchFirstName] =
    useState('');
  const [missingSalesforceFirstName, setMissingSalesforceFirstName] =
    useState(false);
  const [salesforceSearchLastName, setSalesforceSearchLastName] = useState('');
  const [missingSalesforceLastName, setMissingSalesforceLastName] =
    useState(false);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [results, setResults] = useState([]);
  const [searchError, setSearchError] = useState(false);
  const [showAlignModal, setShowAlignModal] = useState(false);
  const [isAligningHousehold, setIsAligningHousehold] = useState(false);

  const alignMembersTable = {
    columnHeadings: [
      { text: 'Asset-Map Role', display: true },
      { text: 'Asset-Map Name', display: true },
      { text: 'Salesforce Action', display: true },
      { text: 'Actions', display: false },
    ],
  };
  const activityTypes = [
    { label: 'Asset-Map - In person', value: 'inperson' },
    { label: 'Asset-Map - Webinar', value: 'web' },
  ];

  //HOOK TO DISPLAY LIST OF MEMBERS TO ALIGN
  useEffect(() => {
    if (
      linkedMembers &&
      currentHousehold.householdmembers &&
      loadedHouseholdFields
    ) {
      const transformedUnlinkedMembers = TransformIntegrationMembers(
        linkedMembers.unlinked_members,
        currentHousehold.householdmembers
      );
      const transformedAlignMembers = transformedUnlinkedMembers.map((mem) => {
        mem.action =
          mem.member_type === 'member' ? 'Contact Record' : 'Entity Record';
        mem.category = GenerateEntityLabel(mem.category, allMemberCategories);
        mem.linked = false;
        return mem;
      });
      setAlignMembersList([
        {
          category: 'Household',
          name: currentHousehold.name,
          action: 'Relationship Group',
          member_type: 'household',
          linked: false,
        },
        ...transformedAlignMembers,
      ]);

      setSearchUrl(linkedMembers.search_url);
      setIsAligningHousehold(false);
    }
  }, [linkedMembers, currentHousehold.householdmembers, loadedHouseholdFields]);

  //HOOK TO TRANSFORM SALESFORCE SEARCH RESULTS
  useEffect(() => {
    if (integrationResults) {
      let results = integrationResults.results;
      results = TransformSalesforceSearchResults(
        results,
        currentHousehold.householdmembers
      );
      setResults(results);
      setIsLoadingResults(false);
      setSearchError('');
    }
  }, [integrationResults]);

  //STOP LOADING IF SEARCH RESULTS ERROR
  useEffect(() => {
    if (integrationResultsError) {
      setIsLoadingResults(false);
    }
  }, [integrationResultsError]);

  //FUNCTION WHEN USER CLICKS ON SEARCH ICON
  const salesForceEditFunction = (e) => {
    setShowSalesforceSearchModal(true);
    setIsLoadingResults(true);
    setResults([]);
    setMemberToAlign(e);
    setSalesforceSearchType(e.member_type);
    if (e.member_type === 'member') {
      setSalesforceSearchFirstName(e.first_name);
      setSalesforceSearchLastName(e.last_name);
    } else {
      setSalesforceSearchValue(e.name);
    }
  };

  //FUNCTION WHEN USER CLICKS ON CLEAR ICON
  const salesForceResetFunction = (e) => {
    setAlignMembersList(
      alignMembersList.map((member) => {
        if (member.name === e.name) {
          member.linked = false;
          member.linked_member = null;
        }
        return member;
      })
    );
  };

  //FUNCTION THAT SEARCHES SALESFORCE FOR VALUE FROM INPUT
  const searchSalesforceAlign = () => {
    let hasError = false;
    if (salesforceSearchType === 'member') {
      if (salesforceSearchFirstName === '') {
        setMissingSalesforceFirstName(true);
        hasError = true;
      }
      if (salesforceSearchLastName === '') {
        setMissingSalesforceLastName(true);
        hasError = true;
      }
    } else {
      if (salesforceSearchValue === '') {
        setMissingSalesforceSearchValue(true);
        hasError = true;
      }
    }
    if (hasError) {
      return;
    }
    setIsLoadingResults(true);
    let data = { searchValue: salesforceSearchValue };
    if (salesforceSearchType === 'member') {
      data = {
        firstName: salesforceSearchFirstName,
        lastName: salesforceSearchLastName,
      };
    }
    dispatch(
      searchSalesforceIntegration(searchUrl, salesforceSearchType, data)
    );
  };

  //FUNCTION THAT UPDATES SEARCH VALUE FOR MODAL INPUTS
  const alignInputOnChange = (e, setter) => {
    setMissingSalesforceFirstName(false);
    setMissingSalesforceLastName(false);
    setMissingSalesforceSearchValue(false);
    return setter(e.target.value);
  };

  //FUNCTION THAT SETS SELECTED MEMBER FROM SEARCH RESULT AND MEMBER TO ALIGN WITH
  const selectAlignSearchResult = (e) => {
    setShowSalesforceSearchModal(false);
    setAlignMembersList(
      alignMembersList.map((member) => {
        if (member.name === memberToAlign.name) {
          member.linked = true;
          member.linked_member = e;
        }
        return member;
      })
    );
  };

  //IF EVERY MEMBER HAS BEEN MATCHED IT WILL ALIGN ENTIRE HOUSEHOLD
  //IF ONLY PARTIAL MATCH IT WILL DISPLAY CONFIRMATION MODAL TO DO PARTIAL
  const alignHousehold = () => {
    if (isAligningHousehold) {
      return;
    }
    let allAligned = alignMembersList.every((mem) => mem.linked);
    if (allAligned) {
      const salesforceHouseholdId = alignMembersList.find(
        (mem) => mem.member_type === 'household'
      );
      let memberList = alignMembersList.filter(
        (mem) => mem.member_type !== 'household'
      );
      memberList = memberList.map((mem) => {
        return {
          hash: mem.id,
          new: false,
          sfid: mem.linked_member.id,
          priority: 1,
          type: mem.member_type,
        };
      });
      let data = {
        household: {
          hash: householdId,
          new: false,
          sfid: salesforceHouseholdId.linked_member.id,
        },
        members: memberList,
        activity_type: activityType.value,
      };
      setIsAligningHousehold(true);
      return dispatch(alignHouseholdSalesforce(householdId, data));
    } else {
      return setShowAlignModal(true);
    }
  };

  //FUNCTION TO ALIGN PARTIAL HOUSEHOLD WITH SALESFORCE
  const alignPartialHousehold = (linkedHousehold) => {
    let memberList = alignMembersList.filter(
      (mem) => mem.member_type !== 'household'
    );
    memberList = memberList.map((mem) => {
      return {
        hash: mem.id,
        new: !mem.linked,
        sfid: mem.linked ? mem.linked_member.id : '',
        priority: 1,
        type: mem.member_type,
      };
    });
    let data = {
      household: {
        hash: householdId,
        new: !linkedHousehold.linked,
        sfid: linkedHousehold.linked ? linkedHousehold.linked_member.id : '',
      },
      members: memberList,
      activity_type: activityType.value,
    };
    setShowAlignModal(false);
    setIsAligningHousehold(true);
    return dispatch(alignHouseholdSalesforce(householdId, data));
  };

  //FUNCTION TO CREATE CONTENT FOR CONFIRMATION OF PARTIAL ALIGN MODAL
  const generateAlignModalContent = () => {
    const linkedHousehold = alignMembersList.find(
      (mem) => mem.member_type === 'household'
    );
    const hasLinkedHousehold = linkedHousehold.linked;

    const members = alignMembersList.filter(
      (mem) => mem.member_type === 'member'
    );
    const linkedMembers = members.filter((mem) => !mem.linked);
    const entities = alignMembersList.filter(
      (mem) => mem.member_type === 'entity'
    );
    const linkedEntities = entities.filter((mem) => !mem.linked);
    return {
      heading: `Align Household`,
      text: `<p style="text-align:left; margin-bottom: 5px">By aligning this household, new records will be created in Salesforce:</p>
      <ul style="text-align:left;list-style: disc;margin-left: 30px;">
        ${!hasLinkedHousehold ? `<li>1 Relationship Group</li>` : ''}
        ${
          linkedMembers.length !== 0
            ? `<li>${linkedMembers.length} Contact Record${
                linkedMembers.length === 1 ? '' : 's'
              }</li>`
            : ''
        }
        ${
          linkedEntities.length !== 0
            ? `<li>
              ${linkedEntities.length} Entity Record
              ${linkedEntities.length === 1 ? '' : 's'}
            </li>`
            : ''
        }
      </ul>
     `,
      minWidth: '380px',
      afterText: 'Are you sure you want to do this?',
      buttons: [
        {
          text: 'Cancel',
          function: () => setShowAlignModal(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes, Align Household',
          function: () => alignPartialHousehold(linkedHousehold),
          theme: ButtonThemes.primary,
        },
      ],
    };
  };

  return (
    <>
      {showAlignModal && (
        <SimpleModal
          hide={() => setShowAlignModal(false)}
          content={generateAlignModalContent()}
        />
      )}
      {showSalesforceSearchModal && (
        <SalesforceSearchModal
          hide={() => setShowSalesforceSearchModal(false)}
          searchType={salesforceSearchType}
          searchValue={salesforceSearchValue}
          missingSearchValue={missingSalesforceSearchValue}
          searchValueOnChange={(e) =>
            alignInputOnChange(e, setSalesforceSearchValue)
          }
          searchFirstName={salesforceSearchFirstName}
          missingFirstName={missingSalesforceFirstName}
          searchFirstNameOnChange={(e) =>
            alignInputOnChange(e, setSalesforceSearchFirstName)
          }
          searchLastName={salesforceSearchLastName}
          missingLastName={missingSalesforceLastName}
          searchLastNameOnChange={(e) =>
            alignInputOnChange(e, setSalesforceSearchLastName)
          }
          onEnter={() => searchSalesforceAlign()}
          results={results}
          selectResult={(e) => selectAlignSearchResult(e)}
          error={searchError}
          updateError={setSearchError}
          isLoadingResults={isLoadingResults}
        />
      )}
      <>
        <AlignHeadingContainer>
          <h3>Align Household with Salesforce</h3>
          <p>
            This page allows you to align an <strong>existing</strong> Asset-Map
            Household with Salesforce in a single step. By default, the
            household and members are set to create new entries in your
            Salesforce account. You can choose to search and associate each
            entry with a pre-existing Salesforce record instead.
          </p>
        </AlignHeadingContainer>
        <SmallTable
          tableHeading={''}
          columnHeadings={alignMembersTable.columnHeadings}
          type={'align'}
          items={alignMembersList}
          deleteFunction={(e) => salesForceResetFunction(e)}
          editFunction={(e) => salesForceEditFunction(e)}
        />
        <ActivityTypeContainer>
          <SelectContainer>
            <InputLabel>Activity Type</InputLabel>
            <Select
              selectedOption={activityType}
              onChange={(e) => setActivityType(e)}
              options={activityTypes}
              isSearchable={true}
              loaded={true}
              placeholder={'Select Activity Type'}
              closeMenuOnSelect={true}
              autoFocus={false}
            />
          </SelectContainer>
        </ActivityTypeContainer>
        <ButtonContainer>
          <Button
            text={'Align Household with Salesforce'}
            onClick={() => alignHousehold()}
            loadingText={'Aligning with Salesforce'}
            showLoading={isAligningHousehold}
          />
        </ButtonContainer>
      </>
    </>
  );
};

const InputLabel = styled.label`
  margin: 0 0 5px 0;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
`;

const SelectContainer = styled.div`
  margin: 10px 0 5px 0;
  display: flex;
  text-align: left;
  flex-direction: column;
  max-width: 500px;
  ${InputLabel} {
    margin: 0 0 5px 0;
  }
  div {
    div {
      div {
        font-size: 13px;
      }
    }
  }
`;

const AlignHeadingContainer = styled.div`
  margin: -10px 0 20px 0;
  max-width: 900px;
  h3 {
    font-size: 16px;
    font-weight: ${fonts.semiBold};
    margin-bottom: 10px;
  }
  p {
    font-size: 12px;
  }
`;

const ActivityTypeContainer = styled.div`
  margin-top: -20px;
  width: 250px;
`;

const ButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

HouseholdSalesforceAlign.propTypes = {
  householdId: PropTypes.string,
  selectedIntegration: PropTypes.object,
  setShowUnlinkModal: PropTypes.func,
};

export default HouseholdSalesforceAlign;
