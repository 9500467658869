/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { darken } from 'polished';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import { SortOrder } from 'react-base-table';
import {
  FilterModal,
  LoadingAnimation,
  Pagination,
  Select,
  SimpleModal,
  Table,
} from '../../components';
import {
  getHouseholdIndex,
  getUserGroups,
  getHouseholdIndexLayouts,
  updateHouseholdIndexLayout,
  deleteHouseholdIndexLayout,
  setDefaultHouseholdIndexLayout,
  createHouseholdIndexLayout,
  getHouseholdIndexCSV,
  clearHouseholdIndexCSV,
} from '../../store/actions';
import {
  chevronDown,
  filterSolid,
  link,
  noHouseholds,
  search,
  times,
} from '../../assets';
import { Page } from '../../styles/library/layoutStyles';
import {
  ClickContainer,
  ClickText,
  ClickImage,
  DropdownContainer,
  DropdownHeading,
  DropdownContent,
  DropdownItem,
} from '../../styles/library/dropdownStyles';
import {
  colors,
  fonts,
  inputColors,
  messageColors,
} from '../../styles/variables';
import { selectThemes, ButtonThemes } from '../../styles/themes';
import {
  TransformSelectOptions,
  FormatCurrency,
  UseOutsideClick,
  standardHHIColumns,
  systemDefaultHHILayout,
  signalsHHILayout,
  idColumnHHI,
  DetermineOptions,
  DetermineTextAlign,
  SendTrackingEvent,
  ErrorNotification,
  LayoutOption,
  ColumnOption,
  integrationOptions,
  SuccessNotificationClick,
  LoadingNotification,
  GenerateSignalColorIcon,
  DetectEnterKeyPress,
  DateFromNowUTC,
} from '../../utils';
import { PrimaryHeading } from '../../styles/library/fontStyles';

const HouseholdIndexContainer = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const {
    householdIndex,
    householdResults,
    groups,
    householdLayouts,
    loadedHouseholdLayouts,
    householdIndexError,
    indexFields,
    canExportIndex,
    showSignalsPlus,
    preferences,
    householdStartPage,
    configs,
    householdIndexCSV,
    invocationId,
    justCreatedLayout,
    layoutId,
    limit,
  } = useSelector((state) => ({
    householdIndex: state.households.householdIndex,
    householdResults: state.households.householdResults,
    groups: state.user.groups,
    householdLayouts: state.households.householdLayouts,
    loadedHouseholdLayouts: state.households.loadedHouseholdLayouts,
    householdIndexError: state.households.householdIndexError,
    indexFields: state.configs.indexFields,
    canExportIndex: state.user.canExportIndex,
    showSignalsPlus: state.user.showSignalsPlus,
    preferences: state.user.user.preferences,
    householdStartPage: state.user.householdStartPage,
    configs: state.configs,
    householdIndexCSV: state.households.householdIndexCSV,
    invocationId: state.configs.invocationId,
    justCreatedLayout: state.households.justCreatedLayout,
    layoutId: state.households.layoutId,
    limit: state.households.householdIndex?.limit || 100,
  }));
  const [households, setHouseholds] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filterColumnOptions, setFilterColumnOptions] = useState([]);
  const [columnDropdownList, setColumnDropdownList] = useState([]);
  const [loadedColumns, setLoadedColumns] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [transformedColumns, setTransformedColumns] = useState([]);
  const [resetVisibleColumns, setResetVisibleColumns] = useState(false);
  const [layouts, setLayouts] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState({});
  const [showFilterBar, setShowFilterBar] = useState(false);
  const [currentFilters, setCurrentFilters] = useState();
  const [modalFilterOptions, setModalFilterOptions] = useState([]);
  const [filterError, setFilterError] = useState('');
  const [selectedGroup, setSelectedGroup] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [hasSearchValue, setHasSearchValue] = useState(false);
  const [sortObj, setSortObj] = useState({});
  const [sortSettings, setSortSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [loadingHouseholdCSV, setLoadingHouseholdCSV] = useState(false);
  const [currency, setCurrency] = useState();
  const [showSaveMenu, setShowSaveMenu] = useState(false);
  const [editingLayout, setEditingLayout] = useState();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showNewLayoutModal, setShowNewLayoutModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditLayoutModal, setShowEditLayoutModal] = useState(false);
  const [editingLayoutName, setEditingLayoutName] = useState();
  const [editingLayoutIsDefault, setEditingLayoutIsDefault] = useState(false);
  const [newLayoutName, setNewLayoutName] = useState('');
  const [saveLayoutError, setSaveLayoutError] = useState('');
  const [editLayoutError, setEditLayoutError] = useState('');
  const [layoutNameFieldError, setLayoutNameFieldError] = useState(false);
  const [editNameFieldError, setEditNameFieldError] = useState(false);
  const [newLayoutIsDefault, setNewLayoutIsDefault] = useState(false);
  const [justSavedLayout, setJustSavedLayout] = useState(false);
  const [loadingToast, setLoadingToast] = useState();
  const [containerStyle, setContainerStyle] = useState({
    width: 'calc(100vw - 65px)',
    height: 'calc(100vh - 208px)',
    margin: ' 0 30px',
  });
  const [offset, setOffset] = useState(0);
  const [changedOffset, setChangedOffset] = useState(false);
  const hiddenDisplayColumns = [
    'name',
    'household__instruments__name',
    'household__instruments__source',
  ];
  const useSelectKeys = [
    'status',
    'signal_ltc_state',
    'signal_ltd_state',
    'signal_liability_state',
    'signal_liquidity_state',
    'signal_longevity_state',
    'signal_life_state',
  ];

  //HIDES SAVE DROPDOWN MENU ON CLICK OUTSIDE
  UseOutsideClick(ref, () => {
    if (showSaveMenu) {
      setShowSaveMenu(!showSaveMenu);
    }
  });

  // MAKES API CALL TO GET USER GROUPS & LAYOUTS
  useEffect(() => {
    dispatch(getUserGroups());
    dispatch(getHouseholdIndexLayouts());
  }, []);

  useEffect(() => {
    setSaveLayoutError('');
  }, [newLayoutName]);

  useEffect(() => {
    if (justCreatedLayout && layoutId) {
      SendTrackingEvent(
        invocationId,
        'action',
        'layout_create',
        'household_index',
        { layout: layoutId }
      );
    }
  }, [justCreatedLayout]);

  const onClickToast = (csv) => {
    window.open(csv);
    setLoadingToast();
    dispatch(clearHouseholdIndexCSV());
  };

  useEffect(() => {
    if (householdIndexCSV && loadingToast) {
      setLoadingHouseholdCSV(false);
      loadingToast.then(() => {
        return SuccessNotificationClick('Click to Download File', () =>
          onClickToast(householdIndexCSV)
        );
      });
    }
  }, [householdIndexCSV]);

  useEffect(() => {
    if (loadingHouseholdCSV) {
      setLoadingToast(LoadingNotification('Creating File', 3));
    }
  }, [loadingHouseholdCSV]);

  // DETERMINES SELECTED LAYOUT ONCE LAYOUT VALUES ARE LOADED
  useEffect(() => {
    if (loadedHouseholdLayouts && showSignalsPlus !== null) {
      const signalsLayout = showSignalsPlus ? [signalsHHILayout] : [];
      if (householdLayouts && householdLayouts.length !== 0) {
        let updatedLayouts = householdLayouts.map((layout) => {
          layout.data.visibleColumns = [...layout.data.columns];
          return layout;
        });
        if (layoutId) {
          const matchingLayout = updatedLayouts.find(
            (layout) => layout.id === layoutId
          );
          if (matchingLayout) {
            setSelectedLayout(matchingLayout);
            setLayouts([
              systemDefaultHHILayout,
              ...signalsLayout,
              ...updatedLayouts,
            ]);
            return;
          }
        }
        if (!justSavedLayout) {
          let defaultLayout = householdLayouts.find((layout) => {
            return layout.is_default;
          });
          if (defaultLayout !== undefined) {
            defaultLayout.data.visibleColumns = [...defaultLayout.data.columns];
            setSelectedLayout(defaultLayout);
            systemDefaultHHILayout.is_default = false;
          } else {
            // let copiedColumns = [...householdLayouts[0].data.columns];
            // householdLayouts[0].data.visibleColumns = copiedColumns;
            // setSelectedLayout(householdLayouts[0]);
            systemDefaultHHILayout.is_default = true;
            setSelectedLayout(systemDefaultHHILayout);
          }
        }
        setLayouts([
          systemDefaultHHILayout,
          ...signalsLayout,
          ...updatedLayouts,
        ]);
      } else if (
        householdLayouts === undefined ||
        householdLayouts.length === 0
      ) {
        setLayouts([systemDefaultHHILayout, ...signalsLayout]);
        setSelectedLayout(systemDefaultHHILayout);
      }
    }
  }, [householdLayouts, loadedHouseholdLayouts, layoutId, showSignalsPlus]);

  // SETS SELECTED GROUP TO VALUE FROM SELECTED LAYOUT
  useEffect(() => {
    if (groups && groups.length !== 0) {
      let matched = groups.find((group) => {
        if (selectedLayout.hasOwnProperty('data')) {
          return group.value === selectedLayout.data.group;
        }
      });
      if (matched !== undefined) {
        setSelectedGroup(matched);
      } else {
        setSelectedGroup({});
      }
    }
  }, [groups, selectedLayout.name]);

  // FORMATS COLUMNS OPTIONS FOR DISPLAY AND SELECT DROPDOWN
  useEffect(() => {
    if (indexFields && indexFields.length !== 0 && householdResults) {
      const transformedColumns = indexFields.reduce((acc, col) => {
        const column = { ...col };
        if (column.display) {
          const shortTitleLength = column.short.length;
          column.style = {
            justifyContent: DetermineTextAlign(column.align),
            borderRight: `1px solid ${colors.lightGrey}`,
          };
          let minWidth = 50;
          let width = 200;
          switch (column.format) {
            case 'textFormatter':
              minWidth = 120;
              break;
            case 'dateFormatter':
              minWidth = 100;
              break;
            case 'currencyFormatter':
              minWidth = 80;
              break;
            case 'linkedFormatter':
              minWidth = 40;
              width = 90;
              break;
            default:
              minWidth = 80;
          }
          if (shortTitleLength >= 27) {
            minWidth = 165;
          } else if (shortTitleLength >= 23) {
            minWidth = 140;
          } else if (shortTitleLength >= 20) {
            minWidth = 128;
          } else if (shortTitleLength >= 18) {
            minWidth = 110;
          } else if (shortTitleLength >= 16) {
            minWidth = 100;
          } else if (shortTitleLength >= 15) {
            minWidth = 90;
          }
          column.minWidth = minWidth;
          column.value = column.fieldName;
          column.sortable = true;
          column.width = width;
          column.resizable = true;
          column.title = column.short;
          column.key = column.fieldName;
          column.dataKey = column.fieldName;
          column.visible = false;
          if (column.fieldName === 'name') {
            column.width = 550;
            column.minWidth = 150;
            column.dataKey = 'link';
            column.style = {
              justifyContent: DetermineTextAlign(column.align),
              borderRight: `1px solid ${colors.lightGrey}`,
              paddingLeft: '15px',
            };
          }
          if (hiddenDisplayColumns.includes(column.fieldName)) {
            column.hideDropdown = true;
          }
          return [...acc, column];
        }
        return acc;
      }, []);

      setColumns([idColumnHHI, ...transformedColumns]);
      let filtered = filterColumns([idColumnHHI, ...transformedColumns]);
      setSelectedColumns(filtered);
      setLoadedColumns(true);
    }
  }, [indexFields, householdResults]);

  const generateFilterOptions = (layout) => {
    if (layout.data.filter.hasOwnProperty('rules')) {
      layout.data.filter.rules.map((rule) => {
        // let matchedColumn = [...columns, ...additionalFilterOptions].find(
        let matchedColumn = columns.find((col) => col.fieldName === rule.field);
        rule.id = rule.field;
        rule.name = rule.field;
        rule.type = 'filter';
        rule.conjunction = 'and';
        if (matchedColumn) {
          let matchedOptions = DetermineOptions(matchedColumn.format);
          rule.columnName = matchedColumn.short;
          rule.selectedOption = matchedOptions.find(
            (option) => option.value === rule.operator
          );
          rule.options = matchedOptions;
        } else {
          return ErrorNotification(
            `Filter Error: Incorrect data format, please delete filters and save.`,
            5
          );
        }
      });
      return setCurrentFilters(selectedLayout?.data?.filter?.rules);
    } else {
      return setCurrentFilters([]);
    }
  };

  // MAKES API CALL ONCE SELECTED LAYOUT IS LOADED OR CHANGED
  useEffect(() => {
    if (
      selectedLayout.name &&
      selectedLayout.hasOwnProperty('data') &&
      loadedColumns
    ) {
      setSortSettings(selectedLayout.data.sort);
      resetOffset();
      generateFilterOptions(selectedLayout);
      // if (!gotSorted) {
      //   console.log('INSIDE NOT GOT SORTED API CALL!! SHOULD ONLY RUN ON LOAD');
      //   dispatch(
      //     getHouseholdIndex(
      //       selectedLayout.data.group,
      //       200,
      //       0,
      //       generateFilterQuery(),
      //       sortSettings
      //     )
      //   );
      // }
    }
  }, [selectedLayout.name, loadedColumns]);

  // WHEN SORT SETTINGS IS UPDATED IT WILL MAKE SORT API CALL
  useEffect(() => {
    if (sortSettings && sortSettings.length !== 0) {
      generateSortObject();
    }
  }, [sortSettings]);

  // const additionalFilterOptions = [
  //   {
  //     align: 'right',
  //     category: null,
  //     display: true,
  //     format: 'textFormatter',
  //     label: 'Reference Name',
  //     priority: 100,
  //     short: 'Ref Name',
  //     sort: false,
  //     type: 'text',
  //     visible: false,
  //     key: 'instrument_name',
  //     value: 'instrument_name',
  //     id: 'instrument_name',
  //     fieldName: 'instrument_name',
  //   },
  //   {
  //     align: 'right',
  //     category: null,
  //     display: true,
  //     format: 'textFormatter',
  //     label: 'Location/Source',
  //     priority: 100,
  //     short: 'Loc/Source',
  //     sort: false,
  //     type: 'text',
  //     visible: false,
  //     key: 'instrument_source',
  //     value: 'instrument_source',
  //     id: 'instrument_source',
  //     fieldName: 'instrument_source',
  //   },
  // ];

  useEffect(() => {
    if (columns && columns.length !== 0) {
      const filtered = [...columns.filter((col) => col.key !== 'id')];
      setFilterColumnOptions(sortColumns(filtered));
      // setFilterColumnOptions(
      //   sortColumns([...filtered, ...additionalFilterOptions])
      // );
    }
  }, [columns]);

  // WHEN CURRENT FILTERS CHANGE MAKE API CALL
  useEffect(() => {
    if (currentFilters) {
      resetOffset();
      if (currentFilters.length !== 0) {
        const transformed = currentFilters.map((filter) => {
          return {
            field: filter.name,
            not: filter?.selectedOption?.not,
            operator: filter?.selectedOption?.value,
            value: filter.value,
          };
        });
        const filterQuery = {
          rules: transformed,
        };
        setSearchValue('');
        SendTrackingEvent(
          invocationId,
          'action',
          'filter',
          'household_index',
          transformed
        );
        dispatch(
          getHouseholdIndex(
            selectedGroup.value,
            200,
            0,
            filterQuery,
            sortSettings
          )
        );
      } else {
        dispatch(
          getHouseholdIndex(
            selectedGroup.value,
            200,
            0,
            generateFilterQuery(),
            sortSettings
          )
        );
      }
    }
  }, [currentFilters]);

  // WHEN PAGINATION VALUES CHANGE
  useEffect(() => {
    if (changedOffset) {
      setChangedOffset(false);
      if (currentFilters && currentFilters?.length !== 0) {
        const transformed = currentFilters.map((filter) => {
          return {
            field: filter.name,
            not: filter?.selectedOption?.not,
            operator: filter?.selectedOption?.value,
            value: filter.value,
          };
        });
        const filterQuery = {
          rules: transformed,
        };
        setSearchValue('');
        dispatch(
          getHouseholdIndex(
            selectedGroup.value,
            100,
            offset,
            filterQuery,
            sortSettings
          )
        );
      } else {
        dispatch(
          getHouseholdIndex(
            selectedGroup.value,
            100,
            offset,
            generateFilterQuery(),
            sortSettings
          )
        );
      }
    }
  }, [offset]);

  // FILTERS AND SORTS COLUMNS WHEN CHANGES TO COLUMNS
  useEffect(() => {
    if (
      selectedColumns &&
      selectedColumns.length > 1 &&
      columns &&
      columns.length > 10 &&
      householdResults
    ) {
      let sorted = sortColumns(columns);
      setColumns(sorted);
      let filtered = filterColumns(sorted);
      setSelectedColumns(sortColumns(filtered));
    }
  }, [columns]);

  useEffect(() => {
    if (households && households.length !== 0) {
      setHouseholds(transformHouseholdValues(households));
    }
  }, [selectedColumns]);

  // SHOWS ERROR & STOPS LOADING IF SERVER ERROR
  useEffect(() => {
    if (householdIndexError) {
      setError(householdIndexError);
      setLoading(false);
    } else {
      setError();
    }
  }, [householdIndexError]);

  // SETS CURRENCY VALUE FROM USER PREFERENCES
  useEffect(() => {
    if (preferences && preferences.hasOwnProperty('currency')) {
      if (configs.currencies && configs.currencies.length !== 0) {
        let matched = configs.currencies.find((cur) => {
          return cur.code === preferences.currency;
        });
        setCurrency(matched);
      }
    }
  }, [configs.currencies, preferences]);

  useEffect(() => {
    let screenWidth = window.innerWidth;
    let margin, width;
    if (screenWidth >= 980) {
      margin = '0 30px';
      width = 'calc(100vw - 65px)';
    } else {
      margin = '0 15px';
      width = 'calc(100vw - 30px)';
    }
    if (currentFilters && currentFilters.length !== 0) {
      setShowFilterBar(true);
      setContainerStyle({
        width: width,
        height: 'calc(100vh - 236px)',
        margin: margin,
      });
    } else {
      setShowFilterBar(false);
      setContainerStyle({
        width: width,
        height: 'calc(100vh - 208px)',
        margin: margin,
      });
    }
  }, [currentFilters]);

  // ONCE THERE ARE HOUSEHOLD RESULTS FROM API IT SETS HOUSEHOLDS VALUE (TABLE DATA)
  useEffect(() => {
    if (householdResults) {
      if (householdResults.length !== 0) {
        setTransformedColumns([]);
        setHouseholds(transformHouseholdValues(householdResults));
        // setLoading(false);
      } else {
        setHouseholds(householdResults);
        // setLoading(false);
      }
    }
  }, [householdResults]);

  // STOPS LOADING ANIMATION ONCE RESULTS ARE LOADED
  useEffect(() => {
    if (householdIndex.results) {
      setLoading(false);
    }
  }, [householdIndex.results]);

  // SETS WHAT COLUMNS ARE VISIBLE (selectedColumns) USING selectedLayout.data.columns
  const filterColumns = (columns, layout = selectedLayout) => {
    if (layout.hasOwnProperty('data')) {
      if (resetVisibleColumns) {
        layout.data.visibleColumns = [...layout.data.columns];
        setResetVisibleColumns(false);
      }
      let visibleColumns = layout.data.visibleColumns.reduce((acc, col) => {
        let matched = columns.find((column) => {
          if (column.key === col) {
            column.visible = true;
            return column;
          }
        });
        if (matched !== undefined) {
          return [...acc, matched];
        }
        return acc;
      }, []);
      let updated;
      if (visibleColumns.some((e) => e.key === 'id')) {
        updated = visibleColumns;
      } else {
        updated = [idColumnHHI, ...visibleColumns];
      }
      return updated;
    } else {
      return standardHHIColumns;
    }
  };

  // SORTS COLUMNS BASED OFF PRIORITY AND ALPHABETICAL
  const sortColumns = (columns) => {
    const sorted = columns.sort((a, b) => {
      if (a.priority > b.priority) return 1;
      if (a.priority < b.priority) return -1;
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
    });
    return sorted;
  };

  // GENERATES SORT OBJECT THAT TABLE NEEDS USING selectedLayout.data.sort
  const generateSortObject = () => {
    const sortingObject = sortSettings.reduce((obj, setting) => {
      if (setting.startsWith('-')) {
        let fieldName = setting.substring(1);
        obj[fieldName] = SortOrder.DESC;
      } else {
        obj[setting] = SortOrder.ASC;
      }
      return obj;
    }, {});
    setSortObj(sortingObject);
  };

  const filterToQueryTransformer = () => {
    return currentFilters.map((filter) => {
      return {
        field: filter?.name,
        not: filter?.selectedOption?.not,
        operator: filter?.selectedOption?.value,
        value: filter?.value,
      };
    });
  };

  const generateFilterQuery = () => {
    let querySet = '';
    if (currentFilters && currentFilters.length !== 0) {
      querySet = {};
      querySet.rules = filterToQueryTransformer();
    }
    return querySet;
  };

  // MAKES API CALL TO GET HOUSEHOLDS WHEN CLICKING TO TOGGLE SORT
  const getSortedHouseholds = (sortSettings) => {
    return dispatch(
      getHouseholdIndex(
        selectedGroup.value,
        200,
        0,
        generateFilterQuery(),
        sortSettings
      )
    );
  };

  // SETS SORT SETTINGS USING VALUE FROM CLICK
  const sortColumn = (column, key, order) => {
    let value;
    if (order === 'asc') {
      value = key;
    } else {
      value = `-${key}`;
    }
    setSortSettings([value]);
    getSortedHouseholds([value]);
    resetOffset();
  };

  // UPDATES VALUES AND MAKES API CALL ON LAYOUT SELECT
  const selectLayoutChange = (e) => {
    setSelectedLayout(e);
    SendTrackingEvent(
      invocationId,
      'action',
      'layout_select',
      'household_index',
      { layout: e.value }
    );
    let updatedColumns = getVisibleColumns(columns, e);
    setColumns(updatedColumns);
    // setCurrentFilters([]);
    // generateFilterOptions(e);
    setModalFilterOptions([]);
    setSelectedColumns(filterColumns(columns, e));
    setResetVisibleColumns(true);
    setSearchValue('');
  };

  const showFilterOptionModal = () => {
    setShowFilterModal(true);
    if (currentFilters) {
      const copied = currentFilters.map((filter) => {
        const updated = { ...filter };
        updated.disabled = filter.name === 'linked_any';
        if (useSelectKeys.includes(filter.id)) {
          updated.useSelect = true;
          const responseOptions = DetermineOptions(filter.id);
          updated.responseOptions = responseOptions;
          if (responseOptions) {
            updated.responseSelectedOption = responseOptions.find(
              (o) => o.value === filter.value
            );
          } else {
            updated.responseSelectedOption = {
              label: filter.value,
              value: filter.value,
            };
          }
        }
        return updated;
      });
      setModalFilterOptions(copied);
      setFilterError('');
    }
  };

  const deleteFilterFunction = (item) => {
    setModalFilterOptions(
      modalFilterOptions.filter((fil) => {
        return fil.id !== item;
      })
    );
  };

  const updateFilterOptions = () => {
    const copied = modalFilterOptions.map((filter) => {
      if (filter.disabled) {
        filter.hasError = false;
      } else if (
        filter.responseSelectedOption?.value &&
        filter.responseSelectedOption?.value !== 'none'
      ) {
        filter.hasError = false;
        filter.value = filter.responseSelectedOption?.value;
      } else if (!filter.hasOwnProperty('value') || filter.value === '') {
        filter.hasError = true;
      } else {
        filter.hasError = false;
      }
      return { ...filter };
    });
    if (copied.some((filter) => filter.hasError)) {
      setFilterError('Please Fill in Empty Fields');
      return;
    } else {
      setCurrentFilters([...copied]);
      setFilterError('');
      setShowFilterModal(false);
    }
  };

  // const changedConjunctionValue = (value, inputName) => {
  //   let updatedModalFilterOptions = modalFilterOptions.map((filter) => {
  //     if (filter.name === inputName) {
  //       filter.conjunction = value;
  //     }
  //     return filter;
  //   });
  //   setModalFilterOptions(updatedModalFilterOptions);
  // };

  const updateFilterValue = (e, inputName) => {
    let updatedModalFilterOptions = modalFilterOptions.map((filter) => {
      if (filter.name === inputName) {
        if (inputName === 'status') {
          filter.value = e;
        } else {
          filter.value = e.currentTarget.value;
        }
      }
      return filter;
    });
    setFilterError('');
    setModalFilterOptions(updatedModalFilterOptions);
  };

  const updateFilterSelect = (e, inputName) => {
    let updatedModalFilterOptions = modalFilterOptions.map((filter) => {
      if (filter.name === inputName) {
        filter.selectedOption = e;
      }
      return filter;
    });
    setModalFilterOptions(updatedModalFilterOptions);
  };

  const updateFilterResponseSelect = (e, inputName) => {
    let updatedModalFilterOptions = modalFilterOptions.map((filter) => {
      if (filter.name === inputName) {
        filter.responseSelectedOption = e;
        filter.value = e.value;
      }
      return filter;
    });
    setFilterError('');
    setModalFilterOptions(updatedModalFilterOptions);
  };

  const selectedFilterColumn = (e) => {
    let hasFilter = modalFilterOptions.some((fil) => fil.id === e.value);
    if (!hasFilter) {
      let newFilterOption = {
        id: e.value,
        type: 'filter',
        columnName: e.label,
        name: e.value,
        selectedOption: DetermineOptions(e.format)[0],
        responseSelectedOption: DetermineOptions(e.key)[0],
        options: DetermineOptions(e.format),
        responseOptions: DetermineOptions(e.key),
        disabled: e.key === 'linked_any',
        conjunction: 'and',
        useSelect: useSelectKeys.includes(e.key),
        hasError: false,
      };
      setModalFilterOptions([...modalFilterOptions, newFilterOption]);
    }
  };

  const addFilter = {
    type: 'select',
    name: 'name',
    isDisabled: false,
    placeholder: 'Select Column to Filter',
    value: editingLayoutName,
    // options: columns.filter((col) => col.key !== 'id'),
    options: filterColumnOptions,
    onChange: (e) => selectedFilterColumn(e),
  };

  const filterModalContent = {
    heading: 'Add & Modify Filters',
    buttons: [
      {
        text: 'Cancel',
        function: () => setShowFilterModal(false),
        theme: ButtonThemes.cancel,
      },
      {
        text: 'Apply Filter',
        function: () => updateFilterOptions(),
        theme: ButtonThemes.primary,
      },
    ],
  };

  const removeCurrentFilter = (index) => {
    let updatedFilters = currentFilters.filter((filter, i) => {
      return i !== index;
    });
    setModalFilterOptions(updatedFilters);
    setCurrentFilters(updatedFilters);
  };

  // FINDS WHICH COLUMNS ARE VISIBLE USING layout.data.columns
  const getVisibleColumns = (columns, layout = selectedLayout) => {
    return columns.map((column) => {
      column.visible = layout.data.columns.includes(column.key);
      return column;
    });
  };

  // WHEN COLUMN IS (DE)SELECTED UPDATES SELECTED COLUMNS & LAYOUT
  const columnOnChange = (e) => {
    if (e.key !== 'name' && e.key !== 'id') {
      let newColumns;
      let selectedLength = selectedLayout.data.visibleColumns.length;
      if (selectedLayout.data.visibleColumns.includes(e.key)) {
        if (selectedLength > 3) {
          newColumns = selectedLayout.data.visibleColumns.filter((col) => {
            return e.key !== col;
          });
        } else {
          newColumns = selectedLayout.data.visibleColumns;
          ErrorNotification('Must have at least 2 columns visible.', 3);
        }
      } else {
        newColumns = [...selectedLayout.data.visibleColumns, e.key];
      }
      //Need to update selected columns
      selectedLayout.data.visibleColumns = newColumns;
      setSelectedLayout(selectedLayout);

      let updatedColumns = columns.map((column) => {
        if (column.fieldName === e.fieldName) {
          column.visible = !column.visible;
        }
        return column;
      });
      setColumns(sortColumns(updatedColumns));
      SendTrackingEvent(
        invocationId,
        'action',
        `column_${e.visible ? 'enable' : 'disable'}`,
        'household_index',
        {
          column: e.value,
        }
      );
    }
  };

  // SETS selectedGroup VALUE AND MAKES API ON CHANGE
  const selectGroupChange = (e) => {
    setSelectedGroup(e);
    setSearchValue('');
    SendTrackingEvent(
      invocationId,
      'action',
      'group_select',
      'household_index',
      { group: e.value }
    );
    return dispatch(
      getHouseholdIndex(e.value, 200, 0, generateFilterQuery(), sortSettings)
    );
  };

  // MAKES API CALL WITH SEARCH FIELD VALUES
  const searchHouseholds = () => {
    if (searchValue !== '') {
      setLoading(true);
      setHasSearchValue(true);
      let querySet = {};
      if (currentFilters && currentFilters.length !== 0) {
        querySet.rules = filterToQueryTransformer();
      } else {
        querySet.rules = [];
      }
      querySet.rules.push({
        field: 'name',
        not: false,
        operator: 'contains',
        value: searchValue,
      });
      SendTrackingEvent(invocationId, 'action', 'search', 'household_index', {
        query: searchValue,
      });
      resetOffset();
      return dispatch(
        getHouseholdIndex(selectedGroup.value, 200, 0, querySet, sortSettings)
      );
    }
  };

  const onKeyDown = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
      searchHouseholds();
    }
  };

  // CLEARS SEARCH FIELDS AND MAKES NEW API CALL
  const clearSearch = () => {
    setSearchValue('');
    setLoading(true);
    setHasSearchValue(false);
    return dispatch(
      getHouseholdIndex(
        selectedGroup.value,
        200,
        0,
        generateFilterQuery(),
        sortSettings
      )
    );
  };

  // MAKES API CALL TO UPDATE EXISTING LAYOUT
  const saveLayout = () => {
    let filterObj = {};
    if (currentFilters && currentFilters.length !== 0) {
      const transformed = currentFilters.map((filter) => {
        return {
          field: filter.name,
          not: filter?.selectedOption?.not,
          operator: filter?.selectedOption?.value,
          value: filter.value,
        };
      });
      filterObj.rules = transformed;
    }
    setShowSaveMenu(false);
    let newLayout = {
      name: selectedLayout.name,
      id: selectedLayout.id,
      data: {
        group: selectedGroup.value,
        sort: sortSettings,
        filter: filterObj,
        columns: selectedColumns.map((column) => {
          return column.key;
        }),
      },
    };
    if (selectedLayout.is_system) {
      ErrorNotification(
        'Cannot be updated. Use Save As to create a new layout.',
        5
      );
    } else {
      dispatch(updateHouseholdIndexLayout(newLayout));
      setJustSavedLayout(true);
      setSelectedLayout(selectedLayout);
      SendTrackingEvent(
        invocationId,
        'action',
        'layout_save',
        'household_index',
        { layout: selectedLayout.id }
      );
    }
  };

  // SHOWS SAVE AS MODAL
  const showSaveAsModal = () => {
    setSaveLayoutError('');
    setShowNewLayoutModal(true);
    setShowSaveMenu(false);
  };

  // SAVES NEW LAYOUT USING SAVE AS MODAL
  const createNewLayout = () => {
    if (newLayoutName === '') {
      setLayoutNameFieldError(true);
      return setSaveLayoutError('Please provide a name.');
    } else {
      let filterObj = {};
      if (currentFilters && currentFilters.length !== 0) {
        const transformed = currentFilters.map((filter) => {
          return {
            field: filter.name,
            not: filter?.selectedOption?.not,
            operator: filter?.selectedOption?.value,
            value: filter.value,
          };
        });
        filterObj.rules = transformed;
      }
      let newLayout = {
        name: newLayoutName,
        data: {
          group: selectedGroup.value,
          sort: sortSettings,
          is_default: newLayoutIsDefault,
          filter: filterObj,
          columns: selectedColumns.map((column) => {
            return column.key;
          }),
        },
      };
      dispatch(createHouseholdIndexLayout(newLayout));
      setNewLayoutIsDefault(false);
      setNewLayoutName('');
      setShowNewLayoutModal(false);
    }
  };

  // UPDATES NEW LAYOUT NAME VALUE AND REMOVES ERROR
  const updateNewLayoutName = (e) => {
    setNewLayoutName(e.currentTarget.value);
    setLayoutNameFieldError(false);
  };

  // HIDES NEW LAYOUT MODAL AND REMOVES ERROR
  const cancelNewLayoutModal = () => {
    setShowNewLayoutModal(false);
    setNewLayoutName('');
    setLayoutNameFieldError(false);
  };

  const createLayoutModal = {
    heading: 'Save Current Results As...',
    infoText:
      'A new Layout will be created from your selected filters, columns and groups.',
    inputs: [
      {
        type: 'text',
        maxLength: '45',
        name: 'name',
        required: true,
        placeholder: 'Name',
        value: newLayoutName,
        onChange: (e) => updateNewLayoutName(e),
        onKeyPress: (e) => DetectEnterKeyPress(e, createNewLayout),
        hasError: layoutNameFieldError,
      },
      {
        type: 'checkbox',
        label: 'Set as Default',
        value: newLayoutIsDefault,
        onChange: () => setNewLayoutIsDefault(!newLayoutIsDefault),
      },
    ],
    buttons: [
      {
        text: 'Cancel',
        function: () => cancelNewLayoutModal(),
        theme: ButtonThemes.cancel,
      },
      {
        text: 'Save',
        function: () => createNewLayout(),
        theme: ButtonThemes.primary,
      },
    ],
  };

  // RUNS EXPORT LAYOUT FUNCTIONALITY
  const exportHouseholdIndex = () => {
    let filterObj = '';
    if (currentFilters && currentFilters.length !== 0) {
      filterObj = {};
      const transformed = currentFilters.map((filter) => {
        return {
          field: filter.name,
          not: filter?.selectedOption?.not,
          operator: filter?.selectedOption?.value,
          value: filter.value,
        };
      });
      filterObj.rules = transformed;
    }
    const mappedColumns = selectedColumns.map((column) => {
      return column.key;
    });
    const dataObject = {
      group: selectedGroup.value,
      sort: sortSettings,
      fields: mappedColumns.filter((column) => {
        return column !== 'id';
      }),
      query: filterObj,
      // filter: filterObj,
      // query: searchValue,
    };
    dispatch(getHouseholdIndexCSV(dataObject));
    setLoadingHouseholdCSV(true);
    SendTrackingEvent(invocationId, 'action', 'export', 'household_index');
  };

  // SETS LAYOUT TO BE EDITED AND SHOWS EDIT LAYOUT MODAL
  const editLayoutModal = (option) => {
    setEditingLayout(option.data);
    setShowEditLayoutModal(true);
    setEditingLayoutName(option.data.name);
    setEditingLayoutIsDefault(option.data.is_default);
  };

  // DISPLAYS CONFIRM DELETE MODAL
  const displayDeleteModal = () => {
    setShowEditLayoutModal(false);
    setShowDeleteModal(true);
  };

  // UPDATES NAME & DEFAULT VALUE OF LAYOUT TO EDIT
  const saveEditedLayout = () => {
    let newLayout = {
      name: editingLayoutName,
      id: editingLayout.id,
      data: editingLayout.data,
    };

    const matchedLayout = layouts.find(
      (layout) => layout.id === editingLayout.id
    );
    if (editingLayoutName !== '') {
      if (editingLayoutIsDefault !== matchedLayout.is_default) {
        dispatch(
          setDefaultHouseholdIndexLayout(newLayout, editingLayoutIsDefault)
        );
      }
      if (matchedLayout.name !== editingLayoutName) {
        dispatch(updateHouseholdIndexLayout(newLayout));
      }
      setShowEditLayoutModal(false);
    } else {
      setEditNameFieldError(true);
      setEditLayoutError('Please provide a name.');
    }
  };

  // UPDATES THE EDIT LAYOUT NAME AND DISMISSES ERROR
  const updateEditLayoutName = (e) => {
    setEditNameFieldError(false);
    setEditLayoutError('');
    setEditingLayoutName(e.currentTarget.value);
  };

  // EDIT LAYOUT MODAL CONTENT
  const editLayoutModalContent = {
    heading: 'Edit Layout',
    infoText:
      'You can change the name of your layout, set it as default or delete.',
    inputs: [
      {
        type: 'text',
        name: 'name',
        maxLength: '45',
        required: true,
        placeholder: 'Name',
        value: editingLayoutName,
        onChange: (e) => updateEditLayoutName(e),
        onKeyPress: (e) => DetectEnterKeyPress(e, saveEditedLayout),
        hasError: editNameFieldError,
      },
      {
        type: 'checkbox',
        label: 'Make Default Layout',
        value: editingLayoutIsDefault,
        onChange: () => setEditingLayoutIsDefault(!editingLayoutIsDefault),
      },
    ],
    buttons: [
      {
        text: 'Delete',
        function: () => displayDeleteModal(),
        theme: ButtonThemes.error,
      },
      {
        text: 'Update',
        function: () => saveEditedLayout(),
        theme: ButtonThemes.primary,
      },
    ],
  };

  // MAKES API CALL TO DELETE SELECTED LAYOUT
  const deleteLayout = () => {
    if (editingLayout.is_system) {
      ErrorNotification('Cannot delete. Create new layout first.', 5);
    } else {
      dispatch(deleteHouseholdIndexLayout(editingLayout.id));
    }
    setShowEditLayoutModal(false);
    setShowDeleteModal(false);
  };

  // HIDES CONFIRM DELETE MODAL
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
    setShowEditLayoutModal(true);
    setEditNameFieldError(false);
  };

  // DELETE LAYOUT MODAL CONTENT
  const deleteLayoutModalContent = {
    heading: 'Delete Layout?',
    text: `Are you sure you want to delete <strong>${editingLayoutName}</strong>?`,
    buttons: [
      {
        text: 'No',
        function: () => hideDeleteModal(),
        theme: ButtonThemes.cancel,
      },
      {
        text: 'Yes',
        function: () => deleteLayout(),
        theme: ButtonThemes.primary,
      },
    ],
  };

  const transformHouseholdValues = (rows) => {
    if (!selectedColumns) return rows;

    let toBeTransformedColumns = selectedColumns.filter(
      (x) => !transformedColumns.includes(x)
    );

    if (toBeTransformedColumns.length === 0) return rows;

    setTransformedColumns([...transformedColumns, ...toBeTransformedColumns]);

    const formatRow = (row) => {
      let rowCurrency = currency;
      if (
        row.hasOwnProperty('currency') &&
        configs.currencies &&
        configs.currencies.length !== 0
      ) {
        let matched = configs.currencies.find((cur) => {
          return cur.code === row['currency'];
        });
        if (matched) {
          rowCurrency = matched;
        }
      }
      row.link = (
        <HouseholdLink
          to={`/household/${row.id}/${householdStartPage}`}
          onClick={() =>
            SendTrackingEvent(
              invocationId,
              'action',
              'household_select',
              'household_index',
              { household: row.id }
            )
          }
        >
          {row.name}
        </HouseholdLink>
      );

      if (!row.householdmember_count) {
        row.householdmember_count = 0;
      }

      // let partnerImages = {
      //   axasf_salesforce_id: 'axasf-sm.png',
      //   axasf_sync_financials: 'axasf-sm.png',
      //   blackdiamond: 'blackdiamond-sm.png',
      //   emoney: 'emoney-sm.png',
      //   orion: 'orion-sm.png',
      //   redtail: 'redtail-sm.png',
      //   riskalyze: 'riskalyze-sm.png',
      //   td: 'td-sm.png',
      // };

      // let getPartnerImage = (partner) => {
      //   if (partner in partnerImages) {
      //     return (
      //       <img
      //         key={partner}
      //         src={'/static/img/partners/' + partnerImages[partner]}
      //         alt={partner}
      //         style={{
      //           height: '18px',
      //           marginLeft: '2px',
      //         }}
      //       />
      //     );
      //   }
      //   return <LinkIcon key={partner} src={link} alt={partner} />;
      // };

      const generateLinkedIcon = (value) => {
        if (value) {
          let integrationsList = row?.linked.map((int) => {
            const matching = integrationOptions.find((op) => op.id === int);
            return matching?.name || int;
          });
          integrationsList = integrationsList.join(', ');
          return (
            <Tooltip
              title={integrationsList}
              position="right"
              trigger="mouseenter click"
              followCursor={false}
              delay={0}
              arrow={true}
              arrowSize={'small'}
              animation={'fade'}
              animateFill={false}
              transitionFlip={false}
              size={'small'}
            >
              <LinkIcon src={link} alt="linked" data-image="linked" />
            </Tooltip>
          );
        } else {
          return '';
        }
      };

      const generateSignalsIcon = (value, column) => {
        let isSnoozed = false;
        const signalsState = [
          'signal_liability_state',
          'signal_life_state',
          'signal_liquidity_state',
          'signal_longevity_state',
          'signal_ltc_state',
          'signal_ltd_state',
        ];
        if (signalsState.includes(column?.value)) {
          let columnValue = column.value.split('_');
          columnValue = `${columnValue[0]}_${columnValue[1]}`;
          const rowValue = row[columnValue];
          if (rowValue) {
            isSnoozed = rowValue?.snooze?.snoozed || rowValue?.snooze?.muted;
          }
        }
        const dataValue = `${column.value}_${value}`;
        if (value) {
          return GenerateSignalColorIcon(
            value,
            isSnoozed,
            true,
            {
              width: '16px',
              height: '16px',
              marginTop: '5px',
            },
            dataValue
          );
        } else {
          return '';
        }
      };

      const getFormatterFromColumn = (column) => {
        if (column.key === 'liabilities_amount') {
          return (value) => (
            <span style={{ color: messageColors.error }}>
              ({FormatCurrency(value, rowCurrency)})
            </span>
          );
        }
        switch (column.format) {
          case 'capitalizedTextFormatter':
            return (value) => value.charAt(0).toUpperCase() + value.slice(1);
          case 'currencyFormatter':
            return (value) => FormatCurrency(value, rowCurrency);
          case 'dateFormatter':
            return (value) => (value ? DateFromNowUTC(value) : '');
          // case 'integrationListFormatter':
          //   return (value) => {
          //     return <span>{value.map(getPartnerImage)}</span>;
          //   };
          case 'linkedFormatter':
            return (value) => generateLinkedIcon(value);
          case 'iconFormatter':
            return (value) => generateSignalsIcon(value, column);
          case 'ratioFormatter':
            return (value) => `${value}%`;
          default:
            return (value) => value;
        }
      };

      const formattersByFieldName = Object.fromEntries(
        toBeTransformedColumns.map((column) => {
          return [column.fieldName, getFormatterFromColumn(column)];
        })
      );

      const getFormatter = (fieldName) => {
        if (fieldName in formattersByFieldName)
          return formattersByFieldName[fieldName];
        return (value) => value;
      };

      return Object.fromEntries(
        Object.entries(row).map((entry) => {
          let fieldName = entry[0];
          let cellValue = entry[1];

          let formatter = getFormatter(fieldName);

          return [fieldName, formatter(cellValue)];
        })
      );
    };

    return rows.map(formatRow);
  };

  //MODIFIES DISPLAY VALUES OF TABLE CELLS
  // const transformCellProps = (
  //   columns,
  //   column,
  //   columnIndex,
  //   rowData,
  //   rowIndex
  // ) => {
  //   // if (column.format === 'dateFormatter') {
  //   //   rowData[column.key] = DateFromNow(rowData[column.key], false);
  //   // }
  //   // rowData.link = (
  //   //   <HouseholdLink to={`/household/${rowData.id}/summary`}>
  //   //     {rowData.name}
  //   //   </HouseholdLink>
  //   // );
  //   if (!rowData.householdmember_count) {
  //     rowData.householdmember_count = 0;
  //   }
  // };

  const generateErrorDisplay = (error) => {
    let errorMessage = `Unknown Error: Try Refreshing Page`;
    if (error) {
      if (error.hasOwnProperty('statusText')) {
        errorMessage = `Error: ${error.statusText}`;
      } else if (error.hasOwnProperty('status')) {
        if (error.hasOwnProperty('data')) {
          errorMessage = `${error.status} Error: ${
            Object.values(error.data)[0]
          }`;
        }
        errorMessage = `${error.status} Error`;
      }
    }
    return errorMessage;
  };

  // DISPLAY FOR LOADING, ERROR & NO RESULTS
  const emptyRenderer = () => {
    if (loading) {
      return (
        <Empty>
          <LoadingAnimation />
          {/* <Loader /> */}
        </Empty>
      );
    } else if (error) {
      return (
        <EmptyResults>
          <img src={noHouseholds} alt="empty" data-image="no-households" />
          <p>{generateErrorDisplay(error)}</p>
        </EmptyResults>
      );
    } else {
      return (
        <EmptyResults>
          <img src={noHouseholds} alt="empty" data-image="no-households" />
          <p>
            No results found
            {searchValue !== '' && (
              <span>
                {' '}
                for &quot;{searchValue}
                &quot;
              </span>
            )}
          </p>
        </EmptyResults>
      );
    }
  };

  const generateRecordCount = () => {
    if (householdIndex.hasOwnProperty('count')) {
      const { count, total, offset } = householdIndex;
      return (
        <>
          {count === 0 ? (
            <>No Records</>
          ) : (
            <>
              Showing {offset + 1} to {offset + count} of {total} Records
            </>
          )}
          <span>
            ({householdIndex.took ? householdIndex.took : '00'}
            ms)
          </span>
        </>
      );
    } else {
      return <span>Loading Results</span>;
    }
  };

  const sortColumnDropdownList = () => {
    let updatedColumns = columns.filter((col) => !col.hideDropdown);
    updatedColumns = updatedColumns.sort((a, b) => {
      return b.visible - a.visible;
    });
    setColumnDropdownList(updatedColumns);
  };

  const resetOffset = () => {
    setOffset(0);
    // setChangedOffset(true);
  };

  const clickNextPagination = () => {
    setOffset(offset + limit);
    setChangedOffset(true);
  };

  const clickPrevPagination = () => {
    setOffset(offset - limit);
    setChangedOffset(true);
  };

  const goToPage = (page) => {
    setOffset(limit * (page - 1));
    setChangedOffset(true);
  };

  const TableHeaderCell = ({ className, column }) => {
    return column.display ? (
      <Tooltip
        className={className}
        title={column.label}
        position="top"
        trigger="mouseenter click"
        followCursor={false}
        delay={0}
        arrow={true}
        arrowSize={'small'}
        animation={'fade'}
        animateFill={false}
        transitionFlip={false}
        size={'small'}
      >
        {column.short}
      </Tooltip>
    ) : null;
  };

  return (
    <>
      {loading ? null : (
        <>
          {showNewLayoutModal && (
            <SimpleModal
              hide={() => cancelNewLayoutModal()}
              content={createLayoutModal}
              updateError={setLayoutNameFieldError}
              onEnter={createNewLayout}
              error={saveLayoutError}
            />
          )}
          {showEditLayoutModal && (
            <SimpleModal
              hide={() => setShowEditLayoutModal(!showEditLayoutModal)}
              content={editLayoutModalContent}
              updateError={setEditNameFieldError}
              error={editLayoutError}
            />
          )}
          {showDeleteModal && (
            <SimpleModal
              hide={() => setShowDeleteModal(!showDeleteModal)}
              content={deleteLayoutModalContent}
            />
          )}
          {showFilterModal && (
            <FilterModal
              hide={() => setShowFilterModal(!showFilterModal)}
              content={filterModalContent}
              inputs={[...modalFilterOptions, addFilter]}
              inputOnChange={updateFilterValue}
              selectOnChange={updateFilterSelect}
              responseSelectOnChange={updateFilterResponseSelect}
              // conjunctionOnChange={changedConjunctionValue}
              deleteFunction={deleteFilterFunction}
              errors={filterError}
            />
          )}
        </>
      )}
      <Page>
        <TopContent>
          <PrimaryHeading margin="13px 0 10px 0">
            Household Index
          </PrimaryHeading>
          <ActionRow>
            <LeftContent>
              <ActionItem style={{ width: '170px' }}>
                <InputLabel>Layouts</InputLabel>
                <Select
                  onChange={(e) => selectLayoutChange(e)}
                  options={TransformSelectOptions(layouts, 'name', 'id')}
                  isSearchable={true}
                  placeholder="Layouts"
                  components={{
                    Option: (e) => LayoutOption(e, editLayoutModal),
                  }}
                  autoFocus={false}
                  selectedOption={selectedLayout}
                  styles={selectThemes.layoutMenu}
                />
              </ActionItem>
              <ActionItem style={{ width: '142px' }}>
                <InputLabel>Columns</InputLabel>
                <Select
                  onChange={(e) => columnOnChange(e)}
                  options={columnDropdownList}
                  isSearchable={true}
                  components={{
                    Option: ColumnOption,
                  }}
                  onMenuOpen={() => sortColumnDropdownList()}
                  onMenuClose={() => sortColumnDropdownList()}
                  placeholder="Columns"
                  autoFocus={false}
                  selectedOption={{
                    label: 'Select Columns',
                  }}
                  styles={selectThemes.wideMenu}
                  closeMenuOnSelect={false}
                />
              </ActionItem>
              <ActionItem style={{ width: '150px' }}>
                <InputLabel>Groups</InputLabel>
                <Select
                  onChange={(e) => selectGroupChange(e)}
                  options={TransformSelectOptions(groups, 'name', 'id')}
                  isSearchable={true}
                  placeholder="Groups"
                  autoFocus={false}
                  selectedOption={selectedGroup}
                  styles={selectThemes.householdIndex}
                />
              </ActionItem>
              <ActionItem style={{ width: '220px' }}>
                <InputLabel>Search</InputLabel>
                <SearchContainer>
                  <SearchBoxContainer>
                    <SearchBox
                      type="text"
                      placeholder="Search Households"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      onKeyDown={(e) => onKeyDown(e)}
                    />
                    {hasSearchValue && (
                      <ClearIcon
                        src={times}
                        alt="clear"
                        data-image="clear-search-households"
                        onClick={clearSearch}
                      />
                    )}
                  </SearchBoxContainer>
                  <button onClick={searchHouseholds}>
                    <img
                      src={search}
                      alt="search"
                      data-image="search-households"
                    />
                  </button>
                </SearchContainer>
              </ActionItem>
              <ActionItem
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                  height: '28px',
                }}
              >
                <FilterActionContainer
                  onClick={() => {
                    showFilterOptionModal();
                  }}
                >
                  Filter
                  <img
                    src={filterSolid}
                    alt="filter"
                    data-image="filter-households"
                  />
                </FilterActionContainer>
              </ActionItem>
            </LeftContent>
            <RightContent>
              <ActionItem>
                <ClickContainer onClick={() => setShowSaveMenu(!showSaveMenu)}>
                  <ClickText>Save</ClickText>
                  <ClickImage
                    src={chevronDown}
                    alt="caret"
                    data-image="arrow-down"
                  />
                </ClickContainer>
                <DropdownContainer
                  ref={ref}
                  display={showSaveMenu ? 'block' : 'none'}
                >
                  <DropdownHeading>Save Layout</DropdownHeading>
                  <DropdownContent>
                    <DropdownItem onClick={() => saveLayout()}>
                      Save
                    </DropdownItem>
                    <DropdownItem onClick={() => showSaveAsModal()}>
                      Save as...
                    </DropdownItem>
                  </DropdownContent>
                </DropdownContainer>
              </ActionItem>
              {canExportIndex && (
                <ActionItem
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => exportHouseholdIndex()}
                >
                  Export
                </ActionItem>
              )}
            </RightContent>
          </ActionRow>
          {showFilterBar && (
            <FilterRow>
              {currentFilters &&
                currentFilters.map((filter, index) => {
                  return (
                    <FilterChipContainer key={index}>
                      <span
                        onClick={() => {
                          showFilterOptionModal();
                        }}
                      >
                        {filter.id === 'linked_any' ? (
                          <>
                            <FilterColumn marginRight="0px">
                              {filter?.selectedOption?.label}
                            </FilterColumn>
                            <FilterValue>Linked</FilterValue>
                          </>
                        ) : (
                          <>
                            <FilterColumn>
                              {filter?.columnName || 'Error'}
                            </FilterColumn>
                            <div
                              style={{
                                flex: '0 0 auto',
                              }}
                            >
                              {filter?.selectedOption
                                ? filter?.selectedOption?.label
                                : 'error'}
                            </div>
                            <FilterValue>
                              {filter?.columnName === 'Linked?'
                                ? ''
                                : filter?.value || 'Error'}
                            </FilterValue>
                          </>
                        )}
                      </span>
                      <img
                        src={times}
                        alt="close"
                        data-image={`remove-filter-${index}`}
                        onClick={() => {
                          removeCurrentFilter(index);
                        }}
                      />
                    </FilterChipContainer>
                  );
                })}
            </FilterRow>
          )}
        </TopContent>
        <Table
          columns={selectedColumns}
          data={households}
          rowHeight={40}
          headerHeight={40}
          sortState={sortObj}
          emptyRenderer={emptyRenderer}
          components={{ TableHeaderCell }}
          // onEndReachedThreshold={total}
          columnSort={sortColumn}
          // transformCells={null}
          containerStyle={containerStyle}
          // onEndReached={handleEndReached}
          // overlayRenderer={renderOverlay}
        />
        <BottomContainer>
          <ResponseContainer>{generateRecordCount()}</ResponseContainer>
          {householdIndex?.total !== 0 && householdIndex?.total > limit && (
            <PaginationContainer>
              <Pagination
                clickNext={() => clickNextPagination()}
                clickPrev={() => clickPrevPagination()}
                goToPage={(page) => goToPage(page)}
                limit={limit}
                offset={offset}
                count={householdIndex?.total}
              />
            </PaginationContainer>
          )}
        </BottomContainer>
        {/* <PaginationContainer>
        Showing 1 to {determineNum()} of {householdIndex.total} Records
        {householdIndex.offset === 0 ? (
          <button onClick={fetchMoreHouseholds}>More</button>
        ) : (
          <button onClick={fetchPreviousHouseholds}>Previous</button>
        )}
      </PaginationContainer> */}
      </Page>
    </>
  );
};

const TopContent = styled.div`
  @media (max-width: 980px) {
    margin: 8px 15px 12px 15px;
  }
  margin: 8px 32px 12px 32px;
`;

const ActionItem = styled.div`
  position: relative;
  padding: 0 5px;
`;

const InputLabel = styled.label`
  margin: 0 0 2px 0;
  display: block;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
`;

const ActionRow = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 13px;
  ${ActionItem} {
    &:first-child {
      padding-left: 0;
    }
  }
`;

const LeftContent = styled.div`
  @media (max-width: 800px) {
    ${ActionItem} {
      margin-bottom: 8px;
    }
  }
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  button {
    background: none;
    margin-left: 5px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    &:hover {
      transform: scale(1.01);
    }
    img {
      width: 15px;
      height: 15px;
      flex: 0 0 auto;
    }
  }
`;

const FilterActionContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 1px;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 20px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
  background: ${colors.lightGrey};
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid ${colors.lightGrey};
  &:hover {
    img {
      opacity: 0.8;
    }
  }
  img {
    width: 11px;
    height: 11px;
    margin-left: 5px;
  }
`;

const SearchContainer = styled.div`
  @media (max-width: 800px) {
    margin-left: 0px;
  }
  width: 220px;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
`;

const SearchBoxContainer = styled.div`
  @media (max-width: 800px) {
    margin-left: 0px;
  }
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
`;

const SearchBox = styled.input`
  display: inline-block;
  padding: 4px 18px 4px 10px;
  font-size: 12px;
  flex: 1 1 auto;
  height: 20px;
  border-radius: 4px;
  border: 1px solid ${inputColors.border};
  &:hover {
    background: ${colors.lighterGrey};
  }
  &:focus {
    background: ${colors.lighterGrey};
    border: 1px solid ${darken(0.02, inputColors.border)};
  }
`;

const ClearIcon = styled.img`
  position: absolute;
  height: 8px;
  width: 8px;
  right: 10px;
  cursor: pointer;
  display: block;
  &:hover {
    transform: scale(1.05);
  }
`;

const RightContent = styled.div`
  @media (max-width: 940px) {
    padding-top: 5px;
  }
  font-size: 13px;
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 10px;
  ${ActionItem} {
    border-right: 1px solid ${colors.paleGrey};
    padding: 0 10px;
    &:last-child {
      border-right: none;
    }
  }
`;

const FilterRow = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: -4px;
  ${ActionItem} {
    &:first-child {
      padding-left: 0;
    }
  }
`;

const FilterChipContainer = styled.div`
  flex: 0 0 auto;
  margin-right: 5px;
  font-size: 10px;
  border-radius: 3px;
  padding: 4px 6px 4px 8px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: ${colors.lightGrey};
  span {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    cursor: pointer;
  }
  img {
    margin-left: 6px;
    width: 6px;
    height: 6px;
    cursor: pointer;
    flex: 0 0 auto;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const FilterColumn = styled.div`
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '5px')};
  flex: 0 0 auto;
  cursor: pointer;
`;

const FilterValue = styled.div`
  margin-left: 5px;
  flex: 0 0 auto;
`;

const BottomContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 30px 5px 30px;
`;

const ResponseContainer = styled.div`
  font-size: 13px;
  flex: 1 1 auto;
  span {
    color: ${colors.paleGrey};
    margin-left: 5px;
    font-style: italic;
  }
`;

const PaginationContainer = styled.div`
  flex: 0 0 auto;
  margin-right: 4px;
  /* margin-right: 70px; */
  div {
    margin-top: 0;
  }
`;

const HouseholdLink = styled(Link)`
  color: ${colors.lightBlue};
  &:hover {
    color: ${colors.blue};
  }
`;

const EmptyResults = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    max-width: 350px;
    width: 100%;
  }
  p {
    margin-top: 30px;
    font-size: 22px;
    font-weight: ${fonts.semiBold};
    color: ${colors.paleGrey};
  }
`;

const Empty = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// const rotate = keyframes`
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(360deg);
//   }
// `;

// const Loader = styled.div`
//   display: inline-block;
//   border-radius: 100%;
//   margin: 2px;
//   border: 4px solid ${colors.lightBlue};
//   border-bottom-color: transparent;
//   margin: 2px;
//   width: 30px;
//   height: 30px;
//   animation: ${rotate} 0.75s linear infinite;
// `;

// const LoadingMoreLayer = styled.div`
//   pointer-events: none;
//   background: rgba(0, 0, 0, 0.8);
//   position: absolute;
//   bottom: 20px;
//   left: 50%;
//   transform: translateX(-50%);
//   padding: 8px 15px;
//   border-radius: 5px;
//   display: flex;
//   align-items: center;
//   ${Loader} {
//     margin: 2px;
//     border: 2px solid ${colors.lightBlue};
//     border-bottom-color: transparent;
//     margin: 2px;
//     width: 15px;
//     height: 15px;
//     animation: ${rotate} 0.75s linear infinite;
//   }
// `;

// const LoadingMoreText = styled.span`
//   color: ${colors.white};
//   margin-right: 10px;
// `;

const LinkIcon = styled.img`
  width: 11px;
  height: 11px;
`;

HouseholdIndexContainer.propTypes = {
  className: PropTypes.string,
  column: PropTypes.object,
};

export default HouseholdIndexContainer;
