import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { AppRouter, ConsumerRouter } from '.';
import { Error } from '../pages';
import { NavbarContainer, NavbarContainerConsumer } from '../containers';
import {
  BASENAME as basename,
  CONSUMER_BASENAME as consumerBasename,
} from '../utils/constants';
import { SendTrackingEvent, CheckIsConnectURL } from '../utils';

/*
  First router encountered by the app
  - App is wrapped Error Boundary which bubbles up errors to an error page instead of a white screen
  - If there is an error a tracking even will also be sent with details
  - First checks is url includes consumerBasename to determine which router to use
  - Router contains navbar and app router (which is everything displayed under navbar)
  - Basename refers to value prepended to app e.g. /ampux
*/
const EntryRouter = ({ isConsumer }) => {
  const { invocationId } = useSelector((state) => ({
    invocationId: state.configs.invocationId,
  }));
  const errorFunc = (error, info) => {
    SendTrackingEvent(invocationId, 'action', 'error_display', null, {
      error: info,
    });
  };

  return (
    <ErrorBoundary FallbackComponent={Error} onError={errorFunc}>
      {CheckIsConnectURL() ? (
        <Router basename={consumerBasename}>
          <NavbarContainerConsumer />
          <ConsumerRouter isConsumer={isConsumer} />
        </Router>
      ) : (
        <Router basename={basename}>
          <NavbarContainer isConsumer={isConsumer} />
          <AppRouter />
        </Router>
      )}
    </ErrorBoundary>
  );
};

EntryRouter.propTypes = {
  isConsumer: PropTypes.bool,
};

export default EntryRouter;
