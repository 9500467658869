/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { noReports } from '../../assets';
import {
  PageHeader,
  EmptyImage,
  SortButtons,
  Pagination,
  LoadingAnimation,
  MilestoneListItem,
  Filter,
  Button,
} from '../../components';
import {
  UpdatePageTitle,
  headerContent,
  DownloadReport,
  DateFormat,
} from '../../utils';
import {
  getHouseholdMilestones,
  toggleShowMilestoneModal,
  setHouseholdMilestones,
} from '../../store/actions';
import {
  colors,
  maxDevice,
  // buttonColors,
  fonts,
} from '../../styles/variables';
import { FilterContainer } from '../../components/Table/Filter';
import { SendTrackingEvent } from '../../utils';

// local filters state
const filtersReducer = (filters, action) => {
  if (action.type === 'update_names') {
    action.payload.map((milestoneOption) => {
      filters[0].options.push({
        value: milestoneOption[0],
        label: milestoneOption[1],
      });
      return true;
    });
    return filters;
  }

  const updatedFilters = filters.map((f) => {
    if (f.key === action.type) {
      f.selectedOption = action.payload;
      if (action.type === 'name') {
        f.param = action.payload.value;
      }
      if (action.type.includes('created_utc')) {
        f.param = action.payload
          ? DateFormat(action.payload, 'YYYY-MM-DD')
          : '';
      }
      if (action.type === 'search') {
        f.param = action.payload;
        f.value = action.payload;
      }
    }
    return f;
  });
  return updatedFilters;
};

const ordersReducer = (orders, action) => {
  const [field, direction] = action.type.split('-');
  const updatedOrders = orders.map((o) => {
    if (o.field === field) {
      o.direction = direction;
      o.param = field;
      if (direction === 'descend') {
        o.param = '-' + field;
      }
    } else {
      o.direction = '';
      o.param = '';
    }
    return o;
  });
  return updatedOrders;
};

const pagesReducer = (pages, action) => {
  if (action.type === 'update_count') {
    pages.count = action.payload;
  }
  if (action.type === 'update_limit') {
    pages.limit = action.payload;
  }
  if (action.type === 'update_offset') {
    pages.offset = action.payload;
  }
  return pages;
};

const HouseholdMilestones = () => {
  /**
   * Global state
   */
  const params = useParams();
  const householdId = params.id;
  UpdatePageTitle('Milestones');
  const dispatch = useDispatch();
  const {
    showMilestonesElite,
    householdMilestones,
    limit,
    milestonesOptions,
    showingMilestoneModal,
    userOpenedMilestoneModal,
    invocationId,
  } = useSelector((state) => ({
    showMilestonesElite: state.user.showMilestonesElite,
    householdMilestones: state.households.householdMilestones,
    limit: state.configs?.pagination?.default_limit || 25,
    milestonesOptions: state.configs.milestonesOptions,
    showingMilestoneModal: state.households.showingMilestoneModal,
    userOpenedMilestoneModal: state.households.userOpenedMilestoneModal,
    invocationId: state.configs.invocationId,
  }));

  useEffect(() => {
    return () => {
      dispatch(setHouseholdMilestones(false));
    };
  }, []);

  useEffect(() => {
    if (showingMilestoneModal === true) {
      SendTrackingEvent(
        invocationId,
        'click',
        'milestone_assign',
        'household_milestones',
        {
          household: householdId,
          user_opened: userOpenedMilestoneModal,
        }
      );
    }
  }, [showingMilestoneModal]);

  // sortable components
  const milestoneName = (order, showMilestonesElite) => {
    return (
      <MilestoneName key={order.field} elite={showMilestonesElite}>
        <SortButtons
          name={order.label}
          clickAscend={() => dispatchOrders({ type: 'name-ascend' })}
          clickDescend={() => dispatchOrders({ type: 'name-descend' })}
          direction={order.direction}
          data-image={`arrow-${order.direction}`}
        />
      </MilestoneName>
    );
  };

  const assignedBy = (order, showMilestonesElite) => {
    return (
      <MilestoneAdvisor key={order.field} elite={showMilestonesElite}>
        <SortButtons
          name={order.label}
          clickAscend={() => dispatchOrders({ type: 'member-ascend' })}
          clickDescend={() => dispatchOrders({ type: 'member-descend' })}
          direction={order.direction}
          data-image={`arrow-${order.direction}`}
        />
      </MilestoneAdvisor>
    );
  };

  const createdDate = (order, showMilestonesElite) => {
    return (
      <MilestoneDate key={order.field} elite={showMilestonesElite}>
        <SortButtons
          name={order.label}
          clickAscend={() => dispatchOrders({ type: 'created_utc-ascend' })}
          clickDescend={() => dispatchOrders({ type: 'created_utc-descend' })}
          direction={order.direction}
          data-image={`arrow-${order.direction}`}
        />
      </MilestoneDate>
    );
  };

  const milestoneComment = (order, showMilestonesElite) => {
    if (showMilestonesElite === false) {
      return null;
    }
    return <MilestoneComment key={order.field}>{order.label}</MilestoneComment>;
  };

  /**
   * Local state
   */
  const [loading, setLoading] = useState(true);
  const [milestones, setMilestones] = useState([]);
  const [queryParams, setQueryParams] = useState();

  const selectFilterChange = (e, key) => {
    // set local state of filters
    (async () => {
      dispatchPages({ type: 'update_offset', payload: 0 });
      dispatchFilters({ type: key, payload: e });
      // set local state of parameters, which will trigger the reload to get new hhs
    })().then(() => updateParams());
  };

  const keyDownSearch = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
      (async () => {
        dispatchFilters({ payload: e.target.value, type: 'search' });
        dispatchPages({ type: 'update_offset', payload: 0 });
      })().then(() => updateParams());
    }
  };

  const clearSearch = () => {
    (async () => {
      dispatchFilters({ type: 'search', payload: '' });
      dispatchPages({ type: 'update_offset', payload: 0 });
    })().then(() => updateParams());
  };

  const changeSearch = (e) =>
    dispatchFilters({ payload: e.target.value, type: 'search' });

  const search = () => {
    (async () => {
      dispatchPages({ type: 'update_offset', payload: 0 });
    })().then(() => updateParams());
  };

  const initFiltersState = [
    {
      filterType: 'select',
      key: 'name',
      label: 'Milestone',
      placeholder: 'All Milestones',
      options: [
        {
          label: 'All Milestones',
          value: '',
        },
      ],
      style: { width: '215px' },
      isVisible: true,
      selectedOption: { label: 'All Milestones', value: '' },
      onChangeCallback: selectFilterChange,
    },
    {
      filterType: 'datepicker',
      key: 'created_utc_after',
      placeholder: 'Start Date',
      label: 'Start Date',
      isVisible: false,
      onChangeCallback: selectFilterChange,
    },
    {
      filterType: 'datepicker',
      key: 'created_utc_before',
      placeholder: 'End Date',
      label: 'End Date',
      isVisible: false,
      onChangeCallback: selectFilterChange,
    },
    {
      filterType: 'search',
      key: 'search',
      type: 'text',
      placeholder: 'Search Milestones by Advisor',
      label: 'Search by Advisor',
      isVisible: true,
      value: '',
      onKeyDownCallback: keyDownSearch,
      onClearCallback: clearSearch,
      onChangeCallback: changeSearch,
      onSearchCallback: search,
    },
  ];
  const [filtersState, dispatchFilters] = useReducer(
    filtersReducer,
    initFiltersState
  );

  const initOrdersState = [
    {
      field: 'name',
      direction: '',
      label: 'Milestone Type',
      component: milestoneName,
      param: '',
    },
    {
      field: 'member',
      direction: '',
      label: 'Assigned By',
      component: assignedBy,
      param: '',
    },
    {
      field: 'created_utc',
      direction: 'descend',
      label: 'Date',
      component: createdDate,
      param: '-created_utc',
    },
    {
      field: 'comment',
      direction: '',
      label: 'Comments',
      component: milestoneComment,
      param: '',
    },
  ];
  const [ordersState, dispatchOrders] = useReducer(
    ordersReducer,
    initOrdersState
  );

  const initPagesState = {
    limit: limit,
    offset: 0,
    count: 0,
  };
  const [pagesState, dispatchPages] = useReducer(pagesReducer, initPagesState);

  useEffect(() => {
    if (milestonesOptions) {
      dispatchFilters({
        type: 'update_names',
        payload: milestonesOptions,
      });
    }
  }, [milestonesOptions]);
  /**
   * Side Effects
   */
  // update hh milestones in ui if there are any changes
  useEffect(() => {
    if (householdMilestones) {
      if (
        householdMilestones?.results?.length === 5 &&
        householdMilestones?.count !== 5
      ) {
        setLoading(true);
        setMilestones([]);
      } else {
        setLoading(false);
        setMilestones(householdMilestones.results);
      }
      if (householdMilestones.count !== pagesState.count) {
        dispatchPages({
          type: 'update_count',
          payload: householdMilestones.count,
        });
      }
    }
  }, [householdMilestones]);

  useEffect(() => {
    if (showMilestonesElite) {
      filtersState.forEach((filterState) => {
        if (filterState.filterType === 'datepicker') {
          filterState.isVisible = showMilestonesElite;
        }
        return filterState;
      });
    }
  }, [showMilestonesElite]);

  useEffect(() => {
    if (!loading) {
      (async () => {
        dispatchPages({ type: 'update_offset', payload: 0 });
      })().then(() => updateParams());
    }
  }, [ordersState]);

  useEffect(() => {
    updateParams();
  }, [pagesState]);

  // every time the filters or ordering changes update the milestones.
  useEffect(() => {
    if (queryParams) {
      dispatch(getHouseholdMilestones(householdId, queryParams));
    }
  }, [queryParams]);

  /**
   * Handling Events
   */
  // this triggers the query params state change which triggers api call to get milestones based on params
  const updateParams = (offset) => {
    const params = filtersState.reduce(
      (o, key) => ({ ...o, [key.key]: key.param }),
      {}
    );
    if (offset !== undefined) {
      params.offset = offset;
    } else {
      params.offset = pagesState.offset;
    }
    params.ordering = ordersState.reduce((o, key) => o + key.param, '');
    params.limit = pagesState.limit;
    setQueryParams(params);
  };

  const nextPage = () => {
    const offset = pagesState.offset + pagesState.limit;
    dispatchPages({
      type: 'update_offset',
      payload: offset,
    });
    updateParams(offset);
  };

  const prevPage = () => {
    const offset = pagesState.offset - pagesState.limit;
    dispatchPages({
      type: 'update_offset',
      payload: offset,
    });
    updateParams(offset);
  };

  const goToPage = (page) => {
    const offset = pagesState.limit * (page - 1);
    dispatchPages({
      type: 'update_offset',
      payload: offset,
    });
    updateParams(offset);
  };

  const openMilestoneModal = () => {
    dispatch(toggleShowMilestoneModal(true, true));
  };

  return (
    <PageContainer>
      <PageHeader
        content={headerContent.milestones}
        buttonFunction={() =>
          DownloadReport({
            value: 'milestones',
            hasOptions: false,
          })
        }
      />
      <PageContent>
        <ActionRow>
          <FiltersContainer>
            {filtersState.map((filterState, ind) => {
              return (
                filterState?.isVisible && (
                  <Filter
                    data={filterState}
                    type={filterState.filterType}
                    key={ind}
                  />
                )
              );
            })}
          </FiltersContainer>
          <ButtonRow>
            <Button text={'Assign Milestone'} onClick={openMilestoneModal} />
          </ButtonRow>
        </ActionRow>
        {/* {showMilestonesElite === false && (
          <Unlimited>
            Results limited to the last 30 days. Want more?{' '}
            <a
              href="https://www.asset-map.com/upgrade"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </a>
          </Unlimited>
        )} */}
        <MilestonesListContainer>
          <MilestonesListHeader>
            {ordersState.map((orderState) => {
              return orderState.component(orderState, showMilestonesElite);
            })}
          </MilestonesListHeader>
          {loading ? (
            <Empty>
              <LoadingAnimation />
            </Empty>
          ) : (
            <MilestonesListOptions>
              {milestones && milestones.length !== 0 ? (
                milestones.map((milestone, index) => {
                  return (
                    <div key={index}>
                      <MilestoneListItem
                        milestone={milestone}
                        showComments={showMilestonesElite}
                      />
                    </div>
                  );
                })
              ) : (
                <EmptyImage
                  imageSrc={noReports}
                  imageName="No Milestones"
                  text="No Milestones Found"
                />
              )}
              {pagesState?.count > pagesState?.limit && (
                <Pagination
                  clickPrev={prevPage}
                  clickNext={nextPage}
                  goToPage={goToPage}
                  limit={pagesState.limit}
                  offset={pagesState.offset}
                  count={pagesState.count}
                />
              )}
            </MilestonesListOptions>
          )}
        </MilestonesListContainer>
      </PageContent>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const MilestoneName = styled.div`
  width: ${(props) => (props.elite ? '25%' : '33%')};
  padding: 0 10px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  span {
    margin-right: 10px;
  }
`;

const MilestoneAdvisor = styled.div`
  width: ${(props) => (props.elite ? '20%' : '33%')};
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const MilestoneDate = styled.div`
  width: ${(props) => (props.elite ? '20%' : '33%')};
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const MilestoneComment = styled.div`
  width: 35%;
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const MilestonesListHeader = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  border-bottom: 1px solid ${colors.darkGrey};
  span {
    cursor: pointer;
  }
`;

const MilestonesListOptions = styled.div`
  min-height: 180px;
`;

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: -20px 20px 50px 20px;
  }
  margin: -25px 40px 30px 40px;
`;

const FiltersContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${FilterContainer} {
    @media (max-width: 800px) {
      margin-bottom: 8px;
    }
  }
`;

const ButtonRow = styled.div`
  text-align: right;
  margin-top: 20px;
  button {
    width: 140px;
  }
`;

// const Unlimited = styled.div`
//   font-size: 13px;
//   padding: 5px 0;
//   font-style: italic;
//   color: ${colors.paleGrey};
//   a {
//     color: ${buttonColors.secondary};
//   }
//   border-bottom: 1px solid ${colors.lightGrey};
// `;

const ActionRow = styled.div`
  @media ${maxDevice.sideNav} {
    button {
      font-size: 10px;
    }
  }
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 0 14px 0;
`;

const MilestonesListContainer = styled.div`
  padding: 15px 0;
  margin-bottom: 60px;
`;

const Empty = styled.div`
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default HouseholdMilestones;
