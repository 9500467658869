/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  closeAllDropdowns,
  deleteCanvas,
  getConfigs,
  getGlobalConfigs,
  getNotifications,
  getUnreadCount,
  getUserInfo,
  logUserOut,
  markAllNotificationsRead,
  toggleBlackMarker,
  toggleCustomMarker,
  toggleShowHouseholdDropdown,
  toggleShowMarkerDropdown,
  toggleShowNotificationDropdown,
  togglePalette,
  toggleRedMarker,
  toggleShowSearchDropdown,
  toggleSelectTool,
  toggleShowDelegateModal,
  toggleShowUserDropdown,
  logoutOfCustomer,
  toggleNotificationRead,
  getSystemVersion,
  updateUserStoreValue,
  getAuthStatus,
} from '../store/actions';
import { NavBar, NavDropdown } from '../components';
import {
  search,
  home,
  plusLogo,
  marker,
  markerBlack,
  markerRed,
  selectTool,
  palette,
  trash,
  chatAlt,
  bell,
  arrowRight,
  plusCircle,
  settingsIcon,
} from '../assets';
import {
  SUPPORT_LINK as supportLink,
  FEEDBACK as feedback,
  REFER as refer,
} from '../utils/constants';
import { ButtonThemes, InputThemes, DropdownThemes } from '../styles/themes';
import {
  SendTrackingEvent,
  LoadingNotification,
  ToggleCookie,
  DateMonth,
} from '../utils';
import { CONSUMER_BASENAME as consumerBasename } from '../utils/constants';

const NavbarContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    currentHousehold,
    applyingStencil,
    appliedStencil,
    sharingReport,
    sharedReport,
    showClassic,
    isAdmin,
    hasAtlas,
    isCustomerAdmin,
    isBilling,
    isMemberBilling,
    isAccountProvider,
    isImpersonating,
    isImpersonatingCustomer,
    loggedOutOfCustomer,
    isManager,
    hasConsumerAccess,
    isShowingHouseholdDropdown,
    isShowingMarkerDropdown,
    isShowingNotificationDropdown,
    isShowingSearchDropdown,
    isShowingUserDropdown,
    isStaff,
    showPartners,
    loggedIn,
    loggedOut,
    hasMultiSessionError,
    showCUR,
    showTargetMaps,
    user,
    invocationId,
    householdStartPage,
    version,
    showDiscovery,
    userRequiresTerms,
    logo,
    showSnow,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    applyingStencil: state.households.applyingStencil,
    appliedStencil: state.households.appliedStencil,
    sharingReport: state.households.sharingReport,
    sharedReport: state.households.sharedReport,
    showClassic: state.user.showClassic,
    isAdmin: state.user.isAdmin,
    hasAtlas: state.user.hasAtlas,
    isCustomerAdmin: state.user.isCustomerAdmin,
    isBilling: state.user.isBilling,
    isMemberBilling: state.user.isMemberBilling,
    isAccountProvider: state.user.isAccountProvider,
    isImpersonating: state.user.isImpersonating,
    isImpersonatingCustomer: state.user.isImpersonatingCustomer,
    loggedOutOfCustomer: state.user.loggedOutOfCustomer,
    isManager: state.user.isManager,
    hasConsumerAccess: state.user.hasConsumerAccess,
    isShowingHouseholdDropdown: state.dropdowns.showingHouseholdDropdown,
    isShowingMarkerDropdown: state.dropdowns.showingMarkerDropdown,
    isShowingNotificationDropdown: state.dropdowns.showingNotificationDropdown,
    isShowingSearchDropdown: state.dropdowns.showingSearchDropdown,
    isShowingUserDropdown: state.dropdowns.showingUserDropdown,
    isStaff: state.user.isStaff,
    showPartners: state.user.showPartners,
    loggedIn: state.user.loggedIn,
    loggedOut: state.user.loggedOut,
    hasMultiSessionError: state.user.hasMultiSessionError,
    showCUR: state.user.showCUR,
    showTargetMaps: state.user.showTargetMaps,
    user: state.user.user,
    invocationId: state.configs.invocationId,
    householdStartPage: state.user.householdStartPage,
    version: state.configs.version,
    showDiscovery: state.user.showDiscovery,
    userRequiresTerms: state.user.userRequiresTerms,
    logo: state.user.logo,
    showSnow: state.user.showSnow,
  }));
  const {
    usingBlackMarker,
    usingRedMarker,
    usingCustomMarker,
    usingSelect,
    showingPalette,
    customPenColor,
    showingCanvas,
  } = useSelector((state) => ({
    usingBlackMarker: state.marker.usingBlackMarker,
    usingRedMarker: state.marker.usingRedMarker,
    usingCustomMarker: state.marker.usingCustomMarker,
    usingSelect: state.marker.usingSelect,
    showingPalette: state.marker.showingPalette,
    customPenColor: state.marker.customPenColor,
    showingCanvas: state.marker.showingCanvas,
  }));
  const { unread, notifications } = useSelector((state) => ({
    unread: state.notifications.unread,
    notifications: state.notifications.notifications,
  }));
  const [loadingToast, setLoadingToast] = useState();

  useEffect(() => {
    if (loggedIn) {
      dispatch(getUserInfo());
      dispatch(getAuthStatus());
      dispatch(getNotifications());
      dispatch(getConfigs());
      // intervalNotification();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!loggedIn) {
      const { pathname } = window.location;
      dispatch(getGlobalConfigs(pathname));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getUnreadCount());
    }
  }, [notifications]);

  useEffect(() => {
    if (!loggedOut && hasMultiSessionError) {
      // dispatch(logUserOut());
      SendTrackingEvent(invocationId, 'action', 'multisession_logout', null);
    }
  }, [loggedOut, hasMultiSessionError]);

  useEffect(() => {
    if (loggedOutOfCustomer) {
      navigate('/member/dashboard');
    }
  }, [loggedOutOfCustomer]);

  useEffect(() => {
    if (isStaff) {
      dispatch(getSystemVersion());
    }
  }, [isStaff]);

  useEffect(() => {
    if (appliedStencil && loadingToast && loggedIn) {
      try {
        loadingToast.hide();
      } catch {
        console.log('error hiding');
      }
      setLoadingToast();
    }
  }, [appliedStencil]);

  useEffect(() => {
    if (applyingStencil && loggedIn) {
      setLoadingToast(LoadingNotification('Applying Stencil...', 0));
    }
  }, [applyingStencil]);

  useEffect(() => {
    if (sharedReport && loadingToast && loggedIn) {
      try {
        loadingToast.hide();
      } catch {
        console.log('error hiding');
      }
      setLoadingToast();
    }
  }, [sharedReport]);

  useEffect(() => {
    if (sharingReport && loggedIn) {
      setLoadingToast(LoadingNotification('Sharing report...', 0));
    }
  }, [sharingReport]);

  const goToNotificationSettings = () => {
    navigate('/member/preferences');
    //Need to scroll down page as well?
  };

  const determineAvatar = (avatar) => {
    if (avatar === '/static/img/icon-user.png') {
      return null;
    } else {
      return avatar;
    }
  };

  const onIconClick = (dispatchFunc, keyword, type = 'click') => {
    dispatch(dispatchFunc);
    return SendTrackingEvent(invocationId, type, keyword, null);
  };

  const onLinkClick = (keyword) => {
    SendTrackingEvent(invocationId, 'click', keyword, null);
  };

  const redirectClick = () => {
    if (!userRequiresTerms) {
      ToggleCookie('isConsumer', true);
      window.location = `${window.location.origin}/${consumerBasename}/dashboard`;
    }
  };

  const navBarContent = {
    leftContent: [
      {
        kind: 'image',
        src: home,
        alt: 'house',
        title: 'Households',
        function: () =>
          onIconClick(
            toggleShowHouseholdDropdown(!isShowingHouseholdDropdown),
            'nav_top_house'
          ),
      },
      {
        kind: 'image',
        src: search,
        alt: 'search',
        title: 'Search',
        function: () =>
          onIconClick(
            toggleShowSearchDropdown(!isShowingSearchDropdown),
            'nav_top_search'
          ),
      },
      {
        kind: 'link',
        value: userRequiresTerms ? '' : currentHousehold.name,
        id: userRequiresTerms ? '' : currentHousehold.id,
      },
    ],
    centerContent: {
      link: '/member/dashboard',
      appLogo: logo,
      plusLogo: plusLogo,
      alt: 'logo',
      onClick: () => onLinkClick('nav_top_logo'),
    },
    rightContent: [
      {
        src: marker,
        alt: 'marker',
        title: 'Marker',
        function: () =>
          onIconClick(
            toggleShowMarkerDropdown(!isShowingMarkerDropdown),
            'nav_top_marker'
          ),
        hideSmall: true,
        isActive: showingCanvas,
      },
      {
        isIntercom: true,
        src: chatAlt,
        alt: 'chat',
        link: supportLink,
        title: 'Support',
        hideSmall: true,
        onClick: () => onLinkClick('nav_top_help'),
      },
      {
        src: bell,
        alt: 'bell',
        title: 'Notifications',
        function: () => {
          if (!isShowingNotificationDropdown) {
            onLinkClick('nav_top_notification');
            dispatch(getNotifications());
          }
          dispatch(
            toggleShowNotificationDropdown(!isShowingNotificationDropdown)
          );
        },
      },
      {
        isAvatar: true,
        src: determineAvatar(user.avatar),
        initials: user.initials,
        alt: 'avatar',
        title: 'User',
        function: () =>
          dispatch(toggleShowUserDropdown(!isShowingUserDropdown)),
      },
    ],
  };

  const notificationClick = (notification) => {
    dispatch(toggleNotificationRead(notification));
    return SendTrackingEvent(
      invocationId,
      'action',
      'nav_top_notification_mark_read',
      null
    );
  };

  const formatNotifications = () => {
    if (notifications) {
      return [...notifications].map((not) => {
        const notification = { ...not };
        notification.kind = 'notification';
        notification.onClick = notificationClick;
        return notification;
      });
    }
  };

  const notificationDropdown = {
    heading: 'Notifications',
    headingIcon: {
      source: settingsIcon,
      name: 'settings',
      function: goToNotificationSettings,
    },
    items: formatNotifications(),
    bottomContent: [
      {
        type: 'link',
        text: 'View All',
        to: '/notifications',
        onClick: () => onLinkClick('nav_top_notification_view'),
      },
      {
        type: 'function',
        text: 'Mark All Read',
        function: () =>
          onIconClick(
            markAllNotificationsRead(),
            'nav_top_notification_mark_all',
            'action'
          ),
      },
    ],
  };

  const delegateDispatch = () => {
    dispatch(toggleShowDelegateModal(true));
    return dispatch(closeAllDropdowns());
  };

  const customerDispatch = (customer) => {
    dispatch(logoutOfCustomer(customer));
    return dispatch(closeAllDropdowns());
  };

  const userDropdown = () => {
    let dropdown = {
      heading: user.name || 'Unknown',
      items: [
        {
          order: 1,
          label: 'User Preferences',
          link: '/member/preferences',
          kind: 'text',
          onClick: () => onLinkClick('nav_top_user_prefs'),
        },
        {
          order: 5,
          label: 'Integrations',
          link: '/integration',
          kind: 'text',
          onClick: () => onLinkClick('nav_top_user_integrations'),
        },
        {
          order: 7,
          label: 'Refer a Peer',
          link: refer,
          outside: true,
          kind: 'text',
          onClick: () => onLinkClick('nav_top_user_invite_a_peer'),
        },
        {
          order: 10,
          label: 'Key Financial Figures',
          link: '/member/kff',
          endSection: true,
          kind: 'text',
          onClick: () => onLinkClick('nav_top_user_kffprefs'),
        },
        {
          order: 11,
          label: 'Support Documentation',
          link: supportLink,
          outside: true,
          kind: 'text',
          onClick: () => onLinkClick('nav_top_user_support'),
        },
        {
          order: 12,
          label: 'Feedback',
          link: feedback,
          outside: true,
          kind: 'text',
          onClick: () => onLinkClick('nav_top_user_feedback'),
        },
        {
          order: 13,
          label: 'Terms of Service',
          link: '/member/terms',
          endSection: true,
          kind: 'text',
          onClick: () => onLinkClick('nav_top_user_terms'),
        },
      ],
      bottomButton: {
        label: 'Log Out',
        theme: isImpersonatingCustomer
          ? ButtonThemes.inactiveFull
          : ButtonThemes.primaryFull,
        function: () =>
          isImpersonatingCustomer
            ? onIconClick(
                logoutOfCustomer(user?.customer),
                'nav_click_logout_customer_impersonation'
              )
            : onIconClick(logUserOut(), 'nav_top_user_logout'),
      },
    };
    const managerOption = {
      order: 3,
      label: 'Team Settings',
      link: `/manager/team`,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_team'),
    };
    const billingOption = {
      order: 4,
      label: 'Billing',
      link: `/manager/billing`,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_billing'),
    };
    const billingMemberOption = {
      order: 4,
      label: 'Billing',
      outside: true,
      link: `${window.location.origin}/member/billing/?popup=true`,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_billing'),
    };
    const accountProviderOption = {
      order: 5,
      label: 'Advisors',
      outside: true,
      link: `${window.location.origin}/member/advisors/?popup=true`,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_advisors'),
    };
    const partnersOption = {
      order: 6,
      label: 'Partners',
      link: `/partners`,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_partners'),
    };
    const tmPrefOption = {
      order: 8,
      label: 'Target-Map Preferences',
      link: '/member/tmpref',
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_tmprefs'),
    };
    const staffOption = {
      order: 13,
      label: `Staff`,
      version: version,
      link: `${window.location.origin}/staff`,
      outside: true,
      endSection: true,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_staff'),
    };
    const adminOption = {
      order: 14,
      label: 'Admin',
      link: `${window.location.origin}/admin`,
      outside: true,
      endSection: true,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_admin'),
    };
    const custAdminOption = {
      order: 15,
      label: 'Customer Admin',
      link: `${window.location.origin}/custadmin`,
      outside: true,
      endSection: true,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_customer_admin'),
    };
    const classicUX = {
      order: 16,
      label: 'Show Classic UX',
      link: `${window.location.origin}/api/v3/member/beta/disable?path=${window.location.pathname}`,
      oldUI: true,
      endSection: true,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_classic'),
    };
    const discovery = {
      order: 8,
      label: 'Discovery',
      superscript: '™',
      link: '/discovery',
      endSection: true,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_discovery'),
    };
    const redirectToConsumer = {
      order: 17,
      label: 'Switch to Asset-Map Consumer',
      link: ``,
      endSection: true,
      kind: 'text',
      onClick: () => redirectClick(),
    };
    const snowOption = {
      order: 18,
      label: showSnow ? 'Stop the Snow ❄️' : 'Make it Snow ❄️',
      link: ``,
      endSection: true,
      kind: 'text',
      onClick: () => dispatch(updateUserStoreValue('showSnow', !showSnow)),
    };
    const atlas = {
      order: 19,
      label: 'Analytics',
      link: '/manager/analytics',
      outside: false,
      kind: 'text',
      onClick: () => onLinkClick('nav_top_user_atlas'),
    };
    if (user) {
      const month = DateMonth();
      const isWinter = month === 11 || month === 0;
      if (isManager) {
        dropdown.items.push(managerOption);
      }
      if (isBilling) {
        dropdown.items.push(billingOption);
      }
      if (isMemberBilling) {
        dropdown.items.push(billingMemberOption);
      }
      if (isAccountProvider) {
        dropdown.items.push(accountProviderOption);
      }
      if (showTargetMaps) {
        dropdown.items.push(tmPrefOption);
      }
      if (isImpersonating) {
        dropdown.impersonating = true;
        dropdown.delegate = user.delegate;
        if (user?.delegate) {
          dropdown.delegate.onClick = () => delegateDispatch();
        }
      } else {
        dropdown.impersonating = false;
      }
      if (isImpersonatingCustomer) {
        dropdown.impersonatingCustomer = true;
        dropdown.customer = user?.customer;
        dropdown.customer.onClick = () => customerDispatch(user?.customer);
      } else {
        dropdown.impersonatingCustomer = false;
      }
      dropdown.showCUR = showCUR;
      if (showPartners) {
        dropdown.items.push(partnersOption);
      }
      if (isStaff) {
        dropdown.items.push(staffOption);
      }
      if (isAdmin) {
        dropdown.items.push(adminOption);
      }
      if (isCustomerAdmin) {
        dropdown.items.push(custAdminOption);
      }
      if (showClassic) {
        dropdown.items.push(classicUX);
      }
      if (showDiscovery) {
        dropdown.items.push(discovery);
      }
      if (hasConsumerAccess) {
        dropdown.items.push(redirectToConsumer);
      }
      if (isWinter) {
        dropdown.items.push(snowOption);
      }
      if (hasAtlas) {
        dropdown.items.push(atlas);
      }
    }
    dropdown.items.sort((a, b) => {
      return a.order - b.order;
    });
    return dropdown;
  };

  const householdDropdown = {
    heading: 'Households',
    isLeft: true,
    items: [
      {
        label: 'View Household Index',
        link: '/member/households',
        kind: 'buttonLink',
        theme: ButtonThemes.secondary,
        icon: arrowRight,
        onClick: () => onLinkClick('nav_view_household_index'),
      },
      {
        label: 'Add Household',
        link: '/household/new',
        kind: 'buttonLink',
        theme: ButtonThemes.primary,
        icon: plusCircle,
        onClick: () => onLinkClick('nav_add_household'),
      },
    ],
  };

  const onMarkerClick = (dispatchFunc, keyword, type = 'click', name) => {
    dispatch(dispatchFunc);
    return SendTrackingEvent(invocationId, type, keyword, null, {
      action: name,
      hotkey: false,
    });
  };

  const markerDropdown = {
    horizontal: true,
    heading: 'Marker',
    items: [
      {
        label: 'Black Marker (b)',
        kind: 'icon',
        name: 'black_marker',
        icon: markerBlack,
        isSelected: usingBlackMarker,
        function: () =>
          onMarkerClick(
            toggleBlackMarker(!usingBlackMarker),
            'marker_action',
            'action',
            'black_marker'
          ),
      },
      {
        label: 'Red Marker (r)',
        kind: 'icon',
        name: 'red_marker',
        icon: markerRed,
        isSelected: usingRedMarker,
        function: () =>
          onMarkerClick(
            toggleRedMarker(!usingRedMarker),
            'marker_action',
            'action',
            'red_marker'
          ),
      },
      {
        label: `Custom Marker (${customPenColor}) (c)`,
        kind: 'icon',
        name: 'custom_marker',
        color: customPenColor,
        isCustom: true,
        isSelected: usingCustomMarker,
        function: () =>
          onMarkerClick(
            toggleCustomMarker(!usingCustomMarker),
            'marker_action',
            'action',
            'custom'
          ),
      },
      {
        label: 'Color Picker (p)',
        kind: 'icon',
        name: 'color_picker',
        icon: palette,
        isSelected: showingPalette,
        function: () =>
          onMarkerClick(
            togglePalette(!showingPalette),
            'marker_action',
            'action',
            'color'
          ),
      },
      {
        label: 'Select Tool (s, e)',
        kind: 'icon',
        name: 'select_tool',
        icon: selectTool,
        isSelected: usingSelect,
        function: () =>
          onMarkerClick(
            toggleSelectTool(!usingSelect),
            'marker_action',
            'action',
            'select'
          ),
      },
      {
        label: 'Delete Canvas (del, esc)',
        kind: 'icon',
        name: 'delete_canvas',
        icon: trash,
        function: () => {
          onMarkerClick(deleteCanvas(), 'marker_action', 'action', 'clear');
          SendTrackingEvent(invocationId, 'action', 'marker_disable', null, {
            hotkey: false,
          });
        },
      },
    ],
  };

  const searchDropdown = {
    horizontal: true,
    householdStartPage: householdStartPage,
    items: [
      {
        kind: 'input',
        label: 'Search Households',
        theme: InputThemes.dropdownInput,
      },
    ],
  };

  return (
    <>
      <NavBar
        loggedIn={loggedIn}
        navBarContent={navBarContent}
        unread={unread}
      />
      <NavDropdown
        content={searchDropdown}
        theme={DropdownThemes.search}
        show={isShowingSearchDropdown}
        invocationId={invocationId}
      />
      <NavDropdown
        content={householdDropdown}
        theme={DropdownThemes.household}
        show={isShowingHouseholdDropdown}
      />
      <NavDropdown
        content={markerDropdown}
        theme={DropdownThemes.marker}
        show={isShowingMarkerDropdown}
      />
      <NavDropdown
        content={notificationDropdown}
        theme={DropdownThemes.notifications}
        show={isShowingNotificationDropdown}
      />
      <NavDropdown
        content={userDropdown()}
        theme={DropdownThemes.user}
        show={isShowingUserDropdown}
      />
    </>
  );
};

export default NavbarContainer;
