import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, boxShadows, fonts } from '../../styles/variables';

const ModalTabs = ({ tabs, activeTab, onClick }) => {
  const activeTabStyle = {
    background: 'white',
    color: colors.darkGrey,
    boxShadow: boxShadows.boxShadowModalTab,
    zIndex: '10',
  };

  return (
    <ModalTabRow>
      {tabs.map((tab, index) => {
        return (
          <ModalTab
            key={index}
            onClick={() => onClick(tab.value)}
            hasIcon={tab.icon && !tab.showTextAndIcon}
            style={activeTab === tab.value ? activeTabStyle : null}
          >
            {tab.showTextAndIcon ? (
              <TabContainer>
                <ModalIcon
                  src={tab.icon}
                  alt={tab.label}
                  title={tab.label}
                  data-image={tab.value}
                />
                <ModalText width="100px">{tab.label}</ModalText>
              </TabContainer>
            ) : tab.icon ? (
              <ModalIcon
                src={tab.icon}
                alt={tab.label}
                title={tab.label}
                data-image={tab.value}
              />
            ) : (
              <ModalText>{tab.label}</ModalText>
            )}
            {/* {tab.icon && (
              <ModalIcon src={tab.icon} alt={tab.label} title={tab.label} />
            )}
            <ModalText>{tab.label}</ModalText> */}
          </ModalTab>
        );
      })}
    </ModalTabRow>
  );
};

const ModalTabRow = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const ModalText = styled.p`
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
  flex: 1 1 auto;
`;

const ModalIcon = styled.img`
  width: 100%;
  height: 100%;
  max-width: 16px;
  max-height: 16px;
  flex: 0 0 auto;
  /* margin-right: 5px; */
`;

const TabContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  ${ModalText} {
    max-width: 100%;
  }
  ${ModalIcon} {
    margin-right: 5px;
  }
`;

const ModalTab = styled.div`
  padding: 8px 10px;
  height: 17px;
  flex: 1 1 33%;
  flex: ${(props) => (props.hasIcon ? '0 0 auto' : '1 1 33%')};
  font-size: 12px;
  border-right: 1px solid white;
  font-weight: ${fonts.semiBold};
  color: ${colors.paleGrey};
  background: ${colors.lightGrey};
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  &:last-child {
    /* border-right: 1px solid ${colors.lightGrey}; */
    border-right: 1px solid transparent;
  }
`;

ModalTabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.string,
  onClick: PropTypes.func,
};

export default ModalTabs;
