/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOM from 'react-dom';
import { useRef, useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ModalButtons,
  DetectModalKeys,
  GenerateInput,
  Error,
  LoadingAnimation,
} from '..';
import { timesWhite } from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalContainer,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ModalLoadingOverlayContainer,
  LoadingOverlayContainer,
  LoadingOverlay,
} from '../../styles/library/modalStyles';
import {
  LegalApplicableMemberOption,
  LegalEmpoweredMemberOption,
  UseHandleInstrumentError,
  CheckHasError,
  GenerateError,
  RemoveError,
  DateFormat,
} from '../../utils';
import {
  createLegal,
  clearLegal,
  updateHouseholdStoreValue,
  updateLegal,
} from '../../store/actions/households';

const legalReducer = (legal, action) => {
  legal[action.type] = action.payload;
  return legal;
};

const LegalModal = ({ isNew, hide, householdId }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {
    legalCategories,
    currentHousehold,
    legalError,
    preferences,
    legal,
    clearedLegal,
  } = useSelector((state) => ({
    legalCategories: state.configs.legalCategories,
    currentHousehold: state.households.currentHousehold,
    legalError: state.households.legalError,
    preferences: state.user.user.preferences,
    legal: state.households.legal,
    clearedLegal: state.households.clearedLegal,
  }));

  const initLegalState = {
    category: '',
    applicable_members: [],
    reference_name: '',
    location: '',
    empowered_members: [],
    date_created: '',
    date_collected: '',
    notes: '',
  };
  const [legalState, dispatchLegal] = useReducer(legalReducer, initLegalState);

  const [typeOptions, setTypeOptions] = useState();
  const [membersList, setMembersList] = useState();
  const [hasMemberList, setHasMemberList] = useState(false);
  const [legalType, setLegalType] = useState();
  const [hasApplicables, setHasApplicables] = useState(false);
  const [hasEmpowered, setHasEmpowered] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadedLegal, setLoadedLegal] = useState(false);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [hasLegal, setHasLegal] = useState(false);
  const [categoryMap, setCategoryMap] = useState();
  const [memberMap, setMemberMap] = useState();

  useEffect(() => {
    return () => {
      dispatch(clearLegal());
    };
  }, []);

  useEffect(() => {
    if (clearedLegal) {
      setTypeOptions();
      setMembersList();
      setHasMemberList(false);
      setLegalType();
      setHasApplicables(false);
      setHasEmpowered(false);
      setLoadedLegal(false);
      setModalError('');
      setModalErrors();
      setErrorTitle();
      setHasLegal(false);
      setCategoryMap();
      dispatch(updateHouseholdStoreValue('clearedLegal', false));
    }
  }, [clearedLegal]);

  useEffect(() => {
    if (legal) {
      setHasLegal(true);
    }
  }, [legal]);

  useEffect(() => {
    if (legalCategories) {
      const optionsArray = Object.entries(legalCategories);
      const sortedOptions = optionsArray.sort(
        (a, b) => a[1].priority - b[1].priority
      );
      const categoryMapper = {};
      const sortedData = sortedOptions.map((option) => {
        categoryMapper[option[0]] = {
          value: option[1].key,
          label: option[1].name,
          index: option[1].priority - 1,
        };
        return categoryMapper[option[0]];
      });
      setCategoryMap(categoryMapper);
      setLegalType(sortedData[0]);
      dispatchLegal({ type: 'category', payload: sortedData[0].value });
      setTypeOptions(sortedData);
    }
  }, [legalCategories]);

  useEffect(() => {
    if (currentHousehold.householdmembers) {
      const memberMapper = {};
      setMembersList(
        currentHousehold.householdmembers.map((member) => {
          member.isApplicable = false;
          member.isEmpowered = false;
          memberMapper[member.uuid] = member;
          return member;
        })
      );
      setHasMemberList(true);
      setMemberMap(memberMapper);
    }
  }, [currentHousehold.householdmembers]);

  UseHandleInstrumentError(
    legalError,
    setButtonLoading,
    setModalError,
    setModalErrors,
    setErrorTitle,
    setLoadedLegal
  );

  const updateApplicable = () => {
    dispatchLegal({
      type: 'applicable_members',
      payload: membersList.filter((member) => member.isApplicable === true),
    });
    setHasApplicables(true);
  };

  const updateEmpowered = () => {
    dispatchLegal({
      type: 'empowered_members',
      payload: membersList.filter((member) => member.isEmpowered === true),
    });
    setHasEmpowered(true);
  };

  const updateSelected = (e, field, callback) => {
    setMembersList(
      membersList.map((member) => {
        if (Array.isArray(e)) {
          e.forEach((m) => {
            let mem = memberMap[m.uuid];
            if (mem.id === member.id) {
              member[field] = !member[field];
            }
          });
          return member;
        } else {
          if (e.id === member.id) {
            member[field] = !member[field];
          }
          return member;
        }
      })
    );
    return callback();
  };

  const updateLegalType = (e) => {
    if (typeof e === 'string') e = categoryMap[e];
    setLegalType(e);
    dispatchLegal({ type: 'category', payload: e.value });
  };

  const changeValue = (e, field) => {
    switch (field) {
      case 'category':
        updateLegalType(e);
        break;
      case 'applicable_members':
        updateSelected(e, 'isApplicable', updateApplicable);
        if (CheckHasError(modalErrors, 'applicable_members')) {
          setModalErrors(RemoveError(modalErrors, 'applicable_members'));
        }
        break;
      case 'reference_name':
      case 'location':
        dispatchLegal({
          type: field,
          payload:
            e?.target?.value !== null && e?.target?.value !== undefined
              ? e?.target?.value
              : e,
        });
        break;
      case 'empowered_members':
        updateSelected(e, 'isEmpowered', updateEmpowered);
        break;
      case 'date_created':
      case 'date_collected':
        dispatchLegal({
          type: field,
          payload: e !== '' ? DateFormat(e, preferences.date_format) : '',
        });
        break;
      default: // notes
        dispatchLegal({ type: field, payload: e });
        break;
    }
  };

  useEffect(() => {
    if (modalErrors) {
      if (!modalErrors || Object.entries(modalErrors).length === 0) {
        setModalError('')
      }
    }
  }, [modalErrors]);

  const validationChecker = (name) => {
    if (CheckHasError(modalErrors, name)) {
      const updatedErrors = RemoveError(modalErrors, name)
      setModalErrors(updatedErrors);
    }
  };

  const legalInputs = [
    {
      type: 'select',
      label: 'Type',
      name: 'category',
      required: true,
      placeholder: 'Type',
      value: legalType,
      options: typeOptions,
      width: '100%',
      isVisible: true,
      onChange: (e) => changeValue(e, 'category'),
      hasError: CheckHasError(modalErrors, 'category'),
      errorMessage: GenerateError(modalErrors, 'category'),
    },
    {
      type: 'select',
      label: 'Applicable Members',
      name: 'applicable_members',
      required: true,
      placeholder: 'Applicable members',
      customSelect: true,
      customComponent: LegalApplicableMemberOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: membersList,
      onChange: (e) => changeValue(e, 'applicable_members'),
      selectedOptions: legalState.applicable_members,
      showSelected: hasApplicables,
      hasSelected: true,
      tooltip: 'On whose behalf; e.g. Principal, Grantor, Testator',
      hasError: CheckHasError(modalErrors, 'applicable_members'),
      errorMessage: GenerateError(modalErrors, 'applicable_members'),
    },
    {
      type: 'text',
      label: 'Reference Name',
      name: 'reference_name',
      required: true,
      placeholder: 'e.g. Will, Trust, POA',
      value: legalState.reference_name,
      onChange: (e) => changeValue(e, 'reference_name'),
      isVisible: true,
      width: '100%',
      hasError: CheckHasError(modalErrors, 'reference_name'),
      errorMessage: GenerateError(modalErrors, 'reference_name'),
      onFocus: () => validationChecker('reference_name'),
    },
    {
      type: 'text',
      label: 'Location',
      name: 'location',
      required: true,
      placeholder: "e.g. Institution, Attorney's Office",
      value: legalState.location,
      onChange: (e) => changeValue(e, 'location'),
      isVisible: true,
      width: '100%',
      hasError: CheckHasError(modalErrors, 'location'),
      errorMessage: GenerateError(modalErrors, 'location'),
      onFocus: () => validationChecker('location'),
    },
    {
      type: 'select',
      label: 'Empowered Members',
      name: 'empowered_members',
      placeholder: 'Empowered members',
      customSelect: true,
      customComponent: LegalEmpoweredMemberOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: membersList,
      onChange: (e) => changeValue(e, 'empowered_members'),
      selectedOptions: legalState.empowered_members,
      showSelected: hasEmpowered,
      hasSelected: true,
      tooltip:
        'The authorized or responsible party, e.g. executor, trustee, agent, attorney-in-fact, personal representative',
      hasError: CheckHasError(modalErrors, 'empowered_members'),
      errorMessage: GenerateError(modalErrors, 'empowered_members'),
    },
    {
      type: 'date',
      label: 'Date',
      name: 'date_created',
      width: '48%',
      required: false,
      placeholder: 'mm/yyyy',
      dateFormat: 'MM/yyyy',
      value: legalState.date_created,
      isVisible: true,
      onChange: (e) => changeValue(e, 'date_created'),
      hasError: CheckHasError(modalErrors, 'date_created'),
      errorMessage: GenerateError(modalErrors, 'date_created'),
    },
    {
      type: 'date',
      label: 'Data Collected',
      name: 'date_collected',
      width: '48%',
      required: false,
      placeholder: 'mm/dd/yyyy',
      dateFormat: 'MM/DD/YYYY',
      value: legalState.date_collected,
      isVisible: true,
      onChange: (e) => changeValue(e, 'date_collected'),
      hasError: CheckHasError(modalErrors, 'date_collected'),
      errorMessage: GenerateError(modalErrors, 'date_collected'),
    },
    {
      type: 'textarea',
      label: 'Notes',
      name: 'notes',
      showClear: true,
      id: 'asset',
      value: legalState.notes,
      onChange: (e) => changeValue(e, 'notes'),
      hasError: CheckHasError(modalErrors, 'notes'),
      errorMessage: GenerateError(modalErrors, 'notes'),
    },
  ];

  const onSaveLegal = () => {
    setButtonLoading(true);
    setModalErrors();
    if (isNew) {
      dispatch(
        createLegal(
          householdId,
          legalState,
          categoryMap[legalState.category].label
        )
      );
    } else {
      dispatch(
        updateLegal(
          householdId,
          legal.id,
          legalState,
          categoryMap[legalState.category].label
        )
      );
    }
  };

  // EDIT
  useEffect(() => {
    if (!isNew && hasLegal && hasMemberList) {
      delete legal.passId;
      delete legal.passSubId;
      for (let field in legal) {
        changeValue(legal[field], field);
      }
      setLoadedLegal(true);
    }
  }, [isNew, hasLegal, hasMemberList]);

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEsc={hide} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>{isNew ? 'Add Legal' : 'Edit Legal'}</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalLoadingOverlayContainer>
            {!loadedLegal && !isNew && (
              <LoadingOverlayContainer>
                <LoadingOverlay>
                  <LoadingAnimation />
                  {/* <LoadingAnimation smaller={true} /> */}
                  {/* <p>Loading</p> */}
                </LoadingOverlay>
              </LoadingOverlayContainer>
            )}
            <ModalContentContainer>
              <TabContent>
                {legalInputs.map((input, index) => {
                  return GenerateInput(input, index);
                })}
              </TabContent>

              <ModalButtons
                isNew={isNew}
                hide={hide}
                //   isHidden={buttonContent.isHidden}
                saveFunction={onSaveLegal}
                showLoading={buttonLoading}
                loadingText={isNew ? 'Creating' : 'Saving'}
              />
            </ModalContentContainer>
          </ModalLoadingOverlayContainer>
          {modalError && modalError !== '' && (
            <Error errorMessage={modalError} title={errorTitle} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

LegalModal.propTypes = {
  isNew: PropTypes.bool,
  hide: PropTypes.func,
  householdId: PropTypes.string,
};

export default LegalModal;
