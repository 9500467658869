/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ChromePicker } from 'react-color';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Select, TextInput, Error, DetectModalKeys } from '..';
import {
  createTargetMap,
  getTargetMapTemplates,
  toggleShowDeleteModal,
  toggleShowTargetMapTemplateModal,
} from '../../store/actions';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import { timesWhite, square, checkboxGreen } from '../../assets';
import {
  MemberOption,
  HtmlToString,
  TargetMapTemplateOption,
  DetectEnterKeyPress,
  UseOutsideClick,
} from '../../utils';
import { ButtonThemes } from '../../styles/themes';
import { Input } from '../Inputs/TextInput';
import { boxShadows, colors, targetMapColors } from '../../styles/variables';

const NewTargetMapModal = ({
  hide,
  householdId,
  selectedType,
  hasTemplates,
  invisible,
}) => {
  const ref = useRef();
  const pickerRef = useRef();
  const dispatch = useDispatch();
  const { user, householdMembers, targetMapTemplates, createTargetMapError } =
    useSelector((state) => ({
      user: state.user.user,
      householdMembers: state.households.currentHousehold.householdmembers,
      targetMapTemplates: state.targetMaps.targetMapTemplates,
      createTargetMapError: state.targetMaps.createTargetMapError,
    }));
  const [targetMapError, setTargetMapError] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [customColor, setCustomColor] = useState(targetMapColors.custom);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [targetMapTypes, setTargetMapTypes] = useState([
    {
      value: 'death',
      label: 'Loss of Life',
      color: targetMapColors.death,
      selected: false,
      noOptionsMessage: 'No Members',
      selectedValue: {
        label: 'Select Members',
        value: '',
      },
    },
    {
      value: 'disability',
      label: 'Long-term Disability',
      color: targetMapColors.disability,
      selected: false,
      noOptionsMessage: 'No Members',
      selectedValue: {
        label: 'Select Members',
        value: '',
      },
    },
    {
      value: 'ltc',
      label: 'Long-term Care Event',
      color: targetMapColors.ltc,
      selected: false,
      noOptionsMessage: 'No Members',
      selectedValue: {
        label: 'Select Members',
        value: '',
      },
    },
    {
      value: 'education',
      label: 'Education Funding',
      color: targetMapColors.education,
      selected: false,
      noOptionsMessage: 'No Dependents Available',
      selectedValue: {
        label: 'Select Members',
        value: '',
      },
    },
    {
      value: 'retirement',
      label: 'Retirement Funding',
      color: targetMapColors.retirement,
      selected: false,
      title: '',
      noOptionsMessage: 'No Members',
      selectedValue: {
        label: 'Select Members',
        value: '',
      },
    },
    {
      value: 'custom',
      label: 'Custom',
      color: customColor,
      showInput: true,
      selected: false,
      hasColorPicker: true,
      noOptionsMessage: 'No Members',
      title: '',
      selectedValue: {
        label: 'Select Members',
        value: '',
      },
    },
    {
      value: 'user',
      label: 'Template',
      color: targetMapColors.user,
      selected: false,
      hidden: true,
      showTemplateSelect: true,
      noOptionsMessage: 'No Members',
      selectedTemplate: '',
      selectedValue: {
        label: 'Select Members',
        value: '',
      },
    },
  ]);
  const [templateList, setTemplateList] = useState([]);

  UseOutsideClick(pickerRef, () => {
    if (showColorPicker) {
      setShowColorPicker(false);
    }
  });

  useEffect(() => {
    if (hasTemplates) {
      dispatch(getTargetMapTemplates());
    }
  }, []);

  useEffect(() => {
    if (targetMapTemplates) {
      setTemplateList(
        targetMapTemplates.map((temp) => {
          temp.label = temp.name;
          temp.value = temp.id;
          return temp;
        })
      );
      setTargetMapTypes(
        targetMapTypes.map((tm) => {
          tm.selectedTemplate = '';
          return tm;
        })
      );
    }
  }, [targetMapTemplates]);

  useEffect(() => {
    if (selectedType) {
      setTargetMapTypes(
        targetMapTypes.map((tm) => {
          if (tm.value === selectedType) {
            tm.selected = true;
          }
          return tm;
        })
      );
    }
  }, [selectedType]);

  useEffect(() => {
    if (householdMembers && user?.entitlements) {
      const targetMapMembers = householdMembers.filter(
        (member) => member.member_type === 'member'
      );
      setTargetMapTypes(
        targetMapTypes.map((tm) => {
          let updatedList = targetMapMembers.map((mem) => {
            let member = { ...mem };
            member.name = HtmlToString(mem.name);
            if (targetMapMembers.length === 1) {
              member.selected = true;
            } else {
              member.selected = false;
            }
            return member;
          });
          if (tm.value === 'user') {
            tm.hidden = !hasTemplates;
          }

          if (tm.value === 'retirement') {
            const mainMembers = updatedList.filter(
              (mem) => mem.category === 'primary' || mem.category === 'spouse'
            );
            if (mainMembers.length >= 2) {
              const updatedMembers = mainMembers.slice(0, 2);
              const jointObj = {
                name: `${updatedMembers[0].name} (${updatedMembers[0].age}) & ${updatedMembers[1].name} (${updatedMembers[1].age})`,
                value: 'is_primary',
                hideAge: true,
                selected: false,
              };
              tm.members = [jointObj, ...updatedList];
            } else {
              tm.members = [...updatedList];
            }
          } else if (tm.value === 'education') {
            const membersList = updatedList.filter((mem) => mem.is_dependent);
            if (membersList.length > 1) {
              const allObj = {
                name: `All Dependents`,
                value: 'is_dependents',
                hideAge: true,
                selected: false,
              };
              tm.members = [allObj, ...membersList];
            } else {
              tm.members = [...membersList];
            }
          } else {
            tm.members = [...updatedList];
          }
          return tm;
        })
      );
    }
  }, [householdMembers, user?.entitlements]);

  useEffect(() => {
    if (createTargetMapError) {
      setTargetMapError(
        createTargetMapError?.data?.detail || 'Unknown Server Error'
      );
      setButtonLoading(false);
    }
  }, [createTargetMapError]);

  const createTargetMaps = () => {
    if (buttonLoading || invisible) {
      return;
    }
    const selectedTargetMaps = targetMapTypes.filter(
      (targetMap) => targetMap.selected
    );
    if (selectedTargetMaps.length === 0) {
      return setTargetMapError('Please select at least one Target-Map type.');
    } else {
      const hasMissingMembers = selectedTargetMaps.map((targetMap) => {
        if (targetMap?.members.some((mem) => mem.selected)) {
          return false;
        } else {
          return true;
        }
      });
      if (hasMissingMembers.some((mem) => mem === true)) {
        return setTargetMapError(
          'You must select at least one member for each selected type.'
        );
      } else {
        const transformedTargetMaps = selectedTargetMaps.reduce((acc, curr) => {
          let obj = {};
          obj.template = curr.value;
          if (curr.value === 'custom') {
            obj.color = customColor;
          }
          const selectedMembers = curr.members.filter((mem) => mem.selected);
          const memberIds = selectedMembers.reduce((acc, curr) => {
            if (curr.hasOwnProperty('id')) {
              acc.push(curr.id);
            }
            return acc;
          }, []);
          obj.members = memberIds;
          if (curr.showInput) {
            obj.name = curr.title;
          }
          if (memberIds.length !== 0) {
            acc.push(obj);
          }
          return acc;
        }, []);

        let hasJointSelected = false;
        const retirementTargetMap = selectedTargetMaps.find(
          (targetMap) => targetMap.value === 'retirement'
        );
        if (retirementTargetMap) {
          const jointOption = retirementTargetMap.members.find(
            (mem) => mem.value === 'is_primary'
          );
          if (jointOption && jointOption.selected) {
            hasJointSelected = true;
          }
        }
        let hasDependentsSelected = false;
        const educationTargetMap = selectedTargetMaps.find(
          (targetMap) => targetMap.value === 'education'
        );
        if (educationTargetMap) {
          const jointOption = educationTargetMap.members.find(
            (mem) => mem.value === 'is_dependents'
          );
          if (jointOption && jointOption.selected) {
            hasDependentsSelected = true;
          }
        }

        let targetMapList = {
          targetmaps: transformedTargetMaps,
        };
        let templateId = false;
        const templateTargetMap = selectedTargetMaps.find(
          (targetMap) => targetMap.value === 'user'
        );
        if (templateTargetMap) {
          if (!templateTargetMap?.selectedTemplate?.value) {
            return setTargetMapError('You must select a template.');
          } else {
            templateId = templateTargetMap.selectedTemplate.value;
          }
        }

        if (templateId) {
          targetMapList.template_id = templateId;
        }

        if (hasJointSelected) {
          targetMapList.targetmaps.push({
            template: 'retirement',
            is_primary: true,
          });
        }
        if (hasDependentsSelected) {
          targetMapList.targetmaps.push({
            template: 'education',
            is_dependents: true,
          });
        }
        setButtonLoading(true);
        dispatch(createTargetMap(householdId, targetMapList));
      }
    }
  };

  const getSelectedMembersName = (targetMap) => {
    const selectedMembers = targetMap.members.filter(
      (member) => member.selected
    );
    const selectedMemberNames = selectedMembers.map((member) => member.name);
    let labelValue = 'Select Members';
    if (selectedMemberNames.length !== 0) {
      labelValue = selectedMemberNames.join(', ');
    }
    return labelValue;
  };

  const toggleSelectTargetMap = (targetMap) => {
    setTargetMapError('');
    setTargetMapTypes(
      targetMapTypes.map((tm) => {
        if (tm.value === targetMap.value) {
          tm.selected = !tm.selected;
          tm.selectedValue = {
            label: getSelectedMembersName(tm),
            value: '',
          };
        }
        return tm;
      })
    );
  };

  const memberSelectChange = (e, targetMap) => {
    setTargetMapError('');
    const updatedTargetMaps = targetMapTypes.map((tm) => {
      if (tm.value === targetMap.value) {
        tm.members = tm.members.map((mem) => {
          if (mem.id === e.id) {
            mem.selected = !mem.selected;
          }
          return mem;
        });
        tm.selectedValue = {
          label: getSelectedMembersName(tm),
          value: '',
        };
      }
      return tm;
    });
    setTargetMapTypes(updatedTargetMaps);
  };

  const editTemplate = (option) => {
    const { data, name, value } = option;
    const transformedWants = data.map((want) => {
      return {
        name: want.name,
        amount: want.amount,
        member: want.member_category,
        apply_all: want.apply_all,
      };
    });
    const selectedTemplate = { name, value };
    return dispatch(
      toggleShowTargetMapTemplateModal(
        true,
        false,
        transformedWants,
        selectedTemplate
      )
    );
  };

  const deleteTemplate = (option) => {
    dispatch(
      toggleShowDeleteModal(true, 'tm_template', option.label, option.value)
    );
  };

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEnter={createTargetMaps} onEsc={hide} />
      <ModalBackground invisible={invisible} />
      <ModalOverlay invisible={invisible}>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>New Target-Map</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <SubText>
              Use the checkboxes to select the Target-Maps you want to create,
              then select which member(s).
            </SubText>
            <TabContent>
              {targetMapTypes.map((targetMap, index) => {
                return !targetMap.hidden ? (
                  <TargetMapOptionContainer
                    key={index}
                    style={
                      targetMap.selected
                        ? {
                            background: 'white',
                            boxShadow: boxShadows.boxShadowSoft,
                            border: `1px solid ${colors.hoverLightGrey}`,
                          }
                        : null
                    }
                  >
                    <DisplayRow
                      onClick={
                        targetMap.hasColorPicker
                          ? null
                          : () => toggleSelectTargetMap(targetMap)
                      }
                    >
                      <CheckboxItem
                        src={targetMap.selected ? checkboxGreen : square}
                        alt="box"
                        data-image={`checkbox-${
                          targetMap.selected ? 'checked' : 'unchecked'
                        }`}
                        style={
                          targetMap.selected ? { opacity: 1 } : { opacity: 0.4 }
                        }
                        onClick={
                          targetMap.hasColorPicker
                            ? () => toggleSelectTargetMap(targetMap)
                            : null
                        }
                      />
                      <CircleContainer>
                        <ColorCircle height="10" width="10">
                          <circle
                            cx="5"
                            cy="5"
                            r="5"
                            fill={
                              targetMap.hasColorPicker
                                ? customColor
                                : targetMap.color
                            }
                            onClick={
                              targetMap.hasColorPicker
                                ? () => setShowColorPicker(!showColorPicker)
                                : null
                            }
                          />
                        </ColorCircle>
                        {targetMap.hasColorPicker && showColorPicker && (
                          <PickerContainer ref={pickerRef}>
                            <ChromePicker
                              color={customColor}
                              onChange={(e) => setCustomColor(e?.hex)}
                              disableAlpha={true}
                            />
                          </PickerContainer>
                        )}
                      </CircleContainer>
                      <TargetMapName
                        onClick={
                          targetMap.hasColorPicker
                            ? () => toggleSelectTargetMap(targetMap)
                            : null
                        }
                      >
                        {targetMap.label}
                      </TargetMapName>
                    </DisplayRow>
                    {targetMap.showInput && targetMap.selected && (
                      <TextInputContainer>
                        <TextInput
                          type="text"
                          placeholder={'Enter a Title'}
                          maxLength={'80'}
                          value={targetMap.title}
                          onKeyPress={(e) =>
                            DetectEnterKeyPress(e, createTargetMaps)
                          }
                          onChange={(e) =>
                            setTargetMapTypes(
                              targetMapTypes.map((tm) => {
                                if (tm.value === targetMap.value) {
                                  tm.title = e.currentTarget.value;
                                }
                                return tm;
                              })
                            )
                          }
                        />
                      </TextInputContainer>
                    )}
                    {targetMap.selected && targetMap.showTemplateSelect && (
                      <MemberRow>
                        <SelectContainer margin="-5px 0 5px 0">
                          <Select
                            selectedOption={targetMap.selectedTemplate}
                            components={{
                              Option: (e) =>
                                TargetMapTemplateOption(
                                  e,
                                  editTemplate,
                                  deleteTemplate
                                ),
                            }}
                            onChange={(e) => {
                              setTargetMapError('');
                              setTargetMapTypes(
                                targetMapTypes.map((tm) => {
                                  if (tm.value === targetMap.value) {
                                    tm.selectedTemplate = e;
                                  }
                                  return tm;
                                })
                              );
                            }}
                            options={templateList}
                            isSearchable={false}
                            placeholder={'Select Template'}
                            autoFocus={false}
                            isDisabled={false}
                            noOptionsMessage={'No Templates'}
                          />
                        </SelectContainer>
                      </MemberRow>
                    )}
                    {targetMap.selected && (
                      <MemberRow>
                        <SelectContainer>
                          <Select
                            selectedOption={targetMap.selectedValue}
                            components={{ Option: MemberOption }}
                            onChange={(e) => memberSelectChange(e, targetMap)}
                            options={targetMap.members}
                            isSearchable={false}
                            placeholder={'Select Members'}
                            autoFocus={false}
                            isDisabled={false}
                            closeMenuOnSelect={false}
                            noOptionsMessage={targetMap.noOptionsMessage}
                          />
                        </SelectContainer>
                      </MemberRow>
                    )}
                  </TargetMapOptionContainer>
                ) : null;
              })}
            </TabContent>
            <ButtonsRowContainer>
              <ButtonContainer>
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={'Create'}
                  onClick={() => createTargetMaps()}
                  showLoading={buttonLoading}
                  loadingText={'Creating'}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {targetMapError && targetMapError !== '' && (
            <Error errorMessage={targetMapError} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const SubText = styled.p`
  margin-bottom: 15px;
  color: ${colors.darkGrey};
  font-size: 12px;
`;

const TargetMapOptionContainer = styled.div`
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-star;
  justify-content: flex-start;
  padding: 10px 15px;
  background: ${colors.lighterGrey};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  cursor: pointer;
`;

const DisplayRow = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  ${Input} {
    margin-left: 10px;
    font-size: 13px;
    padding: 5px 10px;
  }
`;

const CheckboxItem = styled.img`
  cursor: pointer;
  width: 15px;
  height: 15px;
  flex: 0 0 auto;
  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }
`;

const CircleContainer = styled.div`
  position: relative;
`;

const PickerContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 22px;
  left: 10px;
`;

const ColorCircle = styled.svg`
  margin: 0 10px;
  flex: 0 0 auto;
`;

const TargetMapName = styled.span`
  flex: 0 0 auto;
  text-align: left;
`;

const MemberRow = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0 -5px 0;
`;

const TextInputContainer = styled.div`
  margin-top: 10px;
  display: flex;
  input {
    padding: 6px 10px;
  }
`;

const SelectContainer = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '0 0 10px 0')};
  display: flex;
  text-align: left;
  flex-direction: column;
  width: 100%;
  div {
    div {
      div {
        font-size: 13px;
      }
    }
  }
`;

NewTargetMapModal.propTypes = {
  hide: PropTypes.func,
  householdId: PropTypes.string,
  selectedType: PropTypes.object,
  hasTemplates: PropTypes.bool,
  invisible: PropTypes.bool,
};

export default NewTargetMapModal;
