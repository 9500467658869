/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';
import {
  PageHeader,
  TabMenu,
  Button,
  TextInput,
  Select,
} from '../../components';
import {
  getTargetMapPreferences,
  getUserInfo,
  updateTargetMapPreferences,
} from '../../store/actions';
import { Page, PageContent } from '../../styles/library/layoutStyles';
import { SecondaryHeading } from '../../styles/library/fontStyles';
import { checkboxGreen, square, hint, errorCircleRed } from '../../assets';
import {
  colors,
  inputColors,
  messageColors,
  fonts,
} from '../../styles/variables';
import {
  UpdatePageTitle,
  CloseDropdowns,
  headerContent,
  MatchSelectItem,
} from '../../utils';
import { SelectContainer } from '../../styles/library/inputStyles';

const TargetMapPreferences = () => {
  UpdatePageTitle('Target-Map Preferences');
  CloseDropdowns();
  const dispatch = useDispatch();
  const {
    targetMapPreferences,
    updatedTargetMapPreferences,
    tmPrefError,
    preferences,
    currencies,
  } = useSelector((state) => ({
    targetMapPreferences: state.user.targetMapPreferences,
    updatedTargetMapPreferences: state.user.updatedTargetMapPreferences,
    tmPrefError: state.user.tmPrefError,
    preferences: state.user.user.preferences,
    currencies: state.configs.currencies,
  }));

  const [activeTab, setActiveTab] = useState('general');
  const [userCurrency, setUserCurrency] = useState({
    code: 'USD',
    decimal: '.',
    precision: '2',
    symbol: '$',
    symbol_location: 'prefix',
    thousand_separator: ',',
  });
  const [tmPreferencesErrors, setTmPreferencesErrors] = useState();
  const [tmPreferences, setTmPreferences] = useState({
    tm_growth_rate: 0,
    tm_inflation_rate: 0,
    tm_age_retirement: 0,
    tm_age_mortality: 0,
    tm_default_tab: { label: '3. What it means', value: 'means' },
    tm_tax_income_earned: 0,
    tm_tax_income_socialsecurity: 0,
    tm_tax_income_unearned: 0,
    tm_tax_asset_retirement: 0,
    tm_tax_asset_roth: 0,
    tm_tax_asset_nonretirement: 0,
    tm_tax_asset_nonretirement_annuities: 0,
    tm_tax_asset_529: 0,
    tm_tax_asset_cash_value_life: 0,
    tm_tax_insurance_group_ltd: 0,
    tm_tax_insurance_individual_ltd: 0,
    tm_tax_insurance_tax_qualified_ltc: 0,
    tm_tax_insurance_not_tax_qualified_ltc: 0,
    tm_disability_incomereplacement_enabled: true,
    tm_disability_incomereplacement_currentincomeratio: 0,
    tm_disability_incomereplacement_endingage: 0,
    tm_disability_incomereplacement_cola: 0,
    tm_disability_lostretirement_enabled: true,
    tm_disability_lostretirement_currentincomeratio: 0,
    tm_disability_lostretirement_endingage: 0,
    tm_disability_lostretirement_cola: 0,
    tm_ltc_annualcare_enabled: true,
    tm_ltc_annualcare_amount: 0,
    tm_ltc_annualcare_years: 0,
    tm_ltc_annualcare_cola: 0,
    tm_ltc_homemod_enabled: true,
    tm_ltc_homemod_amount: 0,
    tm_ltc_caretraining_enabled: true,
    tm_ltc_caretraining_amount: 0,
    tm_ltc_carecoordination_enabled: true,
    tm_ltc_carecoordination_amount: 0,
    tm_edu_edufund_enabled: true,
    tm_edu_edufund_amount: 0,
    tm_edu_edufund_cola: 0,
    tm_edu_edufund_startage: 0,
    tm_edu_edufund_endage: 0,
    tm_retire_living_enabled: true,
    tm_retire_living_householdincomeratio: 0,
    tm_retire_living_startage: 0,
    tm_retire_living_endage: 0,
    tm_retire_living_cola: 0,
    tm_retire_travel_enabled: true,
    tm_retire_travel_householdincomeratio: 0,
    tm_retire_travel_startage: 0,
    tm_retire_travel_endage: 0,
    tm_retire_travel_cola: 0,
    tm_retire_medical_enabled: true,
    tm_retire_medical_householdincomeratio: 0,
    tm_retire_medical_startage: 0,
    tm_retire_medical_endage: 0,
    tm_retire_medical_cola: 0,
  });
  const tabs = [
    {
      text: 'General Settings',
      value: 'general',
    },
    {
      text: 'Loss of Life',
      value: 'loss',
    },
    {
      text: 'Disability',
      value: 'disability',
    },
    {
      text: 'Long-term Care',
      value: 'ltc',
    },
    {
      text: 'Dependent Education',
      value: 'dependent',
    },
    {
      text: 'Retirement',
      value: 'retirement',
    },
  ];
  const defaultTabOptions = [
    { label: '1. What you want', value: 'want' },
    { label: '2. What you have', value: 'have' },
    { label: '3. What it means', value: 'means' },
  ];

  useEffect(() => {
    dispatch(getTargetMapPreferences());
  }, []);

  useEffect(() => {
    if (updatedTargetMapPreferences) {
      dispatch(getUserInfo());
    }
  }, [updatedTargetMapPreferences]);

  useEffect(() => {
    if (preferences && preferences.hasOwnProperty('currency') && currencies) {
      // SetDefaultItem(currencies, preferences.currency);
      const matchedCurrency = currencies.find(
        (currency) => currency.code === preferences.currency
      );
      if (matchedCurrency) {
        setUserCurrency(matchedCurrency);
      }
    }
  }, [preferences, currencies]);

  useEffect(() => {
    if (targetMapPreferences) {
      if (
        targetMapPreferences.hasOwnProperty(
          'tm_death_incomereplacement_earnedincomeratio'
        )
      ) {
        targetMapPreferences.tm_death_incomereplacement_earnedincomeratio =
          parseInt(
            targetMapPreferences.tm_death_incomereplacement_earnedincomeratio
          );
      }
      const defaultTab = MatchSelectItem(
        defaultTabOptions,
        targetMapPreferences.tm_default_tab
      );
      setTmPreferences({ ...targetMapPreferences, tm_default_tab: defaultTab });
    }
  }, [targetMapPreferences]);

  useEffect(() => {
    if (tmPrefError) {
      if (tmPrefError.hasOwnProperty('data') && tmPrefError.data) {
        setTmPreferencesErrors(tmPrefError.data);
      }
    } else {
      setTmPreferencesErrors(null);
    }
  }, [tmPrefError]);

  const saveTmPrefs = () => {
    dispatch(
      updateTargetMapPreferences({
        ...tmPreferences,
        tm_default_tab: tmPreferences.tm_default_tab?.value,
      })
    );
  };

  const preferencesOnChange = (e, name = null) => {
    if (name) {
      setTmPreferences({
        ...tmPreferences,
        [name]: e,
      });
    } else {
      setTmPreferences({
        ...tmPreferences,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const onValueChange = (values, input) => {
    setTmPreferences({
      ...tmPreferences,
      [input.name]: values.floatValue,
    });
  };

  const generateTitle = (value) => {
    const matched = tabs.find((tab) => tab.value === value);
    if (matched) {
      return matched.text;
    } else {
      return value;
    }
  };

  const generateText = (value) => {
    switch (value) {
      case 'loss':
        return 'the survivors of a lost household member';
      case 'disability':
        return `a household member's disability event`;
      case 'ltc':
        return `a household member's long-term care event`;
      case 'dependent':
        return `a dependent's education need`;
      case 'retirement':
        return 'the selected household members Retirement plan';
      default:
        return value;
    }
  };

  const generateDetailsText = (value) => {
    const matched = tabs.find((tab) => tab.value === value);
    if (matched) {
      return `The ${
        matched.text
      } Template attempts to pre-fill standard funding requirements for ${generateText(
        value
      )} based on the financial professional's preferences. <br/> <strong>Toggle the checkboxes next to the titles below to include (checked) or exclude (unchecked) those fields on the Target-Map calculations.</strong>`;
    } else {
      return value;
    }
  };

  const generalInputs = [
    {
      label: 'Expected Net Return on Capital',
      name: 'tm_growth_rate',
      hasSuffix: true,
    },
    {
      label: 'Annual Increase of Contributions',
      name: 'tm_inflation_rate',
      hasSuffix: true,
    },
    {
      label: 'Retirement Age',
      name: 'tm_age_retirement',
      hasSuffix: true,
      suffixValue: 'yrs',
    },
    {
      label: 'Mortality Age',
      name: 'tm_age_mortality',
      hasSuffix: true,
      suffixValue: 'yrs',
    },
    {
      label: 'Default Tab',
      name: 'tm_default_tab',
      isSelect: true,
      tooltip: `Choose the default tab to be displayed when you enter a target-map`,
      options: defaultTabOptions,
    },
  ];

  const financialSourcesChunks = [
    {
      label: 'Cash Flow Sources Available',
      color: colors.lightBlue,
      inputs: [
        {
          label: `Earned Income`,
          name: 'tm_tax_income_earned',
          hasSuffix: true,
        },
        {
          label: `Social Security`,
          name: 'tm_tax_income_socialsecurity',
          hasSuffix: true,
        },
        {
          label: 'Unearned Income',
          name: 'tm_tax_income_unearned',
          hasSuffix: true,
        },
      ],
    },
    {
      label: 'Assets Available',
      color: colors.green,
      inputs: [
        {
          label: `Retirement (Qualified)`,
          name: 'tm_tax_asset_retirement',
          hasSuffix: true,
        },
        {
          label: `Roth`,
          name: 'tm_tax_asset_roth',
          hasSuffix: true,
        },
        {
          label: `Non-retirement (Non-Qualified)`,
          name: 'tm_tax_asset_nonretirement',
          hasSuffix: true,
        },
        {
          label: `Non-qualified Annuity`,
          name: 'tm_tax_asset_nonretirement_annuities',
          hasSuffix: true,
        },
        {
          label: `529`,
          name: 'tm_tax_asset_529',
          hasSuffix: true,
        },
        {
          label: `Life Ins Cash Value`,
          name: 'tm_tax_asset_cash_value_life',
          hasSuffix: true,
        },
      ],
    },
    {
      label: 'Insurances Available',
      color: colors.blue,
      inputs: [
        {
          label: `Taxable LTD`,
          name: 'tm_tax_insurance_group_ltd',
          hasSuffix: true,
        },
        {
          label: `Tax-Free LTD`,
          name: 'tm_tax_insurance_individual_ltd',
          hasSuffix: true,
        },
        {
          label: `Qualified LTC`,
          name: 'tm_tax_insurance_tax_qualified_ltc',
          hasSuffix: true,
        },
        {
          label: `Non-qualified LTC`,
          name: 'tm_tax_insurance_not_tax_qualified_ltc',
          hasSuffix: true,
        },
      ],
    },
  ];

  const lossContent = [
    {
      label: 'Income Replacement',
      name: 'tm_death_incomereplacement_enabled',
      inputs: [
        {
          label: `Lost Member Income Replacement`,
          name: 'tm_death_incomereplacement_earnedincomeratio',
          hasSuffix: true,
          tooltip: `Percentage of the lost member's gross income that will be replaced, after-tax`,
        },
        {
          label: `Income Replacement Stop Age`,
          name: 'tm_death_incomereplacement_endingage',
          hasSuffix: true,
          suffixValue: 'yrs',
          tooltip:
            'Replacement income set to stop at the age of the lost member',
        },
        {
          label: 'Cost of Living Adjustment',
          name: 'tm_death_incomereplacement_cola',
          hasSuffix: true,
        },
      ],
    },
    {
      label: 'Transitional Income Replacement',
      name: 'tm_death_transitionalincome_enabled',
      inputs: [
        {
          label: `Percentage Member Income Replacement`,
          name: 'tm_death_transitionalincome_currentincomeratio',
          hasSuffix: true,
        },
        {
          label: `Duration in Years`,
          name: 'tm_death_transitionalincome_duration',
          hasSuffix: true,
          suffixValue: 'yrs',
        },
        {
          label: 'Cost of Living Adjustment',
          name: 'tm_death_transitionalincome_cola',
          hasSuffix: true,
        },
      ],
    },
    {
      label: 'Debt Obligations',
      name: 'tm_death_debtobligations_enabled',
      inputs: [
        {
          label: `Liabilities of Household to be paid`,
          name: 'tm_death_debtobligations_householdliabilitiesratio',
          hasSuffix: true,
        },
      ],
    },
    {
      label: 'Final Expenses (Burial, last expenses, party, etc.)',
      name: 'tm_death_finalexpenses_enabled',
      inputs: [
        {
          label: `Amount`,
          name: 'tm_death_finalexpenses_amount',
          type: 'currency',
        },
      ],
    },
    {
      label: 'Emergency Reserves',
      name: 'tm_death_emergencyreserves_enabled',
      inputs: [
        {
          label: `Household Earned Income`,
          hasSuffix: true,
          name: 'tm_death_emergencyreserves_earnedincomeratio',
          tooltip:
            'After-tax, spendable reserve as a % of the total Household Earned Income. (i.e. 50% = 6 months net income, 25% = 3 months',
        },
      ],
    },
    {
      label: 'Legacy / Bequests',
      name: 'tm_death_legacy_enabled',
      inputs: [
        {
          label: `Household Net Worth`,
          name: 'tm_death_legacy_networth',
          hasSuffix: true,
          tooltip:
            '% of Household Net Worth set aside to establish a financial legacy',
        },
      ],
    },
    {
      label: 'Education Funding',
      link: 'See "Dependent Education" Link',
    },
  ];

  const disabilityContent = [
    {
      label: 'Earned Income Replacement',
      name: 'tm_disability_incomereplacement_enabled',
      inputs: [
        {
          label: `Disabled Member's Earned Income Replacement`,
          name: 'tm_disability_incomereplacement_currentincomeratio',
          hasSuffix: true,
          tooltip: `Percentage of the disabled member's gross earned income that will be replaced, after-tax`,
        },
        {
          label: `Until Disabled Member's Age`,
          name: 'tm_disability_incomereplacement_endingage',
          hasSuffix: true,
          suffixValue: 'yrs',
          tooltip:
            'Replacement Income will continue until disabled member reaches this age',
        },
        {
          label: 'Cost of Living Adjustment',
          name: 'tm_disability_incomereplacement_cola',
          hasSuffix: true,
        },
      ],
    },
    {
      label: 'Lost Savings Replacement',
      name: 'tm_disability_lostretirement_enabled',
      inputs: [
        {
          label: `Lost Retirement Savings`,
          name: 'tm_disability_lostretirement_currentincomeratio',
          hasSuffix: true,
          tooltip:
            'Percentage of member’s earnings (pre-disability) needed to replicate would-be retirement savings.',
        },
        {
          label: `Until Disabled Member's Age`,
          name: 'tm_disability_lostretirement_endingage',
          hasSuffix: true,
          suffixValue: 'yrs',
          tooltip:
            'Lost retirement replacement will continue until disabled member reaches this age',
        },
        {
          label: 'Cost of Living Adjustment',
          name: 'tm_disability_lostretirement_cola',
          hasSuffix: true,
        },
      ],
    },
  ];

  const ltcContent = [
    {
      label: 'Long-term Care Annual Expenses',
      name: 'tm_ltc_annualcare_enabled',
      inputs: [
        {
          label: `Net Spendable Amount`,
          name: 'tm_ltc_annualcare_amount',
          type: 'currency',
        },
        {
          label: `Duration of LTC Event in Years`,
          name: 'tm_ltc_annualcare_years',
          hasSuffix: true,
          suffixValue: 'yrs',
        },
        {
          label: 'Cost of Living Adjustment',
          name: 'tm_ltc_annualcare_cola',
          hasSuffix: true,
        },
      ],
    },
    {
      label: 'Home Modification',
      name: 'tm_ltc_homemod_enabled',
      inputs: [
        {
          label: `One-time amount`,
          name: 'tm_ltc_homemod_amount',
          type: 'currency',
        },
      ],
    },
    {
      label: 'Care Coordination',
      name: 'tm_ltc_carecoordination_enabled',
      inputs: [
        {
          label: `One-time amount`,
          name: 'tm_ltc_carecoordination_amount',
          type: 'currency',
          tooltip:
            'The cost of administrative help to deal with the coordination of benefits',
        },
      ],
    },
    {
      label: 'Caregiver Training',
      name: 'tm_ltc_caretraining_enabled',
      inputs: [
        {
          label: `One-time amount`,
          name: 'tm_ltc_caretraining_amount',
          type: 'currency',
          tooltip: 'The cost of training of family to care for loved one',
        },
      ],
    },
  ];

  const dependentEducationContent = [
    {
      label: 'Dependent Education Expenses',
      name: 'tm_edu_edufund_enabled',
      inputs: [
        {
          label: `Annual Spending (Today's Value)`,
          name: 'tm_edu_edufund_amount',
          type: 'currency',
        },
        {
          label: 'Cost of Living Adjustment',
          name: 'tm_edu_edufund_cola',
          hasSuffix: true,
        },
        {
          label: 'Starting Age',
          name: 'tm_edu_edufund_startage',
          hasSuffix: true,
          suffixValue: 'yrs',
        },
        {
          label: 'Ending Age',
          name: 'tm_edu_edufund_endage',
          hasSuffix: true,
          suffixValue: 'yrs',
        },
      ],
    },
  ];

  const retirementContent = [
    {
      label: 'Living Expenses',
      name: 'tm_retire_living_enabled',
      inputs: [
        {
          label: `Percentage of PRIMARY (and SPOUSE/PARTNER) Gross Income`,
          name: 'tm_retire_living_householdincomeratio',
          hasSuffix: true,
          tooltip: `The percentage of the member's gross income needed for retirement living expenses, after-tax`,
        },
        {
          label: `Starting Age`,
          name: 'tm_retire_living_startage',
          hasSuffix: true,
          suffixValue: 'yrs',
        },
        {
          label: 'Ending Age',
          name: 'tm_retire_living_endage',
          hasSuffix: true,
          suffixValue: 'yrs',
        },
        {
          label: 'Cost of Living Adjustment',
          name: 'tm_retire_living_cola',
          hasSuffix: true,
        },
      ],
    },
    {
      label: 'Travel Expenses',
      name: 'tm_retire_travel_enabled',
      inputs: [
        {
          label: `Percentage of PRIMARY (and SPOUSE/PARTNER) Gross Income`,
          name: 'tm_retire_travel_householdincomeratio',
          hasSuffix: true,
          tooltip: `The percentage of the member's gross income needed for travel expenses during retirement, after-tax`,
        },
        {
          label: `Starting Age`,
          name: 'tm_retire_travel_startage',
          hasSuffix: true,
          suffixValue: 'yrs',
        },
        {
          label: 'Ending Age',
          name: 'tm_retire_travel_endage',
          hasSuffix: true,
          suffixValue: 'yrs',
        },
        {
          label: 'Cost of Living Adjustment',
          name: 'tm_retire_travel_cola',
          hasSuffix: true,
        },
      ],
    },
    {
      label: 'Medical Expenses',
      name: 'tm_retire_medical_enabled',
      inputs: [
        {
          label: `Percentage of PRIMARY (and SPOUSE/PARTNER) Gross Income`,
          name: 'tm_retire_medical_householdincomeratio',
          hasSuffix: true,
          tooltip: `The percentage of the member's gross income needed for expected medical expenses during retirement, after-tax`,
        },
        {
          label: `Starting Age`,
          name: 'tm_retire_medical_startage',
          hasSuffix: true,
          suffixValue: 'yrs',
        },
        {
          label: 'Ending Age',
          name: 'tm_retire_medical_endage',
          hasSuffix: true,
          suffixValue: 'yrs',
        },
        {
          label: 'Cost of Living Adjustment',
          name: 'tm_retire_medical_cola',
          hasSuffix: true,
        },
      ],
    },
  ];

  const generateInputsContent = (value) => {
    if (value === 'loss') {
      return lossContent;
    }
    if (value === 'disability') {
      return disabilityContent;
    }
    if (value === 'ltc') {
      return ltcContent;
    }
    if (value === 'dependent') {
      return dependentEducationContent;
    }
    if (value === 'retirement') {
      return retirementContent;
    }
    return [];
  };

  const checkHasError = (name) => {
    if (tmPreferencesErrors) {
      if (tmPreferencesErrors[name]) {
        return true;
      }
    }
    return false;
  };

  const generateError = (name) => {
    if (tmPreferencesErrors) {
      if (tmPreferencesErrors[name]) {
        return tmPreferencesErrors[name][0];
      }
    }
    return 'Error';
  };

  const generateCurrencyInput = (input, currency) => {
    const prefixValue =
      currency?.symbol_location === 'prefix' ? currency.symbol : '';
    const suffixValue =
      currency?.symbol_location === 'suffix' ? currency.symbol : '';

    return (
      <NumericFormat
        thousandSeparator={currency?.thousand_separator}
        decimalSeparator={currency?.decimal}
        prefix={prefixValue ? `${prefixValue} ` : ''}
        suffix={suffixValue ? ` ${suffixValue}` : ''}
        allowNegative={true}
        className="number-formatter"
        inputMode="numeric"
        value={tmPreferences[input.name]}
        placeholder={'0'}
        onValueChange={(values) => onValueChange(values, input)}
        style={
          checkHasError(input.name)
            ? { border: `1px solid ${messageColors.error}` }
            : null
        }
      />
    );
  };

  const generateLabel = (input) => {
    return (
      <InputLabel>
        {input.label}
        {(input.tooltip || checkHasError(input.name)) && (
          <Tooltip
            title={
              checkHasError(input.name)
                ? generateError(input.name)
                : input.tooltip
            }
            position="top"
            trigger="mouseenter click"
            followCursor="true"
            delay={0}
            arrow={true}
            arrowSize={'small'}
            animation={'fade'}
            animateFill={false}
            transitionFlip={false}
            size={'small'}
          >
            {checkHasError(input.name) ? (
              <TooltipIconError
                src={errorCircleRed}
                alt="error"
                data-image="input-error"
              />
            ) : (
              <TooltipIcon src={hint} alt="hint" data-image="input-hint" />
            )}
          </Tooltip>
        )}
      </InputLabel>
    );
  };

  const generateInput = (input, index) => {
    if (input.isSelect) {
      return (
        <SelectContainer key={index} width="192px">
          {generateLabel(input)}
          <Select
            selectedOption={tmPreferences[input.name]}
            onChange={(e) => preferencesOnChange(e, input.name)}
            options={input.options}
            isSearchable={true}
            placeholder={input.label}
            autoFocus={false}
            hasError={checkHasError(input.name)}
            closeMenuOnSelect={true}
          />
        </SelectContainer>
      );
    } else {
      return (
        <TextInputContainer key={index}>
          {generateLabel(input)}
          <TextInput
            type={'number'}
            name={input.name}
            maxLength={input.maxLength}
            hasError={checkHasError(input.name)}
            required={false}
            placeholder={'0'}
            hasSuffix={input.hasSuffix}
            suffixValue={input.suffixValue}
            value={tmPreferences[input.name]}
            onChange={(e) => preferencesOnChange(e)}
          />
        </TextInputContainer>
      );
    }
  };

  return (
    <Page>
      <PageHeader content={headerContent.tmPref} />
      <TabMenu tabs={tabs} activeTab={activeTab} onClick={setActiveTab} />
      {activeTab === 'general' ? (
        <PageContent>
          <SecondaryHeading>{generateTitle(activeTab)}</SecondaryHeading>
          <DetailsText>
            The General Settings establish overall defaults for new Target-Map
            Reports. The Age of Retirement and Mortality also affect the Earned
            and Unearned Income Financials that are associated with a Household.
          </DetailsText>
          <ContentContainer>
            <TopContainer>
              <ChunkHeading>Target-Map Defaults</ChunkHeading>
              <ChunkText>
                The Target-Map Defaults are the essential variables that are the
                foundation for the beginning of any Target-Map Templates. It is
                recommended that the financial professional update these based
                on their average client&apos;s needs. The defaults may be
                adjusted on a per case basis by editing specific Target-Map
                Reports.
              </ChunkText>
              <TextInputsRow>
                {generalInputs.map((input, index) => {
                  return generateInput(input, index);
                })}
              </TextInputsRow>
            </TopContainer>
            <BottomContainer>
              <ChunkHeading>
                Financial Sources Available: Percent Lost to Taxes
              </ChunkHeading>
              <ChunkText style={{ marginBottom: '20px' }}>
                The Amount Lost to Taxes is the percentage of the gross value of
                a financial instrument that will effectively be lost to taxes.
                Since gross values are commonly used in the Asset-Map and
                financial statements, a reduction of that gross value is
                necessary to account for the embedded tax obligation that is due
                upon consumption. This figure is not necessarily equal to the
                marginal tax rate but rather an intelligent accommodation to the
                amount of unpaid taxes embedded in the gross value that should
                be discounted for purposes of consumption.
              </ChunkText>
              {financialSourcesChunks.map((chunk, index) => {
                return (
                  <ContentChunkContainer key={index}>
                    <ContentChunkHeading>
                      <ColorSquare style={{ background: chunk.color }} />
                      {chunk.label}
                    </ContentChunkHeading>
                    <TextInputsRow>
                      {chunk.inputs.map((input, index) => {
                        return generateInput(input, index);
                      })}
                    </TextInputsRow>
                  </ContentChunkContainer>
                );
              })}
            </BottomContainer>
          </ContentContainer>
        </PageContent>
      ) : (
        <PageContent>
          <SecondaryHeading>{generateTitle(activeTab)}</SecondaryHeading>
          <DetailsText
            dangerouslySetInnerHTML={{ __html: generateDetailsText(activeTab) }}
          />
          <ContentContainer>
            {generateInputsContent(activeTab).map((chunk, index) => {
              return (
                <ContentChunkContainer key={index}>
                  {chunk.name ? (
                    <HeadingCheckbox>
                      <HeadingClickContainer
                        onClick={() =>
                          setTmPreferences({
                            ...tmPreferences,
                            [chunk.name]: !tmPreferences[chunk.name],
                          })
                        }
                      >
                        {tmPreferences[chunk.name] ? (
                          <CheckBoxImage src={checkboxGreen} alt="checked" />
                        ) : (
                          <CheckBoxImage src={square} alt="empty" />
                        )}
                        <CheckBoxLabel>{chunk.label}</CheckBoxLabel>
                      </HeadingClickContainer>
                    </HeadingCheckbox>
                  ) : (
                    <HeadingCheckbox>
                      <CheckBoxLabel>{chunk.label}</CheckBoxLabel>
                    </HeadingCheckbox>
                  )}
                  {chunk.link ? (
                    <ChunkLink onClick={() => setActiveTab('dependent')}>
                      {chunk.link}
                    </ChunkLink>
                  ) : (
                    <TextInputsRow>
                      {chunk.inputs.map((input, index) => {
                        return (
                          <TextInputContainer key={index}>
                            {generateLabel(input)}
                            {input.type === 'currency' ? (
                              generateCurrencyInput(input, userCurrency)
                            ) : (
                              <TextInput
                                type={input.type ? input.type : 'number'}
                                name={input.name}
                                maxLength={input.maxLength}
                                hasError={checkHasError(input.name)}
                                required={false}
                                placeholder={'0'}
                                hasSuffix={input.hasSuffix}
                                suffixValue={input.suffixValue}
                                currency={userCurrency}
                                value={tmPreferences[input.name]}
                                onChange={(e) => preferencesOnChange(e)}
                              />
                            )}
                          </TextInputContainer>
                        );
                      })}
                    </TextInputsRow>
                  )}
                </ContentChunkContainer>
              );
            })}
          </ContentContainer>
        </PageContent>
      )}
      <ButtonRowContainer>
        <Button text="Save Changes" onClick={() => saveTmPrefs()} />
      </ButtonRowContainer>
    </Page>
  );
};

const HeadingCheckbox = styled.div`
  padding: 3px 5px;
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const HeadingClickContainer = styled.span`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const CheckBoxLabel = styled.span`
  flex: 0 0 auto;
`;

const DetailsText = styled.p`
  font-size: 12px;
  margin-bottom: 20px;
  color: ${colors.paleGrey};
`;

const ContentContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: flex-start;
`;

const ContentChunkContainer = styled.div`
  width: 100%;
  border-bottom: thin solid ${colors.lightGrey};
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const ContentChunkHeading = styled.div`
  padding: 3px 4px;
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const ChunkHeading = styled.h4`
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  margin-bottom: 5px;
`;

const ColorSquare = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 8px;
`;

const ChunkText = styled.h4`
  font-size: 12px;
  color: ${colors.paleGrey};
  max-width: 1000px;
`;

const TopContainer = styled.div`
  flex: 1 1 auto;
  margin-bottom: 35px;
  width: 100%;
`;

const BottomContainer = styled.div`
  flex: 1 1 auto;
`;

const InputLabel = styled.label`
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
  max-width: 300px;
  margin: 0 0 2px 2px;
  font-size: 12px;
`;

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 0 0 150px;
  width: 150px;
  margin: 5px 5px;
  .number-formatter {
    background: white;
    border: 1px solid ${inputColors.border};
    color: ${colors.darkGrey};
    padding: 9.5px 15px;
    border-radius: 6px;
    flex: 1 1 auto;
    &::placeholder {
      color: ${colors.paleGrey};
      opacity: 1;
    }
  }
`;

const ChunkLink = styled.div`
  color: ${colors.lightBlue};
  font-size: 13px;
  padding-left: 5px;
  cursor: pointer;
  &:hover {
    color: ${colors.blue};
  }
`;

const TextInputsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  ${TextInputContainer} {
    width: 100%;
    margin: 10px 5px 10px 0;
    flex: 1 1 auto;
    max-width: 220px;
  }
`;

const TooltipIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-left: 5px;
  margin-bottom: -1px;
  opacity: 0.75;
`;

const TooltipIconError = styled(TooltipIcon)`
  opacity: 1;
`;

// const InputRowContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   align-items: flex-end;
//   align-content: center;
//   justify-content: flex-start;
//   width: 100%;
//   /* margin-left: 25px; */
// `;

// const InputsContainer = styled.div`
//   max-width: 350px;
//   flex: 1 1 auto;
//   ${TextInputContainer} {
//     width: 100%;
//     margin: 10px 5px;
//     ${InputLabel} {
//       max-width: 200px;
//     }
//   }
// `;

// const LeftInputsContainer = styled(InputsContainer)``;

// const RightInputsContainer = styled(InputsContainer)``;

const CheckBoxImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const ButtonRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  text-align: right;
  padding: 20px 40px 80px 40px;
`;

export default TargetMapPreferences;
